import React, { useContext } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./ItineraryHeader.css";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";
import CircleButton from "../../../Reusable/CircleButton/CircleButton";

function ItineraryHeader({
  onClickEdit,
  onClickSave,
  isEditMode,
  isIndividualMode,
}) {
  const { state, actions } = useContext(ItineraryContext);

  const itineraryData = state.itinerary.data;
  const travellerData = state.traveller.data;

  const onClickMessagesChecked = (e) => {
    actions.approveJourneyScheduledArticles(state.traveller.data.id);
  };

  const onClickMessagesUnchecked = (e) => {
    actions.rejectJourneyScheduledArticles(state.traveller.data.id);
  };

  return (
    <div className="itinerary-header">
      <div className="itinerary-header__title">
        {isIndividualMode ? (
          <div className="itinerary-header__ind-title">
            <CircleButton
              type="prev"
              href={`/itineraries/${travellerData.itinerary_id}`}
            />
            {travellerData.conversation && (
              <div className="itinerary-header__full-name">
                {travellerData.conversation.traveller.full_name}
              </div>
            )}
          </div>
        ) : (
          <div className="itinerary-header__title">
            <CircleButton type="prev" href="/itineraries" />
            <div className="itinerary-header__dossier-id">
              {itineraryData?.dossier_id && (
                <div># {itineraryData.dossier_id}</div>
              )}
            </div>
            {itineraryData?.task &&
              itineraryData.task.map((tripTag, key) => (
                <div
                  className={classnames(
                    "itinerary-header__tag",
                    tripTag.trip_action.tag
                  )}
                  key={key}
                >
                  {tripTag.trip_action.tag}
                </div>
              ))}
          </div>
        )}
        <div className="itinerary-header__status">
          {isIndividualMode ? (
            <div
              className={classnames(
                "itinerary-header__status-title",
                travellerData?.itinerary?.status_label?.title
              )}
            >
              {travellerData?.itinerary?.status_label?.title}
            </div>
          ) : (
            <div
              className={classnames(
                "itinerary-header__status-title",
                itineraryData?.status_label?.title
              )}
            >
              {itineraryData?.status_label?.title}
            </div>
          )}
        </div>
      </div>

      <div className="itinerary-header__btn">
        {(isIndividualMode && travellerData?.conversation )&& (
          <>
            {travellerData?.conversation?.messages_checked === "Checked" ? (
              <RectangleButton
                className="itinerary-header__unchecked-btn"
                type="submit"
                label="Mark messages as unchecked"
                onClick={onClickMessagesUnchecked}
              />
            ) : (
              <RectangleButton
                className="itinerary-header__checked-btn"
                type="submit"
                label="Mark messages as checked"
                onClick={onClickMessagesChecked}
              />
            )}
          </>
        )}

        {!isEditMode && (
          <RectangleButton
            isHighlight
            label="Edit itinerary"
            onClick={onClickEdit}
          />
        )}
        {isEditMode && (
          <RectangleButton
            isHighlight
            type="submit"
            label="Save"
            onClick={onClickSave}
          />
        )}
      </div>
    </div>
  );
}

ItineraryHeader.propTypes = {
  onClickEdit: PropTypes.func,
  onClickSave: PropTypes.func,
  isEditMode: PropTypes.bool,
  isIndividualMode: PropTypes.bool,
};

export default withRouter(ItineraryHeader);
