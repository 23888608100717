import React, {useState, useContext, useEffect} from "react";
import {Form} from "react-bootstrap";
import {AppContext} from "../../../store/context/AppContext";
import PropTypes from "prop-types";
import Select from "react-select";
import AddTypeModal from "../TipForm/AddTypeModal";
import AddTagModal from "../TipForm/AddTagModal";

import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

import "./TipForm.css";
import {getToken} from "../../../utils/auth";
import SelectRequired from "../../Reusable/SelectRequired/RequiredSelect";

function TipForm({button, data, onTipSubmit}) {
    const {state: AppState} = useContext(AppContext);
    const [tip, setTip] = useState(data);
    const [buttonData] = useState(button);
    const [isAddTypeModalShown, setIsAddTypeModalShown] = useState(false);
    const [isAddTagModalShown, setIsAddTagModalShown] = useState(false);

    useEffect(() => {
        // add specific options for types and tags
        if(getToken().is_admin) {
            if(typeof AppState.inputs.typesTips[AppState.inputs.typesTips.length - 1] !== "undefined"
                && AppState.inputs.typesTips[AppState.inputs.typesTips.length - 1].value !== 'new_type') {
                AppState.inputs.typesTips.push({value: 'new_type',
                    label: <div>
                        <button onClick={openAddTypeModal} className="circle-button  circle-button--add d-inline"></button>
                    </div>
                });
            }

            if(typeof AppState.inputs.tags[AppState.inputs.tags.length - 1] !== "undefined"
                && AppState.inputs.tags[AppState.inputs.tags.length - 1].id !== 'new_tag') {
                AppState.inputs.tags.push({id: 'new_tag',
                    tag: <div>
                        <button onClick={openAddTagModal} className="circle-button  circle-button--add"></button>
                    </div>
                });
            }
        }
    }, [AppState.inputs.typesTips, AppState.inputs.tags]);

  const onChangeTip = (e) => {
    setTip({ ...tip, [e.target.name]: e.target.value });
  };

  const onChangeCheckbox = (e) => {
    setTip({ ...tip, [e.target.name]: e.target.checked });
  };

  const onSubmitTip = (e) => {
    e.preventDefault();
    if (onTipSubmit) onTipSubmit(tip);
  };

    const openAddTypeModal = (e) => {
        setIsAddTypeModalShown(true);
    };

    const openAddTagModal = (e) => {
        setIsAddTagModalShown(true);
    };

    const closeAddType = () => {
        setIsAddTypeModalShown(false);
    };

    const closeAddTag = () => {
        setIsAddTagModalShown(false);
    };

    const setType = (tip, data) => {
        if(data.value !== 'new_type') {
            setTip({
                ...tip,
                type: data,
            })
        }
    };

    const setLanguage = (tip, data) => {
        setTip({
            ...tip,
            language: data,
            language_id: data.id,
        })
    };

    const SelectReq = props => (
        <SelectRequired
            {...props}
            SelectComponent={Select}
            required
        />
    );

    const setTag = (tip, data) => {
        if(data != null)
        {
            if((typeof data[data.length - 1] !== "undefined" && data[data.length - 1].id !== 'new_tag') || data.length === 0) {
                setTip({
                    ...tip,
                    tags: data,
                })
            }
        }
    };


    return (
        <div>
            <Form className="tip-form" onSubmit={onSubmitTip}>
                <Form.Label className="tip-form__title">NAME*</Form.Label>
                <Form.Control
                    required
                    name="title"
                    type="text"
                    placeholder="Add a title"
                    value={tip.title}
                    onChange={onChangeTip}
                />
                <Form.Label className="tip-form__title">LANGUAGE*</Form.Label>
                <SelectReq
                    name="language_id"
                    placeholder="select language"
                    options={AppState.inputs.languages}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => `${option.id}`}
                    value={tip.language}
                    onChange={(data) => setLanguage(tip, data)}
                />
                <Form.Label className="tip-form__title">TYPE*</Form.Label>
                <SelectReq
                    name="type"
                    placeholder="select type"
                    options={AppState.inputs.typesTips}
                    value={tip.type}
                    onChange={(data) => setType(tip, data)}
                />
                <Form.Label className="tip-form__title">TAGS</Form.Label>
                <Select
                    isMulti
                    name="tags"
                    placeholder="select tags"
                    options={AppState.inputs.tags}
                    getOptionLabel={(option) => option.tag}
                    value={tip.tags}
                    getOptionValue={(option) => `${option.id}`}
                    onChange={(data) => setTag(tip, data)}
                />
                <Form.Label className="tip-form__title">ADDRESS*</Form.Label>
                <Form.Control
                    name="address"
                    type="text"
                    placeholder="Add address or location"
                    value={tip.address}
                    onChange={onChangeTip}
                    required
                />
                <Form.Label className="tip-form__title">MESSAGE</Form.Label>
                <Form.Control
                    name="body"
                    as="textarea"
                    rows="3"
                    type="text"
                    placeholder="Draft a message for this tip"
                    value={tip.body}
                    onChange={onChangeTip}
                />
                <Form.Label className="tip-form__title">OPENING HOURS</Form.Label>
                <Form.Control
                    name="opening_hours"
                    as="textarea"
                    rows="3"
                    type="text"
                    placeholder="Add opening hours"
                    value={tip.opening_hours || ""}
                    onChange={onChangeTip}
                />
                <Form.Label className="tip-form__title">GOOGLEMAP LINK *</Form.Label>
                <Form.Control
                    required
                    name="maps_url"
                    type="text"
                    placeholder="Add the Google Map link"
                    value={tip.maps_url}
                    onChange={onChangeTip}
                />
                <Form.Label className="tip-form__title">NOTE</Form.Label>
                <Form.Control
                    type="text"
                    name="remarks"
                    placeholder="Add a note"
                    value={tip.remarks || ""}
                    onChange={onChangeTip}
                />
                <div className="tip-form__draft">
                    <input type="checkbox" name="draft" onChange={onChangeCheckbox}/>
                    <Form.Label className="tip-form__title tip-form__title--draft ">
                        SAVE AS DRAFT
                    </Form.Label>
                </div>
                <div className="tip-form__btns">
                    {buttonData && (
                        <RectangleButton
                            className={buttonData.className}
                            onClick={buttonData.action}
                            label={buttonData.label}
                        />
                    )}
                    <RectangleButton type="submit" label="Save"/>
                </div>
            </Form>

            <AddTypeModal show={isAddTypeModalShown} onHide={closeAddType}/>
            <AddTagModal show={isAddTagModalShown} onHide={closeAddTag}/>

        </div>
    );
}

TipForm.propTypes = {
  button: PropTypes.object,
  data: PropTypes.object,
  onTipSubmit: PropTypes.func.isRequired,
};

export default TipForm;
