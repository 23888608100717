import { getToken } from "../../utils/auth";
import conversationIcon from "../../../src/images/no-assignee.png";

const initialState = {
    conversations: [],
    messageTemplates: [],
    agentTemplates: [],
    preferences: [],
    analysisStatuses: [],
    tripProgresses: [],
    feedbackSelect: [],
    csq: [],
    retention: [],
    lead_status: [],
    messageTags: [],
    messageTemplateTags: [],
    managerList: [],
    reactions: [],
    currentConversation: [],
    conversationTaskList: [],
    userPreferences: [],
    journeyScheduledArticles: [],
    analysisData: {},
    analysisStoreResponseData: [],
    currentPageOpen: 1,
    currentPageClosed: 1,
    currentMessagePage: 1,
    unreadMessagesCount: 0,
    branchId: null,
    agents: [],
    searchDossierIdResult: [],
    errors: "",
    uploadingMoreOpenConversations: false,
    uploadingMoreClosedConversations: false,
    success: null,
    itineraries: [],
    journeys: [],
    cities: [],
    filteredTravellers: [],
    filteredConversations: [],
    accommodations: [],
    itineraryProducts: [],
    bulkMessages: {
        data:[],
        paginate: []
    },
    languages: [],
    validationErrors: {},

};

const types = {
    FETCH_CONVERSATIONS: "FETCH_CONVERSATIONS",
    FETCH_MESSAGE_TEMPLATES: "FETCH_MESSAGE_TEMPLATES",
    FETCH_AGENT_TEMPLATES: "FETCH_AGENT_TEMPLATES",
    FETCH_PREFERENCES: "FETCH_PREFERENCES",
    FETCH_ANALYSIS_STATUS: "FETCH_ANALYSIS_STATUS",
    FETCH_MESSAGE_TAGS: "FETCH_MESSAGE_TAGS",
    FETCH_TEMPLATE_MESSAGE_TAGS: "FETCH_TEMPLATE_MESSAGE_TAGS",
    FETCH_MANAGER_LIST: "FETCH_MANAGER_LIST",
    FETCH_REACTIONS: "FETCH_REACTIONS",
    FETCH_CURRENT_CONVERSATION: "FETCH_CURRENT_CONVERSATION",
    FETCH_CONVERSATION_TASK_LIST: "FETCH_CONVERSATION_TASK_LIST",
    FETCH_USER_PREFERENCES: "FETCH_USER_PREFERENCES",
    DELETE_USER_PREFERENCE: "DELETE_USER_PREFERENCE",
    ADD_USER_PREFERENCE: "ADD_USER_PREFERENCE",
    FETCH_JOURNEY_SCHEDULED_ARTICLES: "FETCH_JOURNEY_SCHEDULED_ARTICLES",
    CLEAR_JOURNEY_SCHEDULED_ARTICLES: "CLEAR_JOURNEY_SCHEDULED_ARTICLES",
    FETCH_ANALYSIS_DATA: "FETCH_ANALYSIS_DATA",
    STORE_ANALYSIS_ERROR_DATA: "STORE_ANALYSIS_ERROR_DATA",
    CLEAR_ANALYSIS_DATA: "CLEAR_ANALYSIS_DATA",
    ADD_NEW_MESSAGE: "ADD_NEW_MESSAGE",
    DELETE_MESSAGE: "DELETE_MESSAGE",
    TAG_MESSAGE: "TAG_MESSAGE",
    REACTION: "REACTION",
    ASSIGN_TASK: "ASSIGN_TASK",
    UNASSIGN_TASK: "UNASSIGN_TASK",
    FETCH_AGENTS: "FETCH_AGENTS",
    SEARCH_DOSSIER_ID: "SEARCH_DOSSIER_ID",
    SHOW_ERRORS: "SHOW_ERRORS",
    FETCH_MORE_MESSAGES: "FETCH_MORE_MESSAGES",
    EDIT_TRAVELER_NAME: "EDIT_TRAVELER_NAME",
    SHOW_SUCCESS: "SHOW_SUCCESS",
    FETCH_ITINERARIES: "FETCH_ITINERARIES",
    FETCH_JOURNEYS: "FETCH_JOURNEYS",
    FETCH_CITIES: "FETCH_CITIES",
    FETCH_FILTERED_TRAVELLERS: "FETCH_FILTERED_TRAVELLERS",
    FETCH_FILTERED_CONVERSATIONS: 'FETCH_FILTERED_CONVERSATION',
    FETCH_ACCOMMODATIONS: "FETCH_ACCOMMODATIONS",
    FETCH_ITINERARY_PRODUCTS: "FETCH_ITINERARY_PRODUCTS",
    FETCH_BULK_MESSAGES: "FETCH_BULK_MESSAGES",
    FETCH_LANGUAGES: "FETCH_LANGUAGES",
    FETCH_VALIDATION_ERROR: "FETCH_VALIDATION_ERROR"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_CONVERSATIONS:
            let refHeight = 0;
            if (action.payload?.ref) {
                refHeight = action.payload.ref.scrollHeight;
            }
            if (action.payload.conversationType === '') {
                state.conversations = [];
                state.conversations = action.payload.data;
            }

            if (action.payload.conversationType === '/open') {
                state.conversations.filtered.open.other = state.conversations.filtered.open.other.concat(action.payload.data.filtered.open.other);
            }
            if (action.payload.conversationType === '/closed') {
                state.conversations.filtered.closed = state.conversations.filtered.closed.concat(action.payload.data.filtered.closed);
            }

            if (action.payload?.ref) {
                setTimeout(() => {
                    state.uploadingMoreConversations = false;
                    action.payload.ref.scrollTop = refHeight;
                }, 10);
            }
            return {
                ...state,
            };
        case types.FETCH_MESSAGE_TEMPLATES:
            return {
                ...state,
                messageTemplates: action.payload,
            };
        case types.FETCH_AGENT_TEMPLATES:
            return {
                ...state,
                agentTemplates: action.payload,
            };
        case types.FETCH_PREFERENCES:
            return {
                ...state,
                preferences: action.payload,
            };
        case types.SHOW_SUCCESS:
            return {
                ...state,
                success: action.payload,
            };
        case types.FETCH_ANALYSIS_STATUS:
            return {
                ...state,
                analysisStatuses: action.payload.statuses,
                tripProgresses: action.payload.progresses,
                feedbackSelect: action.payload.feedbackSelect,
                retention: action.payload.retention,
                csq: action.payload.csq,    
                lead_status: action.payload.lead_status,
                interaction: action.payload.interaction,
            };
        case types.FETCH_MESSAGE_TAGS:
            return {
                ...state,
                messageTags: action.payload,
            };
        case types.FETCH_TEMPLATE_MESSAGE_TAGS:
            return {
                ...state,
                messageTemplateTags: action.payload,
            };
        case types.FETCH_MANAGER_LIST:
            return {
                ...state,
                managerList: action.payload,
            };
        case types.FETCH_REACTIONS:
            return {
                ...state,
                reactions: action.payload,
            };
        case types.FETCH_CURRENT_CONVERSATION:
            setTimeout(() => {
                let chatDiv = document.getElementById("chat");
                if (chatDiv !== null) {
                    chatDiv.scrollTop = chatDiv.scrollHeight;
                }
                let item = document.getElementById('unread_' + action.payload.id);
                if (item != null) {
                    item.style.display = "none";
                }
            }, 100);
            state.currentMessagePage = 1;

            return {
                ...state,
                currentConversation: action.payload,
                branchId: action.payload.branch_id,
            };
        case types.FETCH_CONVERSATION_TASK_LIST:
            return {
                ...state,
                conversationTaskList: action.payload,
            };
        case types.FETCH_USER_PREFERENCES:
            action.payload.map((userPreference) => {
                let deletePreference = state.preferences.find((preference) => {
                    if (preference.id === userPreference.id) {
                        return true;
                    }
                    return false;
                });
                state.preferences.splice(
                    state.preferences.indexOf(deletePreference),
                    1
                );
                return true;
            });

            return {
                ...state,
                userPreferences: action.payload,
            };
        case types.DELETE_USER_PREFERENCE:
            let deletedUserPreference = state.userPreferences.find((preference) => {
                if (preference.id === action.payload.preferenceId) {
                    return true;
                }
                return false;
            });
            state.preferences.push(deletedUserPreference);
            state.userPreferences.splice(
                state.userPreferences.indexOf(deletedUserPreference),
                1
            );

            return {
                ...state,
            };
        case types.ADD_USER_PREFERENCE:
            let addedUserPreference = state.preferences.find((preference) => {
                if (preference.id === action.payload.preferenceId) {
                    return true;
                }
                return false;
            });
            state.userPreferences.push(addedUserPreference);
            state.preferences.splice(
                state.preferences.indexOf(addedUserPreference),
                1
            );

            return {
                ...state,
            };
        case types.FETCH_JOURNEY_SCHEDULED_ARTICLES:
            return {
                ...state,
                journeyScheduledArticles: action.payload,
            };
        case types.CLEAR_JOURNEY_SCHEDULED_ARTICLES:
            return {
                ...state,
                journeyScheduledArticles: initialState.journeyScheduledArticles,
            };
        case types.FETCH_ANALYSIS_DATA:
            let defaultData = {
                accomodation: null,
                conversation_itinerary_id: null,
                created_at: null,
                deleted_at: null,
                emergency_contact_service: null,
                feedback: null,
                feedback_comment: null,
                feedback_rating: null,
                feedback_sophie: null,
                id: null,
                interaction: null,
                notes: null,
                other_keywords: null,
                other_travel_documents: null,
                product_complaints: null,
                product_questions: null,
                other_questions: null,
                questions: null,
                questions_select: null,
                status: null,
                tips: null,
                travel_pictures: null,
                trip_progress: null,
                updated_at: null,
                vouchers: null, 
                nps1: null,
                retention: null,
                lead_status: null,
                csq: null
            };
            return {
                ...state,
                analysisData: action.payload ? action.payload : defaultData,
            };
        case types.STORE_ANALYSIS_ERROR_DATA:
            return {
                ...state,
                analysisStoreResponseData: action.payload,
            };
        case types.ADD_NEW_MESSAGE:
            state.currentConversation.messages.push(action.payload);

            return {
                ...state,
                currentConversation: state.currentConversation,
            };
        case types.DELETE_MESSAGE:
            let index = state.currentConversation.messages.findIndex(
                (x) => x.id === action.payload
            );
            state.currentConversation.messages.splice(index, 1);
            return {
                ...state,
                currentConversation: state.currentConversation,
            };
        case types.TAG_MESSAGE:
            let indexTaggedMessage = state.currentConversation.messages.findIndex(
                (x) => x.id === action.payload.id
            );
            let tagIndex = state.currentConversation.messages[
                indexTaggedMessage
            ].tags.findIndex((x) => x.typeable_id === action.payload.tag.id);
            if (tagIndex >= 0) {
                state.currentConversation.messages[indexTaggedMessage].tags.splice(
                    tagIndex,
                    1
                );
            } else {
                state.currentConversation.messages[indexTaggedMessage].tags.push({
                    id: action.payload.tag.id,
                    typeable: {
                        id: action.payload.tag.id,
                        type: action.payload.tag.type,
                    },
                    itinerary: action.payload.itinerary,
                    itinerary_id: action.payload.itinerary.id,
                    typeable_id: action.payload.tag.id
                });
            }
            return {
                ...state,
                currentConversation: state.currentConversation,
            };
        case types.REACTION:
            let indexReactedMessage = state.currentConversation.messages.findIndex(
                (x) => x.id === action.payload.messageId
            );
            let reactionIndex = state.reactions.findIndex((x) => x.id === action.payload.reactionId);
            let hasReaction = state.currentConversation.messages[indexReactedMessage].reactions.findIndex((x) => x.id === action.payload.reactionId);
            if (hasReaction >= 0) {
                state.currentConversation.messages[indexReactedMessage].reactions.splice(hasReaction, 1);
            } else {
                state.currentConversation.messages[indexReactedMessage].reactions.push(state.reactions[reactionIndex]);
            }
            return {
                ...state,
                currentConversation: state.currentConversation,
            };
        case types.ASSIGN_TASK:
            state.conversationTaskList.find((task) => {
                if (task.id === action.payload.taskId) {
                    task.agentname = {
                        id: getToken().id,
                        name: getToken().user_name,
                    };
                    return true;
                }
                return false;
            });

            return {
                ...state,
            };
        case types.UNASSIGN_TASK:
            state.conversationTaskList.find((task) => {
                if (task.id === action.payload.taskId) {
                    task.agentname = null;
                    return true;
                }
                return false;
            });

            return {
                ...state,
            };
        case types.FETCH_AGENTS:
            let agents = [{ value: 0, label: "Unassign", icon: null }];
            action.payload.map((agent) => {
                agents.push({
                    value: agent.id,
                    label: agent.name,
                    icon: agent.avatar !== null ? agent.avatar : conversationIcon,
                });
                return true;
            });
            return {
                ...state,
                agents: agents,
            };

        case types.SEARCH_DOSSIER_ID:
            return {
                ...state,
                searchDossierIdResult: action.payload,
            };

        case types.SHOW_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case types.FETCH_MORE_MESSAGES:
            state.currentConversation.messages = action.payload.data;

            return {
                ...state,
            };

        case types.EDIT_TRAVELER_NAME:
            state.currentConversation.traveler.full_name = action.payload;
            return {
                ...state,
            };
        case types.CLEAR_ANALYSIS_DATA:
            return {
                ...state,
                analysisData: {},
            };
        case types.FETCH_ITINERARIES:
            return {
                ...state,
                itineraries: action.payload,
            };
        case types.FETCH_JOURNEYS:
            return {
                ...state,
                journeys: action.payload,
            };
        case types.FETCH_CITIES:
            return {
                ...state,
                cities: action.payload,
            };
        case types.FETCH_FILTERED_TRAVELLERS:
            return {
                ...state,
                filteredTravellers: action.payload,
            };
        case types.FETCH_FILTERED_CONVERSATIONS:
            return {
                ...state,
                filteredConversations: action.payload,
            };
        case types.FETCH_ACCOMMODATIONS:
            return {
                ...state,
                accommodations: action.payload,
            };
        case types.FETCH_ITINERARY_PRODUCTS:
            return {
                ...state,
                itineraryProducts: action.payload.data,
            };
        case types.FETCH_BULK_MESSAGES:
            return {
                ...state,
                bulkMessages: {
                    data: action.payload.data.data,
                    paginate: action.payload.data.paginate
                }
            };
        case types.FETCH_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
            }
        case types.FETCH_VALIDATION_ERROR:
            return {
                ...state,
                validationErrors: action.payload,
            }
        default:
            throw new Error("Unexpected action");
    }
};
export { initialState, types, reducer };
