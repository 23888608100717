import Axios from "axios";
import { types } from "../reducers/TipsReducer";
import {AppContext} from "../context/AppContext";
import {useContext} from "react";

export const useActions = (state, dispatch) => {
  const {state: AppState} = useContext(AppContext);

  const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    withCredentials: true,
  });

  function fetchTips(page = 1, filters = {}) {
    let url = `api/tips?page=${page}`;
    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "undefined") continue;
      if (value === null) continue;
      if (typeof value === "string" && !value.length) continue;
      if (key === "location" && value.lat === null) continue;
      if (key === "bounds" && value.nelat === null) continue;
      if (key === "tag" && value.length && Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          url += `&tag[]=${value[i].id}`;
        }
      }
      if (value > 0) {
        url += `&${key}=${value}`;
      }
      if (key === "location" && value !== null) {
        url += `&lat=${value.lat}&lng=${value.lng}`;
      } else if (key === "bounds" && value !== null) {
        url += `&nelat=${value.nelat}&nelng=${value.nelng}&swlat=${value.swlat}&swlng=${value.swlng}`;
      } else if (value.length && !Array.isArray(value)) {
        url += `&${key}=${value}`;
      }
    }

    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        const newTips = Object.assign({}, state.tips, {
          allTips: response.data.data,
          nextPageUrl: response.data.next_page_url,
          lastPageUrl: response.data.last_page_url,
          lastPage: response.data.last_page,
        });
        dispatch({ type: types.UPDATE_TIPS, payload: newTips });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to fetch the tips from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function filterTips(filter = {}) {
    if (filter.radius !== null && filter.location.lat === null) {
      const errorMessage = "You need to chose a location to filter on radius";
      dispatch({
        type: types.SHOW_ERRORS,
        payload: errorMessage,
      });
    } else {
      fetchTips(1, filter);
      dispatch({
        type: types.UPDATE_FILTER,
        payload: filter,
      });
    }
  }

  function clearFilterTips(filter = {}) {
    fetchTips(1, filter);
    dispatch({
      type: types.CLEAR_FILTER,
      payload: false,
    });
  }

  function updateTipId(tipId) {
    dispatch({
      type: types.UPDATE_TIP_ID,
      payload: tipId,
    });
  }

  function clearTipId() {
    dispatch({
      type: types.CLEAR_TIP_ID,
      payload: false,
    });
  }

  function addNewTip(data, onHide) {
    if (!data.draft) {
      data.save = true;
    }
    axios({
      method: "POST",
      url: "api/tips/create",
      data,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        dispatch({ type: types.UPDATE_TIP, payload: data });
        if (onHide) onHide();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = "Sorry, we failed to create a new tip";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function addNewType(data, onHide) {
    axios({
      method: "POST",
      url: "api/tag-types/create",
      data: data,
      headers: {"Content-Type": "multipart/form-data",}
    })
        .then((response) => {
          if (response.data.code && response.data.code === 200) {
            AppState.inputs.typesTips = response.data.data;
          }
          if (onHide) onHide();
        })
        .catch((error) => {
          console.log(error);
          const errorMessage =
              "Sorry, we failed to add new type";
          dispatch({
            type: types.SHOW_ERRORS,
            payload: errorMessage,
          });
        });
  }

  function addNewTag(typeId, tagName, onHide) {
    axios({
      method: "POST",
      url: "api/tags/create",
      data: {tag: tagName, type_id: typeId},
    })
        .then((response) => {
          if (response.data.code && response.data.code === 200) {
            AppState.inputs.tags = response.data.data;
          }
          if (onHide) onHide();
        })
        .catch((error) => {
          console.log(error);
          const errorMessage =
              "Sorry, we failed to add new tag";
          dispatch({
            type: types.SHOW_ERRORS,
            payload: errorMessage,
          });
        });
  }

  function getTip(tipId) {
    axios({
      method: "GET",
      url: `/api/tips/${tipId}`,
    })
      .then((response) => {
        const tipToEdit = Object.assign({}, state.tip, {
          data: response.data,
        });
        dispatch({ type: types.UPDATE_TIP, payload: tipToEdit });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = "Failed to get tip from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function editTip(data, onHide) {
    if (!data.draft) {
      data.save = true;
    }
    axios({
      method: "PUT",
      url: `api/tips/${data.id}/update`,
      data,
    })
      .then((response) => {
        const tipToEdit = Object.assign({}, state.tip, {
          data: response.data,
        });
        dispatch({ type: types.UPDATE_TIP, payload: tipToEdit });
        if (onHide) onHide();
      })
      .then(() => {
        fetchTips(state.pageIndex, state.filters);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to save the edited version of the tip";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function deleteTip(tipId) {
    axios
      .delete(process.env.REACT_APP_BASEURL + `/api/tips/${tipId}/delete`)
      .then(() => {
        fetchTips(state.pageIndex, state.filters);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = "Sorry, we failed to delete the tip";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function getItinerariesByTipId(tipId) {
    axios
      .get(process.env.REACT_APP_BASEURL + `/api/itineraries-by-tip/${tipId}`)
      .then((response) => {
        console.log(response.data);
        dispatch({ type: types.FETCH_ITINERARIES, payload: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getJourneysByTipId(tipId) {
    axios
        .get(process.env.REACT_APP_BASEURL + `/api/journeys-by-tip/${tipId}`)
        .then((response) => {
          console.log(response.data);
          dispatch({ type: types.FETCH_JOURNEYS, payload: response.data });
        })
        .catch((error) => {
          console.log(error);
        });
  }

  function clearError() {
    const emptyError = "";
    dispatch({
      type: types.SHOW_ERRORS,
      payload: emptyError,
    });
  }

  function updatePageIndex(pageIndex) {
    dispatch({
      type: types.UPDATE_PAGE_INDEX,
      payload: pageIndex,
    });
  }

  return {
    fetchTips,
    filterTips,
    clearFilterTips,
    updateTipId,
    clearTipId,
    addNewTip,
    addNewType,
    addNewTag,
    getTip,
    editTip,
    deleteTip,
    clearError,
    updatePageIndex,
    getItinerariesByTipId,
    getJourneysByTipId,
  };
};
