import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { ConversationContext } from "../../store/context/ConversationContext";
import Select from "react-select";
import {AppContext} from "../../store/context/AppContext";

const Language = () => {
  const { state, actions } = useContext(ConversationContext);
  const { state: AppState } = useContext(AppContext);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    state.languages.find((language) => {
      if (language.id === state.currentConversation.language_id) {
        setSelectedLanguage(language);
        return true;
      }
      setSelectedLanguage("");
      return false;
    });
  }, [state.currentConversation]);

  const changeLanguage = (event) => {
    actions.changeLanguageConversation(state.currentConversation.id, event.id);
    setSelectedLanguage(event);
  };

  return (
    <Container>
      <Row className="assignee__name">
        <Col sm={12}>
          <Select
            name="language_id"
            options={AppState.inputs.languages}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            placeholder="Select Language"
            classNamePrefix="select"
            onChange={(event) => changeLanguage(event)}
            value={selectedLanguage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Language;
