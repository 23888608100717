import React from "react";
import PropTypes from "prop-types";

import "./Paginator.css";

function Paginator({
  getPreviousPage,
  page,
  getNextPage,
  lastPage,
  className = "",
  getPageButton,
}) {
  const buttonClass = `paginate-btn ${className}`;
  let lastPageDisabled = page === lastPage;
  const pageDisabled = lastPageDisabled ? true : false;

  let pageLinks = [];
  let maxButtons = lastPage;
  if (lastPage > 5) {
    if (page + 4 < lastPage) maxButtons = page + 4;
  }

  let startButton = page;
  if (lastPage < 5) {
    startButton = 1;
  }

  let showThreeDots = true;
  if (lastPage < 5 || lastPage - 4 <= page) {
    showThreeDots = false;
  }

  for (let i = startButton; i <= maxButtons; i++) {
    let pageButton = i;
    pageLinks.push(
      <div key={pageButton}>
        <button
          className="paginate-btn--page"
          onClick={() => getPageButton(pageButton)}
          disabled={page === pageButton}
        >
          {pageButton}
        </button>
      </div>
    );
  }

  return (
    <div className={buttonClass}>
      <div>
        <button
          className="paginate-btn--previous"
          onClick={getPreviousPage}
          disabled={page === 1}
        ></button>
      </div>
      {pageLinks}
      <div>
        {showThreeDots && (
          <div>
            <span className="paginate-btn__dots">...</span>
            <button
              onClick={() => getPageButton(lastPage)}
              className="paginate-btn--page"
              disabled={pageDisabled || !page || !getNextPage}
            >
              {lastPage}
            </button>
          </div>
        )}
      </div>
      <div>
        <button
          className="paginate-btn--next"
          onClick={getNextPage}
          disabled={pageDisabled || !page || !getNextPage}
        ></button>
      </div>
    </div>
  );
}

Paginator.propTypes = {
  getPreviousPage: PropTypes.func,
  page: PropTypes.number,
  getNextPage: PropTypes.func,
  className: PropTypes.string,
  getPageButton: PropTypes.func,
};

export default Paginator;
