import React, { useState } from 'react';
import './Login.css'
import axios from "axios";

const initialState = {
  email: '',
};

const ForgotPassword = () => {
  const [login, setLogin] = useState(initialState);

  const handleChange = (e) => {
    setLogin({
      ...login, [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let forgotPasswordUrl = process.env.REACT_APP_BASEURL + '/auth/forgot-password/'
    axios.get(forgotPasswordUrl + login.email);
    setLogin(initialState);
    document.getElementById('email_address').style.display = "none";
    document.getElementById('restore_password_btn').style.display = "none";
    document.getElementById('check_mail_label').style.display = "block";
  }


  return (
    <article className="bg">
      <form>
        <div className="login__container">
          <img src="https://res.cloudinary.com/dsuhcbmxk/image/upload/v1594286546/logo-1-small.png" alt="logo" />
          <div className="login__form">
            <div id="email_address">
              <input
                onChange={handleChange}
                defaultValue={login.email}
                name="email"
                type="text"
                placeholder="Email address"
                className="form-section"
              />
            </div>
          </div>
          <button id="restore_password_btn" className="login__btn restore_password_btn" onClick={handleSubmit}>
            <p className="login__text">Restore password</p>
          </button>
          <p id="check_mail_label" className="check_mail_label" style={{ display: 'none' }}>Check your email</p>
          <br />
          <p id="login__error-message"></p>
        </div>
      </form>
    </article>
  );
}

export default ForgotPassword;

