const initialState = {
  tips: {
    allTips: [],
    nextPageUrl: "",
    lastPageUrl: "",
    lastPage: "",
  },
  pageIndex: 1,
  filters: {
    searchText: "",
    radius: null,
    type: null,
    tag: [],
    bounds: {
      swlat: null,
      swlng: null,
      nelat: null,
      nelng: null,
    },
    location: {
      lat: null,
      lng: null,
    },
  },
  tip: {
    data: {},
  },
  tipId: {
    id: null,
  },
  errors: "",
  itineraries: "",
  journeys: "",
};

const types = {
  UPDATE_TIPS: "UPDATE_TIPS",
  UPDATE_FILTER: "UPDATE_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  UPDATE_TIP: "UPDATE_TIP",
  UPDATE_TIP_ID: "UPDATE_TIP_ID",
  CLEAR_TIP_ID: "CLEAR_TIP_ID",
  SHOW_ERRORS: "SHOW_ERRORS",
  UPDATE_PAGE_INDEX: "UPDATE_PAGE_INDEX",
  FETCH_ITINERARIES: "FETCH_ITINERARIES",
  FETCH_JOURNEYS: "FETCH_JOURNEYS",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_TIPS:
      return {
        ...state,
        tips: action.payload,
      };
    case types.UPDATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case types.CLEAR_FILTER:
      return {
        ...state,
        filters: initialState.filters,
      };
    case types.UPDATE_TIP:
      return {
        ...state,
        tip: action.payload,
      };
    case types.UPDATE_TIP_ID:
      return {
        ...state,
        tipId: action.payload,
      };
    case types.CLEAR_TIP_ID:
      return {
        ...state,
        tipId: initialState.tipId,
      };
    case types.SHOW_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case types.UPDATE_PAGE_INDEX:
      return {
        ...state,
        pageIndex: action.payload,
      };
    case types.FETCH_ITINERARIES:
      return {
        ...state,
        itineraries: action.payload,
      };
    case types.FETCH_JOURNEYS:
      return {
        ...state,
        journeys: action.payload,
      };
    default:
      throw new Error("Unexpected action");
  }
};
export { initialState, types, reducer };
