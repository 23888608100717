import React, {useContext, useEffect} from 'react';
import {Row, Col} from "react-bootstrap";
import { useParams } from "react-router";
import OpenConversationTabs from "./OpenConversationTabs/OpenConversationTabs";
import ConversationMessages from "../ConversationMessenger/ConversationMessages";
import { ConversationContext } from "../../../store/context/ConversationContext";
import { AppContext } from "../../../store/context/AppContext";

function ConversationDetails() {
    const { state, actions } = useContext(ConversationContext);
    const params = useParams();
    const { pusher } = useContext(AppContext);

    useEffect(() => {
        actions.fetchCurrentConversation(params.id);

        const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CURRENT_CONV_CHANNEL_NAME);
        channel.bind(process.env.REACT_APP_PUSHER_CURRENT_CONV_EVENT_NAME, (data) => {
            console.log('new signal from pusher');
          if (typeof data.message.conversation_id != "undefined") {
            if (params.id === data.message.conversation_id) {
                actions.fetchCurrentConversation(params.id);
                console.log('fetching current conversation again');
            }
          }
        });

        return () => {
          channel.unbind(process.env.REACT_APP_PUSHER_CURRENT_CONV_CHANNEL_NAME)
        }
    }, [params.id]);

    return (
        <Row className="open-conversations-row">
            <div className="conversation-close text-right">
                <button onClick={() => actions.closeConversation(params.id)}>Close Conversation</button>
            </div>

            <Col sm="7">
                { state?.currentConversation?.id && <ConversationMessages /> }
            </Col>
            <Col sm="5">
                { state?.currentConversation?.id && <OpenConversationTabs /> }
            </Col>
        </Row>
    )
}

export default ConversationDetails;