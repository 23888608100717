const initialState = {
  itineraries: {
    allItineraries: [],
    nextPageUrl: "",
    lastPageUrl: "",
    lastPage: "",
  },
  pageIndex: 1,
  closed: false,
  filters: {
    dossier_id: "",
    status: null,
    city: "",
    country: "",
    description: "",
    departure: "",
    return: "",
    branch: "",
  },
  itinerary: {
    data: {},
    length: [],
  },
  traveller: {
    data: {},
    length: [],
  },
  searchPhoneNumberResult: {
    data: {},
    length: [],
  },
  whatsappTemplates: [],
  journeyTemplates: [],
  errors: "",
  validateErrors: {
    file: '',
  },
};

const types = {
  UPDATE_ITINERARIES: "UPDATE_ITINERARIES",
  UPDATE_FILTER: "UPDATE_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  UPDATE_CLOSED_ITINERARIES: "UPDATE_CLOSED_ITINERARIES",
  UPDATE_ITINERARY: "UPDATE_ITINERARY",
  UPDATE_TRAVELLER: "UPDATE_TRAVELLER",
  UPDATE_WHATSAPP_TEMPLATES: "UPDATE_WHATSAPP_TEMPLATES",
  UPDATE_JOURNEYDAY: "UPDATE_JOURNEYDAY",
  CLEAR_JOURNEYDAY: "CLEAR_JOURNEYDAY",
  ADD_EMPTY_JOURNEYDAY: "ADD_EMPTY_JOURNEYDAY",
  REMOVE_JOURNEYDAY: "REMOVE_JOURNEYDAY",
  APPROVE_ARTICLES: "APPROVE_ARTICLES",
  REJECT_ARTICLES: "REJECT_ARTICLES",
  SEARCH_PHONE_NUMBERS: "SEARCH_PHONE_NUMBERS",
  SHOW_ERRORS: "SHOW_ERRORS",
  UPDATE_PAGE_INDEX: "UPDATE_PAGE_INDEX",
  UPDATE_JOURNEY_TEMPLATES: "UPDATE_JOURNEY_TEMPLATES",
  VALIDATE_ERRORS: "VALIDATE_ERRORS",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ITINERARIES:
      return {
        ...state,
        itineraries: action.payload,
      };
    case types.UPDATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case types.CLEAR_FILTER:
      return {
        ...state,
        filters: initialState.filters,
      };
    case types.UPDATE_CLOSED_ITINERARIES:
      return {
        ...state,
        closed: action.payload,
      };
    case types.UPDATE_ITINERARY:
      return {
        ...state,
        itinerary: action.payload,
      };
    case types.UPDATE_TRAVELLER:
      return {
        ...state,
        traveller: action.payload,
      };
    case types.UPDATE_WHATSAPP_TEMPLATES:
      return {
        ...state,
        whatsappTemplates: action.payload,
      };
    case types.UPDATE_JOURNEYDAY:
      const newTraveller = state.traveller;
      const dayIndex = newTraveller.data.itinerary.journey.days.findIndex(
        (d) => d.id === action.payload.id
      );
      newTraveller.data.itinerary.journey.days[dayIndex] = action.payload;
      return {
        ...state,
        traveller: newTraveller,
      };
    case types.CLEAR_JOURNEYDAY:
      const dayToRemoveIndex = state.traveller.data.days.findIndex(d => d.day === action.payload.dayIndex);

      const msgIndex = state.traveller.data.days[dayToRemoveIndex].messages.findIndex(msg => msg.journey_scheduled_article_id === action.payload.messageId);

      state.traveller.data.days[dayToRemoveIndex].messages.splice(msgIndex, 1)
      return { ...state };

    case types.ADD_EMPTY_JOURNEYDAY:
      state.itinerary.data.timeline_end += 1;
      const newJourneyDay = {
        day: state.itinerary.data.timeline_end,
      };
      state.itinerary.data.journey.days.push(newJourneyDay);
      return { ...state };

    case types.REMOVE_JOURNEYDAY:
      state.itinerary.data.timeline_end -= 1;
      state.itinerary.data.journey.days.pop();
      return { ...state };

    case types.APPROVE_ARTICLES:
      state.traveller.data.conversation.messages_checked = "Checked";
      return { ...state };

    case types.REJECT_ARTICLES:
      state.traveller.data.conversation.messages_checked = "";
      return { ...state };

    case types.SEARCH_PHONE_NUMBERS:
      return {
        ...state,
        searchPhoneNumberResult: action.payload,
      };
    case types.UPDATE_PAGE_INDEX:
      return {
        ...state,
        pageIndex: action.payload,
      };
    case types.SHOW_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case types.UPDATE_JOURNEY_TEMPLATES:
      return {
        ...state,
        journeyTemplates: action.payload,
      };
    case types.VALIDATE_ERRORS:
      return {
        ...state,
        validateErrors: action.payload,
      }

      throw new Error("Unexpected action");
  }
};

export { initialState, types, reducer };
