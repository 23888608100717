import React from "react";
import { Form } from "react-bootstrap";

import "./ItineraryContainer.css";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";

const ItineraryContainer = ({ formData, handleFormData, isEditMode }) => {
  const onChangeItinerary = (e) => {
    handleFormData(e.target.name, e.target.value);
  };

  return (
    <>
      {formData?.id && (
        <DataBlock
          header="ITINERARY"
          classNameContent="itinerary-container"
          content={
            <>
              <div className="itinerary-container__details">
                <div>
                  <div className="itinerary-container__title">Branch</div>
                  {formData.branch?.branch_name ?
                      <div className="itinerary-container__data">
                        <b>{formData.branch.branch_name}</b>
                      </div>
                      : ''}
                </div>
                <div>
                  <div className="itinerary-container__title">Trip</div>
                  {formData.itinerary_sale?.sale ?
                    <div className="itinerary-container__data">
                      <b>{formData.itinerary_sale.sale}</b>
                    </div>
                  : ''}
                  <div className="itinerary-container__data">
                    {formData.description}
                  </div>
                </div>
                <div>
                  <div className="individual-itinerary-container__title">
                    Channel
                  </div>
                  <div className="individual-itinerary-container__data">
                    {formData.itinerary_type}
                  </div>
                </div>
                <div>
                  <div className="itinerary-container__title">Destination</div>
                  <div className="itinerary-container__data">
                    {formData.country}
                  </div>
                </div>
              </div>

              <div className="itinerary-container__title itinerary-container__title--accommodation">
                Accommodation
              </div>
              <div className="itinerary-container__accommodation-container">
                {formData.itinerary_accomodations &&
                  formData.itinerary_accomodations.map((acco, key) => (
                    <div
                      className="itinerary-container__accommodation"
                      key={key}
                    >
                      {acco.accomodation.accomodation_name !== null ? (<p className="mb-0">{acco.accomodation.accomodation_name},</p>) : null}
                      <p className="mb-0">{acco.accomodation.city.title} {acco.accomodation.city_part}</p>
                      {acco.accomodation.address !== null ? (<div>
                        {acco.accomodation.address.address !== null ? (<p className="mb-0">{acco.accomodation.address.address}</p>) : null}
                        {acco.accomodation.care_type !== null ? (<p className="mb-0">Care type: {acco.accomodation.care_type}</p>) : null}
                        {acco.accomodation.stars !== null ? (<p className="mb-0">Stars: {acco.accomodation.stars}</p>) : null}
                        {acco.accomodation.accomodation_code !== null ? (<p className="mb-0">Code: {acco.accomodation.accomodation_code}</p>) : null}
                        {acco.accomodation.address.maps_url !== null && acco.accomodation.address.maps_url !== '' ? (<a className="mb-0" target="_blank" href={acco.accomodation.address.maps_url}>See on map</a>) : null}
                      </div>) : null}
                      <p className="mb-0">{acco.departure_date} - {acco.arrival_date}</p>
                    </div>
                  ))}
              </div>

              <div className="itinerary-container__title itinerary-container__title--accommodation">
                Products
              </div>
              <div className="itinerary-container__accommodation-container">
                <div className="itinerary-container__accommodation">
                  <ul>
                {formData.itinerary_products &&
                    formData.itinerary_products.map((product, key) => (
                        <li className="mb-0" key={key}>
                          {product.product_name !== null ? (
                              <div>
                                {product.departure_date} - {product.return_date} - {product.product_name}
                              </div>
                          ) : null}
                        </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="individual-itinerary-container__body">
                <div>
                  <div className="itinerary-container__title">Travel Party</div>
                  <div className="itinerary-container__data">
                    {formData.travellers &&
                      formData.travellers.map((pax, key) => (
                        <div className="itinerary-container__pax" key={key}>
                          {pax.full_name}
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <div className="individual-itinerary-container__title">
                    Departure
                  </div>
                  {isEditMode ? (
                      <>
                        <Form.Group
                            controlId="departure_date"
                            className="itinerary-container__input-field"
                        >
                          <Form.Control
                              type="text"
                              name="departure_date"
                              placeholder="YYYY-MM-DD"
                              defaultValue={formData.departure_date}
                              onChange={onChangeItinerary}
                          />
                        </Form.Group>
                      </>
                  ) : (
                  <div className="individual-itinerary-container__data">
                    {formData.departure_date}
                  </div>
                      )}
                </div>
                <div>
                  <div className="individual-itinerary-container__title">
                    Return
                  </div>
                  {isEditMode ? (
                      <>
                        <Form.Group
                            controlId="return_date"
                            className="itinerary-container__input-field"
                        >
                          <Form.Control
                              control-id=""
                              type="text"
                              name="return_date"
                              placeholder="YYYY-MM-DD"
                              defaultValue={formData.return_date}
                              onChange={onChangeItinerary}
                          />
                        </Form.Group>
                      </>
                  ) : (
                  <div className="individual-itinerary-container__data">
                    {formData.return_date}
                  </div>
                      )}
                </div>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default ItineraryContainer;
