import React from 'react'
import './Searchbar.css'
import Input from "reactstrap/lib/Input";
import axios from "axios";

function Searchbar({id, className, searchUrl, allDataUrl, callback}, ref) {

    let typingTimer;
    let doneTypingInterval = 1500;

    function keyUp() {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(search, doneTypingInterval);
    }

    function keyDown() {
        clearTimeout(typingTimer);
    }

    function search() {
        let text = document.getElementById(id).value;

        if (text.length === 0) {
            axios.get(allDataUrl)
                .then((response) => {
                    callback(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (text.length >= 3) {
            axios.post(searchUrl, {search_value: text})
                .then((response) => {
                    callback(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <Input
            ref={ref}
            id={id}
            className={className}
            name="search"
            type="text"
            placeholder="search"
            onKeyUp={keyUp}
            onKeyDown={keyDown}
        />
    )
}

/**
 * forward ref: https://reactjs.org/docs/forwarding-refs.html
 */
export default React.forwardRef(Searchbar);
