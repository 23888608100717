import React, { useContext, useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import ClosedConversations from './ClosedConversations/ClosedConversations'
import OpenConversations from './OpenConversations/OpenConversations'
import './Conversations.css'
import { ConversationContext } from "../../store/context/ConversationContext";
import ErrorModal from "../Reusable/ErrorModal/ErrorModal";
import { AppContext } from "../../store/context/AppContext";

const Conversations = () => {
    const { state, actions } = useContext(ConversationContext);
    const [currentPage, setCurrentPage] = useState(1);
    const { pusher } = useContext(AppContext);
    const [haveNewMessage, setHaveNewMessage] = useState(false);

    useEffect(() => {
        actions.fetchAllData();
        actions.fetchConversations(1);
    }, [])

    useEffect(() => {
        actions.fetchConversations(currentPage);

        const channel = pusher.subscribe(process.env.REACT_APP_PUSHER_CHANNEL_NAME);
        channel.bind(process.env.REACT_APP_PUSHER_CONV_EVENT_NAME, data => {
            if (typeof data.have_new_message != 'undefined' && data.have_new_message) {
                setHaveNewMessage(true);
                actions.fetchConversations(currentPage);
                actions.updateFavicon();
            }
        });

        return () => {
            channel.unbind(process.env.REACT_APP_PUSHER_CHANNEL_NAME)
        }
    }, [currentPage, haveNewMessage])

    if (state.conversations?.filtered?.open) {
        actions.updateFavicon();
    }

    const clearError = () => {
        actions.clearError();
    };

    return (
        <div>
            <Tabs defaultActiveKey="open" id="conversation-tabs" sm="10">
                <Conversations className="open-conversations" eventKey="open" title="Open">
                    {typeof state.conversations != 'undefined' && typeof state.conversations.filtered != 'undefined' ?
                        <OpenConversations />
                        : null}
                </Conversations>

                <Conversations className="closed-conversations" eventKey="closed" title="Closed">
                    {typeof state.conversations != 'undefined' && typeof state.conversations.filtered != 'undefined' ?
                        <ClosedConversations />
                        : null}
                </Conversations>
            </Tabs>

            <ErrorModal errorMessage={state.errors} onHide={clearError} />
        </div>
    );
};

export default Conversations;
