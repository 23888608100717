import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { BrowserRouter as Router } from 'react-router-dom';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  environment: process.env.REACT_APP_ENV || "local",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 0,

  tracesSampler: samplingContext => {
    // Examine provided context data (including parent decision, if any) along
    // with anything in the global namespace to compute the sample rate or
    // sampling decision for this transaction
    if (process.env.REACT_APP_ENV === 'production') {
      // These are important - take a big sample
      return 0.5;
    } else if (process.env.REACT_APP_ENV === 'staging') {
      // These are less important or happen much more frequently - only take 1%
      return 0.01;
    } else if (process.env.REACT_APP_ENV === 'local') {
      // These aren't something worth tracking - drop all transactions like this
      console.log("environment tracesSampler: " + process.env.REACT_APP_ENV);
      return 0;
    } else {
      // Default sample rate
      return 0.1;
    }
  }
});

ReactDOM.render(
    <Router>
        <App />
     </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
