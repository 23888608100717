import React, { useState, useEffect, useContext } from "react";
import { TipsContext } from "../../../store/context/TipsContext";
import PropTypes from "prop-types";
import EditTipModal from "../TipForm/EditTip";

import "./TipDetails.css";

import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

function TipDetails({
  onTipSelected,
  tipClose,
  tipId,
  editTipButton,
  tipSelectorButton,
}) {
  const { state, actions } = useContext(TipsContext);
  const [editTipButtonData] = useState(editTipButton);
  const [tipSelectorButtonData] = useState(tipSelectorButton);
  const [isEditTipModalVisible, setIsEditTipModalVisible] = useState(false);

  useEffect(() => {
    actions.getTip(tipId);
  }, [tipId]);

  const onClickCloseTipDetails = () => {
    if (tipClose) tipClose();
  };

  const onClickSelectTip = () => {
    onTipSelected(state.tip.data);
  };

  const onClickEditTip = () => {
    setIsEditTipModalVisible(true);
  };

  const onClickCloseEditTip = () => {
    setIsEditTipModalVisible(false);
  };

  return (
    <div className="tip-details">
      {state.tip.data && (
        <>
          <div className="tip-details__header">
            {state.tip.data.title ? (
              <h1 className="tip-details__name">{state.tip.data.title}</h1>
            ) : (
              <h1 className="tip-details__name">Tip details</h1>
            )}
            {state.tip.data.type?.label && (
              <div className="tip-details__type__container">
                <p className="tip-details__type">{state.tip.data.type.label}</p>
                <img
                  className="tip-details__type__img"
                  src={state.tip.data.type?.icon}
                  alt="tip type"
                />
              </div>
            )}
          </div>

          <p className="tip-details__title">TAGS</p>
          <div className="tip-details__tag__container">
            {state.tip.data.tags &&
              state.tip.data.tags.map((tag) => (
                <p className="tip-details__tag" key={tag.id}>
                  {tag.tag && tag.tag}
                </p>
              ))}
          </div>

          <p className="tip-details__title">ADDRESS</p>
          <p className="tip-details__text">{state.tip.data.address}</p>

          <p className="tip-details__title">MESSAGE</p>
          <p className="tip-details__text tip-details__text--message">
            {state.tip.data.body}
          </p>

          <p className="tip-details__title">OPENING HOURS</p>
          <p className="tip-details__text">{state.tip.data.opening_hours}</p>

          <p className="tip-details__title">LINK</p>
          <a
            target="_blank"
            href={state.tip.data.maps_url}
            rel="noopener"
            className="tip-details__maps_url"
          >
            {state.tip.data.maps_url}
          </a>

          <p className="tip-details__title">NOTE</p>
          <p className="tip-details__text tip-details__text--notes">
            {state.tip.data.remarks}
          </p>

          <div className="tip-details__btns">
            {editTipButtonData && (
              <RectangleButton
                onClick={onClickEditTip}
                label={editTipButtonData.label}
              ></RectangleButton>
            )}
            {tipSelectorButtonData && (
              <RectangleButton
                onClick={onClickSelectTip}
                label={tipSelectorButtonData.label}
              ></RectangleButton>
            )}
            <RectangleButton
              isHighlight
              className="tip-details__close-btn"
              onClick={onClickCloseTipDetails}
              label="Close"
            ></RectangleButton>
          </div>
          <EditTipModal
            onClickCloseTipDetails={onClickCloseTipDetails}
            show={isEditTipModalVisible}
            onHide={onClickCloseEditTip}
          />
        </>
      )}
    </div>
  );
}

TipDetails.propTypes = {
  editTipButton: PropTypes.object,
  onTipSelected: PropTypes.func,
  tipClose: PropTypes.func,
  tipId: PropTypes.number.isRequired,
  tipSelectorButton: PropTypes.object,
};

export default TipDetails;
