import React, { useContext } from "react";
import Timeline from "../../../Reusable/Timeline/Timeline";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import TemplateDay from "../../../Templates/EditTemplate/TemplateDay/TemplateDay";
import PropTypes from "prop-types";
import DragDropTemplate from "../../../Reusable/DragAndDrop/DragDropTemplate";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";

const IndividualTimeline = ({
  isEditMode,
  selectedDayData,
  individualData,
  onClickEditJourneyDay,
  onClickNewJourneyDay,
  closeTemplateDay,
}) => {
  const { state, actions: ItineraryActions } = useContext(ItineraryContext);

  const onClickClearJourneyDay = (message) => {
    ItineraryActions.clearJourneyDay(message);
  };

  return (
    <>
      {selectedDayData && (
        <TemplateDay
          isIndividualMode
          closeTemplateDay={closeTemplateDay}
          selectedDayData={selectedDayData}
        />
      )}
      {!selectedDayData && state.traveller.data && (
        <DataBlock
          header="TIMELINE"
          content={
            <>
              <span className="individual-itinerary-timeline__journey-title">{(state.traveller.data.itinerary && state.traveller.data.itinerary.journey) && state.traveller.data.itinerary.journey.title}</span>
              <DragDropTemplate
                  title={'itinerary'}
                  timelineData={individualData}
                  isEditMode={isEditMode}
                  onClickEditJourneyDay={onClickEditJourneyDay}
                  onClickClearJourneyDay={onClickClearJourneyDay}
                  onClickNewJourneyDay={onClickNewJourneyDay}
                  onClickDeleteFromTemplateAndItineraries={''}
                  modalTitle={'Delete scheduled message'}
                  modalFirstButton={'Delete from this template.'}
                  departureDate={
                      state.traveller.data.itinerary &&
                      state.traveller.data.itinerary.departure_date
                  }
              />
              {/*<Timeline*/}
              {/*  timelineData={individualData}*/}
              {/*  departureDate={*/}
              {/*    state.traveller.data.itinerary &&*/}
              {/*    state.traveller.data.itinerary.departure_date*/}
              {/*  }*/}
              {/*  isEditMode={isEditMode}*/}
              {/*  onClickEditJourneyDay={onClickEditJourneyDay}*/}
              {/*  onClickClearJourneyDay={onClickClearJourneyDay}*/}
              {/*  onClickNewJourneyDay={onClickNewJourneyDay}*/}
              {/*  onClickDeleteFromTemplateAndItineraries={''}*/}
              {/*  modalTitle={'Delete scheduled message'}*/}
              {/*  modalFirstButton={'Delete from this itinerary.'}*/}
              {/*/>*/}
            </>
          }
        />
      )}
    </>
  );
};

export default IndividualTimeline;

IndividualTimeline.propTypes = {
  isEditMode: PropTypes.bool,
  selectedDayData: PropTypes.object,
  individualData: PropTypes.object,
  onClickEditJourneyDay: PropTypes.func,
  closeTemplateDay: PropTypes.func,
};
