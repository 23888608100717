import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../../../../../store/context/AppContext";
import { TipsContext } from "../../../../../store/context/TipsContext";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import TemplateGoogleMap from "./TemplateGoogleMap/TemplateGoogleMap";
import TipRow from "../../../../Tips/TipRow/TipRow";
import TipDetails from "../../../../Tips/TipDetails/TipDetails";
import AutoCompleteLocation from "../../../../AutoCompleteLocation";
import SearchInputField from "../../../../Reusable/SearchInputField/SearchInputField";
import AddTipModal from "../../../../Tips/TipForm/AddNewTip";

import "./TipSelector.css";

import Paginator from "../../../../Reusable/Paginator/Paginator";
import CircleButton from "../../../../Reusable/CircleButton/CircleButton";

function TipSelector({ show, onHide, submitTip }) {
  const { state: AppState } = useContext(AppContext);
  const { state, actions } = useContext(TipsContext);
  const [activeTipId, setActiveTipId] = useState(null);
  const [filter, setFilter] = useState(state.filters);
  const [pageIndex, setPageIndex] = useState(1);
  const [isInitial, setIsInitial] = useState(true);
  const [isAddTipModalShown, setIsAddTipModalShown] = useState(false);
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });

  const selectInputRefType = useRef();
  const selectInputRefRadius = useRef();

  let firstTip = state.tips.allTips[0];

  useEffect(() => {
    actions.fetchTips(pageIndex, state.filters);
  }, [pageIndex]);

  useEffect(() => {
    setFilter(state.filters);
  }, [state.filters]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    if (filter.searchText !== "" && filter.searchText.length >= 3) {
      actions.filterTips(filter);
    } else if (filter.searchText === "") {
      actions.filterTips(filter);
    }
  }, [
    filter.radius,
    filter.type,
    filter.tag,
    filter.bounds,
    filter.searchText,
  ]);

  useEffect(() => {
    if (location.lat == null && firstTip?.lat && firstTip?.lng) {
      setLocation({ lat: firstTip.lat, lng: firstTip.lng });
    }
  }, [state.tips.allTips]);

  const getNextPage = () => {
    setPageIndex(
      state.tips.appTips || state.tips.nextPageUrl ? pageIndex + 1 : pageIndex
    );
  };

  const getPreviousPage = () => {
    setPageIndex((old) => Math.max(old - 1, 1));
  };

  const setPageNumber = (pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onCloseTipDetail = () => {
    setActiveTipId(null);
  };

  const onTipSelected = (tip) => {
    if (submitTip) {
      submitTip(tip);
    }
  };

  const onChangeLocation = (data) => {
    setFilter({ ...filter, bounds: null, location: data });
  };

  const onChangeBounds = (data) => {
    setFilter({ ...filter, location: null, bounds: data });
  };

  const onChangeType = (data) => {
    if (data) {
      setFilter({ ...filter, type: data.value });
    }
  };

  const onChangeTag = (data) => {
    if (data) {
      setFilter({ ...filter, tag: data });
    }
  };

  const onChangeRadius = (data) => {
    if (data) {
      setFilter({ ...filter, radius: data.value });
    }
  };

  const onClickTip = (id) => {
    setActiveTipId(id);
  };

  const onClickFilterLocation = () => {
    setLocation(filter.location);
    actions.filterTips(filter);
  };

  const onClickClearFilter = () => {
    actions.clearFilterTips();
    selectInputRefType.current.select.clearValue();
    selectInputRefRadius.current.select.clearValue();
    setFilter(state.filters);
  };

  const onChangeText = (e) => {
    setFilter({ ...filter, searchText: e.target.value });
  };

  const onClickAddTip = () => {
    setIsAddTipModalShown(true);
  };

  const closeAddTip = () => {
    setIsAddTipModalShown(false);
    setActiveTipId(null);
  };

  return (
    <div>
      {isAddTipModalShown ? (
        <AddTipModal show={isAddTipModalShown} onHide={closeAddTip} />
      ) : (
        <Modal
          show={show}
          onHide={onHide}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="tip-selector__title">
            <div className="tip-selector__header">
            <Modal.Title id="contained-modal-title-vcenter">
              Add Tip to Template
            </Modal.Title>
            <CircleButton type="add" onClick={onClickAddTip} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="tip-selector">
              <div className="tip-selector__header--right-column">
                <Select
                  ref={selectInputRefType}
                  className="tip-selector__search-type"
                  options={AppState.inputs.typesTips}
                  getOptionLabel={(option) => `${option.label}`}
                  value={filter.types}
                  getOptionValue={(option) => `${option.value}`}
                  placeholder="Search type"
                  onChange={onChangeType}
                />
                <Select
                  isMulti
                  defaultValue={state.filters.tag}
                  className="tip-selector__search-tags"
                  options={AppState.inputs.tags}
                  getOptionLabel={(option) => `${option.tag}`}
                  value={state.filters.tag}
                  getOptionValue={(option) => `${option.id}`}
                  placeholder="Search tag"
                  onChange={onChangeTag}
                />
                <Select
                  ref={selectInputRefRadius}
                  className="tip-selector__search-radius"
                  options={AppState.inputs.radius}
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                  value={filter.rad}
                  placeholder="Search radius"
                  onChange={onChangeRadius}
                />
                <AutoCompleteLocation
                  onChangeLocation={onChangeLocation}
                  onClickClearFilter={onClickClearFilter}
                  onClickFilterLocation={onClickFilterLocation}
                  locationClassName={{
                    inputField: "tip-selector__search-location-input",
                    suggestions: "tip-selector__search-suggestions",
                  }}
                />
                <SearchInputField
                  placeholder="Search on name, message or address"
                  value={filter.searchText}
                  onChange={onChangeText}
                  onClickSearch={null}
                  className="tip-selector__text-input"
                />
              </div>

              <div className="tip-selector__body">
                <>
                  <div className="tip-selector__table">
                    <div className="tip-selector__table-header">
                      <div className="tip-selector___table-header--type">
                        Type
                      </div>
                      <div className="tip-selector___table-header--name">
                        Name
                      </div>
                      <div className="tip-selector___table-header--message">
                        Message
                      </div>
                      <div className="tip-selector___table-header--tags">
                        Tags
                      </div>
                    </div>
                    <div className="tip-selector__table-body">
                      {state.tips.allTips &&
                        state.tips.allTips.map((tip, key) => (
                          <TipRow tip={tip} key={key} onClickTip={onClickTip} />
                        ))}
                    </div>
                  </div>
                </>
              </div>

              <div className="tip-selector__detail-and-googlemap-container">
                {activeTipId ? (
                  <TipDetails
                    tipId={activeTipId}
                    tipClose={onCloseTipDetail}
                    onTipSelected={onTipSelected}
                    tipSelectorButton={{
                      label: "Add Tip",
                    }}
                  />
                ) : (
                  <TemplateGoogleMap
                    onBoundsUpdate={onChangeBounds}
                    lat={location.lat}
                    lng={location.lng}
                    onTipSelected={onTipSelected}
                    tipSelectorButton={{
                      label: "Add Tip",
                    }}
                  />
                )}
              </div>

              <Paginator
                page={pageIndex}
                getPreviousPage={getPreviousPage}
                getNextPage={getNextPage}
                lastPage={state.tips.lastPage}
                className={"tip-selector__paginate-btn"}
                getPageButton={setPageNumber}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

TipSelector.propTypes = {
  onHide: PropTypes.func.isRequired,
  submitTip: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default TipSelector;
