import React, {useContext, useEffect, useState} from 'react';
import './Dashboard.css'
import {DashboardContext} from "../../store/context/DashboardContext";
import {Table} from "reactstrap";

const ChangedTemplates = () => {
    const {state, actions} = useContext(DashboardContext);

    useEffect(() => {
        // actions.fetchOverviewChangedTemplates();
    }, [])


    return (
        <div className="dashboard">
            <b className="title">Changes by admins in templates</b>
            <br/>
            <br/>

            <Table responsive="sm">
                <thead>
                <tr>
                    <th>Template title</th>
                    <th>Company</th>
                    <th>Impacted itineraries</th>
                    <th>Admin name</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {state.overviewChangedTemplates.map((changedTemplate, i) => (
                    <tr key={i}>
                        <td>{changedTemplate.title}</td>
                        <td>{changedTemplate.company}</td>
                        <td>{changedTemplate.impactedItineraries}</td>
                        <td>{changedTemplate.adminName}</td>
                        <td>{changedTemplate.date}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ChangedTemplates;
