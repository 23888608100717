import React, { useState, useContext, useEffect } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import { DeepClone } from "../../../../utils";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";

import "./AddTemplateToItineraryModal.css";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";

function AddTemplateToItineraryModal({ show, onHide }) {
  const { state, actions } = useContext(ItineraryContext);
  const [itinerary, setItinerary] = useState({});
  const [ journeyTemplates, setJourneyTemplates ] = useState([]);

  useEffect(() => {
    if (state?.itinerary?.data?.id) {
      const clonedItineraryData = DeepClone(state.itinerary.data);
      setItinerary(clonedItineraryData);
    }

    actions.fetchJourneyTemplates(state.itinerary.data.branch_id)
  }, [state.itinerary]);

  useEffect(() => {
    setJourneyTemplates(state.journeyTemplates)
  }, [state.journeyTemplates])

  const onSubmitTemplate = (e) => {
    e.preventDefault();
    actions.updateTemplateItinerary(itinerary.template);
    onHide(onHide);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title >
            Add / Edit Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-template-modal__body">
          <Form onSubmit={onSubmitTemplate}>
            <Form.Group>
              <Select
                name="type"
                className="add-template-modal__input-select"
                options={journeyTemplates}
                getOptionLabel={(option) => `${option.title}`}
                value={itinerary.template}
                getOptionValue={(option) => `${option.id}`}
                onChange={(data) =>
                  setItinerary({
                    ...itinerary,
                    template: data,
                  })
                }
              />
            </Form.Group>
            <div className="add-template-modal__btn">
              <RectangleButton type="submit" label="Next" />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

AddTemplateToItineraryModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default AddTemplateToItineraryModal;
