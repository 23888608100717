import React, { useContext, useState } from 'react';
import './TipsConversation.css';
import { Container, Row, Col } from 'react-bootstrap';
import { ConversationContext } from "../../../../store/context/ConversationContext";
import DeleteScheduledMessageModal from "./DeleteScheduledMessageModal";

const TipsConversationTabs = () => {
    const { state } = useContext(ConversationContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function sendDate(date) {
        let currentDate = new Date(date);
        return currentDate.toLocaleString("default", { weekday: "short" }) + ', ' + date.replace(/ /g, ' at ');
    }

    const onClickDeleteScheduledMessage = () => {
        setShowDeleteModal(true);
    };

    const onDeleteModalClose = () => {
        setShowDeleteModal(false);
    };

    return (
        <div className="conversation-tab-task-container">
            <div className="open-conversation-task">
                {state.journeyScheduledArticles != null ?
                    <Container className="tips-container">
                        {state.journeyScheduledArticles.map((journeyScheduledArticle, i) => (
                            <Row className="tips-row" key={journeyScheduledArticle.id}>

                                {new Date(journeyScheduledArticle.send_at) < Date.now() ?
                                    <Col sm={1}>
                                        {i === 0 ?
                                            <div className="tips-circle-sent" />
                                            : null}
                                        <div className="tips-line-sent" />
                                        <div className="tips-circle-sent" />
                                    </Col>
                                    :
                                    <Col sm={1}>
                                        {i === 0 ?
                                            <div className="tips-circle-scheduled" />
                                            : null}
                                        <div className="tips-line-scheduled" />
                                        <div className="tips-circle-scheduled" />
                                    </Col>
                                }

                                <Col sm={9}>
                                    <div className="tips-date">{sendDate(journeyScheduledArticle.send_at)}</div>
                                    {journeyScheduledArticle.template ? (
                                        <div className="tips-text">{journeyScheduledArticle.complete_message}</div>
                                    ) : (
                                        <>
                                            <div className="tips-text scheduled-msg">
                                                {journeyScheduledArticle.complete_message}
                                                {journeyScheduledArticle.status !== 3 &&
                                                    <button className="tips__delete-scheduled-msg-btn" onClick={onClickDeleteScheduledMessage} type="submit" label="X"></button>
                                                }
                                            </div>

                                            <DeleteScheduledMessageModal show={showDeleteModal} onHide={onDeleteModalClose} data={journeyScheduledArticle} />
                                        </>
                                    )}
                                </Col>

                                <Col sm={2}>
                                    {journeyScheduledArticle.status === 3 ? (
                                        <p className="sent-tips">Sent</p>
                                    ) : (
                                        <></>
                                    )}
                                    {journeyScheduledArticle.status === 1 ? (
                                        <p className="scheduled-tips">Scheduled</p>
                                    ) : (
                                        <></>
                                    )}
                                    {journeyScheduledArticle.status === 0 ? (
                                        <p className="scheduled-tips">Paused</p>
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </Container>
                    : null}
            </div>

        </div>
    );
};

export default TipsConversationTabs;
