import React, {useContext, useState} from 'react';
import DefaultTabInfo from '../../OpenConversations/OpenConversationTabs/DefaultTabInfo';
import './TaskConversation.css';
import {Col, Container, Row} from "react-bootstrap";
import {ConversationContext} from "../../../../store/context/ConversationContext";

const TaskConversation = () => {
    const {state, actions} = useContext(ConversationContext);

    const [user,] = useState(JSON.parse(localStorage.getItem('access_token')));

    return (
        <div className="conversation-tab-task-container">
            <div className="open-conversation-task">
                {state.conversationTaskList.length > 0 ?
                    <Container>
                        {state.conversationTaskList.map((task, i) => (
                            <Row key={i}>
                                {task.trip_action != null && task.trip_action?.name ?
                                    <div className="conversation-task-block">
                                        <Col sm={8}>
                                            <label className="conversation-task-label">
                                                <input className="conversation-task-checkbox-input"
                                                       type="checkbox"
                                                       name="bookingRefercePresent"
                                                       checked={false}
                                                       onChange={() => {
                                                       }}
                                                /> {task.trip_action.name}
                                            </label>
                                        </Col>

                                        {task.agentname === null ?
                                            <Col sm={4}>
                                                <button className="no-assignee"
                                                        onClick={() => actions.assignTask(task.id)}>
                                                    Assign to me
                                                </button>
                                            </Col>
                                            :
                                            <Col sm={4}>
                                                {task.agentname.id === user.id ?
                                                    <button className="no-assignee"
                                                            onClick={() => actions.unAssignTask(task.id)}>
                                                        Unassign
                                                    </button>
                                                    :
                                                    <span className="no-assignee ml-1 text-dark">
                                                {task.agentname.name}
                                            </span>
                                                }
                                            </Col>
                                        }
                                    </div>
                                    : ''
                                }
                            </Row>
                        ))}
                    </Container>
                    : null}
            </div>

            <DefaultTabInfo/>
        </div>
    );
};

export default TaskConversation;
