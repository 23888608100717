import React from "react";
import { Route, Redirect } from "react-router-dom";
import {} from "history";
import { checkToken } from "./utils/auth";

const ProtectRoute = ({ path, children }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        if (checkToken()) {
          return <>{children}</>;
        } else {
          return <Redirect to={{ pathName: "/" }} />;
        }
      }}
    ></Route>
  );
};

export default ProtectRoute;
