import React from "react";
import { useReducer } from "react";
import { reducer, initialState } from "../reducers/DashboardReducer";
import { useActions } from "../actions/DashboardActions";

export const DashboardContext = React.createContext();

const DashboardContextApi = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actions = useActions(state, dispatch);

    return (
        <DashboardContext.Provider value={{state, dispatch, actions}}>
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardContextApi;
