import React, {useContext, useEffect, useRef, useState} from 'react';
import './Dashboard.css'
import { DashboardContext } from "../../store/context/DashboardContext";
import { Table } from "reactstrap";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import RectangleButton from "../Reusable/RectangleButton/RectangleButton";
import {AppContext} from "../../store/context/AppContext";
import SuccessModal from "../Reusable/SuccessModal/SuccessModal";
import ErrorModal from "../Reusable/ErrorModal/ErrorModal";

const ImportQuickReplies = () => {
    const { state, actions } = useContext(DashboardContext);
    const { state: AppState } = useContext(AppContext);
    const [quickReply, setQuickReply] = useState({title: '', branch_id: null, body: ''});
    const [quickReplyFile, setQuickReplyFile] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedBranchFile, setSelectedBranchFile] = useState(null);
    const ref = useRef();


    useEffect(() => {
    }, [quickReply, quickReplyFile, selectedBranch, selectedBranchFile]);

    const onChange = (e) => {
        setQuickReply({ ...quickReply, [e.target.name]: e.target.value });
    };

    const onChangeFile = (e) => {
        setQuickReplyFile(e.target.files[0]);
    };

    const onCurrentFormSubmit = (e) => {
        e.preventDefault();
        quickReply.branch_id = selectedBranch.id;
        actions.addNewQuickReply(quickReply);
        setQuickReply({title: '', branch_id: null, body: ''});
        setSelectedBranch(null);
    };

    const onCurrentFileFormSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('branch_id', selectedBranchFile.id)
        formData.append('file', quickReplyFile)
        actions.addNewQuickReplyFile(formData);
        setQuickReplyFile(null);
        setSelectedBranchFile(null);
        ref.current.value = "";
    };

    const clearSuccess = () => {
        actions.clearSuccess();
    };

    const clearErrors = () => {
        actions.clearErrors();
    };

    return (
        <div className="dashboard">
            <b className="title">Import Quick Replies</b>
            <br />
            <br />

            <Table responsive="sm">
                <thead>
                <tr>
                    <th>Import current quick reply</th>
                    <th>Import using xls file</th>
                </tr>
                </thead>
            </Table>

            <Row>
                <Col md={6}>
                    <Form
                        className="add-itinerary-modal__form"
                        onSubmit={onCurrentFormSubmit}
                    >
                        <Form.Group>
                            <Form.Control
                                className="add-itinerary-modal__form-input"
                                required
                                type="text"
                                onChange={onChange}
                                name="title"
                                placeholder="Enter title"
                                value={quickReply.title}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Select
                                className="add-itinerary-modal__form-input"
                                name="branch_id"
                                placeholder="Select branch"
                                value={selectedBranch}
                                options={AppState.inputs.companies}
                                getOptionLabel={(option) => `${option.branch_name}`}
                                getOptionValue={(option) => `${option.id}`}
                                onChange={(data) =>
                                    setSelectedBranch(data)
                                }
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                className="add-itinerary-modal__form-input"
                                required
                                type="text"
                                onChange={onChange}
                                name="body"
                                placeholder="Enter body"
                                value={quickReply.body}
                            />
                        </Form.Group>

                        <RectangleButton
                            type="submit"
                            label="Create"
                            className="add-itinerary-modal__submit-btn"
                        />
                    </Form>
                </Col>
                <Col md={6}>
                    <Form
                        className="add-itinerary-modal__form"
                        onSubmit={onCurrentFileFormSubmit}
                    >
                        <Form.Group>
                            <Select
                                className="add-itinerary-modal__form-input"
                                name="file_branch_id"
                                placeholder="Select branch"
                                value={selectedBranchFile}
                                options={AppState.inputs.companies}
                                getOptionLabel={(option) => `${option.branch_name}`}
                                getOptionValue={(option) => `${option.id}`}
                                onChange={(data) =>
                                   setSelectedBranchFile(data)
                                }
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                className="add-itinerary-modal__form-input"
                                required
                                type="file"
                                onChange={onChangeFile}
                                name="file"
                                ref={ref}
                            />
                        </Form.Group>
                        <p className="import-quick-replies_label">The file should have a CSV format and contain only a `title` and `body` columns</p>

                        <RectangleButton
                            type="submit"
                            label="Create"
                            className="add-itinerary-modal__submit-btn"
                        />
                    </Form>
                </Col>
            </Row>

            <SuccessModal successMessage={state.success} onHide={clearSuccess} />
            <ErrorModal errorMessage={state.errors} onHide={clearErrors} />
        </div>
    );
};

export default ImportQuickReplies;
