//Note: Do we need a sign up in front end? Or will administrator make an account in the database?

import Axios from "axios";
import qs from "qs";

Axios.defaults.withCredentials = true;
const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  withCredentials: true,
  // Do we need headers? Otherwise please remove
  // headers: { 'content-type': 'application/x-www-form-urlencoded', }
});

export const login = (user, setLoginFailed) => {
  return axios({
    method: "POST",
    url: "auth/login",
    data: qs.stringify(user),
  })
    .then((response) => {
      setToken(response.data);
    })
    .catch((err) => {
      setLoginFailed(true);
      alert("Failed to log in, please try again");
    });
};

export const logout = () => {
  return axios
    .post("/auth/logout")
    .then(() => {
      clearToken();
      clearUserData();
    })
    .then(() => {
      window.location = window.location;
    })
    .catch((err) => {
      console.log(err);
      clearToken();
      clearUserData();
      window.location = window.location;
    });
};

export const getMe = () => {
  axios
    .post("/auth/me")
    .then((response) => {
      setUserData(response);
    })
    .catch((err) => {
      console.log(err);
    });
};

export async function getGoogle2faCode(credentials) {
  return axios({
    method: "POST",
    url: "/auth/google-2fa",
    data: qs.stringify(credentials)
  })
};

export const checkGoogle2fa = (code, setLoginFailed) => {
  return axios({
    method: "POST",
    url: "auth/check-google-2fa",
    data: code,
  })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        setLoginFailed(true);
        alert("Failed to log in, please try again");
      });
};

export const clearToken = () => {
  window.localStorage.removeItem("access_token");
};

export const userIsLoggedIn = () => (getToken() ? true : false);

//keeping the token in localStorage. localStorage can only save strings
export const setToken = (token) => {
  window.localStorage.setItem("access_token", JSON.stringify(token));
};

export const setUserData = (response) => {
  window.localStorage.setItem("user_data", JSON.stringify(response.data));
};

export const clearUserData = () => {
  window.localStorage.removeItem("user_data");
};

//a function that gets the token out of the localstorage and turns it into a js object again. Use this function to protect routes, or personalisation
export const getToken = (token) => {
  return JSON.parse(window.localStorage.getItem("access_token"));
};

export const checkToken = () => {
  //this function checks if the token has less than one minute until it expires
  const subMinutes = function (dt, minutes) {
    return dt.getTime() - minutes * 60000;
  };
  let token = JSON.parse(window.localStorage.getItem("access_token"));

  if (token !== null) {
    let token_expiry_date = subMinutes(new Date(token.token_expiry), 2);
    let current_time = Date.now();

    if (token_expiry_date <= current_time) {
      console.log("token is expired");
      return axios
        .get("/auth/refresh")
        .then((response) => {
          if (response.data.access_token != null) {
            setToken(response.data);
            return true;
          } else {
            clearToken();
            clearUserData();
            window.location.reload();
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
          clearToken();
          clearUserData();
          window.location.reload();
          return false;
        });
    } else {
      console.log("token is still valid");
      document.getElementById("favicon").href = 'static/images/logo.png';
      return true;
    }
  } else {
    clearToken();
    clearUserData();
    return false;
  }
};
