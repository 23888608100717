import React from "react";
import PropTypes from "prop-types";

import "./ActivityLog.css";

function ActivityLog({ date, name, type, module, className = "" }) {
  const activityLogClass = `activity-log ${className}`;
  const activityLogClassName = `activity-log activity-log--name ${className}`;
  const activityLogClassType = `activity-log activity-log--type${className}`;

  let text;
  switch (type) {
    case "created":
      text = `created this ${module}`;
      break;
    case "updated":
      text = `updated this ${module}`;
      break;
    case "approved":
      text = `approved this ${module}`;
      break;
    case "rejected":
      text = `rejected this ${module}`;
      break;
    
    default:
      text = `worked on this ${module}`;
  }

  let shortDate = date.substring(0, 10);

  return (
    <div>
      <div className="activity-log__date-time">
        <div className={activityLogClass}>{shortDate}</div>
      </div>
      <div className="activity-log__name-type">
        <div className={activityLogClassName}>{name ? name : "Unknown"}</div>
        <div className={activityLogClassType}>{text}</div>
      </div>
    </div>
  );
}

ActivityLog.propTypes = {
  data: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  module: PropTypes.string,
  className: PropTypes.string,
};

export default ActivityLog;
