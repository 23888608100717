import React from "react";
import PropTypes from "prop-types";
import { withGoogleMap, GoogleMap } from "react-google-maps";
import useScript from "../../../utils/useScript";
import { GOOGLE_MAPS_URL } from "../../../lib/constants";

const CustomGoogleMap = withGoogleMap(
  ({ children, center, defaultZoom = 13, onBoundsUpdate }) => {
    const mapRef = React.useRef();

    const onMapLoad = (map) => {
      mapRef.current = map;
    };

    const onMapClicked = React.useCallback((map) => {
      const latLongChange = mapRef.current.getBounds();

        let keys = Object.keys(latLongChange);
        let firstValue = latLongChange[keys[0]];
        let secondValue = latLongChange[keys[1]];
        let firstValueKeys = Object.keys(firstValue);
        let secondValueKeys = Object.keys(secondValue);

        const newBounds = {
          swlat: firstValue[firstValueKeys[1]],
          swlng: secondValue[secondValueKeys[1]],
          nelat: firstValue[firstValueKeys[0]],
          nelng: secondValue[secondValueKeys[0]],
      };

      const centerChange = mapRef.current.getCenter().toJSON();
      const newCenter = {
        lat: centerChange.lat,
        lng: centerChange.lng,
      };

      if (onBoundsUpdate) onBoundsUpdate(newBounds, newCenter);
    }, []);

    return (
      <GoogleMap
        ref={mapRef}
        onClick={onMapClicked}
        center={center}
        defaultZoom={defaultZoom}
        onLoad={onMapLoad}
      >
        {children}
      </GoogleMap>
    );
  }
);

CustomGoogleMap.propTypes = {
  children: PropTypes.any,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  defaultZoom: PropTypes.number,
  onBoundsUpdate: PropTypes.func,
};

function GoogleMapWrapper(props) {
  const status = useScript(GOOGLE_MAPS_URL);

  if (status !== "ready") {
    return <div>Loading Google Maps</div>;
  }

  return (
    <CustomGoogleMap
      {...props}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={
        <div
          style={{
            height: `100%`,
            borderRadius: "8px",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.08)",
          }}
        />
      }
    />
  );
}

export default GoogleMapWrapper;
