import React from "react";
import { useReducer } from "react";
import { reducer, initialState } from "../reducers/KpiReducer";
import { useActions } from "../actions/KpiActions";

export const KpiContext = React.createContext();

const KpiContextApi = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actions = useActions(state, dispatch);

    return (
        <KpiContext.Provider value={{state, dispatch, actions}}>
            {children}
        </KpiContext.Provider>
    );
};

export default KpiContextApi;
