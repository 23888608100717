import React, { useContext, useEffect } from 'react';
import './Dashboard.css'
import Tabs from "react-bootstrap/Tabs";
import ErrorList from "./ErrorList";
import ImportedFiles from "./ImportedFiles";
import ChangedTemplates from "./ChangedTemplates";
import ImportedSheets from "./ImportedSheets";
import OverviewScheduledMessages from "./OverviewScheduledMessages";
import ImportQuickReplies from "./ImportQuickReplies";

import { DashboardContext } from "../../store/context/DashboardContext";

const Dashboard = () => {
    const { state, actions } = useContext(DashboardContext);

    useEffect(() => {
        actions.fetchClients();
    }, [])

    return (
        <div className="roles-permissions">
            <Tabs defaultActiveKey="overviewScheduledMessages" id="overview-messages">
                <Dashboard className="overviewScheduledMessages" eventKey="overviewScheduledMessages" title="Overview scheduled messages">
                    <OverviewScheduledMessages />
                </Dashboard>
                <Dashboard className="errorList" eventKey="errorList" title="Error List">
                    <ErrorList />
                </Dashboard>
                <Dashboard className="importFiles" eventKey="importFiles" title="Imported Files Logs">
                    <ImportedFiles />
                </Dashboard>
                <Dashboard className="changedTemplates" eventKey="changedTemplates" title="Overview changes templates">
                    <ChangedTemplates />
                </Dashboard>
                <Dashboard className="importedSheets" eventKey="importedSheets" title="Imported Journey Templates">
                    <ImportedSheets />
                </Dashboard>
                <Dashboard className="importQuickReplies" eventKey="importQuickReplies" title="Import Quick Replies">
                    <ImportQuickReplies />
                </Dashboard>
            </Tabs>
        </div>
    );
}
    ;

export default Dashboard;
