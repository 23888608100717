import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./SearchInputField.css";

function SearchInputField({
  placeholder,
  value,
  onChange,
  onClickSearch,
  className,
}) {
  return (
    <div className="search-input-field">
      <div>
        <input
          type="text"
          name={placeholder}
          placeholder={placeholder}
          value={value || ""}
          onChange={onChange}
          className={classnames("search-input-field__input", className)}
        ></input>
      </div>
      {onClickSearch &&
        <button
          className="search-input-field__btn"
          onClick={onClickSearch}
        ></button>
      }

    </div>
  );
}

SearchInputField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClickSearch: PropTypes.func,
  className: PropTypes.string,
};

export default SearchInputField;
