import React from "react";
import { useReducer } from "react";
import { reducer, initialState } from "../reducers/ConversationReducer";
import { useActions } from "../actions/ConversationActions";

export const ConversationContext = React.createContext(     );

const ConversationContextApi = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actions = useActions(state, dispatch);

    return (
        <ConversationContext.Provider value={{state, dispatch, actions}}>
            {children}
        </ConversationContext.Provider>
    );
};

export default ConversationContextApi;
