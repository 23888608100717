import React, { useContext, useEffect, useState } from 'react';
import './AnalysisConversation.css';
import Select from "react-select";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { ConversationContext } from "../../../../store/context/ConversationContext";

const AnalysisConversationTabs = (props) => {
    const { state, actions } = useContext(ConversationContext);
    const [selectedItinerary, setSelectedItinerary] = useState([]);
    const [tipsCount, setTipsCount] = useState(0);
    const [analysisData, setAnalysisData] = useState({})
    const [formData, updateFormData] = useState({});

    useEffect(() => {
        setSelectedItinerary([]);
    }, [state.currentConversation.id])

    useEffect(() => {
        setAnalysisData(state.analysisData);

        return () => {
            setAnalysisData({});
            updateFormData({});
        }
    }, [state.analysisData.id, state.currentConversation.id]);

    const handleChange = (e, name, isCheckbox = false) => {
        if (typeof e.target === 'undefined') {
            analysisData[name] = e.value;
            updateFormData({
                ...formData,
                [name]: e.value
            });
        } else {
            if (isCheckbox) {
                analysisData[name] = e.target.checked;
            } else {
                if (name === 'feedback_rating') {
                    analysisData[name] = e.target.value % 1 === 0 ? e.target.value : parseFloat(e.target.value).toFixed(1);
                } else if (name === 'status') {
                    analysisData['status'] = state.analysisStatuses.find(obj => obj.value == analysisData['status']) ?? '';
                } else {
                    analysisData[name] = e.target.value;
                }
            }

            updateFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }

        // check interaction
        if (name === 'questions') {
            analysisData['interaction'] = e.target.value > 0 ? '1' : '0';
        }
    };

    const changeItinerary = (event) => {
        setSelectedItinerary(event);
        actions.fetchAnalysisData(state.currentConversation.id, event.id);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let storeData = {};

        storeData = {
            'conversation_id': state.currentConversation.id,
            'itinerary_id': selectedItinerary?.id ? selectedItinerary.id : null,
            'status': analysisData['status'],
            "trip_progress": analysisData['trip_progress'],
            "feedback_comment": analysisData['feedback_comment'],
            "tips": analysisData['tips'] != null ? analysisData['tips'] : tipsCount,
            "interaction": Boolean(Number(analysisData['interaction'])),
            "questions": analysisData['questions'] != null ? analysisData['questions'] : state.currentConversation.number_of_questions,
            "product_questions": analysisData['product_questions'],
            "other_questions": analysisData['other_questions'],
            "product_complaints": analysisData['product_complaints'],
            "travel_pictures": Boolean(Number(analysisData['travel_pictures'])),
            "notes": analysisData['notes'],
            "feedback_rating": analysisData['feedback_rating'],
            "feedback": analysisData['feedback'],
            "retention": analysisData['retention'] ?? null,
            "nps1": analysisData['nps1'] ? analysisData['nps1'] : null,
            "lead_status": analysisData['lead_status'],
        };

        actions.storeConversationAnalyse(storeData);
        setTimeout(() => { setShow(true); }, 1000);
    }

    const [show, setShow] = useState(false);
    const handleShowCloseConversation = () => setShow(false);

    return (
        <div className="conversation-tab-task-container">
            <div className="open-conversation-task">

                <div className="analysis-block">
                    <p className="analysis-title">Choose itinerary</p>
                    <Select
                        name="itinerary_id"
                        options={props.selectItineraries}
                        classNamePrefix="select"
                        placeholder="Select itinerary"
                        getOptionLabel={(option) => `${option.dossier_id}`}
                        getOptionValue={(option) => `${option.id}`}
                        onChange={(event) => changeItinerary(event)}
                        value={selectedItinerary}
                    />
                </div>
                <hr></hr>

                <form className="analysis-form" onSubmit={handleSubmit}>
                    <div className={`save-analysis-${props.pageName} text-right`}>
                        <input type="submit" value="Save analysis" />
                    </div>
                    <div id="analysis_errors">
                        <Modal show={show} onHide={handleShowCloseConversation}>
                            <Modal.Header closeButton>
                                <Modal.Title>Conversation analysis</Modal.Title>
                            </Modal.Header>
                            {state.analysisStoreResponseData.code === 200 ?
                                <Modal.Body>Saved successfully</Modal.Body>
                                : ''}
                            {state.analysisStoreResponseData.code === 400 ?
                                <Modal.Body>
                                    <p>Analysis could not be saved because of the following error(s):</p>
                                    <ul>
                                        {state.analysisStoreResponseData.data.map((analysisStoreResponse, key) => (
                                            <li key={key}>{analysisStoreResponse}</li>
                                        ))}
                                    </ul>
                                </Modal.Body>
                                : ''}
                            <Modal.Footer>
                                <Button className="delete-button" onClick={handleShowCloseConversation}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Status analysis</p>
                        <Select
                            name="status"
                            options={state.analysisStatuses}
                            classNamePrefix="select"
                            placeholder="Not done"
                            onChange={(event) => handleChange(event, 'status')}
                            value={state.analysisStatuses.find(obj => obj.value == analysisData['status']) ?? ''}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Trip progress</p>
                        <Select
                            name="trip_progress"
                            options={state.tripProgresses}
                            classNamePrefix="select"
                            placeholder="Set a trip progress"
                            onChange={(event) => handleChange(event, 'trip_progress')}
                            value={state.tripProgresses.find(obj => obj.value == analysisData['trip_progress']) ?? ''}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Will use it again?</p>
                        <Select
                            name="feedback"
                            options={state.feedbackSelect}
                            classNamePrefix="select"
                            placeholder="Select will use option"
                            onChange={(event) => handleChange(event, 'feedback')}
                            value={analysisData['feedback'] != null ? state.feedbackSelect.find(obj => obj.value == analysisData['feedback']) : ''}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Current lead status</p>
                        <Select
                            name="lead_status"
                            options={state.lead_status}
                            classNamePrefix="select"
                            placeholder="Select lead status option"
                            onChange={(event) => handleChange(event, 'lead_status')}
                            value={analysisData['lead_status'] != null ? state.lead_status.find(obj => obj.value === analysisData['lead_status']) : ''}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Retention</p>
                        <Select
                            name="retention"
                            options={state.retention}
                            classNamePrefix="select"
                            placeholder="Select retention option"
                            onChange={(event) => handleChange(event, 'retention')}
                            value={analysisData['retention'] != null ? state.retention.find(obj => obj.value === analysisData['retention']) : ''}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Feedback rating</p>
                        <input className="analysis-input" type="number" name="feedback_rating"
                            placeholder=""
                            step='0.1'
                            value={analysisData['feedback_rating'] != null ? analysisData['feedback_rating'] : ''}
                            onChange={(event) => handleChange(event, 'feedback_rating')}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Feedback</p>
                        <textarea placeholder="Add feedback comments here" name="feedback_comment"
                            className="analysis-textarea"
                            rows="5"
                            id="feedback_comment"
                            value={analysisData['feedback_comment'] != null ? analysisData['feedback_comment'] : ''}
                            onChange={(event) => handleChange(event, 'feedback_comment')}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Number of tips</p>
                        <input className="analysis-input" type="number" min="0" name="tips"
                               placeholder="0"
                               value={analysisData['tips'] != null ? analysisData['tips'] : tipsCount}
                               onChange={(event) => handleChange(event, 'tips')}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Number of questions</p>
                        <input className="analysis-input" type="number" min="0" name="questions"
                            placeholder="0"
                            value={analysisData['questions'] != null ? analysisData['questions'] : state.currentConversation.number_of_questions}
                            onChange={(event) => handleChange(event, 'questions')}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Product questions</p>
                        <textarea placeholder="Add questions about product here" name="product_questions"
                            className="analysis-textarea"
                            rows="5"
                            id="product_questions"
                            value={analysisData['product_questions'] != null ? analysisData['product_questions'] : ''}
                            onChange={(event) => handleChange(event, 'product_questions')}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Other questions</p>
                        <textarea placeholder="Add other questions here" name="other_questions"
                            className="analysis-textarea"
                            rows="5"
                            id="other_questions"
                            value={analysisData['other_questions'] != null ? analysisData['other_questions'] : ''}
                            onChange={(event) => handleChange(event, 'other_questions')}
                        />
                    </div>

                    <div className="analysis-block">
                        <p className="analysis-title">Product complaints</p>
                        <textarea name="product_complaints"
                            className="analysis-textarea"
                            rows="5"
                            id="product_complaints"
                            placeholder="This is a complaint about a suggestion that I received"
                            value={analysisData['product_complaints'] != null ? analysisData['product_complaints'] : ''}
                            onChange={(event) => handleChange(event, 'product_complaints')}
                        />
                    </div>

                    <Container className="analysis-block">
                        <Row>
                            <Col sm={12}>
                                <p className="analysis-title">Travel pictures</p>
                                <div>
                                    <input className="analysis-input" type="checkbox" name="travel_pictures"
                                        onChange={(event) => handleChange(event, 'travel_pictures', true)}
                                        checked={Boolean(Number(analysisData['travel_pictures'])) ? true : false} />
                                    <label className="analysis-label">Sent pictures</label>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <div className="analysis-block">
                        <p className="analysis-title">Notes</p>
                        <textarea name="notes"
                            className="analysis-textarea"
                            rows="5"
                            id="notes"
                            placeholder="Add notes"
                            value={analysisData?.notes || ''}
                            onChange={(event) => handleChange(event, 'notes')}
                        />
                    </div>

                </form>
            </div>
        </div>
    );
};

export default AnalysisConversationTabs;
