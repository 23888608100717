const initialState = {
    users: [],
    companies: [],
    roles: [],
    permissions: [],
    updateUserErrors: [],
    updateCreateRoleErrors: [],
};

const types = {
    FETCH_ROLES_DATA: "FETCH_ROLES_DATA",
    FETCH_ROLES_PERMISSIONS_DATA: "FETCH_ROLES_PERMISSIONS_DATA",
    FETCH_USERS_DATA: "FETCH_USERS_DATA",
    FETCH_COMPANIES_DATA: "FETCH_COMPANIES_DATA",
    DELETE_ROLE: "DELETE_ROLE",
    FAILED_UPDATE_USER: "FAILED_UPDATE_USER",
    FAILED_CREATE_UPDATE_ROLE: "FAILED_CREATE_UPDATE_ROLE",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_ROLES_DATA:
            return {
                ...state,
                roles: action.payload
            };
        case types.FETCH_ROLES_PERMISSIONS_DATA:
            return {
                ...state,
                permissions: action.payload
            };
        case types.DELETE_ROLE:
            let deleteRole = state.roles.find((role) => {
                if (role.id === parseInt(action.payload)) {
                    return true;
                }
                return false;
            });
            state.roles.splice(state.roles.indexOf(deleteRole),1);

            return {
                ...state,
                permissions: action.payload
            };
        case types.FETCH_USERS_DATA:
            return {
                ...state,
                users: action.payload
            };
        case types.FETCH_COMPANIES_DATA:
            return {
                ...state,
                companies: action.payload
            };
        case types.FAILED_UPDATE_USER:
            state.updateUserErrors = [];
            return {
                ...state,
                updateUserErrors: action.payload
            };
        case types.FAILED_CREATE_UPDATE_ROLE:
            state.updateCreateRoleErrors = [];
            return {
                ...state,
                updateCreateRoleErrors: action.payload
            };
        default:
            throw new Error("Unexpected action");
    }
};
export {initialState, types, reducer};
