import React, { useState } from "react";
import {getGoogle2faCode, setToken} from "../../utils/auth";
import { useHistory } from "react-router-dom";
import "./Login.css";

function Login(props) {
  const history = useHistory();
  const [loginFailed, setLoginFailed] = useState(false)
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // 'login' refers to function in 'utils/auth'
  const handleSubmit = (e) => {
    e.preventDefault();
    getGoogle2faCode(state)
        .then((response) => {
          if(typeof response.data.access_token !== "undefined" && response.data.access_token !== '') {
            setToken(response.data)
            history.push("/inbox");
          } else {
            history.push({
              pathname: "/google-2fa",
              state: state,
              qr_code: response.data.data.qr_code
            });
          }
        })
        .catch((err) => {
          console.log(err);
          history.push("/");
        });
  };

  const forgotPassword = () => {
    history.push("/forgot-password");
  };

  return (
    <article className="bg">
      <form>
        <div className="login__container">
          <img
            src="https://res.cloudinary.com/dsuhcbmxk/image/upload/v1594286546/logo-1-small.png"
            alt="logo"
          />
          <div className="login__form">
            <div>
              <input
                onChange={handleChange}
                defaultValue={state.email}
                name="email"
                type="text"
                placeholder="Email address"
                className="form-section"
              />
            </div>
          </div>
          <div className="login__form">
            <div>
              <input
                onChange={handleChange}
                defaultValue={state.password}
                name="password"
                type="password"
                placeholder="Password"
                className="form-section"
              />
            </div>
          </div>
          <p className="forgot_password_link" onClick={forgotPassword}>
            Forgot password?
          </p>
          <button className="login__btn" onClick={handleSubmit}>
            <p className="login__text">LOG IN</p>
          </button>
          <br />
          <div>{loginFailed && "failed to log in"} </div>
          {/* <p id="login__error-message">failed message</p> */}
        </div>
      </form>
    </article>
  );
}

export default Login;
