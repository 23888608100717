import Axios from "axios";
import { types } from "../reducers/TemplateReducer";
import axios from "axios";

export const useActions = (state, dispatch) => {
  const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    withCredentials: true,
  });

  function fetchTemplates(page = 1, filters = {}) {
    let url = `api/journeys?page=${page}`;
    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "undefined") continue;
      if (value === null) continue;
      if (typeof value === "string" && !value.length) continue;
      url += `&${key}=${value}`;
    }

    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        const newTemplates = Object.assign({}, state.templates, {
          allTemplates: response.data.data,
          nextPageUrl: response.data.next_page_url,
          lastPageUrl: response.data.last_page_url,
          lastPage: response.data.last_page,
        });
        dispatch({ type: types.UPDATE_TEMPLATES, payload: newTemplates });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to fetch the templates from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function fetchChannels(branchId) {
    axios({
      method: "GET",
      url: "api/channels/" + branchId,
    })
      .then((response) => {
        dispatch({ type: types.UPDATE_CHANNELS, payload: response.data });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to fetch the channels from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

   function fetchChannelsByBranches(branchIds) {
    const url = process.env.REACT_APP_BASEURL + "/api/channels-by-branches";
    let data = {
      branch_ids: branchIds
    };

    axios
        .post(url, data)
        .then((response) => {
          dispatch({ type: types.UPDATE_CHANNELS, payload: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
  }


  function filterTemplates(filter = {}) {
    fetchTemplates(1, filter);
    dispatch({
      type: types.UPDATE_FILTER,
      payload: filter,
    });
  }

  function clearFilterTemplates(filter = {}) {
    fetchTemplates(1, filter);
    dispatch({
      type: types.CLEAR_FILTER,
      payload: false,
    });
  }

  function addNewTemplate(data, history) {
    if (!data.draft) {
      data.save = true;
    }
    axios({
      method: "POST",
      url: "api/journeys/create",
      data,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        const templateToEdit = Object.assign(state.template.data, data);
        dispatch({ type: types.UPDATE_TEMPLATE, payload: templateToEdit });
        history.push(`/templates/${data.id}`);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to create a new template";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function getTemplate(id) {
    axios({
      method: "GET",
      url: `api/journeys/${id}`,
    })
      .then((response) => {
        const templateToEdit = Object.assign({}, state.template, {
          data: response.data,
        });
        dispatch({ type: types.UPDATE_TEMPLATE, payload: templateToEdit });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Failed to get template from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function editTemplate(data, onClickEdit) {
    let type = null;
    if (data.type) {
      type = data.type.value;
    }
    let draft = null;
    if (data.draft) {
      draft = data.draft;
    }
    let save = null;
    if (data.save) {
      save = data.save;
    }

    let branch_id = null;
    if (data.branch) {
      branch_id = data.branch.id;
    }

    let editedTemplate = {
      id: data.id,
      title: data.title,
      type: type,
      link: data.link,
      countries: data.countries,
      tags: data.tags,
      branch_id: branch_id,
      draft: draft,
      save: save,
    };
    axios({
      method: "PUT",
      url: `api/journeys/${data.id}/update`,
      data: editedTemplate,
    })
      .then((data) => {
        axios({
          method: "GET",
          url: `api/journeys/${data.data.id}`,
        })
          .then((response) => {
            const templateToEdit = Object.assign({}, state.template, {
              data: response.data,
            });
            dispatch({ type: types.UPDATE_TEMPLATE, payload: templateToEdit });
          })
          .then(() => onClickEdit())
          .catch((error) => {
            console.log(error);
            const errorMessage =
              "Sorry, we failed to get the new version of the template";
            dispatch({
              type: types.SHOW_ERRORS,
              payload: errorMessage,
            });
          });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to save the edited version of the template";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function addDayToCurrentTemplate() {
    let data = { add_day: 1 };

    axios({
      method: "PUT",
      url: `api/journeys/${state.template.data.id}/change-end`,
      data,
    })
      .then((result) => {
        dispatch({ type: types.ADD_EMPTY_JOURNEYDAY });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Add a day to template failed";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function removeDayFromCurrentTemplate() {
    let data = { remove_day: 1 };

    axios({
      method: "PUT",
      url: `api/journeys/${state.template.data.id}/change-end`,
      data,
    })
      .then((result) => {
        dispatch({ type: types.REMOVE_JOURNEYDAY });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Remove a day from template failed";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function createJourneyDay(day, closeTemplateDay, file) {
    axios({
      method: "POST",
      url: `api/journeys/${state.template.data.id}/journey-days/create`,
      data: day,
      file: file,
    })
      .then((result) => {
        dispatch({
          type: types.SHOW_SUCCESS,
          payload:  result.data.data.response_message,
        });
        return result.data.data.journey_day_data;
      })
      .then((data) => {
        if (data.code && data.code === 400) {
          const errorMessage =
            data.data;
          dispatch({
            type: types.SHOW_ERRORS,
            payload: errorMessage,
          });
        } else {
          dispatch({
            type: types.UPDATE_JOURNEYDAY,
            payload: data,
          });
          if (closeTemplateDay) closeTemplateDay();
        }
      }).catch((error) => {
          console.log(error);
          if (error.response.status === 422) {

            dispatch({
              type: types.VALIDATE_ERRORS,
              payload: error.response.data,
            });

          } else {
            const errorMessage =
                "Sorry, we failed to update the template day";
            dispatch({
              type: types.SHOW_ERRORS,
              payload: errorMessage,
            });
          }

      });
  }

  function clearJourneyDay(journeyDay, handleCloseModal) {
    axios({
      method: "DELETE",
      url: `api/journey-day/${journeyDay.journey_day_id}/delete`,
      data: journeyDay,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        dispatch({
          type: types.CLEAR_JOURNEYDAY,
          payload: {
            dayIndex: journeyDay.day,
            messageId: journeyDay.journey_day_id
          },
        });

        if (handleCloseModal) handleCloseModal();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, deleting the data from the template failed";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function updateJourneyDay(day, closeTemplateDay, file = null) {
    axios({
      method: "PUT",
      url: `api/journeys/${state.template.data.id}/journey-days/${day.journey_day_id}/update`,
      data: day,
      file: file,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        if (data.code && data.code === 400) {
          const errorMessage =
            data.data;
          dispatch({
            type: types.SHOW_ERRORS,
            payload: errorMessage,
          });
        } else {
          getTemplate(state.template.data.id)
          dispatch({
            type: types.UPDATE_JOURNEYDAY,
            payload: data,
          });
          if (closeTemplateDay) closeTemplateDay();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {

            dispatch({
              type: types.VALIDATE_ERRORS,
              payload: error.response.data,
            });

          } else {
            const errorMessage =
                "Sorry, we failed to update the template day";
            dispatch({
              type: types.SHOW_ERRORS,
              payload: errorMessage,
            });
        }
        
      });
  }

  function cleanUpValidationErrors() {
    const errors = {
      file: ''
    };
    dispatch({
      type: types.VALIDATE_ERRORS,
      payload: errors
    });
  }

  function createLocationTemplate(location, id, onHide) {
    if (!id) throw new Error("missing journey id");
    axios({
      method: "POST",
      url: `api/journeys/${id}/journey-locations/create`,
      data: location,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        axios({
          method: "GET",
          url: `api/journeys/${data.journey_id}`,
        })
          .then((response) => {
            const templateToEdit = Object.assign({}, state.template, {
              data: response.data,
            });
            dispatch({ type: types.UPDATE_TEMPLATE, payload: templateToEdit });
            if (onHide) onHide();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = "Creating the location failed";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function updateLocationTemplate(location, id, onHide) {
    if (!id) throw new Error("missing journey id");
    axios({
      method: "PUT",
      url: `api/journeys/${id}/journey-locations/${location.id}/update`,
      data: location,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        axios({
          method: "GET",
          url: `api/journeys/${data.journey_id}`,
        })
          .then((response) => {
            const templateToEdit = Object.assign({}, state.template, {
              data: response.data,
            });
            dispatch({ type: types.UPDATE_TEMPLATE, payload: templateToEdit });
            if (onHide) onHide();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Updating the location failed";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function duplicateTemplate(data, history, onClickEdit, isDuplicate) {
    axios({
      method: "GET",
      url: `api/journeys/duplicate/${data.id}/${isDuplicate}`,
      data,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        history.push(`/templates/${data.id}`);
      })

      .then(() => onClickEdit())
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, duplicating a template failed";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function clearError() {
    const emptyError = "";
    dispatch({
      type: types.SHOW_ERRORS,
      payload: emptyError,
    });
  }

  function clearSuccess() {
    const emptySuccess = "";
    dispatch({
      type: types.SHOW_SUCCESS,
      payload: emptySuccess,
    });
  }

  function fetchWhatsappTemplates(branchId, companyChannelId) {
    axios({
      method: "GET",
      url: `api/message-templates/${branchId}/${companyChannelId}`,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        dispatch({
          type: types.UPDATE_WHATSAPP_TEMPLATES,
          payload: data,
        });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, unable to fetch whatsapp templates from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function deleteTemplate(templateId) {
    axios({
      method: "DELETE",
      url: `api/journeys/${templateId}/delete`,
    })
      .then(() => {
        console.log('template is deleted')
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, unable to delete this template.";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function importTemplates(formData) {
    axios({
      method: "POST",
      url: `api/journeys/import`,
      data: formData
    })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, unable to import new templates.";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function importAndCreateTemplates(formData) {
    axios({
      method: "POST",
      url: `api/journeys/import-and-create`,
      data: formData
    })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, unable to import new templates to be created by system failed.";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }
  
  function importWafbTemplates (formData) {
    axios({
      method: "POST",
      url: `api/message-templates/import`,
      data: formData
    })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, unable to import new WaFB templates.";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function moveMessage (id, journeyDayId, from, to, forItineraries) {
    axios({
      method: "GET",
      url: `api/journeys/move-message/${id}/${journeyDayId}/${from}/${to}/${forItineraries}`,
    })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
  }

  return {
    fetchTemplates,
    fetchChannels,
    filterTemplates,
    clearFilterTemplates,
    addNewTemplate,
    getTemplate,
    editTemplate,
    updateJourneyDay,
    clearJourneyDay,
    addDayToCurrentTemplate,
    removeDayFromCurrentTemplate,
    createJourneyDay,
    createLocationTemplate,
    updateLocationTemplate,
    duplicateTemplate,
    clearError,
    clearSuccess,
    fetchWhatsappTemplates,
    deleteTemplate,
    importTemplates,
    importAndCreateTemplates,
    importWafbTemplates,
    fetchChannelsByBranches,
    moveMessage,
    cleanUpValidationErrors
  };
};
