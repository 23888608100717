import React from "react";
import "./Assignee.css";
import { useContext } from "react";
import { TasksContext } from "../../../../../store/context/TasksContext";

const Assignee = ({ task }) => {
  const { actions } = useContext(TasksContext);

  function getInitials(agentName) {
    var initials = agentName.charAt(0);
    if (typeof agentName.split(' ')[1] != 'undefined') {
      initials += agentName.split(' ')[1].charAt(0);
    }
    return initials;
  }

  return (
    <div className="assignee__container">

      {(task.agentname && task.agentname.avatar && task.agentname.avatar.charAt(0) === '#') ?
        <div className="agent_task-icon" style={{ backgroundColor: task.agentname.avatar }}>{getInitials(task.agentname.name)} </div>
        : (
          <div className="no-assignee__icon"></div>
        )}
      <div className="assignee__information">
        <p className="assignee__name">{(task.agentname) && task.agentname.name}</p>

        {(task.completed === 1) ? (
          <button onClick={() => actions.setUncompleted(task)} className="assignee__mark-uncomplete">Mark as uncompleted</button>
        ) : (
            <button className="no-assignee" onClick={() => actions.assignTask(task)}><u>Assign to me</u></button>
          )}
      </div>
    </div>
  );
}

export default Assignee;
