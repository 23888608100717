import React, { useContext } from 'react';
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ConversationContext } from "../../../../store/context/ConversationContext";
import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";

function DeleteScheduledMessageModal({ show, onHide, data }) {
    const { actions } = useContext(ConversationContext);

    const onClickDelete = () => {
        actions.deleteScheduledMessage(data);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title className="logout__title">
                    Do you want to delete this scheduled message?
        </Modal.Title>
            </Modal.Header>
            <Modal.Body className="logout__btn">
                <RectangleButton onClick={onClickDelete} type="submit" label="Yes" />
            </Modal.Body>
        </Modal>
    );
}

DeleteScheduledMessageModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
};

export default DeleteScheduledMessageModal;
