import React, { useContext } from "react";
import Tabs from "react-bootstrap/Tabs";
import "./Inbox.css";
import { TasksContext } from "../../../store/context/TasksContext";
import UnAssignedTaskContainer from "../TaskContainer/UnAssignedTaskContainer";
import AssignedTaskContainer from "../TaskContainer/AssignedTaskContainer";
import CompletedTaskContainer from "../TaskContainer/CompletedTaskContainer";
import ErrorModal from "../../Reusable/ErrorModal/ErrorModal";

function Inbox() {
  const { state, actions } = useContext(TasksContext);

  const clearError = () => {
    actions.clearError();
  }

  return (
    <div>
      <Tabs defaultActiveKey="active" id="inbox-tabs">
        <Inbox className="active-tab" eventKey="active" title="Active Tasks">
          <AssignedTaskContainer className="tasks-agent" id='active-task-container--dropzone1' title={{ label: "WORKING ON" }}></AssignedTaskContainer>
          <UnAssignedTaskContainer className="tasks-unassigned" id="task-container--dropzone2" title={{ label: "ACTIVE" }}></UnAssignedTaskContainer>
        </Inbox>
        <Inbox className="completed-tab" eventKey="complete" title="Completed">
          <CompletedTaskContainer className="tasks-completed" title={{ label: "COMPLETED" }}></CompletedTaskContainer>
        </Inbox>

      </Tabs>
      <ErrorModal errorMessage={state.errors} onHide={clearError} />

    </div>
  );
}

export default Inbox;