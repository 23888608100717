import React, { useState } from "react";
import { GOOGLE_MAPS_URL } from "../lib/constants";
import useOnclickOutside from "react-cool-onclickoutside";
import useScript from "../utils/useScript";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import "./AutoCompleteLocation.css";

function AutoCompleteLocation({
  locationClassName,
  onChangeLocation,
  onClickClearFilter,
  onClickFilterLocation,
}) {
  const [locationDataInput] = useState(locationClassName);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const registerRef = useOnclickOutside(() => {
    clearSuggestions();
  });

  const onChangeInputLocation = (e) => {
    setValue(e.target.value);
  };

  const onClickLocation = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        if (onChangeLocation) onChangeLocation({ lat, lng });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const onClickClearInput = () => {
    setValue(null);
    onClickClearFilter();
  };

  const onFilterLocationClick = () => {
    if (value !== ""){
      onClickFilterLocation();
    }
  }

  const renderSuggestions = () =>
    data.map((suggestion, key) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <div key={key} className={locationDataInput.suggestionsontainer}>
          <li
            className={locationDataInput.suggestions}
            onClick={onClickLocation(suggestion)}
          >
            <strong>{main_text}</strong>, <small>{secondary_text}</small>
          </li>
        </div>
      );
    });

  return (
    <div className="auto-complete-location">
      <div ref={registerRef}>
        <input
          className={locationDataInput.inputField}
          value={value || ""}
          onChange={onChangeInputLocation}
          disabled={!ready}
          placeholder="Search location"
        />
        {status === "OK" && <ul>{renderSuggestions()}</ul>}
      </div>
      <button
        className="auto-complete-location__search-btn"
        onClick={onFilterLocationClick}
      ></button>
      <button
        className="auto-complete-location__clear-all-filters-btn"
        onClick={onClickClearInput}
      ></button>
    </div>
  );
}

function AutoCompleteLocationWrapper(props, key) {
  const status = useScript(GOOGLE_MAPS_URL);

  if (status !== "ready") {
    return <></>;
  }

  return <AutoCompleteLocation {...props} key={key} />;
}

export default AutoCompleteLocationWrapper;
