import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import './UserRole.css'
import {UserRolesContext} from "../../store/context/UserRolesContext";
import {Form} from "react-bootstrap";
import Select from "react-select";
import RectangleButton from "../Reusable/RectangleButton/RectangleButton";

const CreateEditRole = () => {
    let {id} = useParams();
    const {state, actions} = useContext(UserRolesContext);

    const [data, setData] = useState({
        title: '',
        permissions: '',
    });

    useEffect(() => {
        if(state.roles.length === 0) {
            actions.fetchRoles();
        }
        if(state.permissions.length === 0) {
            actions.fetchPermissions();
        }

        if (typeof id != 'undefined' && state.roles.length > 0) {
            let role = state.roles.find((role) => {
                if (role.id === parseInt(id)) {
                    return true;
                }
                return false;
            });

            setData({
                title: role?.title ? role?.title : '',
                permissions: role?.permissions ? role.permissions : '',
            });
        }

    }, [state]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (typeof id != 'undefined') {
            actions.updateRole(id, data);
        } else {
            actions.storeRole(data);
        }
    };

    const onChangeTitle = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    const changePermissions = (selectedPermission) => {
        setData({...data, permissions: selectedPermission});
    };

    return (
        <div className="roles-permissions">
            <p className="create-edit-page-title">{typeof id != 'undefined' ? 'Edit Role' : 'Create Role'}</p>

            <Form className="roles-form" onSubmit={onSubmit}>
                <Form.Group>
                    <Form.Label className="roles-form__title">Title</Form.Label>
                    <Form.Control

                        name="title"
                        type="text"
                        placeholder="add a title"
                        value={data.title}
                        onChange={onChangeTitle}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label className="roles-form__title">Permissions</Form.Label>
                    <Select
                        isMulti
                        name="permissions"
                        placeholder="select permissions"
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => `${option.id}`}
                        options={state.permissions}
                        value={data.permissions}
                        onChange={changePermissions}
                    />
                </Form.Group>

                {typeof id != 'undefined' ?
                    <RectangleButton className="btn-success" type="submit" label="Update"/>
                    :
                    <RectangleButton className="btn-success" type="submit" label="Save"/>}
            </Form>

            {state?.updateCreateRoleErrors && state.updateCreateRoleErrors.length > 0 ?
                <div>
                    {state.updateCreateRoleErrors.map((error, key) => (
                        <p key={key} className="text-danger">{error}</p>
                    ))}
                </div>
                : ''}
        </div>
    );
};

export default CreateEditRole;
