import React, {useContext, useEffect, useState} from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DragDropColumn from "./DragDropColumn";
import AddLocation from "../../Templates/EditTemplate/AddLocation/AddLocation";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {getToken} from "../../../utils/auth";
import {TemplateContext} from "../../../store/context/TemplateContext";
import {ItineraryContext} from "../../../store/context/ItineraryContext";

const ONEDAY = 24 * 60 * 60 * 1000;
const todaysDate = new Date().toDateString();

function DragDropTemplate({
        title,
        className,
        timelineData,
        departureDate,
        isEditMode,
        onClickEditJourneyDay,
        onClickClearJourneyDay,
        onClickNewJourneyDay,
        doNotShowMessages,
        onClickDeleteFromTemplateAndItineraries,
        modalTitle,
        modalFirstButton
}) {
    const [movedMessage, setMovedMessage] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    const [locationId, setLocationId] = useState(null);
    const [show, setShow] = useState(false);
    const [showMoveMessageModel, setShowMoveMessageModel] = useState(false);
    const [journeyDayToDelete, setJourneyDayToDelete] = useState({});
    const startDate = new Date(departureDate).getTime() - ONEDAY;
    const handleCloseModal = () => setShow(false);
    const handleCloseMoveMessageModal = () => setShowMoveMessageModel(false);
    const { state: templateState, actions: templateActions } = useContext(TemplateContext);
    const { state: itineraryState, actions: itineraryActions } = useContext(ItineraryContext);

    useEffect(() => {
    }, [timelineData.days, movedMessage]);

    const renderDate = (date) => {
        if (startDate) {
            const dayDate = new Date(date).toDateString();
            return (
                <div
                    className={`timeline__date ${dayDate === todaysDate ? "timeline__date--today" : ""
                    }`}
                >
                    {date}
                </div>
            );
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let movedMessage = null;

        // remove from the day
        for (let i = 0; i < timelineData.days.length; i++) {
            if(timelineData.days[i].day == result.source.droppableId) {
                for (let j = 0; j < timelineData.days[i].messages.length; j++) {
                    if(timelineData.days[i].messages[j].journey_day_id == result.draggableId) {
                        movedMessage = timelineData.days[i].messages[j];
                        timelineData.days[i].messages.splice(j,1);
                    }
                }
            }
        }

        // add to the day
        let sendAt = null;
        for (let i = 0; i < timelineData.days.length; i++) {
            if(timelineData.days[i].day == result.destination.droppableId) {
                let date = new Date(timelineData.days[i].date);
                sendAt = date.getFullYear() +
                    "-" +(date.getMonth()+1 <=9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) +
                    "-" + (date.getDate() <= 9 ? "0" + date.getDate() : date.getDate());
                movedMessage.day = parseInt(result.destination.droppableId);
                movedMessage.send_at = sendAt + movedMessage.send_at.slice(movedMessage.send_at.indexOf('T'));
                timelineData.days[i].messages.push(movedMessage)
                templateState.template.data.days = timelineData.days
                movedMessage.complete_message = movedMessage.complete_message.replace('STATUS OF MESSAGE: SENDING', 'STATUS OF MESSAGE: CHECKED')
            }
        }

        setFrom(result.source.droppableId)
        if(title === 'itinerary') {
            setTo(sendAt)
        } else {
            setTo(result.destination.droppableId)
        }
        setMovedMessage(movedMessage);

        setShowMoveMessageModel(true)
    };

    const onClickAddLocation = (location) => {
        setLocationId(location.id);
    };

    const onCloseAddLocation = () => {
        setLocationId(null);
    };

    const deleteJourneyDay = (message) => {
        setJourneyDayToDelete(message)
        setShow(true);
    }

    const onClickMoveMessageOnlyForTemplate = () => {
        if(title === 'itinerary') {
            itineraryActions.moveMessage(
                movedMessage.id,
                to
            );
        } else {
            templateActions.moveMessage(
                timelineData.id,
                movedMessage.journey_day_id,
                from,
                to,
                0
            );
        }

        setShowMoveMessageModel(false)
        setMovedMessage(null)
        setTo(null)
        setFrom(null)
    }

    const handleCancelMove = () => {
        let movedMessageBack = null;
        for (let i = 0; i < timelineData.days.length; i++) {
            if(timelineData.days[i].day == movedMessage.day) {
                for (let j = 0; j < timelineData.days[i].messages.length; j++) {
                    if(timelineData.days[i].messages[j].journey_day_id == movedMessage.journey_day_id) {
                        movedMessageBack = timelineData.days[i].messages[j];
                        timelineData.days[i].messages.splice(j,1);
                    }
                }
            }
        }

        // add to the day
        let sendAt = null;
        for (let i = 0; i < timelineData.days.length; i++) {
            if(timelineData.days[i].day == from) {
                let date = new Date(timelineData.days[i].date);
                sendAt = date.getFullYear() +
                    "-" +(date.getMonth()+1 <=9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) +
                    "-" + (date.getDate() <= 9 ? "0" + date.getDate() : date.getDate());
                movedMessageBack.day = parseInt(movedMessageBack.day);
                movedMessageBack.send_at = sendAt + movedMessageBack.send_at.slice(movedMessageBack.send_at.indexOf('T'));
                timelineData.days[i].messages.push(movedMessageBack)
                templateState.template.data.days = timelineData.days
                movedMessage.complete_message = movedMessage.complete_message.replace('STATUS OF MESSAGE: CHECKED', 'STATUS OF MESSAGE: ' + movedMessage.status)
            }
        }

        setShowMoveMessageModel(false)
        setMovedMessage(null)
        setTo(null)
        setFrom(null)
    }

    const onClickMoveMessageForTemplateAndItineraries = () => {
        templateActions.moveMessage(
            timelineData.id,
            movedMessage.journey_day_id,
            from,
            to,
            1
        );

        setShowMoveMessageModel(false)
        setMovedMessage(null)
        setTo(null)
        setFrom(null)
    }

    return (
        <div className={className}>
            {locationId && (
                <AddLocation
                    show={locationId !== null}
                    onHide={onCloseAddLocation}
                    data={timelineData}
                    locationId={locationId}
                />
            )}

            <ul className="timeline">
                <DragDropContext onDragEnd={onDragEnd}>
                {timelineData && timelineData.days &&
                    timelineData.days.map((journeyDay, key) => (
                        <li
                            key={key}
                            className={`timeline__journey-day-list-item ${key === 0 || key > timelineData.journey_length || key > timelineData.itinerary?.timeline_length
                                ? "timeline__journey-day-list-item--inactive"
                                : ""
                            }`}
                        >
                            <DragDropColumn
                                journeyDay={journeyDay}
                                key={journeyDay.day}
                                prefix={journeyDay.day}
                                renderDate={renderDate}
                                isEditMode={isEditMode}
                                onClickEditJourneyDay={onClickEditJourneyDay}
                                onClickClearJourneyDay={onClickClearJourneyDay}
                                onClickNewJourneyDay={onClickNewJourneyDay}
                                deleteJourneyDay={deleteJourneyDay}
                            />
                        </li>
                    ))}
                </DragDropContext>
            </ul>

            <Modal show={show} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to delete this message?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onClickClearJourneyDay(journeyDayToDelete, handleCloseModal)}>
                        {modalFirstButton}
                    </Button>
                    {(getToken().is_admin && onClickDeleteFromTemplateAndItineraries != '' )&& (
                        <Button className="delete-button" onClick={() => onClickDeleteFromTemplateAndItineraries(journeyDayToDelete, handleCloseModal)}>
                            Delete from this template and from current itineraries.
                        </Button>
                    )}

                </Modal.Footer>
            </Modal>


            <Modal show={showMoveMessageModel} onHide={handleCloseMoveMessageModal} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Move message</Modal.Title>
                </Modal.Header>

                {title === 'itinerary' ?
                    <div>
                        <Modal.Body>Move this message?</Modal.Body>
                        <Modal.Footer>
                            <Button className="delete-button" onClick={() => onClickMoveMessageOnlyForTemplate()}>
                                Move the message
                            </Button>
                            <Button variant="secondary" onClick={() => handleCancelMove()}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </div>
                :
                    <div>
                        <Modal.Body>Move message only for this template or for current itineraries?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => onClickMoveMessageOnlyForTemplate()}>
                                Only for this template
                            </Button>
                            {(getToken().is_admin)&& (
                                <Button className="delete-button" onClick={() => onClickMoveMessageForTemplateAndItineraries()}>
                                    For this template and for current itineraries
                                </Button>
                            )}
                        </Modal.Footer>
                    </div>
                }

            </Modal>
        </div>
    );
}

DragDropTemplate.propTypes = {
    className: PropTypes.string,
    timelineData: PropTypes.object,
    departureDate: PropTypes.string,
    onClickEditJourneyDay: PropTypes.func,
    onClickClearJourneyDay: PropTypes.func,
    isEditMode: PropTypes.bool,
    doNotShowMessages: PropTypes.bool,
};

export default DragDropTemplate;
