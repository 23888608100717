const initialState = {
  messageErrors: [],
  clients: [],
  tipsClientError: [],
  respondingClientError: [],
  importedFilesLogs: [],
  overviewChangedTemplates: [],
  importedSheets: [],
  overviewScheduledMessages: [],
  branches: [],
  success: "",
  errors: "",
};

const types = {
  FETCH_CLIENTS: "FETCH_CLIENTS",
  FETCH_MESSAGE_ERRORS: "FETCH_MESSAGE_ERRORS",
  FETCH_IMPORTED_FILES_LOGS: "FETCH_IMPORTED_FILES_LOGS",
  FETCH_CHANGED_TEMPLATES: "FETCH_CHANGED_TEMPLATES",
  FETCH_IMPORTED_SHEETS: "FETCH_IMPORTED_SHEETS",
  FETCH_OVERVIEW_MESSAGES: "FETCH_OVERVIEW_MESSAGES",
  FETCH_BRANCHES: "FETCH_BRANCHES",
  SHOW_SUCCESS: "SHOW_SUCCESS",
  SHOW_ERRORS: "SHOW_ERRORS",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MESSAGE_ERRORS:
      return {
        ...state,
        messageErrors: action.payload,
      };
    case types.FETCH_CLIENTS:
      let clients = [];
      action.payload.forEach((element) => {
        clients.push({
          value: element.id,
          label: element.company_name,
        });
      });
      return {
        ...state,
        clients: clients,
      };
    case types.FETCH_IMPORTED_FILES_LOGS:
      return {
        ...state,
        importedFilesLogs: action.payload,
      };
    case types.FETCH_CHANGED_TEMPLATES:
      return {
        ...state,
        overviewChangedTemplates: action.payload,
      };
    case types.FETCH_IMPORTED_SHEETS:
      return {
        ...state,
        importedSheets: action.payload,
      };
    case types.FETCH_OVERVIEW_MESSAGES:
      return {
        ...state,
        overviewScheduledMessages: action.payload,
      };
    case types.FETCH_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case types.SHOW_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case types.SHOW_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      throw new Error("Unexpected action");
  }
};
export { initialState, types, reducer };
