import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./PillButton.css";

function PillButton({ label, onClick, className, disabled }) {
  return (
    <button className={classnames("pill-button", className)} disabled={disabled} onClick={onClick}>
      {label}
    </button>
  );
}

PillButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.object, 
};

export default PillButton;
