import React, { useState, useContext } from "react";
import { TemplateContext } from "../../../../store/context/TemplateContext";
import PropTypes from "prop-types";
import AddLocation from "../../EditTemplate/AddLocation/AddLocation";
import { getToken } from "../../../../utils/auth";

import "./TemplateHeader.css";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";
import PillButton from "../../../Reusable/PillButton/PillButton";
import CircleButton from "../../../Reusable/CircleButton/CircleButton";
import {ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";

function TemplateHeader({
  isHeaderDisplayed,
  isEditMode,
  onClickEdit,
  onClickSaveDraft,
  onClickSubmit,
  onClickDuplicate,
  onClickDelete,
}) {
  const { state, actions } = useContext(TemplateContext);
  const [showAddLocation, setShowAddLocation] = useState(false);

  const onClickAddADay = (e) => {
    actions.addDayToCurrentTemplate();
  };

  const onClickRemoveDay = (e) => {
    actions.removeDayFromCurrentTemplate();
  };

  const onClickAddLocation = () => {
    setShowAddLocation(true);
  };

  const onCloseAddLocation = () => {
    setShowAddLocation(false);
  };

  return (
    <>
      {!isHeaderDisplayed && state?.template?.data && (
        <div className="template-header">
          <div className="template-header__left-column">
            <div className="template-header__btn-and-name">
              {!isEditMode ? (
                <CircleButton href="/templates" type="prev" />
              ) : (
                <CircleButton onClick={onClickEdit} type="prev" />
              )}

              <div className="template-header__title-container">
                <div className="template-header__title">
                  {state.template.data.title}
                </div>
              </div>
            </div>

            {showAddLocation && (
              <AddLocation
                show={showAddLocation}
                onHide={onCloseAddLocation}
                data={state.template.data}
              />
            )}

            <>
              {isEditMode && (
                <div>
                  <PillButton label="Add a day" onClick={onClickAddADay} />
                  <PillButton label="Remove a day" onClick={onClickRemoveDay} />
                  <PillButton
                    label="Add location"
                    onClick={onClickAddLocation}
                    className="template-header__add-location"
                  />
                </div>
              )}
            </>
          </div>

          <div className="template-header__right-column">
            {!isEditMode ? (
              <div className="template-header__edit-btns-container">
                <RectangleButton
                  label="Edit"
                  onClick={onClickEdit}
                  isHighlight
                  isSmall
                />
                {getToken().is_admin ? (
                  <RectangleButton
                    label="Delete"
                    onClick={onClickDelete}
                    isSmall
                  />
                ): null}
              </div>
            ) : (
              <div className="template-header__edit-btns-container">
                <RectangleButton
                  type="submit"
                  onClick={onClickSaveDraft}
                  name="draft"
                  value="draft"
                  label="Save draft"
                  isHighlight
                  isSmall
                />
                <RectangleButton
                  type="submit"
                  onClick={onClickSubmit}
                  name="submit"
                  value="submit"
                  label="Submit"
                  isSmall
                />
                <Dropdown className="template-header__dropdown">
                  <Dropdown.Toggle className="template-header__dropdown"  id="dropdown-basic">
                    Action
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onClickDuplicate(0)}>
                      Copy
                    </Dropdown.Item>
                    <Dropdown.Item  onClick={() => onClickDuplicate(1)}>
                      Duplicate
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

TemplateHeader.propTypes = {
  isHeaderDisplayed: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickSaveDraft: PropTypes.func,
  onClickSubmit: PropTypes.func,
  onClickDuplicate: PropTypes.func,
};

export default TemplateHeader;
