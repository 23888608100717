import axios from "axios";
import {types} from "../reducers/UserRolesReducer";

export const useActions = (state, dispatch) => {
    function fetchRoles() {
        const url = process.env.REACT_APP_BASEURL + '/api/roles';

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ROLES_DATA,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchPermissions() {
        const url = process.env.REACT_APP_BASEURL + '/api/permissions';

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ROLES_PERMISSIONS_DATA,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function deleteRole(id) {
        const url = process.env.REACT_APP_BASEURL + '/api/roles/delete/' + id;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.DELETE_ROLE,
                    payload: id,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function updateRole(id, data) {
        const url = process.env.REACT_APP_BASEURL + '/api/roles/update/' + id;

        axios
            .put(url, data)
            .then((response) => {
                window.location.href = '/users-roles';
            })
            .catch((err) => {
                dispatch({
                    type: types.FAILED_CREATE_UPDATE_ROLE,
                    payload: err.response.data.data,
                });
                console.log(err);
            });
    }

    function storeRole(data) {
        const url = process.env.REACT_APP_BASEURL + '/api/roles/store';

        axios
            .put(url, data)
            .then((response) => {
                window.location.href = '/users-roles';
            })
            .catch((err) => {
                dispatch({
                    type: types.FAILED_CREATE_UPDATE_ROLE,
                    payload: err.response.data.data,
                });
                console.log(err);
            });
    }

    function fetchUsers() {
        const url = process.env.REACT_APP_BASEURL + '/api/users';

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_USERS_DATA,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchCompanies() {
        const url = process.env.REACT_APP_BASEURL + '/api/companies';

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_COMPANIES_DATA,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function updateUser(id, data) {
        const url = process.env.REACT_APP_BASEURL + '/api/users/update/' + id;

        axios
            .put(url, data)
            .then((response) => {
                window.location.href = '/users-roles';
            })
            .catch((err) => {
                dispatch({
                    type: types.FAILED_UPDATE_USER,
                    payload: err.response.data.data,
                });
                console.log(err);
            });
    }

    return {
        fetchRoles,
        fetchPermissions,
        deleteRole,
        updateRole,
        storeRole,
        fetchUsers,
        fetchCompanies,
        updateUser,
    };
};
