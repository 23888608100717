 const STATUS = {
     0: "Imported",
     1: "Unvalidated",
     2: "Mismatch",
     3: "Analyse",
     4: "Analysed",
     5: "Cancelled",
     6: "Current",
     7: "Opt-out",
     8: "Opt-in"
}
export default STATUS;