import React, { useState, useEffect, useContext, useRef } from "react";
import TipsGoogleMap from "../TipsGoogleMap/TipsGoogleMap";
import AddTipModal from "../TipForm/AddNewTip";
import TipDetails from "../TipDetails/TipDetails";
import TipRow from "../TipRow/TipRow";
import Select from "react-select";
import AutoCompleteLocation from "../../AutoCompleteLocation";
import { TipsContext } from "../../../store/context/TipsContext";
import { AppContext } from "../../../store/context/AppContext";
import ErrorModal from "../../Reusable/ErrorModal/ErrorModal";
import SearchInputField from "../../Reusable/SearchInputField/SearchInputField";
import {useParams} from "react-router";

import "./Tips.css";

import Paginator from "../../Reusable/Paginator/Paginator";
import CircleButton from "../../Reusable/CircleButton/CircleButton";

const Tips = () => {
  let {id} = useParams();

  const { state, actions } = useContext(TipsContext);
  const { state: AppState } = useContext(AppContext);
  const [filter, setFilter] = useState(state.filters);
  const [isAddTipModalShown, setIsAddTipModalShown] = useState(false);
  const [activeTipId, setActiveTipId] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [isInitial, setIsInitial] = useState(true);
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });

  const selectInputRefType = useRef();
  const selectInputRefRadius = useRef();

  let firstTip = state.tips.allTips[0];

  useEffect(() => {
    setPageIndex(state.pageIndex);
    if(typeof id !== 'undefined') {
      setActiveTipId(id);
    }
  }, [state.pageIndex]);

  useEffect(() => {
    actions.fetchTips(state.pageIndex, state.filters);
  }, [state.pageIndex]);

  useEffect(() => {
    setFilter(state.filters);
  }, [state.filters]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    if (filter.searchText !== "" && filter.searchText.length >= 3) {
      actions.filterTips(filter);
    } else if (filter.searchText === "") {
      actions.filterTips(filter);
    }
  }, [filter.searchText, filter.radius, filter.type, filter.tag, filter.bounds]);

  useEffect(() => {
    if (location.lat == null && firstTip?.lat) {
      setLocation({ lat: firstTip.lat, lng: firstTip.lng });
    }
  }, [state.tips.allTips]);

  const getNextPage = () => {
    actions.updatePageIndex(
      state.tips.appTips || state.tips.nextPageUrl ? pageIndex + 1 : pageIndex
    );
  };

  const getPreviousPage = () => {
    let newPageIndex = Math.max(pageIndex - 1, 1);
    actions.updatePageIndex(newPageIndex);
  };

  const setPageNumber = (pageIndex) => {
    actions.updatePageIndex(pageIndex);
  };

  const closeTipDetails = () => {
    setActiveTipId(null);
  };

  const closeAddTip = () => {
    setIsAddTipModalShown(false);
    setActiveTipId(null);
  };

  const onChangeLocation = (data) => {
    setFilter({ ...filter, bounds: null, location: data });
  };

  const onChangeBounds = (newBounds, newCenter) => {
    setFilter({ ...filter, location: newCenter, bounds: newBounds });
  };

  const onChangeType = (data) => {
    if (data) {
      setFilter({ ...filter, type: data.value });
    }
  };

  const onChangeTag = (data) => {
    if (data) {
      setFilter({ ...filter, tag: data });
    }
  };

  const onChangeRadius = (data) => {
    if (data) {
      setFilter({ ...filter, radius: data.value });
    }
  };

  const onClickGetTipDetails = (id) => {
    setActiveTipId(id);
  };

  const onClickAddTip = () => {
    setIsAddTipModalShown(true);
  };

  const onClickFilterLocation = () => {
    setLocation(filter.location);
    actions.filterTips(filter);
  };

  const onClickClearFilter = () => {
    actions.clearFilterTips();
    selectInputRefType.current.select.clearValue();
    selectInputRefRadius.current.select.clearValue();
    setLocation({lat: null, lng: null})
    setFilter(state.filters);
  };

  const clearError = () => {
    actions.clearError();
  };

  const onChangeText = (e) => {
    setFilter({ ...filter, searchText: e.target.value });
  };

  return (
    <div className="tips">
      <div className="tips__header--left-column">
        <p className="tips__title">Tips</p>
        <CircleButton type="add" onClick={onClickAddTip} />
      </div>

      <div className="tips__header--right-column">
        <Select
          ref={selectInputRefType}
          className="tips__search-type"
          options={AppState.inputs.typesTips}
          getOptionLabel={(option) => `${option.label}`}
          value={filter.types}
          getOptionValue={(option) => `${option.value}`}
          placeholder="Search type"
          onChange={onChangeType}
        />
        <Select
          isMulti
          defaultValue={state.filters.tag}
          className="tips__search-tags"
          options={AppState.inputs.tags}
          getOptionLabel={(option) => `${option.tag}`}
          value={state.filters.tag}
          getOptionValue={(option) => `${option.id}`}
          placeholder="Search tag"
          onChange={onChangeTag}
        />
        <AutoCompleteLocation
          onChangeLocation={onChangeLocation}
          onClickClearFilter={onClickClearFilter}
          onClickFilterLocation={onClickFilterLocation}
          locationClassName={{
            inputField: "tips__search-location-input",
            suggestions: "tips__search-suggestions",
          }}
        />
        <Select
          ref={selectInputRefRadius}
          className="tips__search-radius"
          options={AppState.inputs.radius}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option.value}`}
          value={filter.rad}
          placeholder="Search radius"
          onChange={onChangeRadius}
        />
        <SearchInputField
          placeholder="Search on name, message or address"
          value={filter.searchText}
          onChange={onChangeText}
          onClickSearch={null}
          className="tips-search-bar__input"
        />
      </div>

      <div className="tips__body">
        <div className="tips__table">
          <div className="tips__table-header">
            <p>Type</p>
            <p>Name</p>
            <p>Message</p>
            <p>Tags</p>
          </div>
          <div className="tips__table-body">
            {state.tips.allTips.map((tip, key) => (
              <TipRow tip={tip} key={key} onClickTip={onClickGetTipDetails} />
            ))}
          </div>
        </div>
      </div>

      <div className="tips__detail-and-googlemap-container">
        {!activeTipId && (
          <TipsGoogleMap
            lat={location.lat}
            lng={location.lng}
            onBoundsUpdate={onChangeBounds}
          />
        )}
        {activeTipId && (
          <TipDetails
            tipId={activeTipId}
            tipClose={closeTipDetails}
            editTipButton={{
              label: "Edit",
            }}
          />
        )}
      </div>
      <Paginator
        className="tips__paginate"
        page={pageIndex}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        lastPage={state.tips.lastPage}
        getPageButton={setPageNumber}
      />
      {isAddTipModalShown && (
        <AddTipModal show={isAddTipModalShown} onHide={closeAddTip} />
      )}
      <ErrorModal errorMessage={state.errors} onHide={clearError} />
    </div>
  );
};

export default Tips;
