import React, { useState, useContext } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "../../../store/context/AppContext";
import { TemplateContext } from "../../../store/context/TemplateContext";

import './ImportTemplates.css'
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";
import ImportStartedModal from "./ImportStartedModal";

const ImportWafbTemplates = () => {
    const [formData, setFormData] = useState({});
    const { state: AppState } = useContext(AppContext);
    const { state: TemplateState, actions } = useContext(TemplateContext);
    const [showModal, setShowModal] = useState(false);
    const onClickCloseModal = () => {
        if (showModal){
            setShowModal(false);
        } else {
            setShowModal(true);
        }
    };

    const onChangeFormData = (e, name) => {
        if (typeof e.target === 'undefined') {
            setFormData({ ...formData, [name]: e });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const onClickSubmitImport = () => {
        actions.importWafbTemplates(formData);
        onClickCloseModal();
    };

    return (
        <div className="import-templates">
            <div className="import-templates__header">
                <p className="import-templates__title">Import Whatsapp For Business templates</p>
            </div>
            <div className="import-templates__form">
                <Form.Group
                    controlId="import-templates__form__url"
                    className="import-templates__form__input-field"
                >
                    <Form.Label className="import-templates__form__title">Url</Form.Label>

                    <Form.Control
                        type="text"
                        defaultValue={formData.url}
                        onChange={(e) => onChangeFormData(e, 'url')}
                        name="url"
                        placeholder="Enter url to Google Sheets"
                    />
                </Form.Group>

                <Form.Group
                    controlId="import-templates__form__sheet-name"
                    className="import-templates__form__input-field"
                >
                    <Form.Label className="import-templates__form__sheet-name">Sheet name</Form.Label>

                    <Form.Control
                        type="text"
                        defaultValue={formData.sheetname}
                        onChange={(e) => onChangeFormData(e, 'sheetname')}
                        name="sheetname"
                        placeholder="Enter the name of the tab in the Google Sheet"
                    />
                </Form.Group>

                <Form.Group
                    controlId="import-templates__form__branch"
                    className="import-templates__form__input-field input-field-branch"
                >
                    <Form.Label className="import-templates__form__branch">Branch</Form.Label>

                    <Select
                        name="branch"
                        placeholder="Select branch"
                        options={AppState.inputs.companies}
                        getOptionLabel={(option) => `${option.branch_name}`}
                        getOptionValue={(option) => `${option.id}`}
                        value={formData.branch}
                        onChange={(e) => onChangeFormData(e, 'branch')}
                    />
                </Form.Group>

                <Form.Group
                    controlId="import-templates__form__line"
                    className="import-templates__form__input-field input-field-line"
                >
                    <Form.Label className="add-template__title">Import from specific line in sheet</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        value={formData.line}
                        onChange={(e) => onChangeFormData(e, 'line')}
                        name="line"
                        placeholder={1}
                        min={1}
                    />
                </Form.Group>


                <div className="import-templates__btn">
                    <RectangleButton type="submit" label="Import" isHighlight onClick={onClickSubmitImport} />
                </div>
            </div>

            <ImportStartedModal show={showModal} onHide={onClickCloseModal}/>
        </div>
    );
};

export default ImportWafbTemplates;
