import React, {useContext, useEffect, useState} from "react";
import {ConversationContext} from "../../../store/context/ConversationContext";
import {Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import TemplateItem from "../../Templates/EditTemplate/TemplateItem/TemplateItem";
import TipSelector from "../../Templates/EditTemplate/TemplateDay/TipSelector/TipSelector";
import TextInput from "../../Templates/EditTemplate/TemplateDay/TextInput/TextInput";
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

function BulkFormSchedule({journey_id}) {
        const { state: ConversationState, actions } = useContext(ConversationContext);
        const [vars, setVars] = useState(null);
        const [selectedTipId, setSelectedTipId] = useState(null);
        const [selectedText, setSelectedText] = useState(null);
        const [showTextInput, setShowTextInput] = useState(false);
        const [message, setMessage] = useState(null);
        const [selectedTextId, setSelectedTextId] = useState(null);
        const [name, setName] = useState('');
        const [messageTemplate, setMessageTemplate] = useState(null);
        const [sendAt, setSendAt] = useState(null);
        const [file, setFile] = useState(null);


        function onChangeTemplate(data) {
            document.getElementById('messageTemplateBody').append(data.body);
            setVars((currentVars) => data.vars);
            setMessageTemplate((currentMessageTemplate)=> data.id);

        }

         function onClickAddTip(tipId) {
           setSelectedTipId(tipId);
        }

        function onChangeName(e) {

            const name = e.target.value;
           setName((currentName) => name);
        }

        function onChangeSendAt(e) {
            const sendAt = e.target.value;
            setSendAt((currentSendAt) => sendAt );
        }


        function onClickAddText(article, key) {
            setSelectedText(article);
            setSelectedTextId(key);
            setShowTextInput(true);
        }

        function onCloseTextInput() {
            setShowTextInput(false);
        }

        function onCloseTipSelector() {
            setSelectedTipId(null);
        }

        const submitTip = (tip) => {
            vars[selectedTipId].article_body = tip.body;
            vars[selectedTipId].article_id = tip.id;
            vars[selectedTipId].value = tip.body;
            setSelectedTipId(null);
        };

        function submitText(text) {
            vars.find((item) => {
                if (item.key === text.key) {
                    item.value = text.value;
                    return true;
                }
            });
            setSelectedText(null);
            setSelectedTextId(null);
            setShowTextInput(false);
        }

        function onChangeFile(event) {
            event.persist();
            setFile((currentFile) => event.target.files[0]);
        }

        function onClickSubmitSendMessage(e) {

            let conversationIds = [];

            ConversationState.filteredConversations.data.forEach((conversation) => {
                conversationIds.push(conversation.conversation_id);
            });

            e.preventDefault();
            var formDataSendMessage = new FormData();
            formDataSendMessage.append('conversation_ids', JSON.stringify(conversationIds));
            formDataSendMessage.append('message_template_id', messageTemplate);
            formDataSendMessage.append('name', name);
            formDataSendMessage.append('journey_id', journey_id);
            formDataSendMessage.append('vars', JSON.stringify(vars));
            formDataSendMessage.append('message', message);
            formDataSendMessage.append('send_at', sendAt);
            formDataSendMessage.append('file', file);

            for (var pair of formDataSendMessage.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

            actions.sendBulkMessages(formDataSendMessage)
        }

    useEffect(() => {

        console.log(ConversationState.validationErrors);
    }, [ConversationState.validationErrors]);

    function getError(key) {
        return (ConversationState.validationErrors[key] !== undefined) ? ConversationState.validationErrors[key] : null;
    }

   return (
       <Row className={"col-12"}>
               <Row className={'g-2'}>
                   <Row className={"col-12"}>
                   <Col md>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name={'name'} type={'text'}
                            className={`${(getError("name")) ? "is-invalid" : ""}`}
                            onChange={(event) => onChangeName(event)}
                            required={true}
                        />
                       {(getError("name")) ? <div className="invalid-feedback">{getError("name")}</div> : null}
                   </Col>
                   <Col md>
                       <Form.Label>Message template</Form.Label>
                       <Select
                           name={'message_template'}
                           options={ConversationState.messageTemplates}
                           getOptionLabel={(option) => `${option.title} `}
                           getOptionValue={(option) => `${option.id}`}
                           onChange={(data) => onChangeTemplate(data)}
                       />
                   </Col>
                   <Col md>
                       <Form.Label>Send time</Form.Label>
                       <Form.Control
                           type={'datetime-local'}
                           name={'send_at'}
                           min={new Date().toISOString().slice(0, 16)}
                           className={`${(getError("send_at")) ? "is-invalid" : ""}`}
                           onChange={(event) => onChangeSendAt(event)}
                           required={true}
                       />
                       {(getError("send_at")) ? <div className="invalid-feedback">{getError("send_at")}</div> : null}
                   </Col>
                   <Col md>
                       <Form.File
                           name={'file'}
                           label={'Choose file'}
                           onChange={(event) => onChangeFile(event)}
                       />
                   </Col>
                   </Row>
               </Row>
               <Row className={"col-12"}>
                   <Row className={'col-12'}>
                   <Col md>
                       <Form.Label>Message template body preview</Form.Label>
                       <Form.Control
                           as={"textarea"}
                           rows={3}
                           disabled={true}
                           id={"messageTemplateBody"}
                       />
                   </Col>
                   <Col md>
                       {vars?.map((article, key) => (
                           <div key={key}>
                                   {article.var_is_message ?
                                   <TemplateItem
                                       labelKey={`{{${key + 1}}}`}
                                       copy={article.article_body}
                                       label="Add Tip"
                                       buttonAction={() => onClickAddTip(key)}
                                   />
                                   :
                                   <TemplateItem
                                       labelKey={`{{${key + 1}}}`}
                                       copy={article.value}
                                       label="Add Text"
                                       buttonAction={() => onClickAddText(article, key)}
                                   />
                               }
                           </div>
                       ))}
                       {typeof selectedTipId === "number" && selectedText === null && (
                           <TipSelector
                               show={true}
                               onHide={onCloseTipSelector}
                               submitTip={submitTip}
                           />
                       )}
                       {showTextInput && (
                           <TextInput
                               show={true}
                               onHide={onCloseTipSelector}
                               textData={selectedText}
                               submitText={submitText}
                           />
                       )}

                   </Col>
                   </Row>
                   <Row className={'col-12'}>
                       <Col  md>
                           <RectangleButton
                               disabled={ConversationState.filteredConversations?.data?.length ===  0 || ConversationState.filteredConversations?.data?.length ===  undefined}
                               type="submit"
                               label="Send messages"
                               className={'float-right'}
                               isHighlight
                               onClick={onClickSubmitSendMessage} />
                       </Col>
                   </Row>
               </Row>

       </Row>
   )
}

export default BulkFormSchedule;