const initialState = {
    kpi: [],
};

const types = {
    FETCH_KPI_DATA: "FETCH_KPI_DATA",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_KPI_DATA:
            return {
                ...state,
                kpi: action.payload
            };
        default:
            throw new Error("Unexpected action");
    }
};
export {initialState, types, reducer};
