import React, {useContext, useEffect} from 'react';
import './UserRole.css'
import Tabs from "react-bootstrap/Tabs";
import RolesTab from "./RolesTab";
import UsersTab from "./UsersTab";
import {UserRolesContext} from "../../store/context/UserRolesContext";
import {getToken} from "../../utils/auth";

const UserRole = () => {
    const {state, actions} = useContext(UserRolesContext);

    useEffect(() => {
       actions.fetchUsers();
       actions.fetchCompanies();
       actions.fetchRoles();
       actions.fetchPermissions();
    }, []);

    if(!getToken().is_admin) {
        return <h3 className="text-danger">Permission denied</h3>
    }

    return (
        <div className="roles-permissions">
            <Tabs defaultActiveKey="users" id="roles_permissions">
                <UserRole className="users" eventKey="users" title="Users">
                    <UsersTab/>
                </UserRole>
                <UserRole className="roles_permissions" eventKey="roles_permissions" title="Role & Permissions">
                    <RolesTab/>
                </UserRole>
            </Tabs>
        </div>
    );
};

export default UserRole;
