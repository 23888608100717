import axios from "axios";
import {types} from "../reducers/KpiReducer";

export const useActions = (state, dispatch) => {
    function fetchKpi() {
        const url = process.env.REACT_APP_BASEURL + '/api/kpi-report';

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_KPI_DATA,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return {
        fetchKpi,
    };
};
