import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import {login} from "../../utils/auth";

function Login(props) {
  const history = useHistory();
  const [loginFailed, setLoginFailed] = useState(false)
  const [qrBase64, setQrBase64] = useState('');
  const [state, setState] = useState({
    code: ""
  });

  useEffect(() => {
    setQrBase64(props.location.qr_code)
  }, [])


  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login({...props.location.state, ...state}, setLoginFailed)
      .then(() => {
        history.push("/inbox");
      })
      .catch((err) => {
        console.log('failed to log in', err)
      });
  };

  return (
    <article className="bg">
      <form>
        <div className="login__container">
          {qrBase64 !== '' ?
              <img
                  src={qrBase64}
                  alt="logo"
              />
              : ''}
          <div className="login__form">
            <div>
              <input
                onChange={handleChange}
                name="code"
                type="text"
                placeholder="Google 2FA code"
                className="form-section"
              />
            </div>
          </div>
          <p className="system_message">If you lose your Google 2FA codes, contact your admin</p>
          <button className="login__btn" onClick={handleSubmit}>
            <p className="login__text">LOG IN</p>
          </button>
          <br />
          <div>{loginFailed && "failed to log in"} </div>
        </div>
      </form>
    </article>
  );
}

export default Login;
