import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from 'react-bootstrap';

import "./ConfirmationModal.css";

function ConfirmationModal({ askConfirmation, text, confirmationGiven, onHide }) {
    return (
        <div className="error-modal">
            <Modal
                show={askConfirmation}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="error-modal__header"
                    >
                        Confirm the following action:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {text}
                </Modal.Body>
                <Modal.Footer className="confirmation-modal__buttons">
                    <Button className="delete-button" onClick={confirmationGiven}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={onHide}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

ConfirmationModal.propTypes = {
    askConfirmation: PropTypes.bool,
    text: PropTypes.string,
    onHide: PropTypes.func,
};


export default ConfirmationModal;