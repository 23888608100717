import React, { useEffect, useContext, useState } from "react";
import { ItineraryContext } from "../../../store/context/ItineraryContext";
import { useParams } from "react-router";
import ItineraryHeader from "./ItineraryHeader/ItineraryHeader";
import ItineraryContainer from "./ItineraryContainer/ItineraryContainer";
import TravellersContainer from "./TravellersContainer/TravellersContainer";
import ItineraryTimeline from "./ItineraryTimeline/ItineraryTimeline";
import ItineraryTaskList from "./ItineraryTaskList/ItineraryTaskList";
import ItineraryActivityLog from "./ItineraryActivityLog/ItineraryActivityLog";
import { DeepClone } from "../../../utils";
import { AppContext } from "../../../store/context/AppContext";

import "./ItineraryGroup.css";

const ItineraryDetails = () => {
  const { state, actions } = useContext(ItineraryContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const { pusher } = useContext(AppContext);

  const params = useParams();

  useEffect(() => {
    if (state?.itinerary?.data?.id) {
      const clonedItineraryData = DeepClone(state.itinerary.data);
      setFormData(clonedItineraryData);
    }
  }, [state]);

  useEffect(() => {
    let id = params.id;
    actions.getItinerary(id);

    const channel = pusher.subscribe('itinerary');
    channel.bind(process.env.REACT_APP_PUSHER_ITINERARY_EVENT_NAME, data => {
      if (typeof data.update_itinerary != 'undefined' && data.update_itinerary && data.itinerary_id == id) {
        console.log('fetching itinerary again');
        actions.getItinerary(id);
      }
    });

    return () => {
      channel.unbind(process.env.REACT_APP_PUSHER_ITINERARY_EVENT_NAME)
    }
  }, [params]);

  const onChangeFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const processItineraryForm = () => {
    actions.editItinerary(formData);
    setIsEditMode(false);
  };

  const onClickSave = () => {
    processItineraryForm();
  };

  const onClickEdit = () => {
    if (isEditMode === false) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  };

  return (
    <div className="itinerary-details">
      <div className="itinerary-details__header">
        <ItineraryHeader
          onClickEdit={onClickEdit}
          onClickSave={onClickSave}
          isEditMode={isEditMode}
        />
      </div>
      <div className="itinerary-details__itinerary-container">
        <ItineraryContainer
          isEditMode={isEditMode}
          formData={formData}
          handleFormData={onChangeFormData}
        />
      </div>
      <div className="itinerary-details__travellers-container">
        <TravellersContainer />
      </div>
      <div className="itinerary-details__timeline">
        <ItineraryTimeline isEditMode={isEditMode} />
      </div>
      <div className="itinerary-details__task-list">
        <ItineraryTaskList />
      </div>
      <div className="itinerary-details__activity-log">
        <ItineraryActivityLog />
      </div>
    </div>
  );
};

export default ItineraryDetails;
