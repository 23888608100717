import React from "react";
import PropTypes from "prop-types";

import "./TipRow.css";

function TipRow({ tip, key, onClickTip }) {
  return (
    <div className="tip-row" key={key}>
      <button
        className="tip-row__btn"
        onClick={() => onClickTip(tip.id)}
        alt="tip details"
      >
        {tip.type ? (
          <img className="tip-row__type" src={tip.type.icon} alt="type" />
        ) : (
          <div className={`tip-row__type`}></div>
        )}

        <p className="tip-row__name">{tip.title}</p>
        <p className="tip-row__message">{tip.body}</p>
        <div className="tip-row__tags">
          {tip.tags.map((tag) => (
            <div key={tag.id}>
              {tag.tag && <p className="tip-row__tag">{tag.tag}</p>}
            </div>
          ))}
        </div>
      </button>
    </div>
  );
}

TipRow.propTypes = {
  tip: PropTypes.object,
};

export default TipRow;
