import React, { useContext, useEffect, useState } from 'react';
import DefaultTabInfo from '../../OpenConversations/OpenConversationTabs/DefaultTabInfo';
import './ProfileConversation.css';
import { Col, Container, Dropdown, FormControl, Modal, Row } from "react-bootstrap";
import Itinerary from "../Itinerary";
import conversationIcon from '../../../../images/no-assignee.png';
import { ConversationContext } from "../../../../store/context/ConversationContext";

const TaskConversation = () => {
    const { state, actions } = useContext(ConversationContext);
    const [editName, setEditName] = useState(false);
    const [value, setValue] = useState('');
    const [show, setShow] = useState(false);
    const [ name, setName ] = useState()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button ref={ref} onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>
            {children}
        </button>
    ));

    useEffect(() => {
        setName(state.currentConversation.traveler.full_name)
    }, [state.currentConversation])

    const onClickEditName = () => {
        setEditName(true)
    }

    const onChangeEditName = (e) => {
        setName(e.target.value)
    }

    const onClickSaveName = () => {
        let data = {
            full_name: name
        }
        actions.editTravelersName(data);
        setEditName(false)
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

            return (
                <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                    <FormControl autoFocus className="mx-3 my-2 w-auto" placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)} value={value} />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children[1].match(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <div className="conversation-tab-task-container">
            {typeof state.currentConversation.traveler != 'undefined' ?
                <div>
                    <div className="open-conversation-task">
                        <Container className="profile-container">
                            <Row>
                                <Col sm={1}>
                                    {state.currentConversation.traveler.avatar === null || state.currentConversation.traveler.avatar === '' ?
                                        <img className="avatar" src={state.currentConversation.traveler.avatar} alt="" />
                                        :
                                        <img className="avatar" src={conversationIcon} alt="" />
                                    }
                                </Col>
                                <Col sm={6} className="d-flex">
                                    <div className="d-inline info-block info-block-long-text">
                                        <p className="info-block-title">Full name</p>
                                        {!editName &&
                                            <p>{state.currentConversation.traveler.full_name}</p>
                                        }
                                        {editName &&
                                            <textarea name="full_name" className="info-block-title text-edit-name"
                                                onChange={onChangeEditName} value={name || ""} rows="1" cols="12"/>}
                                    </div>
                                </Col>
                                <Col sm={2} className="d-inline">
                                    <div className="tips-question-block">
                                        <div className="d-inline info-block info-block-title" onClick={handleShow}>
                                            <p className="tips-question-title">Trips</p>
                                            <p className="tips-question-count">{state.currentConversation.itineraries && state.currentConversation.itineraries.length > 0 ? state.currentConversation.itineraries.length : 0}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={3} className="d-inline">
                                    <div className="tips-question-block">
                                        <div className="d-inline info-block info-block-title">
                                            <p className="tips-question-title">Questions</p>
                                            <p className="tips-question-count">{state.currentConversation.number_of_questions ? state.currentConversation.number_of_questions : 0}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} />
                                {!editName &&
                                    <button className="rectangle-btn edit-name-profile" onClick={onClickEditName}>
                                        Edit name
                                 </button>
                                }
                                {editName &&
                                    <button className="rectangle-btn edit-name-profile" onClick={onClickSaveName}>
                                        Save
                                    </button>
                                }

                            </Row>
                            <Row>
                                <Col sm={1} />
                                <Col sm={6} className="d-flex">
                                    <div className="d-inline info-block info-block-long-text">
                                        <p className="info-block-title">Email</p>
                                        <p>{state.currentConversation.traveler.email}</p>
                                    </div>
                                </Col>
                                <Col sm={5} className="d-flex">
                                    <div className="d-inline info-block info-block-long-text">
                                        <p className="info-block-title">Phone</p>
                                        <p>{state.currentConversation.traveler.phone}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={1} />
                                <Col sm={6} className="d-flex">
                                    <div className="d-inline info-block info-block-long-text">
                                        <p className="info-block-title">Birthday</p>
                                        <p>{state.currentConversation.traveler.birthday}</p>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={1} />
                                <Col sm={8}>
                                    {state.userPreferences.length > 0 ?
                                        <div className="d-inline preferences" id="user-preferences">
                                            <p className="info-block-title">Preferences</p>

                                            {state.userPreferences.map((preference, i) => (
                                                <span id={`preference-item-${preference.id}`}
                                                    className="preferences-item" key={i}>
                                                    {preference.icon ?
                                                        <img className="preference-icon" src={preference.icon} alt="" />
                                                        : null}
                                                    {preference.name}
                                                    <button className="delete-preference"
                                                        onClick={() => actions.deleteUserPreference(preference.id, state.currentConversation.id)}>x</button>
                                                </span>
                                            ))}
                                        </div>
                                        : null}
                                </Col>
                                <Col sm={3}>
                                    <Dropdown className="add-preference">
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            + Add new
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu as={CustomMenu}>
                                            {state.preferences.map(preference => (
                                                <Dropdown.Item key={preference.id}
                                                    onClick={() => actions.addNewUserPreference(preference.id, state.currentConversation.id)}>
                                                    {preference.icon ?
                                                        <img className="preference-icon" src={preference.icon} alt="" />
                                                        : null}
                                                    {preference.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>

                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title><p className="modal-title">Previous trips</p></Modal.Title>
                                </Modal.Header>
                                {typeof state.currentConversation.itineraries != 'undefined' ?
                                    <Modal.Body>
                                        {state.currentConversation.itineraries.map(itinerary => (
                                            <Itinerary key={itinerary.id} itinerary={itinerary} />
                                        ))}
                                    </Modal.Body>
                                    : null}

                            </Modal>
                        </Container>
                    </div>

                    <DefaultTabInfo />
                </div>
                :
                null
            }
        </div>
    );
};

export default TaskConversation;
