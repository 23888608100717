import { Droppable } from "react-beautiful-dnd";
import DragDropItem from "./DragDropItem";
import React from "react";

const DragDropColumn = ({
          prefix,
          journeyDay,
          renderDate,
          isEditMode,
          onClickEditJourneyDay,
          onClickClearJourneyDay,
          onClickNewJourneyDay,
          deleteJourneyDay
}) => (
    <div>
        {renderDate(journeyDay.date)}
        <div className="timeline__journey-day-header">
            <div className="timeline__journey-day-header-day">
                Day {journeyDay.day}
            </div>

            {isEditMode ? (
                <div className="timeline__btn-container">
                    {onClickNewJourneyDay && (
                        <button
                            type="button"
                            className="timeline__btn timeline__btn--add"
                            onClick={() => onClickNewJourneyDay(journeyDay)}
                        />
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>

        {journeyDay?.product && (
            <div>
                <div className="timeline__journey-day-accommodation" id={`timeline__journey-day-location`}>{journeyDay.product.country} <br/> {journeyDay.product.city}</div>
                <div
                    className="timeline__journey-day-accommodation"
                    id={`timeline__journey-day-accommodation--${journeyDay.product.color}`}
                >
                    {journeyDay.product.name &&
                        journeyDay.product.name}
                </div>
            </div>
        )}

        {!journeyDay?.product && (
            <div className="timeline__journey-day-no-accommodation"></div>
        )}

        {journeyDay?.accommodation && (
            <div
                className="timeline__journey-day-accommodation"
                id={`timeline__journey-day-accommodation--${journeyDay.accommodation.color}`}
            >
                {journeyDay.accommodation.name &&
                    journeyDay.accommodation.name}
            </div>
        )}

        {!journeyDay?.accommodation && (
            <div className="timeline__journey-day-no-accommodation"></div>
        )}

        <Droppable droppableId={`${prefix}`}>
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    {journeyDay.messages.map((item, index) => (
                        <DragDropItem
                            key={index}
                            message={item}
                            index={index}
                            isEditMode={isEditMode}
                            onClickEditJourneyDay={onClickEditJourneyDay}
                            onClickClearJourneyDay={onClickClearJourneyDay}
                            deleteJourneyDay={deleteJourneyDay}
                        />
                    ))}
                    <DragDropItem key="default" item="" index="" />
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </div>
);

export default DragDropColumn;
