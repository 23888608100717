import React from "react";

const InteractButton = (props) => {

    if (props.buttons === undefined) {
        return null;
    }

    if (props.buttons.length === 0) {
        return null;
    }

    return (
        <div>
            <div className="button group">
                {props.buttons.map((button, index) =>
                (<>
                    <button className="btn btn-group btn-primary">{button.text}</button>
                </>))}
            </div>

        </div>

    );
}

export default InteractButton;