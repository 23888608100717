import React from "react";
import { Modal } from "react-bootstrap";
import { logout } from "../../../utils/auth";
import PropTypes from "prop-types";

import "./LogoutModal.css";

import RectangleButton from "../../../Components/Reusable/RectangleButton/RectangleButton";

function LogoutModal({ show, onHide }) {
  const onClickLogout = () => {
    logout();
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title className="logout__title">
          Would you like to log out?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="logout__btn">
        <RectangleButton onClick={onClickLogout} type="submit" label="Yes" />
      </Modal.Body>
    </Modal>
  );
}

LogoutModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default LogoutModal;
