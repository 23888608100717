const initialState = {
  unassignedTasks: {
    tasks: [],
    unassignedOrderByDate: "",
    unassignedLastPage: "",
  },
  unassignedPage: 1,
  assignedTasks: {
    tasks: [],
    assignedOrderByDate: "",
    assignedLastPage: "",
  },
  assignedPage: 1,
  completedTasks: {
    tasks: [],
    completedOrderByDate: "",
    completedLastPage: "",
  },
  completedPage: 1,
  errors: "",
  branches: [],
  taskActions: [],
  filters: {
    from: null,
    to: null,
    branch: null,
    status: null,
    phone: null,
  },
};

const types = {
  UPDATE_UNASSIGNED_TASKS: "UPDATE_UNASSIGNED_TASKS",
  UPDATE_ASSIGNED_TASKS: "UPDATE_ASSIGNED_TASKS",
  UPDATE_COMPLETED_TASKS: "UPDATE_COMPLETED_TASKS",
  SHOW_ERRORS: "SHOW_ERRORS",
  UPDATE_BRANCHES: "UPDATE_BRANCHES",
  UPDATE_TASKACTIONS: "UPDATE_TASKACTIONS",
  UPDATE_FILTER: "UPDATE_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  UPDATE_PAGE_INDEX_UNASSIGNED: "UPDATE_PAGE_INDEX_UNASSIGNED",
  UPDATE_PAGE_INDEX_ASSIGNED: "UPDATE_PAGE_INDEX_ASSIGNED",
  UPDATE_PAGE_INDEX_COMPLETED: "UPDATE_PAGE_INDEX_COMPLETED",
  UPDATE_SORT_DATE_UNASSIGNED: "UPDATE_SORT_DATE_UNASSIGNED",
  UPDATE_SORT_DATE_ASSIGNED: "UPDATE_SORT_DATE_ASSIGNED",
  UPDATE_SORT_DATE_COMPLETED: "UPDATE_SORT_DATE_COMPLETED",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_UNASSIGNED_TASKS:
      return {
        ...state,
        unassignedTasks: action.payload,
      };
    case types.UPDATE_ASSIGNED_TASKS:
      return {
        ...state,
        assignedTasks: action.payload,
      };
    case types.UPDATE_COMPLETED_TASKS:
      return {
        ...state,
        completedTasks: action.payload,
      };
    case types.SHOW_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case types.UPDATE_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case types.UPDATE_TASKACTIONS:
      return {
        ...state,
        taskActions: action.payload,
      };
    case types.UPDATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case types.CLEAR_FILTER:
      return {
        ...state,
        filters: initialState.filters,
      };
    case types.UPDATE_PAGE_INDEX_UNASSIGNED:
      return {
        ...state,
        unassignedPage: action.payload,
      };
    case types.UPDATE_PAGE_INDEX_ASSIGNED:
      return {
        ...state,
        assignedPage: action.payload,
      };
    case types.UPDATE_PAGE_INDEX_COMPLETED:
      return {
        ...state,
        completedPage: action.payload,
      };
    case types.UPDATE_SORT_DATE_UNASSIGNED:
      state.unassignedTasks.unassignedOrderByDate = action.payload;
      return { ...state };
    case types.UPDATE_SORT_DATE_ASSIGNED:
      state.assignedTasks.assignedOrderByDate = action.payload;
      return { ...state };
    case types.UPDATE_SORT_DATE_COMPLETED:
      state.completedTasks.completedOrderByDate = action.payload;
      return { ...state };
    default:
      throw new Error("Unexpected action");
  }
};
export { initialState, types, reducer };
