import React, {useContext} from 'react';
import './UserRole.css'
import {UserRolesContext} from "../../store/context/UserRolesContext";
import {Link} from "react-router-dom";
import {Table} from "reactstrap";

const UsersTab = () => {
    const {state,} = useContext(UserRolesContext);

    return (
        <div className="roles-permissions">
            <div>
                <Table responsive="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="th-title">Username</th>
                        <th className="th-companies">Companies</th>
                        <th className="th-roles">Roles</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {state.users.map((user, key) => (
                        <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{user.name}</td>
                            <td>
                                {user.companies.map((company, key) => (
                                    <div key={key} className="permission-block">{company.company_name}</div>
                                ))}
                            </td>
                            <td>
                                {user.roles.map((role, key) => (
                                    <div key={key} className="permission-block">{role.title}</div>
                                ))}
                            </td>
                            <td>
                                <Link to={`users/${user.id}`}>
                                    <button type="button" className="btn btn-info role-settings">Edit</button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default UsersTab;
