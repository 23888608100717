import React, { useReducer } from "react";
import { reducer, initialState } from "../reducers/TemplateReducer";
import { useActions } from "../actions/TemplateActions";

export const TemplateContext = React.createContext();

const TemplateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  return (
    <TemplateContext.Provider value={{ actions, state, dispatch }}>
      {children}
    </TemplateContext.Provider>
  );
};

export default TemplateContextProvider;
