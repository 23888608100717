import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../store/context/AppContext";
import { ItineraryContext } from "../../../store/context/ItineraryContext";
import Select from "react-select";

import "./ItinerarySearchBar.css";

import SearchInputField from "../../Reusable/SearchInputField/SearchInputField";
import ClearButton from "../../Reusable/ClearButton/ClearButton";

export default function ItinerarySearchbar({ clearSort, pageIndex }) {
  const { state: ItineraryState, actions } = useContext(ItineraryContext);
  const { state: AppState } = useContext(AppContext);
  const [isInitial, setIsInitial] = useState(true);
  const [filter, setFilter] = useState(ItineraryState.filters);
  const [styleIsHighlight, setStyleIsHighlight] = useState(ItineraryState.closed);

  const selectInputRefStatus = useRef();
  const selectInputRefBranch = useRef();

  useEffect(() => {
    setFilter(ItineraryState.filters);
  }, [ItineraryState.filters]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    actions.filterItineraries(filter);
  }, [filter.status]);

  useEffect(() => {
    return () => {
      actions.clearFilterItineraries(ItineraryState.closed);
    };
  }, []);

  const fetchOpenItineraries = () => {
    actions.clearFilterItineraries(false);
    actions.updatePageIndex(1)
    actions.fetchItineraries(pageIndex, ItineraryState.filters, false);
    setStyleIsHighlight(false)
  };

  const fetchClosedItineraries = () => {
    actions.clearFilterItineraries(true);
    actions.updatePageIndex(1);
    actions.fetchItineraries(pageIndex, ItineraryState.filters, true);
    setStyleIsHighlight(true)
  };

  const onChangeStatus = (data) => {
    if (data) {
      setFilter({ ...filter, status: data.value });
    }
  };

  const onChangeBranch = (data) => {
    if (data) {
      setFilter({ ...filter, branch: data.id });
    }
  };

  const onChangeDossierId = (e) => {
    setFilter({ ...filter, dossier_id: e.target.value });
  };

  const onChangeCity = (e) => {
    setFilter({ ...filter, city: e.target.value });
  };

  const onChangeCountry = (e) => {
    setFilter({ ...filter, country: e.target.value });
  };

  const onChangeDescription = (e) => {
    setFilter({ ...filter, description: e.target.value });
  };

  const onClickSearch = () => {
    actions.filterItineraries(filter);
  };

  const onClickGetClosedItineraries = () => {
    actions.updateStateClosed(true);
    fetchClosedItineraries();
  };

  const onClickGetOpenItineraries = () => {
    actions.updateStateClosed(false);
    fetchOpenItineraries();
  };

  const onClickClearFilter = () => {
    actions.clearFilterItineraries(ItineraryState.closed);
    if (!ItineraryState.closed) {
      selectInputRefStatus.current.select.clearValue();
      selectInputRefBranch.current.select.clearValue();
    }
    clearSort();
  };

  return (
    <div className="itineraries-search-bar">
      <div>
        <button
          className={`itineraries-search-bar__tab ${!styleIsHighlight ? "itineraries-search-bar--is-highlight" : ""
            } `}
          onClick={onClickGetOpenItineraries}
        >
          Open
        </button>
        <button
          className={`itineraries-search-bar__tab ${styleIsHighlight ? "itineraries-search-bar--is-highlight" : ""
            } `}
          onClick={onClickGetClosedItineraries}
        >
          Closed
        </button>
      </div>
      <div className="itineraries-search-bar__search">
        {!ItineraryState.closed &&
          <Select
            name="status"
            className="itineraries-search-bar__select"
            placeholder="search status"
            ref={selectInputRefStatus}
            options={AppState.inputs.statusItineraries}
            getOptionLabel={(option) => `${option.title}`}
            defaultValue={filter.status}
            onChange={onChangeStatus}
          />
        }
        <Select
          name="branch"
          className="itineraries-search-bar__select"
          placeholder="search on company"
          ref={selectInputRefBranch}
          options={AppState.inputs.companies}
          getOptionLabel={(option) => `${option.branch_name}`}
          getOptionValue={(option) => `${option.id}`}
          defaultValue={filter.branch}
          onChange={onChangeBranch}
        />
        <SearchInputField
          className="itineraries-search-bar__input"
          placeholder="Search booking number"
          value={filter.dossier_id}
          onChange={onChangeDossierId}
          onClickSearch={onClickSearch}
        />
        {!ItineraryState.closed &&
          <SearchInputField
            className="itineraries-search-bar__input"
            placeholder="Search city (EN)"
            value={filter.city}
            onChange={onChangeCity}
            onClickSearch={onClickSearch}
          />
        }
        {!ItineraryState.closed &&

          <SearchInputField
            className="itineraries-search-bar__input"
            placeholder="Search country (EN)"
            value={filter.country}
            onChange={onChangeCountry}
            onClickSearch={onClickSearch}
          />
        }
        {!ItineraryState.closed &&

          <SearchInputField
            className="itineraries-search-bar__input"
            placeholder="Search trip name"
            value={filter.description}
            onChange={onChangeDescription}
            onClickSearch={onClickSearch}
          />
        }
        <ClearButton onClick={onClickClearFilter} />
      </div>
    </div>
  );
}
