import React, { useState, useContext } from "react";
import { TipsContext } from "../../../store/context/TipsContext";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import TipForm from "./TipForm";

const initialState = {
  title: "",
  type: "",
  tags: [],
  body: "",
  opening_hours: null,
  address: "",
  maps_url: "",
  remarks: "",
};

function AddNewTip({ show, onHide }) {
  const { actions } = useContext(TipsContext);
  const [tip, setTip] = useState(initialState);

  const onSubmitNewTip = (data) => {
    actions.addNewTip(data, onHide);
    setTip(initialState);
  };

  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title>New Tip</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TipForm data={tip} onTipSubmit={onSubmitNewTip} />
      </Modal.Body>
    </Modal>
  );
}

AddNewTip.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AddNewTip;
