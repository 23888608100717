import React, { useReducer } from "react";
import { reducer, initialState } from "../reducers/TipsReducer";
import { useActions } from "../actions/TipsActions";

export const TipsContext = React.createContext();

const TipsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  return (
    <TipsContext.Provider value={{ actions, state, dispatch }}>
      {children}
    </TipsContext.Provider>
  );
};

export default TipsContextProvider;
