import React, { useState, useContext, useEffect } from "react";
import { TemplateContext } from "../../../../store/context/TemplateContext";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import {Col, Form, Image, Row} from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import TipSelector from "./TipSelector/TipSelector";
import TemplateItem from "../TemplateItem/TemplateItem";
import TextInput from "./TextInput/TextInput";
import TimePicker from "react-times";
import moment from 'moment';
import "react-times/css/material/default.css";
import "./TemplateDay.css";
import { getToken } from "../../../../utils/auth";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";
import CircleButton from "../../../Reusable/CircleButton/CircleButton";
import ErrorModal from "../../../Reusable/ErrorModal/ErrorModal";
import traveler from "../../../TravelerInformation/Traveler";

function TemplateDay({ closeTemplateDay, selectedDayData, isIndividualMode }) {
  const { state: templateState, actions: templateActions } = useContext(
    TemplateContext
  );
  const { state: itineraryState, actions: itineraryActions } = useContext(
    ItineraryContext
  );
  const [selectedTipId, setSelectedTipId] = useState(null);
  const [selectedTextId, setSelectedTextId] = useState(null);
  const [selectedText, setSelectedText] = useState(null);
  const [journeyDay, setJourneyDay] = useState(selectedDayData);
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [showTextInput, setShowTextInput] = useState(false);
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [defaultSendAt, setDefaultSendAt] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (isIndividualMode) {
      if (itineraryState?.traveller?.data?.conversation?.branch_id && itineraryState?.traveller?.data?.conversation?.company_channel_id) {
        itineraryActions.fetchWhatsappTemplates(
          itineraryState.traveller.data.conversation.branch_id,
          itineraryState.traveller.data.conversation.company_channel_id
        );
      }

      const sendAt = moment(`${journeyDay.date} 12:00`).format("YYYY-MM-DDTHH:mm");
      setDefaultSendAt(sendAt);
      templateActions.cleanUpValidationErrors();
    } else {
      if (templateState?.template?.data?.branch_id && templateState?.template?.data?.company_channel_id) {
        templateActions.fetchWhatsappTemplates(
          templateState.template.data.branch_id,
          templateState.template.data.company_channel_id
        );
      }
    }
  }, []);

  useEffect(() => {
    if (isIndividualMode) {
      setWhatsappTemplates(itineraryState.whatsappTemplates);
    } else {
      setWhatsappTemplates(templateState.whatsappTemplates);
    }
  }, [itineraryState, templateState]);

  const onChangeWhatsappTemplate = (templateId) => {
    //cleanup state during the edit template
    if (journeyDay?.template?.accept_media === true) {
      journeyDay.template.accept_media = false;
    }

    const newTemplate = whatsappTemplates.find((t) => t.id === templateId);
    if (newTemplate) {
      const newJourneyDay = { ...journeyDay };

      newJourneyDay.message_template_id = newTemplate.id;
      newJourneyDay.selectedTemplate = newTemplate;


      if (newJourneyDay.message_template_id !== journeyDay.message_template_id) {
        newJourneyDay.media = null;
      }

      if (newJourneyDay.vars && newJourneyDay.vars.length > 0) {
        newTemplate.vars.forEach((variable) => {
          if (variable.var_is_message) {
            let tipToKeep = newJourneyDay.vars.filter(
              (tip) => tip.key === variable.key
            );
            if (tipToKeep && tipToKeep.length > 0) {
              variable.article_id = tipToKeep[0].article_id;
              variable.article_body = tipToKeep[0].article_body;
            }
          } else if (variable.value === "") {
            let textToKeep = newJourneyDay.vars.filter(
              (tip) => tip.key === variable.key
            );
            if (textToKeep && textToKeep.length > 0) {
              variable.value = textToKeep[0].value;
            }
          }
        });
      }

      newJourneyDay.vars = newTemplate.vars;
      newJourneyDay.message_template_body = newTemplate.body;
      setJourneyDay(newJourneyDay);
    }
  };

  const submitTip = (tip) => {
    const newJourneyDay = { ...journeyDay };
    if (newJourneyDay.vars[selectedTipId]) {
      newJourneyDay.vars[selectedTipId].article_body = tip.body;
      newJourneyDay.vars[selectedTipId].article_id = tip.id;
      newJourneyDay.vars[selectedTipId].value = tip.body;
      setJourneyDay(newJourneyDay);
    }
    setSelectedTipId(null);
  };

  const submitText = (text) => {
    const newJourneyDay = { ...journeyDay };
    if (newJourneyDay.vars[selectedTextId]) {
      newJourneyDay.vars[selectedTextId].value = text.value;
      setJourneyDay(newJourneyDay);
    }
    setSelectedText(null);
    setSelectedTextId(null);
    setShowTextInput(false);
  };

  const onChangeTime = (value) => {
    if (isIndividualMode) {
      const newJourneyDay = { ...journeyDay };
      newJourneyDay.send_at = value;
      setJourneyDay(newJourneyDay);
    } else {
      const newJourneyDay = { ...journeyDay };
      newJourneyDay.send_at = `${value.hour}:${value.minute}`;
      setJourneyDay(newJourneyDay);
    }
  };

  const onChangeDay = (value) => {
    if (!isIndividualMode) {
      journeyDay.day = value;
      setJourneyDay(journeyDay);
    }
  };

  const onClickSave = () => {
    journeyDay.file = file;
    if (!journeyDay.send_at) {
      if (isIndividualMode) {
        journeyDay.send_at = defaultSendAt;
        if (journeyDay.template) {
          setDisabled(true);

          itineraryActions.updateJourneyDayMessage(
            journeyDay,
            closeTemplateDay
          );
        } else {
          setDisabled(true);
          itineraryActions.createJourneyDayMessage(
            journeyDay,
            closeTemplateDay
          );
        }
      } else {
        journeyDay.send_at = "12:00";
        if (journeyDay.id) {
          setDisabled(true);
          templateActions.updateJourneyDay(journeyDay, closeTemplateDay);
        } else {
          setDisabled(true);
          templateActions.createJourneyDay(journeyDay, closeTemplateDay);
        }
      }
    } else {
      if (isIndividualMode) {
        if (journeyDay.template ) {
          setDisabled(true);
          itineraryActions.updateJourneyDayMessage(
            journeyDay,
            closeTemplateDay
          );
        } else {
          setDisabled(true);
          itineraryActions.createJourneyDayMessage(
            journeyDay,
            closeTemplateDay
          );
        }
      } else {
        if (journeyDay.journey_day_id) {
          setDisabled(true);
          templateActions.updateJourneyDay(journeyDay, closeTemplateDay);
        } else {
          setDisabled(true);
          templateActions.createJourneyDay(journeyDay, closeTemplateDay);
        }
      }
    }

    templateActions.cleanUpValidationErrors();
    setDefaultSendAt(null);
  };

  const onClickSaveToTemplateAndItineraries = () => {
    journeyDay.apply_to_current_itineraries = 1;
    journeyDay.file =file;
    if (!journeyDay.send_at) {
      journeyDay.send_at = "12:00";
      if (journeyDay.id) {
        setDisabled(true);
        templateActions.updateJourneyDay(journeyDay, closeTemplateDay);
      } else {
        setDisabled(true);
        templateActions.createJourneyDay(journeyDay, closeTemplateDay);
      }
    } else {
      if (journeyDay.journey_day_id) {
        setDisabled(true);
        templateActions.updateJourneyDay(journeyDay, closeTemplateDay);
      } else {
        setDisabled(true);
        templateActions.createJourneyDay(journeyDay, closeTemplateDay);
      }
    }
    setDefaultSendAt(null);
  }

  const onClickAddTip = (tipId) => {
    setSelectedTipId(tipId);
  };

  const onClickAddText = (article, key) => {
    setSelectedText(article);
    setSelectedTextId(key);
    setShowTextInput(true);
  };

  const onCloseTextInput = () => {
    setShowTextInput(false);
  };

  const onCloseTipSelector = () => {
    setSelectedTipId(null);
  };

  const onCloseTemplateDay = () => {
    if (closeTemplateDay) {
      closeTemplateDay();
      setJourneyDay(null);
      templateActions.cleanUpValidationErrors();
    }
  };

  const clearError = () => {
    setShowError(false);
  };

  const onChangeFile= (event) => {
    event.persist();

    if (event.target.files.length === 0) {
      setFile((currentFile) => null);
      return;
    }

    let reader = new FileReader()
    getBase64(event.target.files[0]);

  }

  const getBase64 = file => {
    let reader = new FileReader();
     reader.readAsDataURL(file);
    reader.onload = () => {
      onLoadFile(reader.result);
    };
  };

  const onLoadFile = fileString => {
    setFile((currentFile) => fileString);
  };

  return (
    <div className="template-day">
      <div className="template-day__header">
        <div className="template-day__header--text">Template Day Message</div>
        <CircleButton type="prev" onClick={onCloseTemplateDay} />
      </div>

      <Form>
        <Form.Group>
          <Select
            className="template-day__select-whatsapp"
            options={whatsappTemplates}
            getOptionLabel={(option) => `${option.title} :: ${option.body} `}
            placeholder="Please select a whatsapp template to continue"
            defaultValue={journeyDay.template || ""}
            getOptionValue={(option) => `${option.id}`}
            onChange={(whatsappTemplate) =>
              onChangeWhatsappTemplate(whatsappTemplate.id)
            }
          />
        </Form.Group>
        <Row>
          <Col md={8} >
            {journeyDay?.selectedTemplate?.accept_media === true  || journeyDay?.template?.accept_media === true  ? (
                <Form.Group>
                  <Form.Label>Upload media</Form.Label>
                  <Form.File
                      name={'file'}
                      onChange={(event) => onChangeFile(event)}
                      isInvalid={templateState.validateErrors.file.length !== 0 || itineraryState.validateErrors.file.length !== 0 }
                      feedback={templateState.validateErrors.file[0] || itineraryState.validateErrors.file[0]}
                  />
                </Form.Group>
            ) : null}
          </Col>

            {journeyDay?.media !== null && journeyDay?.media?.media_type === 'image' ? (
                <Col md={4}  >
                  <Col xs={6} md={4} className={'float-right'} >
                    <Image  src={journeyDay?.media.path} thumbnail />
                  </Col>
                </Col>
            ) : null }
            {journeyDay?.media !== null && journeyDay?.media?.media_type === 'document' ? (
              <Col md={4}>
                <div className={'timeline__btn-container'}>
                  <a href={journeyDay?.media.path} target={'blank'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                      <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                      <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                    </svg>
                  </a>
                </div>
              </Col>
            ): null }
            {journeyDay?.media !== null && journeyDay?.media?.media_type === 'video' ? (

                <video
                    muted
                    controls
                >
                  <source
                      src={journeyDay.media.path}
                  ></source>
                </video>
            ) : null }

        </Row>
      </Form>


      {typeof selectedTipId === "number" && selectedText === null && (
        <TipSelector
          show={typeof selectedTipId === "number" && selectedText === null}
          onHide={onCloseTipSelector}
          submitTip={submitTip}
        />
      )}
      {showTextInput && (
        <TextInput
          show={showTextInput}
          onHide={onCloseTextInput}
          textData={selectedText}
          submitText={submitText}
        />
      )}
      {showError && <ErrorModal errorMessage={showError} onHide={clearError} />}

      {journeyDay.message_template_id && (
        <div>
          {journeyDay?.message_template_body && (
            <div className="template-day__message">
              {journeyDay.message_template_body}
            </div>
          )}
          <div>
            {journeyDay?.vars.map((article, key) => {
              if (article.var_is_message) {
                return (
                  <TemplateItem
                    key={key}
                    labelKey={`{{${key + 1}}}`}
                    copy={article.article_body}
                    label="Add Tip"
                    buttonAction={() => onClickAddTip(key)}
                  />
                );
              } else {
                return (
                  <TemplateItem
                    key={key}
                    labelKey={`{{${key + 1}}}`}
                    copy={article.value}
                    label="Add Text"
                    buttonAction={() => onClickAddText(article, key)}
                  />
                );
              }
            })}
          </div>

          {isIndividualMode &&
            <div className="template-day__status-message">
              STATUS OF MESSAGE: {journeyDay?.status}
            </div>
          }

          <div className="template-day__footer">
            {isIndividualMode ? (
              <div className="template-day__time">
                Send message at:
                <div>
                  <input
                    required
                    type="datetime-local"
                    name="send_at"
                    placeholder="Select day and time message should be send"
                    defaultValue={journeyDay.send_at || defaultSendAt}
                    onChange={(e) => onChangeTime(e.target.value)}
                  >
                  </input>
                </div>
              </div>
            ) : (

                <div className="template-day__time">
                  Send message at:
                  <TimePicker
                      isRequired
                      onTimeChange={(value) => onChangeTime(value)}
                      time={journeyDay.send_at || "09:00"}
                  />

                  <div className="template-day__time">
                    Send message at day:
                    <div>
                      <input
                          required
                          type="number"
                          name="day"
                          placeholder="Select day"
                          defaultValue={journeyDay.day}
                          onChange={(e) => onChangeDay(e.target.value)}
                          className="form-control"
                      >
                      </input>
                    </div>
                  </div>
                </div>
            )}


            <RectangleButton
              label="Save"
              onClick={onClickSave}
              isHighlight
              isSmall
              disabled={disabled && (templateState.validateErrors.file.length === 0 && itineraryState.validateErrors.file.length === 0)}
            />

            {!isIndividualMode && getToken().is_admin || !isIndividualMode && getToken().is_highagent ? (
              <>
                <RectangleButton
                  label="Save and add to itineraries"
                  onClick={onClickSaveToTemplateAndItineraries}
                  isLarge
                  disabled={disabled && (templateState.validateErrors.file.length === 0 && itineraryState.validateErrors.file.length === 0)}
                />
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

TemplateDay.propTypes = {
  closeTemplateDay: PropTypes.func,
  selectedDayData: PropTypes.object,
  isIndividualMode: PropTypes.bool,
};

export default TemplateDay;
