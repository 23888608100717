import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./RectangleButton.css";

function RectangleButton({
  label,
  onClick,
  className='',
  href,
  disabled,
  isHighlight,
  isSmall,
  isLarge,
  type
}) {
  let modifier = "";
  if (isHighlight) modifier += "rectangle-btn--orange ";
  if (isSmall) modifier += "rectangle-btn--small ";
  if (isLarge) modifier += "rectangle-btn--large ";

  const buttonClassName = `rectangle-btn ${modifier} ${className}`;

  const buttonRegular = () => {
    return (
      <button className={buttonClassName} disabled={disabled} onClick={onClick} type={type}>
        {label}
      </button>
    );
  };

  const buttonLink = () => {
    return (
      <Link to={href} className={buttonClassName} disabled={disabled}>
        {label}
      </Link>
    );
  };

  return (
    <>
      {href && buttonLink()}
      {!href && buttonRegular()}
    </>
  );
}

RectangleButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  isHighlight: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default RectangleButton;
