import React, { useState, useContext, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { TemplateContext } from "../../../../store/context/TemplateContext";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import "./AddLocation.css";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";

function AddLocation({ show, onHide, data, isItinerary, locationId }) {
  const { actions: templateActions } = useContext(TemplateContext);
  const { actions: itineraryActions } = useContext(ItineraryContext);
  const [maxLength, setMaxLength] = useState();
  const [location, setLocation] = useState({
    title: "",
    start_day: 1,
    end_day: 1,
  });

  useEffect(() => {
    if (locationId) {
      let location = data.locations.find((j) => j.id === locationId);
      setLocation(location);
    }
    if (data) {
      setMaxLength(data.journey_length);
    }
  }, [isItinerary, data, locationId]);

  const handleLocation = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const rawValue = e.target.value;
    let value;

    if (name === "start_day" || name === "end_day") {
      value = parseInt(rawValue);
      value = Number.isNaN(value) ? rawValue : value;
    } else {
      value = rawValue;
    }
    setLocation({ ...location, [name]: value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (isItinerary) {
      itineraryActions.updateLocationItinerary(location, onHide);
    } else {
      if (location.id) {
        templateActions.updateLocationTemplate(location, data.id, onHide);
      } else {
        templateActions.createLocationTemplate(location, data.id, onHide);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="add-location__form" onSubmit={onSubmitForm}>
          <Form.Control
            name="title"
            value={location.title}
            onChange={handleLocation}
            placeholder="Add location"
          />
          <div className="add-location__days-and-btn">
            <div className="add-location__days">
              <Form.Label className="add-location__title">Day</Form.Label>
              <Form.Control
                name="start_day"
                className="add-location__input-field"
                type="number"
                value={location.start_day}
                onChange={handleLocation}
                placeholder={1}
                min={1}
              />
              <div className="add-location__days-to">to</div>

              <Form.Label className="add-location__title">Day</Form.Label>
              <Form.Control
                name="end_day"
                className="add-location__input-field"
                type="number"
                value={location.end_day}
                onChange={handleLocation}
                placeholder={1}
                min={1}
                max={maxLength}
              />
            </div>
            <RectangleButton type="submit" isSmall label="Add" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

AddLocation.propTypes = {
  data:PropTypes.object,
  isItinerary: PropTypes.bool,
  locationId:PropTypes.number,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(AddLocation);
