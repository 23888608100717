import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { withRouter } from "react-router";
import { DeepClone } from "../../../utils";
import { TemplateContext } from "../../../store/context/TemplateContext";
import EditTemplateForm from "../EditTemplate/EditTemplateForm/EditTemplateForm";
import TemplateTimeline from "./TemplateTimeline/TemplateTimeline";
import TemplateDetails from "./TemplateDetails/TemplateDetails";
import TemplateHeader from "./TemplateHeader/TemplateHeader";
import PropTypes from "prop-types";

import "./Template.css";

import ErrorModal from "../../Reusable/ErrorModal/ErrorModal";
import ConfirmationModal from "../../Reusable/ConfirmationModal/ConfirmationModal";
import SuccessModal from "../../Reusable/SuccessModal/SuccessModal";

function Template({ history }) {
  const { state, actions } = useContext(TemplateContext);
  const [template, setTemplate] = useState({});
  const [isHeaderDisplayed, setIsHeaderDisplayed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [askConfirmationText, setAskConfirmationText] = useState('');

  const params = useParams();

  useEffect(() => {
    let id = params.id;
    actions.getTemplate(id);
  }, [params.id]);

  useEffect(() => {
    if (state?.template?.data?.id) {
      const clonedTemplateData = DeepClone(state.template.data);
      setTemplate(clonedTemplateData);
    }
  }, [state, isEditMode, askConfirmation]);

  const onClickEdit = () => {
    if (isEditMode === false) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  };

  const displayHeader = () => {
    if (isHeaderDisplayed === false) {
      setIsHeaderDisplayed(true);
      setIsEditMode(false);
    } else {
      setIsHeaderDisplayed(false);
      setIsEditMode(true);
    }
  };

  const handleFormData = (key, value) => {
    setTemplate({ ...template, [key]: value });
  };

  const onClickSubmit = () => {
    processForm();
  };

  const onClickSaveDraft = () => {
    processForm(true);
  };

  const onClickDuplicate = (isDuplicate) => {
    duplicateForm(isDuplicate);
  };

  const onClickDelete = () => {
    setAskConfirmation(true)
    setAskConfirmationText('Are you sure you want to delete this template?');
  };

  const processForm = (isDraft = false) => {
    template[isDraft ? "draft" : "save"] = 1;
    actions.editTemplate(template, onClickEdit);
  };

  const duplicateForm = (isDuplicate) => {
    actions.duplicateTemplate(template, history, onClickEdit, isDuplicate);
  };

  const clearError = () => {
    actions.clearError();
  };

  const clearSuccess = () => {
    actions.clearSuccess();
  };

  const clearModal = () => {
    setAskConfirmation(false)
  }

  const deleteTemplateConfirmed = () => {
    actions.deleteTemplate(template.id);
    setAskConfirmation(false)
  }

  return (
    <div className="template-container">
      <div className="template-header">
        <TemplateHeader
          onClickEdit={onClickEdit}
          onClickSaveDraft={onClickSaveDraft}
          onClickSubmit={onClickSubmit}
          onClickDuplicate={onClickDuplicate}
          isEditMode={isEditMode}
          isHeaderDisplayed={isHeaderDisplayed}
          onClickDelete={onClickDelete}
        />
      </div>
      <div className="template-timeline">
        <TemplateTimeline
          isEditMode={isEditMode}
          template={template}
          displayHeader={displayHeader}
        />
      </div>

      {!isEditMode ? (
        <div className="template-details">
          <TemplateDetails onClickEdit={onClickEdit} />
        </div>
      ) : (
        <div className="template-details">
          <EditTemplateForm
            formData={template}
            handleFormData={handleFormData}
          />
        </div>
      )}
      <ErrorModal errorMessage={state.errors} onHide={clearError} />
      <SuccessModal successMessage={state.success} onHide={clearSuccess} />
      <ConfirmationModal askConfirmation={askConfirmation} text={askConfirmationText} confirmationGiven={deleteTemplateConfirmed} onHide={clearModal} />
    </div>
  );
}

Template.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Template);
