import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../../store/context/AppContext";
import { TemplateContext } from "../../../../store/context/TemplateContext";
import Select from "react-select";

import "./TemplatesSearchBar.css";

import SearchInputField from "../../../Reusable/SearchInputField/SearchInputField";
import ClearButton from "../../../Reusable/ClearButton/ClearButton";

export default function TemplatesSearchbar() {
  const { state: AppState } = useContext(AppContext);
  const { state, actions } = useContext(TemplateContext);
  const [isInitial, setIsInitial] = useState(true);
  const [filter, setFilter] = useState(state.filters);

  const selectInputRefCountry = useRef();
  const selectInputRefCompany = useRef();
  const selectInputRefStatus = useRef();
  const selectInputRefChannel = useRef();

  useEffect(() => {
    setFilter(state.filters);
  }, [state.filters]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    actions.filterTemplates(filter);
  }, [filter.country, filter.branch, filter.status, filter.channel]);

  const onChangeCountry = (data) => {
    if (data) {
      setFilter({ ...filter, country: data.id });
    }
  };

  const onChangeTitle = (e) => {
    setFilter({ ...filter, title: e.target.value });
  };

  const onChangeCompany = (data) => {
    if (data) {
      setFilter({ ...filter, branch: data.id });
    }
  };

  const onChangeStatus = (data) => {
    if (data) {
      setFilter({ ...filter, status: data.value });
    }
  };

  const onChangeChannel = (data) => {
    if (data) {
      setFilter({ ...filter, channel: data.id });
    }
  }

  const onClickSearchCountry = (e) => {
    actions.filterTemplates(filter);
  };

  const onClickSearchTitle = () => {
    actions.filterTemplates(filter);
  };

  const onClickClearFilter = () => {
    actions.clearFilterTemplates();
    selectInputRefCountry.current.select.clearValue();
    selectInputRefCompany.current.select.clearValue();
    selectInputRefStatus.current.select.clearValue();
    selectInputRefChannel.current.select.clearValue();
  };

  return (
    <div className="templates-search-bar">
      <Select
        placeholder="Search country"
        ref={selectInputRefCountry}
        options={AppState.inputs.countries.data}
        getOptionLabel={(option) => `${option.title}`}
        className="templates-search-bar__select"
        defaultValue={filter.country}
        getOptionValue={(option) => `${option.id}`}
        onChange={onChangeCountry}
        onClickSearch={onClickSearchCountry}
      />
      <SearchInputField
        placeholder="Search title"
        value={filter.title}
        defaultValue={state.filters.title}
        onChange={onChangeTitle}
        onClickSearch={onClickSearchTitle}
      />
      <Select
        ref={selectInputRefCompany}
        className="templates-search-bar__select"
        options={AppState.inputs.companies}
        getOptionLabel={(option) => `${option.branch_name}`}
        defaultValue={filter.branch}
        getOptionValue={(option) => `${option.id}`}
        placeholder="search company"
        onChange={onChangeCompany}
      />
      <Select
        ref={selectInputRefStatus}
        className="templates-search-bar__select"
        options={AppState.inputs.statusTemplates}
        defaultValue={filter.status}
        getOptionValue={(option) => `${option.value}`}
        placeholder="search status"
        onChange={onChangeStatus}
      />
       <Select
        ref={selectInputRefChannel}
        className="templates-search-bar__select"
        options={AppState.inputs.channels}
        getOptionLabel={(option) => `${option.channel_name}`}
        defaultValue={filter.channel}
        getOptionValue={(option) => `${option.id}`}
        placeholder="search channel"
        onChange={onChangeChannel}
      />
      <ClearButton onClick={onClickClearFilter} />
    </div>
  );
}
