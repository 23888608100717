import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import axios from "axios";

const initialState = {
  new_password: "",
  confirm_new_password: "",
};

const NewPassword = () => {
  const history = useHistory();
  const [newPasswordData, setNewPasswordData] = useState(initialState);

  const handleChange = (e) => {
    setNewPasswordData({ ...newPasswordData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      token: window.location.search.split("=")[1],
      new_password: newPasswordData.new_password,
      confirm_new_password: newPasswordData.confirm_new_password,
    };

    let resetPasswordUrl =
      process.env.REACT_APP_BASEURL + "/auth/reset-password";
    axios
      .post(resetPasswordUrl, data)
      .then(() => {
        setNewPasswordData(initialState);
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <article className="bg">
      <form>
        <div className="login__container">
          <img
            src="https://res.cloudinary.com/dsuhcbmxk/image/upload/v1594286546/logo-1-small.png"
            alt="logo"
          />
          <div className="login__form">
            <div>
              <input
                onChange={handleChange}
                defaultValue={newPasswordData.password}
                name="new_password"
                type="password"
                placeholder="New password"
                className="form-section"
              />
            </div>
          </div>
          <div className="login__form">
            <div>
              <input
                onChange={handleChange}
                defaultValue={newPasswordData.password}
                name="confirm_new_password"
                type="password"
                placeholder="Confirm new password"
                className="form-section"
              />
            </div>
          </div>
          <br />
          <p>
            Choose a password that consists of at least seven characters which
            includes:
          </p>
          <p>lowercase, UPPERCASE, numbers and / or special characters </p>

          <button
            className="login__btn restore_password_btn"
            onClick={handleSubmit}
          >
            <p className="login__text">Restore password</p>
          </button>
          <br />
          <p id="login__error-message"></p>
        </div>
      </form>
    </article>
  );
};

export default NewPassword;
