import React, {useState, useContext} from "react";
import {Form, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {TipsContext} from "../../../store/context/TipsContext";
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

function AddTypeModal({show, onHide}) {
    const {actions} = useContext(TipsContext);
    const [typeName, setTypeName] = useState(null);
    const [typeIcon, setTypeIcon] = useState(null);
    const [typePin, setTypePin] = useState(null);

    const onSubmitTypeForm = (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('label', typeName);
        formData.append('icon', typeIcon);
        formData.append('pin', typePin);
        actions.addNewType(formData, onHide);
    };

    const onChangeTypeName = (e) => {
        setTypeName(e.target.value);
    };

    const onChangeTypeIcon = (e) => {
        setTypeIcon(e.target.files[0]);
    };

    const onChangeTypePin = (e) => {
        setTypePin(e.target.files[0]);
    };

    return (
        <Modal key="add-new-type" show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>New Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="add-type-form" onSubmit={onSubmitTypeForm}>
                    <Form.Label className="tip-form__title">TYPE NAME</Form.Label>
                    <Form.Control
                        required
                        name="title"
                        type="text"
                        placeholder="Add a type name"
                        onChange={onChangeTypeName}
                    />
                    <Form.Label className="tip-form__title">TYPE ICON</Form.Label>
                    <Form.Control
                        required
                        name="icon"
                        type="file"
                        placeholder="Add a type icon"
                        onChange={onChangeTypeIcon}
                    />
                    <Form.Label className="tip-form__title">TYPE PIN</Form.Label>
                    <Form.Control
                        required
                        name="pin"
                        type="file"
                        placeholder="Add a type pin"
                        onChange={onChangeTypePin}
                    />
                    <br/>
                    <div className="tip-form__btns">
                        <RectangleButton type="submit" label="Save"/>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

AddTypeModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default AddTypeModal;
