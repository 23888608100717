import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import './UserRole.css'
import {UserRolesContext} from "../../store/context/UserRolesContext";
import {Form} from "react-bootstrap";
import Select from "react-select";
import RectangleButton from "../Reusable/RectangleButton/RectangleButton";

const CreateEditRole = () => {
    let {id} = useParams();
    const {state, actions} = useContext(UserRolesContext);

    const [data, setData] = useState({
        name: '',
        companies: '',
        roles: '',
    });

    useEffect(() => {
        if(state.users.length === 0) {
            actions.fetchUsers();
        }
        if(state.companies.length === 0) {
            actions.fetchCompanies();
        }
        if(state.roles.length === 0) {
            actions.fetchRoles();
        }

        if (typeof id != 'undefined' && state.users.length > 0) {
            let user = state.users.find((user) => {
                if (user.id === parseInt(id)) {
                    return true;
                }
                return false;
            });

            setData({
                name: user?.name ? user?.name : '',
                companies: user?.companies ? user.companies : '',
                roles: user?.roles ? user.roles : '',
            });
        }

    }, [state]);

    const onSubmit = (e) => {
        e.preventDefault();
        actions.updateUser(id, data);
    };

    const onChangeName = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    const changeCompanies = (selectedCompany) => {
        setData({...data, companies: selectedCompany});
    };

    const changeRoles = (selectedRole) => {
        setData({...data, roles: selectedRole});
    };

    return (
        <div className="roles-permissions">
            <p className="create-edit-page-title">Edit User</p>

            <Form className="roles-form" onSubmit={onSubmit}>
                <Form.Group>
                    <Form.Label className="roles-form__title">Name</Form.Label>
                    <Form.Control
                        required
                        name="name"
                        type="text"
                        placeholder="add a name"
                        value={data.name}
                        onChange={onChangeName}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label className="roles-form__title">Companies</Form.Label>
                    <Select
                        isMulti
                        name="permissions"
                        placeholder="select companies"
                        getOptionLabel={(option) => `${option.company_name}`}
                        getOptionValue={(option) => `${option.id}`}
                        options={state.companies}
                        value={data.companies}
                        onChange={changeCompanies}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label className="roles-form__title">Roles</Form.Label>
                    <Select
                        isMulti
                        name="roles"
                        placeholder="select roles"
                        getOptionLabel={(option) => `${option.title}`}
                        getOptionValue={(option) => `${option.id}`}
                        options={state.roles}
                        value={data.roles}
                        onChange={changeRoles}
                    />
                </Form.Group>

                <RectangleButton className="btn-success" type="submit" label="Update"/>
            </Form>

            {state?.updateUserErrors && state.updateUserErrors.length > 0 ?
                <div>
                    {state.updateUserErrors.map((error, key) => (
                        <p key={key} className="text-danger">{error}</p>
                    ))}
                </div>
            : ''}
        </div>
    );
};

export default CreateEditRole;
