import React from 'react';
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

function ImportStartedModal({ show, onHide}) {
    return (
        <Modal show={show} onHide={onHide} size="sm" centered>
        <Modal.Header closeButton>
            <Modal.Title className="import-templates-modal__title">
                Import has been started. Go to the dashboard for an update on the import.
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="import-templates-modal__btn">
            <RectangleButton href="/dashboard" type="submit" label="Go to dashboard" />
        </Modal.Body>
    </Modal>
    );
}

ImportStartedModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
};

export default ImportStartedModal;
