import React, { useContext, useEffect, useState } from 'react';
import './Dashboard.css'
import { DashboardContext } from "../../store/context/DashboardContext";
import { Table } from "reactstrap";

const ImportedSheets = () => {
    const { state, actions } = useContext(DashboardContext);

    useEffect(() => {
        actions.fetchImportedSheets();
    }, [])

    return (
        <div className="dashboard">
            <b className="title">Imported journey templates</b>
            <br />
            <br />

            <Table responsive="sm">
                <thead>
                    <tr>
                        <th>Company name</th>
                        <th>Status</th>
                        <th>Error message</th>
                        <th>Sheet ID</th>
                        <th>Tab name</th>
                        <th>User</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {state.importedSheets && state.importedSheets.length > 0 && state.importedSheets.map((sheet, i) => {
                        return <tr key={i}>
                            <td>{sheet.branch?.branch_name}</td>
                            <td>{sheet.status}</td>
                            <td>{sheet.error_message}</td>
                            <td>{sheet.sheet_name}</td>
                            <td>{sheet.tab_name}</td>
                            <td>{sheet.user?.name}</td>
                            <td>{sheet.updated_at}</td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default ImportedSheets;
