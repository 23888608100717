import React from "react";
import "./Company.css";

const Company = ({task}) => {
    return (
      <div className="company__container">
        <div className="company__icon"></div>
        <div className="company__information">
          <p className="company__name">{(task.branch) && task.branch.branch_name}</p>
          <p className="company__type">Travel company</p>
        </div>
      </div>
    );
}

export default Company;
