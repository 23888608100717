import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import Conversations from "../src/Components/Conversations/Conversations.jsx";
import Inbox from "./Components/Inbox/InboxContainer/Inbox.jsx";
import GoogleAuth from "./PublicLayout/Login/GoogleAuth";
import Login from "../src/PublicLayout/Login/Login";
import ForgotPassword from "../src/PublicLayout/Login/ForgotPassword";
import NewPassword from "../src/PublicLayout/Login/NewPassword";
import Tips from "../src/Components/Tips/Tips/Tips";
import Kpi from "../src/Components/Kpi/Kpi";
import Dashboard from "../src/Components/Dashboard/Dashboard";
import ProtectRoute from "./ProtectRoute";
import Template from "../src/Components/Templates/Template/Template";
import Templates from "../src/Components/Templates/Templates/Templates.jsx";
import ImportTemplates from "../src/Components/Templates/ImportTemplates/ImportTemplates.jsx";
import ImportWafbTemplates from "../src/Components/Templates/ImportTemplates/ImportWafbTemplates.jsx";
import ImportAndCreateTemplates from "../src/Components/Templates/ImportTemplates/ImportAndCreateTemplates.jsx";
import Itineraries from "./Components/Itineraries/Itineraries";
import ItineraryGroup from "./Components/Itineraries/ItineraryGroup/ItineraryGroup";
import ItineraryIndividual from "./Components/Itineraries/ItineraryIndividual/ItineraryIndividual";
import MassiveMessageSend from "../src/Components/Conversations/MassiveMessageSend.jsx";
import BulkMessage from "./Components/Conversations/BulkMessage/BulkMessage.jsx";

//Layouts:
import { checkToken, getToken } from "./utils/auth";
import PublicLayout from "./PublicLayout/PublicLayout";
import PrivateLayout from "./PrivateLayout/PrivateLayout";

//CSS
import "./App.css";
import "./css/colours.css";
import "./css/labelColours.css";
import "./css/modal.css";
import UserRole from "./Components/UserRoles/UserRole";
import CreateEditRole from "./Components/UserRoles/CreateEditRole";
import EditUser from "./Components/UserRoles/EditUser";
import bulkMessage from "./Components/Conversations/BulkMessage/BulkMessage";

// PATHS
const PUBLIC_PATHS = ["/", "/forgot-password", "/new-password", "/google-2fa"];
const PRIVATE_PATHS = [
  "/inbox",
  "/conversations",
  "/massive-send",
  "/bulk-message",
  "/conversations/open/:id",
  "/conversations/closed/:id",
  "/itineraries",
  "/itineraries/:id",
  "/itineraries/:itineraryId/:conversationId",
  "/templates",
  "/templates/:id",
  "/import-templates",
  "/create-automated-templates",
  "/import-wafb-templates",
  "/tips",
  "/tips/:id",
  "/kpi",
  "/dashboard",
  "/users-roles",
  "/roles/create",
  "/roles/edit/:id",
  "/users/:id",
];

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

const App = () => {
  const interval = useRef(null);
  const history = useHistory();

  useEffect(() => {
    interval.current = setInterval(() => {
      checkToken(history);
      if (getToken() === false || getToken() === null) {
        clearInterval(interval.current);
      }
    }, 1000 * 60);
  }, []);

  return (
    <Router>
      <Route exact path={PUBLIC_PATHS}>
        <PublicLayout>
          <AppRoute exact path="/" component={Login} layout={PublicLayout} />
          <AppRoute
            path="/forgot-password"
            component={ForgotPassword}
            layout={PublicLayout}
          />
          <AppRoute
            path="/new-password"
            component={NewPassword}
            layout={PublicLayout}
          />
          <AppRoute
            path="/google-2fa"
            component={GoogleAuth}
            layout={PublicLayout}
          />
        </PublicLayout>
      </Route>
      <div className="App">
        <ProtectRoute path={PRIVATE_PATHS}>
          <PrivateLayout>
            <Route exact path="/inbox" component={Inbox} />

            <Route path="/conversations" component={Conversations} />
            <Route path="/massive-send" component={MassiveMessageSend} />
            <Route path="/bulk-message" component={BulkMessage} />

            <Route exact path="/itineraries" component={Itineraries} />
            <Route exact path="/itineraries/:id" component={ItineraryGroup} />
            <Route
              exact
              path="/itineraries/:itineraryId/:conversationId"
              component={ItineraryIndividual}
            />
            <Route exact path="/templates" component={Templates} />
            <Route exact path="/import-templates" component={ImportTemplates} />
            <Route exact path="/import-wafb-templates" component={ImportWafbTemplates} />
            <Route exact path="/create-automated-templates" component={ImportAndCreateTemplates} />
            <Route path="/templates/:id" component={Template} />
            <Route exact path="/tips" component={Tips} />
            <Route exact path="/tips/:id" component={Tips} />
            <Route exact path="/kpi" component={Kpi} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/users-roles" component={UserRole} />
            <Route exact path="/users/:id" component={EditUser} />
            <Route exact path="/roles/create" component={CreateEditRole} />
            <Route exact path="/roles/edit/:id" component={CreateEditRole} />

          </PrivateLayout>
        </ProtectRoute>
      </div>
    </Router>
  );
};

export default withRouter(App);
