import React, { useContext } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import PropTypes from "prop-types";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";
import ActivityLog from "../../../Reusable/ActivityLog/ActivityLog";

const ItineraryActivityLog = ({ isIndividualMode }) => {
  const { state } = useContext(ItineraryContext);

  const itineraryData = state.itinerary.data;
  const travellerData = state.traveller.data;

  let activityLogData;
  if (isIndividualMode === true) {
    activityLogData = travellerData;
  } else {
    activityLogData = itineraryData;
  }

  return (
    <DataBlock
      header="ACTIVITY LOG"
      content={
        <>
          {activityLogData?.user_actions?.map((user_action) => (
            <ActivityLog
              key={user_action.id}
              date={user_action.updated_at}
              name={user_action.user.name}
              module={user_action.action_model}
              type={user_action.action}
            />
          ))}
        </>
      }
    />
  );
};

ItineraryActivityLog.propTypes = {
  isIndividualMode: PropTypes.bool,
};

export default ItineraryActivityLog;
