import React, { useContext, useState } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

import "./IndividualTravellerContainer.css";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";
import PreviousTripsModal from "../PreviousTripsModal/PreviousTripsModal";
import PreferencesIndividualItinerary from "../PreferencesIndividualItinerary/PreferencesIndividualItinerary";

const IndividualTravellerContainer = ({
  isEditMode,
  isEditPreferencesMode,
  formData,
  preferenceFormData,
  onChangeFormData,
  onChangePreferenceFormData,
  onClickEditPreferences,
  onClickSavePreferences,
}) => {
  const { state } = useContext(ItineraryContext);
  const [showPreviousTrips, setShowPreviousTrips] = useState(false);

  const onChange = (e) => {
    onChangeFormData(e.target.name, e.target.value);
  };

  const onClickPreviousTrips = () => {
    setShowPreviousTrips(true);
  };

  const onClosePreviousTrips = () => {
    setShowPreviousTrips(false);
  };

  return (
    <div>
      <DataBlock
        header="TRAVELLER"
        classNameContent="individual-traveller-container"
        content={
          <>
            <div className="individual-traveller-container__details">
              <div className="individual-traveller-container__first-last-name">
                <div className="individual-traveller-container__title">
                  Name
                </div>
                {isEditMode ? (
                  <>
                    <Form.Group controlId="individual-traveller-container__full_name">
                      <Form.Control
                        name="full_name"
                        placeholder="Enter full name"
                        type="text"
                        defaultValue={formData.full_name}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <div className="individual-traveller-container__data">
                    {formData.full_name && formData.full_name}
                  </div>
                )}
              </div>

              <div>
                <div className="individual-traveller-container__title">
                  Birthday
                </div>
                {isEditMode ? (
                  <>
                    <Form.Group
                      controlId="individual-traveller-container__birthday"
                      className="individual-traveller-container__input-field"
                    >
                      <Form.Control
                        type="date"
                        defaultValue={formData.dob}
                        onChange={onChange}
                        name="dob"
                        placeholder="YYYY/MM/DD"
                      />
                    </Form.Group>
                  </>
                ) : (
                  <div className="individual-traveller-container__data">
                    {formData.dob && formData.dob}
                  </div>
                )}
              </div>

              <button
                onClick={onClickPreviousTrips}
                className="individual-traveller-container__container individual-traveller-container__container--prev-trips"
              >
                <div className="individual-traveller-container__title">
                  Previous trips
                </div>
                <div className="individual-traveller-container__data">
                  {state.traveller.data.conversation ? (
                    <>
                      {
                        state.traveller.data.conversation.previous_itineraries
                          .length
                      }
                    </>
                  ) : (
                    <p>0</p>
                  )}
                </div>
              </button>

              <div className="individual-traveller-container__container">
                <div className="individual-traveller-container__title">
                  Questions
                </div>
                <div className="individual-traveller-container__data">
                  {state.traveller.data.analysis ? (
                    <>{state.traveller.data.analysis.questions}</>
                  ) : (
                    <p>0</p>
                  )}
                </div>
              </div>
            </div>

            <div className="individual-traveller-container__details">
              <div>
                <div className="individual-traveller-container__title">
                  Email address
                </div>
                {isEditMode ? (
                  <>
                    <Form.Group
                      controlId="individual-traveller-container__email"
                      className="individual-traveller-container__input-field"
                    >
                      <Form.Control
                        type="text"
                        defaultValue={formData.email}
                        onChange={onChange}
                        name="email"
                        placeholder="Enter email"
                      />
                    </Form.Group>
                  </>
                ) : (
                  <div className="individual-traveller-container__data">
                    {formData.email && formData.email}
                  </div>
                )}
              </div>

              <div className="individual-traveller-container__preference-container">
                <div className="individual-traveller-container__title">
                  Preferences
                </div>
                <PreferencesIndividualItinerary
                  onChangePreferences={onChangePreferenceFormData}
                  preferenceFormData={preferenceFormData}
                  onClickSavePreferences={onClickSavePreferences}
                  onClickEditPreferences={onClickEditPreferences}
                  isEditPreferencesMode={isEditPreferencesMode}
                />
              </div>

              <div className="individual-traveller-container__phone-number">
                <div className="individual-traveller-container__title">
                  Phone number
                </div>
                <div className="individual-traveller-container__data">
                  {formData.phone1 && formData.phone1}
                  {!formData.phone1 && formData.phone2 && formData.phone2}
                  {!formData.phone1 && !formData.phone2 && formData.phone3}
                </div>
              </div>
            </div>
          </>
        }
      />
      <PreviousTripsModal
        show={showPreviousTrips}
        onHide={onClosePreviousTrips}
      />
    </div>
  );
};

export default IndividualTravellerContainer;

IndividualTravellerContainer.propTypes = {
  isEditMode: PropTypes.bool,
  formData: PropTypes.object,
  handleFormData: PropTypes.func,
};
