import React from "react";
import "./TripComponent.css";
import classnames from "classnames";

const TripComponent = ({ task }) => {
    return (
      <div className="trip-component__container">
        <p className="trip-component__action">{(task.trip_action) && task.trip_action.name}</p>
        <div className={classnames("trip-component__icon", task.trip_action?.tag && task.trip_action.tag )}>{task.trip_action?.tag && task.trip_action.tag}</div>
      </div>
    );
}

export default TripComponent;
