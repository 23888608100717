import React from 'react';
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

function ScheduledMessageSavedModal({ show, onHide}) {
    const onClickClose = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title className="logout__title">
                    Message has been succesfully scheduled.
        </Modal.Title>
            </Modal.Header>
            <Modal.Body className="logout__btn">
                <RectangleButton onClick={onClickClose} type="submit" label="Ok" />
            </Modal.Body>
        </Modal>
    );
}

ScheduledMessageSavedModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
};

export default ScheduledMessageSavedModal;
