import React, { useContext } from "react";
import { TemplateContext } from "../../../../store/context/TemplateContext";

import "./TemplateDetails.css";

import ActivityLog from "../../../Reusable/ActivityLog/ActivityLog";
import TaskList from "../../../Reusable/TaskList/TaskList";

const TemplateDetails = () => {
  const { state } = useContext(TemplateContext);

  const templateData = state.template.data;

  return (
    <>
      {templateData && (
        <>
          <div className="template-details__name">
            TRIP INFO
            <div className="template-details__container">
              <div className="template-details__header">Alias</div>
              <div className="template-details__information">
                {templateData?.alias && templateData.alias.title}
              </div>

              <div className="template-details__header">
                Type
                <div className="template-details__information">
                  {templateData.type && templateData.type.label}
                </div>
              </div>
              <div className="template-details__header">
                Link
                <div className="template-details__information">
                  {templateData.link}
                </div>
              </div>
              <div className="template-details__header">
                Countries
                <div className="template-details__information">
                  {templateData.countries &&
                    templateData.countries.map((country) => (
                      <div key={country.id}>{country.title}</div>
                    ))}
                </div>
              </div>
              <div className="template-details__header">
                Tags
                <div className="template-details__information template-details__tag-container">
                  {templateData.tags &&
                    templateData.tags.map((tag) => (
                      <div key={tag.id} className="template-details__tag">
                        {tag.tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="template-details__name">
            TRAVEL COMPANY
            <div className="template-details__container">
              <div className="template-details__information template-details__information--company">
                {templateData.branch && templateData.branch.branch_name}
              </div>
            </div>
          </div>

          <div className="template-details__name">
            ACTIVITY LOG
            <div className="template-details__container">
              {templateData?.user_actions?.map((user_action) => (
                <ActivityLog
                  key={user_action.id}
                  date={user_action.updated_at}
                  name={user_action.user.name}
                  module={user_action.action_model}
                  type={user_action.action}
                />
              ))}
            </div>
          </div>

          <div className="template-details__name">
          {templateData?.tasks?.map((task) => (
              <TaskList
                key={task.id}
                taskable_type={task.taskable_type}
                agentName={task.agentname?.name}
                tripAction={task.trip_action.name}
                statusColour={task.status_color_class}
                errorDetails={task.error_details}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default TemplateDetails;
