import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./PreviousTripsModal.css";

const PreviousTripsModal = ({ show, onHide }) => {
  const { state } = useContext(ItineraryContext);

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Previous Trips</Modal.Title>
        </Modal.Header>
        <Modal.Body className="previous-trips__body">
          {state.traveller.data.conversation &&
            state.traveller.data.conversation.previous_itineraries.map(
              (itinerary, key) => (
                <Link
                  to={`/itineraries/${itinerary.id}`}
                  key={key}
                  className="previous-trips__link"
                >
                  <div key={itinerary.id} className="previous-trips__container">
                    <div className="previous-trips__icon"></div>
                    <div>
                      <div className="previous-trips__name">
                        {itinerary.description}
                      </div>
                      <div className="previous-trips__start-end-date">
                        {itinerary.departure_date} - {itinerary.return_date}
                      </div>
                    </div>
                  </div>
                </Link>
              )
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

PreviousTripsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default PreviousTripsModal;
