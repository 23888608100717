import React, {useCallback, useState, useContext, useEffect} from "react";
import {ConversationContext} from "../../../store/context/ConversationContext";
import {AppContext} from "../../../store/context/AppContext";
import {Button, Col, Form , Row} from "react-bootstrap";
import {AsyncTypeahead, Token} from "react-bootstrap-typeahead";
import Select from "react-select";

import 'react-bootstrap-typeahead/css/Typeahead.min.css'
import './BulkMessage.css';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CardMessagePreview from "./CardMessagePreview";
import BulkFormSchedule from "./BulkFormSchedule";
import BulkMessageDashboard from "./BulkMessageDashboard";


const BulkMessage = () => {

    const [branchId, setBranchId] = useState(null);
    const [statusId, setStatusId] = useState([]);
    const [journeyId, setJourneyId] = useState(null);
    const [departureDate, setDepartureDate] = useState(null);
    const [returnDate, setReturnDate] = useState(null);
    const [itineraryId, setItineraryId] = useState(null);

    const { state: AppState, AppActions } = useContext(AppContext);
    const { state: ConversationState, actions } = useContext(ConversationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [removedConversation, setRemovedConversation] = useState([]);


    useEffect(() => {
        console.log(AppState.inputs);
                       
    }, [AppState]);

    const onChangeBranch = useCallback( (branch) => {
        setBranchId((currentBranchId) => branch.id);
        actions.fetchJourneysByBranches(branch.id);
        actions.fetchMessageTemplatesByBranch(branch.id);
        setItineraryId((currentItineraryId) => null);
    }, [branchId]);

    const onChangeStatus =(status) => {

        const ids = [];

        if (status !== null && status.length > 0 ) {
            status.forEach((item) => {
                ids.push(item.value);
            });
        }

        setStatusId((currentStatus) => ids)
        setItineraryId((currentItineraryId) => null);
    }

    const onchangeJourney = (journey) => {
        console.log(journey);
        setJourneyId((currentJourneyId) => journey.id);
        setItineraryId((currentItineraryId) => null);
    }

    const onTripChange = (trip) => {
        if (trip.length > 0)  {
            setItineraryId((currentItineraryId) => trip[0].id);
        }
        console.log(trip);
    }

    const onChangeDeparture = (e) => {
        const date = e.target.value;
        setDepartureDate((currentDepartureDate) => date);
        setItineraryId((currentItineraryId) => null);
    }

    const onChangeReturnDate = (e) => {
        const date = e.target.value;
        setReturnDate((currentReturnDate) => date);
        setItineraryId((currentItineraryId) => null);
    }

    const handleSearch = useCallback((q) => {
        setIsLoading(true);
        const data = {
            'description' : q,
            'departure_date' : departureDate,
            'return_date' : returnDate,
            'journey_id' : journeyId,
            'status' : statusId
        }

        actions.fetchItinerariesByBranchAndDescription(branchId,data)
        setIsLoading(false);
    }, [branchId, ConversationState, departureDate, returnDate, journeyId, statusId]);


    const handleSubmit = (e) => {
        const data = {
            'status_ids': statusId,
            'journey_id' : journeyId,
            'departure_date' : departureDate,
            'return_date' : returnDate,
            'itinerary_id' : itineraryId
        };
        actions.fetchFilteredConversations(branchId, data);
        e.preventDefault();
    }


    const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;

        if (checked) {
            setRemovedConversation( (currentRemovedConversations) => [...removedConversation, value]);
        } else {
            setRemovedConversation((currentRemovedConversations) =>  removedConversation.filter((e) => e !== value));
        }
    };

    function  removeItems() {
        const conversations = ConversationState.filteredConversations.data;

        const diff  = conversations.filter(function(item) {
            for(let i=0; i < removedConversation.length; i++){
                if(item.conversation_itinerary_id === parseInt(removedConversation[i])){
                    return false;
                }
            }
            return true;
        });


        actions.updateFilteredConversationWithoutApi(diff);

    }

    useEffect(() => {
        console.log(ConversationState);
    }, [ConversationState]);

    return (
        <div className={'bulk-page'}>
            <div  className="row">
                <div className="col-12">
                    <h1 className='card-title'>Bulk Upload & Send</h1>
                </div>
                <Row className={"col-12"}>
                    <Form className={"col-12"} method={'post'}>
                        <Row className={'g-2'}>
                            <Col md>
                                <Form.Label>Branch</Form.Label>
                                <Select
                                    name="branch_id"
                                    placeholder="Select a branch"
                                    options={AppState.inputs.companies?.sort((a,b) => a.branch_name.localeCompare(b.branch_name))}
                                    getOptionLabel={(option) => `${option.branch_name}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange = { (data) => onChangeBranch(data) }
                                />

                            </Col>
                            <Col md>

                                <Form.Label>Status</Form.Label>
                                <Select
                                    name={'status_id'}
                                    isMulti
                                    placeholder={'Select a status'}
                                    options={AppState.inputs.statusItineraries?.sort((a,b)=> a.title.localeCompare(b.title))}
                                    getOptionLabel={(option) => `${option.title}` }
                                    getOptionValue={(option) => `${option.value}`}
                                    onChange = { (data) => onChangeStatus(data)}
                                />

                            </Col>
                        </Row>
                        <Row className={'g-2'}>
                            <Col md>
                                <Form.Label>Departure date</Form.Label>
                                <Form.Control
                                    name={'departure_date'}
                                    type={'date'}
                                    onChange={(data) =>onChangeDeparture(data)}
                                />
                            </Col>
                            <Col md>
                                <Form.Label>Return date</Form.Label>
                                <Form.Control
                                    name={'return_date'}
                                    type={'date'}
                                    onChange={(data) =>onChangeReturnDate(data)}
                                />
                            </Col>
                        </Row>
                        <Row className={'g-2'}>
                            <Col md>
                                <Form.Label>Journey</Form.Label>
                                <Select
                                    name="journey_id"
                                    placeholder="Select a journey"
                                    options={ConversationState.journeys?.sort((a,b) => a.title.localeCompare(b.title)) }
                                    getOptionLabel={(option) => `${option.title}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    isDisabled={branchId === null}
                                    onChange = { (data) => onchangeJourney(data)}
                                />

                            </Col>
                           <Col md>
                               <Form.Label>Trip</Form.Label>
                                   <AsyncTypeahead
                                   id={'trip_field'}
                                   name={'description'}
                                   isLoading={isLoading}
                                   labelKey="description"
                                   onSearch={handleSearch}
                                   onChange={(data) =>onTripChange(data)}
                                   options={ConversationState.itineraries}
                                   disabled={branchId === null}
                                   renderMenuItemChildren={(option) => (
                                       <Row md key={option.id}>
                                           <span>({option.dossier_id}) - {option.description} </span>
                                       </Row>
                                   )}
                               />
                           </Col>
                        </Row>

                        <Row>                                                       
                            <Col md>
                                <Button type={'submit'} disabled={branchId === null} onClick={(e) =>handleSubmit(e)} className={'float-right'}>Apply filter</Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <Row  className={'col-12'}>
                    <Tabs 
                        id={'tab-bulk-message'}
                        defaultActiveKey="preview"
                    >
                        <Tab 
                            title={'Preview'}
                            eventKey={'preview'}
                        >
                            <Row id={'preview-messages'}>
                                <CardMessagePreview
                                    items={ConversationState.filteredConversations}
                                    onChangeCheck={handleChange}
                                />

                            </Row>
                            {ConversationState.filteredConversations?.data?.length > 0 &&
                                <Row className={'col-12'}>
                                    <Col md>
                                        <Button onClick={removeItems} className={'float-right'}>Remove selected items</Button>
                                    </Col>
                                </Row>
                            }

                        </Tab>
                        <Tab
                            title={'Form'}
                            eventKey={'form'}
                        >
                            <Row className={'col-12'}>
                               <BulkFormSchedule journey_id={journeyId} />
                            </Row>

                        </Tab>
                        <Tab title={'Dashboard'}
                             eventKey={'dashboard'}
                             >
                            <Row className={'col-12'}>
                                <BulkMessageDashboard
                                    messageTemplates={ConversationState.messageTemplates}
                                />
                            </Row>
                        </Tab>
                    </Tabs>
                </Row>


            </div>
        </div>
    );
}

export default BulkMessage;