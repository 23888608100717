import axios from "axios";
import { types } from "../reducers/ConversationReducer";

export const useActions = (state, dispatch) => {
    function fetchAllData() {
        fetchPreferences();
        fetchAnalysisStatus();
        fetchMessageTags();
        fetchTemplateMessageTags();
        fetchManagerList();
        fetchReactions();
        fetchAgents();
        fetchLanguages();
    }

     function fetchConversations(currentPage, ref, conversationType = '') {
        const url = process.env.REACT_APP_BASEURL + '/api/conversations/list/' + currentPage + conversationType;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_CONVERSATIONS,
                    payload: { data: response.data.data, ref: ref, conversationType: conversationType },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchMessageTemplates(branchId, companyChannelId) {
        const url =
            process.env.REACT_APP_BASEURL +
            "/api/conversations/message-templates/" +
            branchId +
            '/' +
            companyChannelId;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_MESSAGE_TEMPLATES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchMessageTemplatesByChannel(branchIds, channelIds) {
        const url =
            process.env.REACT_APP_BASEURL +
                "/api/conversations/message-templates-by-channel";

        let data = {
            branchIds: branchIds,
            channelIds: channelIds,
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_MESSAGE_TEMPLATES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchMessageTemplatesByBranch(branchId) {
        const url =
            process.env.REACT_APP_BASEURL +
            "/api/branch/"+branchId+"/message-template";

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_MESSAGE_TEMPLATES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchAgentTemplates(branchId) {
        const url =
            process.env.REACT_APP_BASEURL +
            "/api/conversations/agent-templates/" +
            branchId;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_AGENT_TEMPLATES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchPreferences() {
        const url = process.env.REACT_APP_BASEURL + "/api/tags";

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_PREFERENCES,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchAnalysisStatus() {
        const url = process.env.REACT_APP_BASEURL + "/api/analysis/statuses";

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ANALYSIS_STATUS,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchMessageTags() {
        const url = process.env.REACT_APP_BASEURL + "/api/messages/by-tag";

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_MESSAGE_TAGS,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchTemplateMessageTags() {
        const url = process.env.REACT_APP_BASEURL + "/api/messages/by-tag-templates";

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_TEMPLATE_MESSAGE_TAGS,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchManagerList() {
        const url = process.env.REACT_APP_BASEURL + "/api/users/email-list";

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_MANAGER_LIST,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchReactions() {
        const url = process.env.REACT_APP_BASEURL + "/api/message/reactions";

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_REACTIONS,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function searchConversation(text, is_open_tab, branches, languages) {
        const url = process.env.REACT_APP_BASEURL + "/api/conversations/search";
        let data = {
            text: text,
            is_open_tab: is_open_tab,
            branches: branches,
            languages: languages
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_CONVERSATIONS,
                    payload: { data: response.data.data, conversationType: '' },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function deleteConversation(conversationId) {
        const url =
            process.env.REACT_APP_BASEURL +
            "/api/conversations/delete/" +
            conversationId;

        axios
            .delete(url)
            .then((response) => {
                document.location.href = "/conversations";
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function closeConversation(conversationId) {
        const url =
            process.env.REACT_APP_BASEURL +
            "/api/conversations/close/" +
            conversationId;

        axios
            .put(url)
            .then((response) => {
                document.location.href = "/conversations";
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchCurrentConversation(conversationId) {
        const url =
            process.env.REACT_APP_BASEURL + "/api/conversations/" + conversationId;

        axios
            .get(url)
            .then((response) => {
                if (
                    state.branchId === null ||
                    state.branchId != response.data.data.branch_id
                ) {
                    fetchMessageTemplates(response.data.data.branch_id, response.data.data.company_channel_id);
                    fetchAgentTemplates(response.data.data.branch_id)
                }
                dispatch({
                    type: types.CLEAR_ANALYSIS_DATA,
                });
                dispatch({
                    type: types.FETCH_CURRENT_CONVERSATION,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function updateFavicon() {
        state.unreadMessagesCount = 0;
        let removeMessagesCount = 0;
        // count unread messages
        if (state.conversations?.filtered?.open?.my) {
            state.conversations.filtered.open.my.forEach(item => {
                if (item.unread) {
                    state.unreadMessagesCount += item.unread;
                }
            });
        }
        if (state.conversations?.filtered?.open?.other) {
            state.conversations.filtered.open.other.forEach(item => {
                if (item.unread) {
                    state.unreadMessagesCount += item.unread;
                }
            });
        }

        if (state.currentConversation?.id) {
            state.unreadMessagesCount = state.unreadMessagesCount - state.currentConversation.unread;
            state.conversations.filtered.open.my.find((item, key) => {
                if (item.id === state.currentConversation.id) {
                    state.conversations.filtered.open.my[key].unread = 0;
                }
            });
            state.conversations.filtered.open.other.find((item, key) => {
                if (item.id === state.currentConversation.id) {
                    state.conversations.filtered.open.other[key].unread = 0;
                }
            });
        }

        const tempImg = document.getElementById("favicon");
        if (state.unreadMessagesCount > 0) {
            let svgTag = state.unreadMessagesCount > 99 ?
                '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="16">'
                : '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">';

            let styleTag = state.unreadMessagesCount > 99 ?
                '<style>div{color:#ffffff; background-color: #cd5b45; font-size: 18px; border-radius: 5px; text-align: center}</style>'
                : '<style>div{color:#ffffff; background-color: #cd5b45; font-size: 18px; border-radius: 5px; text-align: center}</style>';

            tempImg.href = 'data:image/svg+xml,' + encodeURIComponent('' +
                svgTag +
                '<foreignObject width="100%" height="100%">' +
                '<div xmlns="http://www.w3.org/1999/xhtml">' +
                styleTag +
                '<div>' + state.unreadMessagesCount + '</div>' +
                '</div>' +
                '</foreignObject>' +
                '</svg>'
            );
        } else {
            tempImg.href = '/static/images/logo.png';
        }
    }

    function openConversation(conversationId) {
        const url =
            process.env.REACT_APP_BASEURL +
            "/api/conversations/open/" +
            conversationId;

        axios
            .put(url)
            .then((response) => {
                document.location.href = "/conversations";
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchConversationTaskList(conversationId) {
        const url =
            process.env.REACT_APP_BASEURL +
            "/api/tasks/conversation/" +
            conversationId;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_CONVERSATION_TASK_LIST,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function assignTask(taskId) {
        const url = process.env.REACT_APP_BASEURL + "/api/task/" + taskId;
        let data = { assign_to: 1 };

        axios
            .put(url, data)
            .then((response) => {
                dispatch({
                    type: types.ASSIGN_TASK,
                    payload: { taskId: taskId },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function unAssignTask(taskId) {
        const url = process.env.REACT_APP_BASEURL + "/api/task/" + taskId;
        let data = { unassign: 1 };

        axios
            .put(url, data)
            .then((response) => {
                dispatch({
                    type: types.UNASSIGN_TASK,
                    payload: { taskId: taskId },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function reassignConversation(conversationId, agentId) {
        let url =
            process.env.REACT_APP_BASEURL +
            `/api/conversations/reassign/` +
            conversationId +
            `/` +
            agentId;

        axios({
            method: "PUT",
            url: url,
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchUserPreferences(conversationId) {
        let url = process.env.REACT_APP_BASEURL + `/api/tags/` + conversationId;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_USER_PREFERENCES,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function deleteUserPreference(id, conversationId) {
        let url =
            process.env.REACT_APP_BASEURL +
            `/api/tags/delete/` +
            id +
            "/" +
            conversationId;

        axios
            .delete(url)
            .then((response) => {
                dispatch({
                    type: types.DELETE_USER_PREFERENCE,
                    payload: { preferenceId: id },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function addNewUserPreference(id, conversationId) {
        let url =
            process.env.REACT_APP_BASEURL +
            `/api/tags/add/` +
            id +
            "/" +
            conversationId;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.ADD_USER_PREFERENCE,
                    payload: { preferenceId: id },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchJourneyScheduledArticles(conversationId) {
        let url =
            process.env.REACT_APP_BASEURL +
            `/api/journey-scheduled-articles/conversation/${conversationId}`;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_JOURNEY_SCHEDULED_ARTICLES,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function clearJourneyScheduledArticles() {
        dispatch({
            type: types.CLEAR_JOURNEY_SCHEDULED_ARTICLES,
            payload: [],
        });
    }

    function fetchAnalysisData(conversationId, itineraryId) {
        let url =
            process.env.REACT_APP_BASEURL +
            `/api/analysis/` +
            conversationId +
            "/" +
            itineraryId;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ANALYSIS_DATA,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function storeConversationAnalyse(data) {
        let url = process.env.REACT_APP_BASEURL + `/api/analysis/store`;

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.STORE_ANALYSIS_ERROR_DATA,
                    payload: response.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.STORE_ANALYSIS_ERROR_DATA,
                    payload: err.response.data,
                });
            });
    }

    function sendMessage(data) {
        let url = process.env.REACT_APP_BASEURL + `/api/messages/send`;

        axios
            .post(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((response) => {
                if (response.data.code && response.data.code === 400) {
                    const errorMessage = "Message was not send. " + response.data.data;
                    dispatch({
                        type: types.SHOW_ERRORS,
                        payload: errorMessage,
                    });
                }

                if (response.data.code && response.data.code === 200) {
                    dispatch({
                        type: types.ADD_NEW_MESSAGE,
                        payload: response.data.data,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                const errorMessage =
                    "Sorry, we failed to send the message to the traveller. The following error occured: " +
                    err;
                dispatch({
                    type: types.SHOW_ERRORS,
                    payload: errorMessage,
                });
            });
    }

    function deleteMessage(id) {
        let url = process.env.REACT_APP_BASEURL + `/api/messages/delete/` + id;

        axios
            .delete(url)
            .then((response) => {
                dispatch({
                    type: types.DELETE_MESSAGE,
                    payload: id,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function tagMessage(id, tag, itinerary, isTemplate) {
        let url =
            process.env.REACT_APP_BASEURL + `/api/messages/tag/` + tag.id + "/" + id + "/" + itinerary.id + "/" + isTemplate;

        axios
            .put(url)
            .then((response) => {
                dispatch({
                    type: types.TAG_MESSAGE,
                    payload: { id: id, tag: tag, itinerary: itinerary },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function markAsUnread(id) {
        let url =
            process.env.REACT_APP_BASEURL + `/api/messages/mark-as-unread/` + id;

        axios
            .put(url)
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function sendTo(userId, messageId) {
        let url =
            process.env.REACT_APP_BASEURL +
            `/api/messages/send-to/` +
            userId +
            "/" +
            messageId;

        axios
            .get(url)
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function setReaction(reactionId, messageId) {
        let url = process.env.REACT_APP_BASEURL + `/api/messages/reaction/` + reactionId + "/" + messageId;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.REACTION,
                    payload: { reactionId: reactionId, messageId: messageId },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchAgents() {
        let url = process.env.REACT_APP_BASEURL + `/api/conversations/agents`;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_AGENTS,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchLanguages() {
        let url = process.env.REACT_APP_BASEURL + `/api/languages`;

        axios
            .get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_LANGUAGES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function findDossierId(dossierId, branchId) {
        axios({
            method: "GET",
            url:
                process.env.REACT_APP_BASEURL +
                `/api/itineraries/${branchId}/find-dossier-id?dossier_id=${dossierId}`,
        })
            .then((result) => {
                return result.data;
            })
            .then((data) => {
                dispatch({
                    type: types.SEARCH_DOSSIER_ID,
                    payload: data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function addItineraryToConversation(conversationId, itineraryId) {
        axios({
            method: "POST",
            url:
                process.env.REACT_APP_BASEURL +
                `/api/conversations/${conversationId}/itineraries/${itineraryId}/connect`,
        })
            .then((result) => {
                fetchCurrentConversation(conversationId);
            })
            .catch((error) => {
                console.log(error);
                const errorMessage = "Sorry, unable to add itinerary to conversation";
                dispatch({
                    type: types.SHOW_ERRORS,
                    payload: errorMessage,
                });
            });
    }

    function addNewItinerary(data, conversationId) {
        axios({
            method: "POST",
            url:
                process.env.REACT_APP_BASEURL +
                `/api/conversations/${conversationId}/itineraries/create`,
            data,
        })
            .then((result) => {
                fetchCurrentConversation(conversationId);
            })
            .catch((error) => {
                console.log(error);
                const errorMessage =
                    "Sorry, we failed to create a new itinerary";
                dispatch({
                    type: types.SHOW_ERRORS,
                    payload: errorMessage,
                });
            });
    }

    function setError(errorMessage) {
        const error = errorMessage;
        dispatch({
            type: types.SHOW_ERRORS,
            payload: error,
        });
    }

    function clearError() {
        const emptyError = "";
        dispatch({
            type: types.SHOW_ERRORS,
            payload: emptyError,
        });
    }

    function fetchMoreMessages(conversationId, ref) {
        let url = process.env.REACT_APP_BASEURL + `/api/messages/` + conversationId + '/' + ++state.currentMessagePage;
        let previousHeight = ref.current.firstChild.scrollHeight;

        axios.get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_MORE_MESSAGES,
                    payload: { data: response.data.data },
                });

                ref.current.firstChild.scrollTop = Number(ref.current.firstChild.scrollHeight - previousHeight);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function storeConversationNotes(data) {
        let url = process.env.REACT_APP_BASEURL + `/api/conversations/update-notes`;

        axios.post(url, data)
            .then((response) => {
                window.location.href = `/conversations/open/` + state.currentConversation.id;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function editTravelersName(name) {
        let url = process.env.REACT_APP_BASEURL + `/api/conversations/${state.currentConversation.id}/edit-traveller`;

        axios.post(url, name)
            .then((response) => {
                dispatch({
                    type: types.EDIT_TRAVELER_NAME,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.SHOW_ERRORS,
                    payload: err,
                });
            });
    }

    function changeLanguageConversation(conversationId, languageId) {
        let url =
            process.env.REACT_APP_BASEURL +
            `/api/conversations/${state.currentConversation.id}/edit-language?language_id=${languageId}`;

        axios.post(url)
            .then()
            .catch((err) => {
                dispatch({
                    type: types.SHOW_ERRORS,
                    payload: err,
                });
            });
    }

    function massiveMessagesSend(data) {
        let url = process.env.REACT_APP_BASEURL + `/api/conversations/massive-message-send`;

        axios.post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_FILTERED_TRAVELLERS,
                    payload: response.data.data,
                });

                let successMsg = response.data.message;

                dispatch({
                    type: types.SHOW_SUCCESS,
                    payload: successMsg,
                });
            }).catch(() => {
            const errorMessage =
                "Sorry, we failed to schedule the message to the travellers. Please check input data"
            dispatch({
                type: types.SHOW_ERRORS,
                payload: errorMessage,
            });
        });
    }

    function createScheduledMessage(scheduledMessage, setShowSavedModal) {
        axios({
            method: "POST",
            url: process.env.REACT_APP_BASEURL + `/api/conversations/${state.currentConversation.id}/schedule-message`,
            data: scheduledMessage,
        })
            .then((result) => {
                if (result.code === 400) {
                    const errorMessage = result.data;
                    dispatch({
                        type: types.SHOW_ERRORS,
                        payload: errorMessage,
                    });
                } else {
                    let url =
                        process.env.REACT_APP_BASEURL +
                        `/api/journey-scheduled-articles/conversation/${state.currentConversation.id}`;


                    setShowSavedModal(true);
                    axios
                        .get(url)
                        .then((response) => {
                            dispatch({
                                type: types.FETCH_JOURNEY_SCHEDULED_ARTICLES,
                                payload: response.data.data,
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                const errorMessage =
                    "Sorry, we failed to schedule the message to the traveller. Check if the planned time is within 24 hours"
                dispatch({
                    type: types.SHOW_ERRORS,
                    payload: errorMessage,
                });
            });
    }

    function deleteScheduledMessage(scheduledMessage) {
        axios({
            method: "DELETE",
            url: process.env.REACT_APP_BASEURL + `/api/scheduled-message/${scheduledMessage.id}/delete`,
        })
            .then((result) => {
                let url =
                    process.env.REACT_APP_BASEURL +
                    `/api/journey-scheduled-articles/conversation/${state.currentConversation.id}`;

                axios
                    .get(url)
                    .then((response) => {
                        dispatch({
                            type: types.FETCH_JOURNEY_SCHEDULED_ARTICLES,
                            payload: response.data.data,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                dispatch({
                    type: types.SHOW_ERRORS,
                    payload: err,
                });
            });
    }

    function closeMultipleConversations(conversationsToClose) {
        let url = process.env.REACT_APP_BASEURL + `/api/conversations/multi-close`;

        axios.post(url, conversationsToClose)
            .then(() => {
                fetchConversations(1);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function clearSuccess() {
        const emptySuccess = "";
        dispatch({
            type: types.SHOW_SUCCESS,
            payload: emptySuccess,
        });
    }

    function fetchItinerariesByBranches(branchIds) {
        const url = process.env.REACT_APP_BASEURL + "/api/itineraries-by-branch";
        let data = {
            branch_ids: branchIds
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ITINERARIES,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchItinerariesByBranchAndDescription(branchId, data) {
        const url = process.env.REACT_APP_BASEURL + "/api/itineraries/"+branchId+'/description';
        let form = {
            data: data
        };

        axios
            .post(url, form)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ITINERARIES,
                    payload: response.data.data,
                });
            })
            .catch((err) => {

                switch (err.response?.status) {
                    case 422:
                        console.log(err.response);
                        dispatch({
                            type: types.FETCH_VALIDATION_ERROR,
                            payload: err.response.data
                        })
                        break;
                }
                console.log(err);
            });
    }

    function fetchItinerariesByDatesAndBranch(startDate, endDate, branchId)
    {
        const url = process.env.REACT_APP_BASEURL + "/api/itineraries/"+branchId+"/by-dates";
        let data = {
            startDate: startDate,
            endDate: endDate
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ITINERARIES,
                    payload: response.data,
                });

                console.log(response.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    function fetchJourneysByBranches(branchIds) {
        const url = process.env.REACT_APP_BASEURL + "/api/journeys-by-branch";
        let data = {
            branch_ids: branchIds
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_JOURNEYS,
                    payload: response.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchCitiesByCountries(countriesIds) {
        const url = process.env.REACT_APP_BASEURL + "/api/cities";
        let data = {
            countriesIds: countriesIds
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_CITIES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchAccommodations(citiesIds) {
        let url = process.env.REACT_APP_BASEURL + `/api/accommodations`;

        let data = {
            citiesIds: citiesIds,
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ACCOMMODATIONS,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchItineraryProducts(itineraryIds) {
        let url = process.env.REACT_APP_BASEURL + `/api/itineraries/products`;

        let data = {
            itineraryIds: itineraryIds,
        };

        axios
            .post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_ITINERARY_PRODUCTS,
                    payload: response.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchBulkMessages(url = null) {
        if (url === null) {
             url = process.env.REACT_APP_BASEURL + `/api/bulk-messages`;
        }

        axios.get(url)
            .then((response) => {
                dispatch({
                    type: types.FETCH_BULK_MESSAGES,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function fetchFilteredConversations(branch_id, data) {
        let url = process.env.REACT_APP_BASEURL + `/api/conversations/${branch_id}/filter`;

        axios.post(url, data)
            .then((response) => {
                dispatch({
                    type: types.FETCH_FILTERED_CONVERSATIONS,
                    payload: { data: response.data },
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function updateFilteredConversationWithoutApi(data) {
        dispatch({
            type: types.FETCH_FILTERED_CONVERSATIONS,
            payload: {data: data}
        });
    }

    function deleteBulkMessages(id) {
        let url = process.env.REACT_APP_BASEURL + `/api/bulk-messages/` + id;

        axios.delete(url)
            .then((response) => {
                document.location.href = "/bulk-message";
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function updateBulkMessages(data) {
        let url = process.env.REACT_APP_BASEURL + `/api/bulk-messages/update`;

        axios.post(url, data)
            .then((response) => {
                document.location.href = "/massive-send";
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function sendBulkMessages(data) {
        let url = process.env.REACT_APP_BASEURL + `/api/bulk-messages/create-update`;

        axios.post(url, data)
            .then((response) => {
                document.location.href = "/bulk-message";
            }).catch((err) => {

            switch (err.response?.status) {
                case 422:
                    console.log(err.response);
                    dispatch({
                        type: types.FETCH_VALIDATION_ERROR,
                        payload: err.response.data
                    })
                    break;
                default:
                    const errorMessage =
                        "Sorry, we failed to schedule the message to the travellers. Please check input data"
                    dispatch({
                        type: types.SHOW_ERRORS,
                        payload: errorMessage,
                    });
                    break;
            }

        });
    }

    function changeVip(id, isVip) {
        let url = process.env.REACT_APP_BASEURL + `/api/conversation/${id}/setVip`;

        axios.post(url, {is_vip: isVip})
            .then((response) => {
                console.log(response);
            }).catch( (err) => {
                console.log(err.toString());
        });
    }

    return {
        fetchAllData,
        fetchConversations,
        fetchMessageTemplates,
        fetchMessageTemplatesByChannel,
        fetchMessageTemplatesByBranch,
        fetchAgentTemplates,
        fetchPreferences,
        fetchAnalysisStatus,
        fetchMessageTags,
        fetchManagerList,
        fetchReactions,
        searchConversation,
        deleteConversation,
        closeConversation,
        fetchCurrentConversation,
        openConversation,
        fetchConversationTaskList,
        assignTask,
        unAssignTask,
        reassignConversation,
        fetchUserPreferences,
        deleteUserPreference,
        addNewUserPreference,
        fetchJourneyScheduledArticles,
        clearJourneyScheduledArticles,
        fetchAnalysisData,
        storeConversationAnalyse,
        sendMessage,
        deleteMessage,
        tagMessage,
        markAsUnread,
        sendTo,
        setReaction,
        fetchAgents,
        findDossierId,
        addItineraryToConversation,
        addNewItinerary,
        setError,
        clearError,
        clearSuccess,
        fetchMoreMessages,
        updateFavicon,
        storeConversationNotes,
        editTravelersName,
        createScheduledMessage,
        deleteScheduledMessage,
        closeMultipleConversations,
        massiveMessagesSend,
        fetchItinerariesByBranches,
        fetchItinerariesByBranchAndDescription,
        fetchJourneysByBranches,
        fetchCitiesByCountries,
        fetchAccommodations,
        fetchItineraryProducts,
        fetchBulkMessages,
        deleteBulkMessages,
        sendBulkMessages,
        fetchTemplateMessageTags,
        changeLanguageConversation,
        fetchFilteredConversations,
        updateFilteredConversationWithoutApi,
        changeVip
    };
};
