import React, {useContext, useEffect, useState} from 'react';
import Tabs from 'react-bootstrap/Tabs'
import TaskConversation from './TaskConversation'
import ProfileConversation from './ProfileConversation'
import TipsConversation from './TipsConversationTabs'
import './OpenConversationTabs.css'
import AnalysisConversationTabs from "./AnalysisConversationTabs";
import { ConversationContext } from "../../../../store/context/ConversationContext";


function OpenConversationTabs() {
    const { state, actions } = useContext(ConversationContext);
    const [selectItineraries, setSelectItineraries] = useState([]);

    useEffect(() => {
        actions.fetchConversationTaskList(state.currentConversation.id);
        actions.fetchUserPreferences(state.currentConversation.id);

        if(typeof state.currentConversation.itineraries != 'undefined' && typeof state.currentConversation.itineraries[0] != 'undefined') {
            actions.fetchJourneyScheduledArticles(state.currentConversation.id);
            setSelectItineraries(state.currentConversation.itineraries)
        } else {
            actions.clearJourneyScheduledArticles();
        }
        
        return () => {
            setSelectItineraries({});
        }
    }, [state.currentConversation.id]);

    return (
        <div className='open-conversation-tab col-sm-12'>
            <Tabs defaultActiveKey="task" id="conversation-tabs">
                <OpenConversationTabs className="conversation-task" eventKey="task" title="Task">
                        <TaskConversation/>
                </OpenConversationTabs>

                <OpenConversationTabs className="conversation-profile" eventKey="profile" title="Profile">
                    <ProfileConversation/>
                </OpenConversationTabs>

                <OpenConversationTabs className="conversation-tips" eventKey="tips" title="Tips">
                    <TipsConversation/>
                </OpenConversationTabs>

                <OpenConversationTabs className="conversation-analysis" eventKey="analysis" title="Analysis">
                    <AnalysisConversationTabs pageName="open" selectItineraries={selectItineraries}/>
                </OpenConversationTabs>
            </Tabs>
        </div>
    )
}

export default OpenConversationTabs;