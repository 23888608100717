import React, {useContext, useEffect, useState} from 'react';
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";
import Table from "react-bootstrap/Table";
import {ConversationContext} from "../../../store/context/ConversationContext";
import {Button, Col, Form, Modal, Pagination, Row} from "react-bootstrap";
import Select from "react-select";
import TemplateItem from "../../Templates/EditTemplate/TemplateItem/TemplateItem";
import TipSelector from "../../Templates/EditTemplate/TemplateDay/TipSelector/TipSelector";
import TextInput from "../../Templates/EditTemplate/TemplateDay/TextInput/TextInput";
import SuccessModal from "../../Reusable/SuccessModal/SuccessModal";
import ErrorModal from "../../Reusable/ErrorModal/ErrorModal";
import BulkFormSchedule from "./BulkFormSchedule";
import conversationMessages from "../ConversationMessenger/ConversationMessages";
import template from "../../Templates/Template/Template";
import bulkMessage from "./BulkMessage";


const BulkMessageDashboard =  ({messageTemplates})  => {

    const { state: ConversationState, actions } = useContext(ConversationContext);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedBulkMessage, setSelectedBulkMessage] = useState(null);
    const [vars, setVars] = useState(null);
    const [selectedTipId, setSelectedTipId] = useState(null);
    const [selectedText, setSelectedText] = useState(null);
    const [showTextInput, setShowTextInput] = useState(false);
    const [message, setMessage] = useState(null);
    const [selectedTextId, setSelectedTextId] = useState(null);
    const [messageTemplate, setMessageTemplate] = useState(null);
    const [file, setFile] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const handleCloseUpdateModal = () => {
        setShowUpdateModal(false);
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedBulkMessage(null);
    };
    const openDeleteModal = (bulkMessage) => {
        setShowDeleteModal(true);
        setSelectedBulkMessage(bulkMessage);
    };


    const openUpdateModal =  (bulkMessage) => {
        setSelectedBulkMessage(bulkMessage);

        actions.fetchMessageTemplatesByChannel(messageTemplates.branch_id, null);

        setVars(selectedBulkMessage?.vars);

        setShowUpdateModal(true);

    }

    useEffect(() => {
        console.log(selectedBulkMessage);
        }, [selectedBulkMessage]);

    function onChangeTemplate(data)  {
        let newValue = {
            message_template_id: data.id,
            vars: data.vars
        };

        setSelectedBulkMessage({
            ...selectedBulkMessage,
            ...newValue
        });
        setVars(data.vars);
    }

    function onClickAddTip(tipId) {
        setSelectedTipId(tipId);
    }

    function onChangeName(e) {
        setSelectedBulkMessage({
            ...selectedBulkMessage,
            name: e.target.value
        });
    }

    function onChangeSendAt(e) {
        setSelectedBulkMessage({
            ...selectedBulkMessage,
            send_at: e.target.value
        });
    }


    function onClickAddText(article, key) {
        setSelectedText(article);
        setSelectedTextId(key);
        setShowTextInput(true);
    }

    function onCloseTextInput() {
        setShowTextInput(false);
    }

    function onCloseTipSelector() {
        setSelectedTipId(null);
    }

    const submitTip = (tip) => {
        selectedBulkMessage.vars[selectedTipId].article_body = tip.body;
        selectedBulkMessage.vars[selectedTipId].article_id = tip.id;
        selectedBulkMessage.vars[selectedTipId].value = tip.body;
        setSelectedTipId(null);
    };

    function submitText(text) {
        selectedBulkMessage.vars.find((item) => {
            if (item.key === text.key) {
                item.value = text.value;
                return true;
            }
        });
        setSelectedText(null);
        setSelectedTextId(null);
        setShowTextInput(false);
    }

    function onCloseTextInput() {
        setShowTextInput(false);
    }

    function onCloseTipSelector() {
        setSelectedTipId(null);
    }

    useEffect(() => {

        if (ConversationState.bulkMessages.data.length === 0){
            actions.fetchBulkMessages();
        }

    }, [ConversationState.bulkMessages]);

    useEffect(() => {

        const  template =  ConversationState.messageTemplates.find(({ id }) => id === selectedBulkMessage?.message_template_id );
        const element = document.getElementById('editMessageTemplateBody');
                          console.log(selectedTemplate);
        if(element !== null && element !== undefined) {
            element.append(template?.body);
        }

    }, [ConversationState.messageTemplates])

    function onClickSubmitSendMessage(e) {

        let conversationIds = [];

        e.preventDefault();
        var formDataSendMessage = new FormData();
        formDataSendMessage.append('bulk_message_id', selectedBulkMessage.id);
        formDataSendMessage.append('conversation_ids', JSON.stringify(conversationIds));
        formDataSendMessage.append('message_template_id', selectedBulkMessage.message_template_id);
        formDataSendMessage.append('name', selectedBulkMessage.name);
        formDataSendMessage.append('vars', JSON.stringify(selectedBulkMessage.vars));
        formDataSendMessage.append('message', message);
        formDataSendMessage.append('send_at', selectedBulkMessage.send_at);
        formDataSendMessage.append('file', file);

        for (var pair of formDataSendMessage.entries()) {
            console.log(pair[0]+ ', ' + pair[1]);
        }

        actions.sendBulkMessages(formDataSendMessage)
    }

    function handlePaginate(e, url){
        e.preventDefault();
        url = url.replace('http://','https://');
        actions.fetchBulkMessages(url);
    }

    return (
        <div className={'col-12'}>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Bulk messages name</th>
                <th>Send at</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>

            {Array.isArray(ConversationState.bulkMessages.data)
                ? ConversationState.bulkMessages.data.map((bulkMessage, key) => (
                <tr key={key}>
                    <td>{bulkMessage.id}</td>
                    <td>{bulkMessage.name}</td>
                    <td>{bulkMessage.send_at}</td>
                    <td>
                        <p>Successfully sent: {bulkMessage.successfully}</p>
                        <p>Failed:  {bulkMessage.failed}</p>
                        <p>Total:  {bulkMessage.total}</p>
                    </td>

                    {new Date(bulkMessage.send_at).getTime() > (new Date()).getTime() ?
                        <td>
                            <RectangleButton className="actions-btn" type="button" label="Update" isHighlight onClick={() => openUpdateModal(bulkMessage)} />
                            <RectangleButton className="actions-btn" type="button" label="Delete" isHighlight onClick={() => openDeleteModal(bulkMessage)} />
                        </td>
                        : <td>Messages are sent</td>}
                </tr>
            )): null}
            </tbody>
        </Table>
            <Pagination>
                <Row className={'g-2'}>
                    <Col sm>
                        <Pagination.Prev
                                        disabled={ConversationState.bulkMessages.paginate?.previous_page === null}
                                         onClick={(event) => handlePaginate(event, ConversationState.bulkMessages.paginate?.previous_page)}>
                            Previous
                        </Pagination.Prev>
                    </Col>
                    <Col sm>
                        <Pagination.Next
                            disabled={ConversationState.bulkMessages.paginate?.next_page === null}
                            onClick={(event) => handlePaginate(event, ConversationState.bulkMessages.paginate?.next_page)} >
                            Next
                        </Pagination.Next>
                    </Col>
                </Row>
            </Pagination>

        <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Update bulk messages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'bulk-page'}>
                <Form.Group>
                    <Row className={'g-2'}>
                        <Row className={"col-12"}>
                            <Col md>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    name={'name'}
                                    type={'text'}
                                    onChange={(event) => onChangeName(event)}
                                    value={selectedBulkMessage?.name}
                                />
                            </Col>
                            <Col md>
                                <Form.Label>Message template</Form.Label>
                                <Select
                                    name={'message_template'}
                                    options={ConversationState.messageTemplates}
                                    getOptionLabel={(option) => `${option.title} `}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={(data) => onChangeTemplate(data)}
                                    value={ConversationState.messageTemplates.find(obj => obj.id === selectedBulkMessage?.message_template_id) ?? ''}

                                />
                            </Col>
                            <Col md>
                                <Form.Label>Send time</Form.Label>
                                <Form.Control
                                    type={'datetime-local'}
                                    name={'send_at'}
                                    value={selectedBulkMessage?.send_at}
                                    min={new Date().toISOString().slice(0, 16)}
                                    onChange={(event) => onChangeSendAt(event)}
                                />
                            </Col>
                            <Col md>
                                <Form.File
                                    name={'file'}
                                    label={'Choose file'}
                                />
                            </Col>
                        </Row>
                    </Row>
                    <Row className={"g-2"}>
                        <Row className={'col-12'}>
                            <Col md>
                                <Form.Label>Message template body preview</Form.Label>
                                <Form.Control
                                    as={"textarea"}
                                    rows={3}
                                    readOnly={true}
                                    value={selectedTemplate?.body}
                                    id={"editMessageTemplateBody"}
                                />
                            </Col>
                            <Col md>
                                {selectedBulkMessage?.vars?.map((article, key) => (
                                    <div key={key}>
                                        {article.var_is_message ?
                                            <TemplateItem
                                                labelKey={`{{${key + 1}}}`}
                                                copy={article.article_body}
                                                label="Add Tip"
                                                buttonAction={() => onClickAddTip(key)}

                                            />
                                            :
                                            <TemplateItem
                                                labelKey={`{{${key + 1}}}`}
                                                copy={article.value}
                                                label="Add Text"
                                                buttonAction={() => onClickAddText(article, key)}

                                            />
                                        }
                                    </div>
                                ))}
                                {typeof selectedTipId === "number" && selectedText === null && (
                                    <TipSelector
                                        show={true}
                                        onHide={onCloseTipSelector}
                                        submitTip={submitTip}
                                    />
                                )}
                                {showTextInput && (
                                    <TextInput
                                        show={true}
                                        value={messageTemplate?.body}
                                        textData={selectedText}
                                        onHide={onCloseTextInput}
                                        submitText={submitText}
                                    />
                                )}

                            </Col>
                        </Row>

                    </Row>

                </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer >
                <Row>

                    <RectangleButton
                        type="submit"
                        label="Send messages"
                        className={'float-right'}
                        onClick={onClickSubmitSendMessage}
                        isHighlight
                    />

                </Row>
            </Modal.Footer>
        </Modal>

        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Delete bulk messages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button className="delete-button" onClick={() => actions.deleteBulkMessages(selectedBulkMessage.id)}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={handleCloseDeleteModal}>
                    No
                </Button>
            </Modal.Body>
        </Modal>
        </div>
    )

}
export  default BulkMessageDashboard;