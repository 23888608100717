import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { ConversationContext } from "../../store/context/ConversationContext";
import Select, { components } from "react-select";

const Assignee = () => {
  const { state, actions } = useContext(ConversationContext);
  const [selectedAgent, setSelectedAgent] = useState([]);

  const { Option } = components;

  useEffect(() => {
    state.agents.find((agent) => {
      if (agent.value === state.currentConversation.agent.id) {
        setSelectedAgent(agent);
        return true;
      }
      setSelectedAgent(null);
      return false;
    });
  }, [state.currentConversation]);

  const reassignAgent = (event) => {
    actions.reassignConversation(state.currentConversation.id, event.value);
    setSelectedAgent(event);
  };

  const CustomSelectOption = (props) => (
      <div>
        {props?.data?.icon ?
            <Option {...props}>
              {props.data.icon.charAt(0) === '#' ?
                  <div className="small_agent_icon d-inline" style={{backgroundColor: props.data.icon}}>{getInitials(props.data.label)}</div>
                  : <img className="assignee_avatar" src={props.data.icon} alt="assignee" />}
              {props.data.label}
            </Option>
            :  <Option {...props}>{props.data.label}</Option>}
      </div>
  );

  function getInitials(agentName) {
    var initials = agentName.charAt(0);
    if(typeof agentName.split(' ')[1] != 'undefined') {
      initials += agentName.split(' ')[1].charAt(0);
    }
    return initials;
  }

  const CustomSelectValue = (props) => (
      <div>
        {props?.data?.icon ?
        <div>
          {props.data.icon.charAt(0) === '#' ?
              <div className="small_agent_icon d-inline" style={{backgroundColor: props.data.icon}}>{getInitials(props.data.label)}</div>
              : <img className="assignee_avatar" src={props.data.icon} alt="assignee" />}
          {props.data.label}
        </div>
      :  <div {...props}>{props.data.label}</div>}
      </div>
  );
  
  return (
    <Container>
      <Row className="assignee__name">
        <Col sm={12}>
          <Select
            name="agent_id"
            options={state.agents}
            components={{
              Option: CustomSelectOption,
              SingleValue: CustomSelectValue,
            }}
            classNamePrefix="select"
            onChange={(event) => reassignAgent(event)}
            value={selectedAgent}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Assignee;
