import React, { useState } from "react";
import { Link } from "react-router-dom";
import TripComponent from "../TripComponent/TripComponent";
import Traveler from "../../../TravelerInformation/Traveler";
import Itinerary from "../../../TravelerInformation/Itinerary";
import Company from "../../../TravelerInformation/Company";
import NoAssignee from "./NoAssignee/NoAssignee";
import Assignee from "./Assignee/Assignee";
import Agent from "./Agent/Agent";
import TaskTemplateComponent from "./Template/TaskTemplateComponent";
import TaskTemplateDayComponent from "./Template/TaskTemplateDayComponent";

const Task = ({ task, titleContainer }) => {
  const [titleContainerParent] = useState(titleContainer);

  let link;
  function switchLink(task){
    if (task.taskable_type !== 'App\\Journey'){
      link = `/itineraries/${task.taskable.id}`;
    } else if (task.taskable_type === 'App\\Journey' && task.trip_action.id > 8 && task.trip_action.id < 11){
      link = `/templates/${task.taskable.id}`;
    } else if (task.taskable_type === 'App\\Journey' && task.trip_action.id > 10){
      link = `/templates/${task.taskable.journey_id}`;
    } else if (task.taskable_type === 'App\\Article'){
        link = `/tips/${task.taskable.id}`;
    }
      return link;
  }

  return (
    <div className="task">
      <TripComponent task={task} />
      {task.taskable_type === 'App\\Journey' ? (
        <>
          {task.taskable ? (
            <Link to={switchLink(task)}>
              <TaskTemplateComponent task={task} />
            </Link>
          ) : (
            <>
              <TaskTemplateComponent />
            </>
          )}
          {task.taskable && task.trip_action.id > 10 ? (
            <TaskTemplateDayComponent task={task}/>
          ) : (
            <div className="task__empty-container"></div>
          )}
        </>
      ) : (
        <>
          {task.traveller && task.traveller.conversation ? (
            <Link to={`/conversations/open/${task.traveller.conversation.id}`}>
              <Traveler task={task} />
            </Link>
          ) : (
            <Traveler task={task} />
          )}
          {/* only a trip_action with ID:1 is not related to itinerary (yet)*/}
          {task.taskable && task.trip_action && task.taskable_type === 'App\\Itinerary' ? (
            <Link to={switchLink(task)}>
              <Itinerary task={task} />
            </Link>
          ) : (
            <Itinerary task={task} />
          )}
        </>
      )}
      <Company task={task} />
      {titleContainerParent.label === "ACTIVE" && !task.agentname && (
        <NoAssignee task={task}></NoAssignee>
      )}
      {titleContainerParent.label === "ACTIVE" && task.agentname && (
        <Assignee task={task}></Assignee>
      )}
      {titleContainerParent.label === "WORKING ON" && (
        <Agent task={task}></Agent>
      )}
      {titleContainerParent.label === "COMPLETED" && (
        <Assignee task={task}></Assignee>
      )}
      <div className="company__container">
        <p className="traveler__phone-number">{task.updated_at}</p>
      </div>
    </div>
  );
};

export default Task;
