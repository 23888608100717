import React, { useState, useContext } from "react";
import {Form, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {TipsContext} from "../../../store/context/TipsContext";
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";
import Select from "react-select";
import {AppContext} from "../../../store/context/AppContext";
import SelectRequired from "../../Reusable/SelectRequired/RequiredSelect";


function AddTagModal({ show, onHide }) {
    const {actions} = useContext(TipsContext);
    const {state: AppState} = useContext(AppContext);
    const [type, setType] = useState(null);
    const [tagName, setTagName] = useState(null);

    const onSubmitTagForm = (e) => {
        e.preventDefault();
        actions.addNewTag(type.value, tagName, onHide);
    };

    const onChangeTagName = (e) => {
        setTagName(e.target.value);
    };

    const SelectReq = props => (
        <SelectRequired
            {...props}
            SelectComponent={Select}
            required
        />
    );

    return (
        <Modal key="add-new-type" show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>New Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="add-type-form" onSubmit={onSubmitTagForm}>
                    <Form.Label className="tip-form__title">TYPES</Form.Label>
                    <SelectReq
                        name="type"
                        placeholder="Select type"
                        options={AppState.inputs.typesTips}
                        onChange={(data) =>
                            setType(data)
                        }
                        value={type}
                    />

                    <Form.Label className="tip-form__title">TAG NAME</Form.Label>
                    <Form.Control
                        required
                        name="title"
                        type="text"
                        placeholder="Add a tag name"
                        onChange={onChangeTagName}
                    />
                    <br/>
                    <div className="tip-form__btns">
                        <RectangleButton type="submit" label="Save"/>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

AddTagModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AddTagModal;
