import axios from "axios";
import { types } from "../reducers/TaskReducer";
import { getToken } from "../../utils/auth";

export const useActions = (state, dispatch) => {
  function fetchTaskActions() {
    const url = process.env.REACT_APP_BASEURL + "/api/task-actions";
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.UPDATE_TASKACTIONS,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchUnassignedTasks(unassignedPage, filters = {}) {
    let url =
      process.env.REACT_APP_BASEURL + `/api/tasks?page=${unassignedPage}`;
    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "undefined") continue;
      if (value === null) continue;
      if (typeof value === "string" && !value.length) continue;
      if (key === "status" && value.length && Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          url += `&task-action[]=${value[i].id}`;
        }
      }
      if (value > 0) {
        url += `&${key}=${value}`;
      }
      if(key === 'from' || key === 'to') {
        url += `&${key}=${value}`;
      }
    }

    if (state.unassignedTasks.unassignedOrderByDate !== ""){
      url += `&date=${state.unassignedTasks.unassignedOrderByDate}`;
    }

    axios({
      method: "GET",
      url: url,
    })
      .then((response) => {
        let data =response.data.data;
        if (!Array.isArray(data)){
          data = Object.values(data);
        }
        const newUnassignedTasks = Object.assign({}, state.unassignedTasks, {
          tasks: data,
          unassignedOrderByDate: state.unassignedTasks.unassignedOrderByDate,
          unassignedLastPage: response.data.last_page,
          count: response.data.total,
        });
        dispatch({
          type: types.UPDATE_UNASSIGNED_TASKS,
          payload: newUnassignedTasks,
        });
      })
      .catch((err) => {
        console.log(err);
        const errorMessage =
          "Sorry, we failed to fetch the tasks from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function fetchAssignedTasks(assignedPage, filters = {}) {
    let url =
      process.env.REACT_APP_BASEURL + `/api/tasks/agent?page=${assignedPage}`;

    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "undefined") continue;
      if (value === null) continue;
      if (typeof value === "string" && !value.length) continue;
      if (key === "status" && value.length && Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          url += `&task-action[]=${value[i].id}`;
        }
      }
      if (value > 0) {
        url += `&${key}=${value}`;
      }
    }

    if (state.assignedTasks.assignedOrderByDate !== ""){
      url += `&date=${state.assignedTasks.assignedOrderByDate}`;
    }

    axios({
      method: "GET",
      url: url,
    })
      .then((response) => {
        const newAssignedTasks = Object.assign({}, state.assignedTasks, {
          tasks: response.data.data,
          assignedOrderByDate: state.assignedTasks.assignedOrderByDate,
          assignedLastPage: response.data.last_page,
        });
        dispatch({
          type: types.UPDATE_ASSIGNED_TASKS,
          payload: newAssignedTasks,
        });
      })
      .catch((err) => {
        console.log(err);
        const errorMessage =
          "Sorry, we failed to fetch the tasks from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function fetchCompletedTasks(completedPage, filters = {}) {
    let url =
      process.env.REACT_APP_BASEURL +
      `/api/tasks/completed?page=${completedPage}`;

    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "undefined") continue;
      if (value === null) continue;
      if (typeof value === "string" && !value.length) continue;
      if (key === "status" && value.length && Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          url += `&task-action[]=${value[i].id}`;
        }
      }
      if (value > 0) {
        url += `&${key}=${value}`;
      }
    }

    if (state.completedTasks.completedOrderByDate !== ""){
      url += `&date=${state.completedTasks.completedOrderByDate}`;
    }

    axios({
      method: "GET",
      url: url,
    })
      .then((response) => {
        const newCompletedTasks = Object.assign({}, state.completedTasks, {
          tasks: response.data.data,
          completedOrderByDate: state.completedTasks.completedOrderByDate,
          completedLastPage: response.data.last_page,
        });
        dispatch({
          type: types.UPDATE_COMPLETED_TASKS,
          payload: newCompletedTasks,
        });
      })
      .catch((err) => {
        console.log(err);
        const errorMessage =
          "Sorry, we failed to fetch the tasks from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function updatePageIndex(taskContainer, pageIndex) {
    switch (taskContainer) {
      case "unassigned":
        dispatch({
          type: types.UPDATE_PAGE_INDEX_UNASSIGNED,
          payload: pageIndex,
        });
        break;
      case "assigned":
        dispatch({
          type: types.UPDATE_PAGE_INDEX_ASSIGNED,
          payload: pageIndex,
        });
        break;
      case "completed":
        dispatch({
          type: types.UPDATE_PAGE_INDEX_COMPLETED,
          payload: pageIndex,
        });
        break;
    }
  }

  function filterTasks(filter = {}) {
    fetchUnassignedTasks(1, filter);
    fetchAssignedTasks(1, filter);
    fetchCompletedTasks(1, filter);

    dispatch({
      type: types.UPDATE_FILTER,
      payload: filter,
    });
    dispatch({
      type: types.UPDATE_PAGE_INDEX_UNASSIGNED,
      payload: 1,
    });
    dispatch({
      type: types.UPDATE_PAGE_INDEX_ASSIGNED,
      payload: 1,
    });
    dispatch({
      type: types.UPDATE_PAGE_INDEX_COMPLETED,
      payload: 1,
    });
  }

  function clearFilterTasks(filter = {}) {
    fetchUnassignedTasks(1, filter);
    fetchAssignedTasks(1, filter);
    fetchCompletedTasks(1, filter);

    dispatch({
      type: types.CLEAR_FILTER,
      payload: false,
    });

    dispatch({
      type: types.UPDATE_PAGE_INDEX_UNASSIGNED,
      payload: 1,
    });
    dispatch({
      type: types.UPDATE_PAGE_INDEX_ASSIGNED,
      payload: 1,
    });
    dispatch({
      type: types.UPDATE_PAGE_INDEX_COMPLETED,
      payload: 1,
    });
  }

  function completeTask(task) {
    task.completed = 1;
    const newAssigned = Object.assign({}, state.assignedTasks, {
      tasks: state.assignedTasks.tasks.filter((t) => t.id !== task.id),
      assignedOrderByDate: state.assignedTasks.assignedOrderByDate,
      assignedLastPage: state.assignedTasks.assignedLastPage,
    });
    const newCompleted = Object.assign({}, state.completedTasks, {
      tasks: [...state.completedTasks.tasks, task],
      assignedOrderByDate: state.completedTasks.completedOrderByDate,
      completedLastPage: state.completedTasks.completedLastPage,
    });
    dispatch({ type: types.UPDATE_ASSIGNED_TASKS, payload: newAssigned });
    dispatch({ type: types.UPDATE_COMPLETED_TASKS, payload: newCompleted });

    let data = { completed: 1 };
    axios({
      method: "PUT",
      url: process.env.REACT_APP_BASEURL + `/api/task/${task.id}`,
      data: data,
    })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to update the task in the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function setUncompleted(task) {
    task.completed = "";
    const newAssigned = Object.assign({}, state.assignedTasks, {
      tasks: [...state.assignedTasks.tasks, task],
      assignedOrderByDate: state.assignedTasks.assignedOrderByDate,
      assignedLastPage: state.assignedTasks.assignedLastPage,
    });
    const newCompleted = Object.assign({}, state.completedTasks, {
      tasks: state.completedTasks.tasks.filter((t) => t.id !== task.id),
      completedOrderByDate: state.completedTasks.completedOrderByDate,
      completedLastPage: state.completedTasks.completedLastPage,
    });

    dispatch({ type: types.UPDATE_ASSIGNED_TASKS, payload: newAssigned });
    dispatch({ type: types.UPDATE_COMPLETED_TASKS, payload: newCompleted });

    let data = { uncomplete: 1 };
    axios({
      method: "PUT",
      url: process.env.REACT_APP_BASEURL + `/api/task/${task.id}`,
      data: data,
    })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to update the task in the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function setUnassigned(task) {
    task.agentname = null;
    const newUnassigned = Object.assign({}, state.unassignedTasks, {
      tasks: [...state.unassignedTasks.tasks, task],
      unassignedOrderByDate: state.unassignedTasks.unassignedOrderByDate,
      unassignedLastPage: state.unassignedTasks.unassignedLastPage,
    });
    const newAssigned = Object.assign({}, state.assignedTasks, {
      tasks: state.assignedTasks.tasks.filter((t) => t.id !== task.id),
      assignedOrderByDate: state.assignedTasks.assignedOrderByDate,
      assignedLastPage: state.assignedTasks.assignedLastPage,
    });
    dispatch({ type: types.UPDATE_UNASSIGNED_TASKS, payload: newUnassigned });
    dispatch({ type: types.UPDATE_ASSIGNED_TASKS, payload: newAssigned });

    let data = { unassign: 1 };
    axios({
      method: "PUT",
      url: process.env.REACT_APP_BASEURL + `/api/task/${task.id}`,
      data: data,
    })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to update the task in the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function assignTask(task) {
    let agentName = getToken().user_name;
    task.agentname = {
      name: agentName,
    };
    const newUnassigned = Object.assign({}, state.unassignedTasks, {
      tasks: state.unassignedTasks.tasks.filter((t) => t.id !== task.id),
      unassignedOrderByDate: state.unassignedTasks.unassignedOrderByDate,
      unassignedLastPage: state.unassignedTasks.unassignedLastPage,
    });
    const newAssigned = Object.assign({}, state.assignedTasks, {
      tasks: [...state.assignedTasks.tasks, task],
      assignedOrderByDate: state.assignedTasks.assignedOrderByDate,
      assignedLastPage: state.assignedTasks.assignedLastPage,
    });
    dispatch({ type: types.UPDATE_UNASSIGNED_TASKS, payload: newUnassigned });
    dispatch({ type: types.UPDATE_ASSIGNED_TASKS, payload: newAssigned });

    let data = { assign_to: 1 };
    axios({
      method: "PUT",
      url: process.env.REACT_APP_BASEURL + `/api/task/${task.id}`,
      data: data,
    })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to update the task in the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function sortDateUnassigned(sortDate) {
    dispatch({
      type: types.UPDATE_SORT_DATE_UNASSIGNED,
      payload: sortDate,
    });
  }

  function sortDateAssigned(sortDate) {
    dispatch({
      type: types.UPDATE_SORT_DATE_ASSIGNED,
      payload: sortDate,
    });
  }

  function sortDateCompleted(sortDate) {
    dispatch({
      type: types.UPDATE_SORT_DATE_COMPLETED,
      payload: sortDate,
    });
  }

  function clearError() {
    const emptyError = "";
    dispatch({
      type: types.SHOW_ERRORS,
      payload: emptyError,
    });
  }

  return {
    fetchTaskActions,
    fetchUnassignedTasks,
    fetchAssignedTasks,
    fetchCompletedTasks,
    completeTask,
    setUnassigned,
    assignTask,
    sortDateUnassigned,
    sortDateAssigned,
    sortDateCompleted,
    setUncompleted,
    clearError,
    updatePageIndex,
    filterTasks,
    clearFilterTasks,
  };
};
