import React from "react";
import "./NoAssignee.css";
import { useContext } from "react";
import { TasksContext } from "../../../../../store/context/TasksContext";

const NoAssignee = ({ task }) => {
  const {actions} = useContext(TasksContext);

  return (
    <div className="no-assignee__container">
      <div className="no-assignee__icon"></div>
      <div className="no-assignee__information">
        <button className="no-assignee" onClick={() => actions.assignTask(task)}>
          No assignee
          <u>Assign to me</u>
        </button>
      </div>
    </div>
  );
}

export default NoAssignee;
