import React, {useContext, useEffect, useRef, useState} from 'react';
import ConversationCard from '../ConversationCard'
import {Link, Route} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import ConversationDetails from '../ClosedConversations/ConversationDetailsClosed';
import './ClosedConversations.css'
import './../Conversations.css'
import Input from "reactstrap/lib/Input";
import { ConversationContext } from "../../../store/context/ConversationContext";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import {AppContext} from "../../../store/context/AppContext";

const ClosedConversations = () => {
    const { state, actions } = useContext(ConversationContext);
    const [searchedValue, setSearchedValue] = useState('');
    const [filter, setFilter] = useState({branch: null, language_id: ""});
    const { state: AppState } = useContext(AppContext);

    let typingTimer;
    let doneTypingInterval = 1500;

    const closedConversationsRef = useRef(null);

    useEffect(() => {
        if(closedConversationsRef.current && !state.uploadingMoreClosedConversations) {
            state.uploadingMoreClosedConversations = true;
        }
        return () => {
            state.uploadingMoreClosedConversations = false;
        }
    }, [state]);

    // search in conversations
    function tappingKeys() {
        if(document.getElementById('search_input_closed') != null) {
            let text = document.getElementById('search_input_closed').value;
            if (text !== searchedValue) {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(searchConversation, doneTypingInterval);
            }
        }
    }

    function stopTapping() {
        clearTimeout(typingTimer);
    }

    const changeFilter = (filter, data, name) => {
        let values = [];

        if (data != null)
        {
            for (let i = 0; i < data.length; i++)
            {
                values.push(data[i].id)
            }
        }

        setFilter({
            ...filter,
            [name]: values,
        })
    };

    function searchConversation() {
        if(document.getElementById('search_input_closed') != null) {
            let text = document.getElementById('search_input_closed').value;
            let languages = filter.language_id;
            let branches = filter.branch;

            setSearchedValue(text);
            actions.searchConversation(text, false, branches, languages);
        }
    }

    function fetchMoreData() {
        state.currentPageClosed++;
        actions.fetchConversations(state.currentPageClosed, closedConversationsRef.current, '/closed');
    }

    return (
        <Container fluid>
            {typeof state.conversations.filtered.closed != 'undefined' ?
                <Row>
                    <Col sm="4" className="mr-3">
                        <div className="conversation__search">
                            <Input
                                id="search_input_closed"
                                className="conversation-search"
                                name="conversation"
                                type="text"
                                placeholder="search"
                                onKeyUp={tappingKeys}
                                onKeyDown={stopTapping}
                            />
                        </div>
                        <div className="conversation__select">
                            <Row>
                                <Col>
                                    <Select
                                        isMulti
                                        id="language_id"
                                        name="language_id"
                                        className="conversation-dropdown__select"
                                        options={AppState.inputs.languages}
                                        getOptionLabel={(option) => `${option.name}`}
                                        getOptionValue={(option) => `${option.id}`}
                                        placeholder="by language"
                                        onChange={(data) => changeFilter(filter, data, 'language_id')}
                                    />
                                </Col>
                                <Col>
                                    <button
                                        className="search-input-field__btn search-btn-branch"
                                        onClick={searchConversation}
                                    ></button>
                                </Col>
                                <Col>
                                    <Select
                                        isMulti
                                        id="branch"
                                        name="branch"
                                        className="conversation-dropdown__select"
                                        options={AppState.inputs.companies}
                                        getOptionLabel={(option) => `${option.branch_name}`}
                                        getOptionValue={(option) => `${option.id}`}
                                        placeholder="by branch"
                                        onChange={(data) => changeFilter(filter, data, 'branch')}
                                    />
                                </Col>
                                <Col>
                                    <button
                                        className="search-input-field__btn search-btn-branch"
                                        onClick={searchConversation}
                                    ></button>
                                </Col>
                            </Row>
                        </div>
                        <h5 className="closed-conversations-header">CLOSED CONVERSATIONS</h5>
                        <div className="list-of-closed-conversations" id="close_conversation" ref={closedConversationsRef}>
                            <InfiniteScroll
                                dataLength={state.conversations.filtered.closed.length}
                                next={fetchMoreData}
                                hasMore={true}
                                height={700}
                            >
                                {state.conversations.filtered.closed.map(conversation => (
                                    <Link key={conversation.id} onClick={() => actions.fetchCurrentConversation(conversation.id)}
                                          to={`/conversations/closed/${conversation.id}`}
                                    >
                                        <ConversationCard conversation={conversation}/>
                                    </Link>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </Col>
                    <Col className="closed-conversation-block">
                        <div>
                            <Route path="/conversations/closed/:id"
                                   render={() => (<ConversationDetails/>)}
                            />
                        </div>
                    </Col>
                </Row>
                : ''}
        </Container>
    );
};

export default ClosedConversations;
