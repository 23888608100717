import React, {useContext, useEffect, useState} from 'react';
import './Dashboard.css'
import {DashboardContext} from "../../store/context/DashboardContext";
import {Table} from "reactstrap";

const ImportedFiles = () => {
    const {state, actions} = useContext(DashboardContext);

    useEffect(() => {
        actions.importedFilesLogs();
    }, [])


    return (
        <div className="dashboard">
            <b className="title">Imported files logs</b>
            <br/>
            <br/>



            <Table responsive="sm">
                <thead>
                <tr>
                    <th>Company name</th>
                    <th>Status</th>
                    <th>Filename</th>
                    <th>Datetime</th>
                </tr>
                </thead>
                <tbody>
                {state.importedFilesLogs && state.importedFilesLogs.length > 0 && state.importedFilesLogs.map((importedFile, i) => (
                    <tr key={i}>
                        <td>{importedFile.company}</td>
                        <td>{importedFile.status}</td>
                        <td>{importedFile.filename}</td>
                        <td>{importedFile.date}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ImportedFiles;
