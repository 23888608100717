import React, {useContext, useEffect, useState} from 'react';
import './Dashboard.css'
import {DashboardContext} from "../../store/context/DashboardContext";
import {Col, Row} from "reactstrap";
import CheckCircle from '../../images/check_circle.svg';
import IconError from '../../images/IconError.svg';
import Select, {components} from "react-select";

const ErrorList = () => {
    const {state, actions} = useContext(DashboardContext);
    const [selectedClient, setSelectedClient] = useState([]);
    const [showClientsBlock, setShowClientsBlock] = useState(false);
    const [showErrorsBlock, setShowErrorsBlock] = useState(false);
    const [errorType, setErrorType] = useState('');
    const [currentErrors, setCurrentErrors] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isMailings, setIsMailings] = useState(false);

    const selectClient = (event) => {
        setShowErrorsBlock(true);
        setSelectedClient(event);
    };

    function openClientsBlock(errorType) {
        setShowClientsBlock(false);
        setShowErrorsBlock(false);
        setIsMailings(false);
        setSelectedClient([]);

        setShowClientsBlock(true);
        if(errorType === 'mailing') {
            setIsMailings(true);
            setShowErrorsBlock(true);
        }
        setErrorType(errorType);

        state.messageErrors.find((messageErrors) => {
            if (messageErrors.type === errorType) {
                setCurrentErrors(messageErrors)
                return true;
            }
        })
    }

    function handleChange(event, type) {
        type === 'start_date' ? setStartDate(event.target.value) : setEndDate(event.target.value);
    }

    function uploadErrors() {
        actions.fetchLastUpdates(startDate, endDate);
    }

    return (
        <div className="dashboard">
            <b className="title">Error list</b>


            <br/><br/>

            <p>Select timeline for filtering errors or take them empty to show all errors</p>
            <Row>
                <Col sm="3">
                    <div className="form-group date-inputs">
                        <input type="date" name="start_date" className="form-control" onChange={(event) => handleChange(event, 'start_date')}/>
                        <span className="m-1"> - </span>
                        <input type="date" name="end_date" className="form-control" onChange={(event) => handleChange(event, 'end_date')}/>

                        <input type="button" className="btn btn-success upload_errors_btn" onClick={() => uploadErrors()} value="Show"/>
                    </div>
                    <div>
                        {state.messageErrors.map((messageError, i) => (
                            <div key={i}>
                                <b className="error-type"
                                   onClick={() => openClientsBlock(messageError.type)}>{messageError.type_name}</b>
                                <div className="last-update">
                                    {messageError.errors.length > 0 ?
                                        <img src={IconError} alt="icon"/>
                                        :
                                        <img src={CheckCircle} alt="icon"/>
                                    }
                                    <span>Last update: {messageError.last_update}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </Col>

                {showClientsBlock && !isMailings ?
                    <Col sm="3" className="error-block-mt">
                        <b>Clients</b>
                        <div className="dashboard-select">
                            <Select
                                name="client_id"
                                options={state.clients}
                                classNamePrefix="select"
                                onChange={(event) => selectClient(event)}
                                value={selectedClient}
                            />
                        </div>
                    </Col>
                    : ''
                }

                {showErrorsBlock ?
                    <Col sm="6" className="error-block-mt">
                        <div className="error-block">
                            <p><b>{selectedClient.label}</b></p>

                            <div>
                                {currentErrors.errors.map((currentError, i) => (
                                    <div key={i}>
                                        {currentError.map((error, i) => (
                                            <div key={i}>
                                                {error.client_id === selectedClient.value || isMailings ?
                                                    <div>
                                                        {isMailings ?
                                                            <div>
                                                                {i === 0 ? <p className="error-title"><b>{currentError[0].title}</b></p> : ''}
                                                            </div>
                                                        : <p className="error-title"><b>{currentError[0].title}</b></p>}

                                                        <div>
                                                            <Row>
                                                                <Col sm="2" className="text-right">
                                                                    <span>{error.count}</span>
                                                                    <img src={IconError} alt="icon"
                                                                         className="small-error-icon"/>
                                                                </Col>
                                                                <Col sm="10">
                                                                    <span>{error.message}</span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                : ''}
                                            </div>
                                        ))}
                                    </div>
                                ))}


                            </div>
                        </div>

                    </Col>
                    : ''
                }

            </Row>
        </div>
    );
};

export default ErrorList;
