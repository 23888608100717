import React, { useContext } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";

const SummaryTravellerContainer = () => {
  const { state } = useContext(ItineraryContext);

  return (
    <DataBlock
      header="TRAVELLER INFO"
      content={
        <>
          <div className="data-block__title">Name</div>
          <div className="data-block__text">
            {state.traveller.data.conversation.traveller &&
              state.traveller.data.conversation.traveller.full_name}
          </div>

          <div className="data-block__title">Trip name</div>
          <div className="data-block__text">
            {state.traveller.data.itinerary.description}
          </div>

          <div className="data-block__title">Destinations</div>
          <div className="data-block__text">
            {state.traveller.data.itinerary?.journey?.locations &&
              state.traveller.data.itinerary.journey.locations.map(
                (location, key) => <div key={key}>{location.title}</div>
              )}
          </div>

          <div className="data-block__title">Travel party</div>
          <div className="data-block__text">
            {state.traveller.data.itinerary.travellers &&
              state.traveller.data.itinerary.travellers.map(
                (traveller, key) => <div key={key}>{traveller.full_name}</div>
              )}
          </div>

          <div>
            <div className="data-block__title">Preferences</div>
            <div className="data-block__tag-container">
              {state.traveller.data.conversation.tags &&
                state.traveller.data.conversation.tags.map((preference) => (
                  <div key={preference.id} className="data-block__tag">
                    {preference.tag}
                  </div>
                ))}
            </div>
          </div>
        </>
      }
    />
  );
};

export default SummaryTravellerContainer;
