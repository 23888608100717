import React, {useContext} from 'react';
import './UserRole.css'
import {UserRolesContext} from "../../store/context/UserRolesContext";
import {Table} from "reactstrap";
import {Link, Route} from "react-router-dom";
import CreateEditRole from "./CreateEditRole";
import Switch from "react-bootstrap/Switch";

const RolesTab = () => {
    const {state, actions} = useContext(UserRolesContext);

    return (
        <div className="roles-permissions">
            <div>
                <Link to={'roles/create'}>
                    <button className="btn btn-success add-role">Add Role</button>
                </Link>

                <Table responsive="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="th-title">Title</th>
                        <th className="th-permissions">Permissions</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {state.roles.map((role, key) => (
                        <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{role.title}</td>
                            <td>
                                {role.permissions.map((permission, key) => (
                                    <div key={key} className="permission-block">{permission.name}</div>
                                ))}
                            </td>
                            <td>
                                <Link to={`/roles/edit/${role.id}`}>
                                    <button type="button" className="btn btn-info role-settings">Edit</button>
                                </Link>
                                <button type="button" className="btn btn-danger role-settings"
                                        onClick={() => actions.deleteRole(role.id)}>Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Switch>
                    <Route path="/roles/edit/:id" children={<CreateEditRole/>}/>
                </Switch>
            </div>
        </div>
    );
};

export default RolesTab;
