import React, { useContext } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";

import "./ItineraryAnalysis.css";

const ItineraryAnalysis = () => {
  const { state } = useContext(ItineraryContext);

  const analysisData = state.traveller.data.analysis;

  return (
    <DataBlock
      header="ANALYSIS"
      content={
        <>
          {analysisData && (
            <>
              <div className="itinerary-analysis__header">
                <div>
                  <div className="itinerary-analysis__title">
                    Number of Tips
                  </div>
                  <div className="itinerary-analysis__data">
                    {analysisData.tips}
                  </div>
                </div>

                <div>
                  <div className="itinerary-analysis__title">Keywords</div>
                  <div className="itinerary-analysis__keywords">
                    {analysisData.accomodation && (
                      <span className="itinerary-analysis__tag">
                        accommodation
                      </span>
                    )}
                    {analysisData.emergency_contact_service && (
                      <span className="itinerary-analysis__tag">
                        emergency contact service
                      </span>
                    )}
                    {analysisData.other_keywords && (
                      <span className="itinerary-analysis__tag">
                        other keywords
                      </span>
                    )}
                    {analysisData.other_travel_documents && (
                      <span className="itinerary-analysis__tag">
                        other travel documents
                      </span>
                    )}
                    {analysisData.questions_select && (
                      <span className="itinerary-analysis__tag">
                        questions select
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <div className="itinerary-analysis__title">
                    Number of Questions
                  </div>
                  <div className="itinerary-analysis__data">
                    {analysisData.questions}
                  </div>
                </div>
              </div>

              <div>
                <div className="itinerary-analysis__title">Feedback</div>
                <div className="itinerary-analysis__data">
                  {analysisData.feedback}
                </div>
                <div className="itinerary-analysis__data">
                  {analysisData.feedback_sophie}
                  {analysisData.feedback_comment}
                </div>
              </div>

              <div>
                <div className="itinerary-analysis__title">
                  Customer product questions
                </div>
                <div className="itinerary-analysis__keywords">
                  {analysisData.product_questions}
                </div>
              </div>

              <div>
                <div className="itinerary-analysis__title">
                  Customer other questions
                </div>
                <div className="itinerary-analysis__keywords">
                  {analysisData.other_questions}
                </div>
              </div>

              <div>
                <div className="itinerary-analysis__title">Complaints</div>
                <div className="itinerary-analysis__keywords">
                  {analysisData.product_complaints}
                </div>
              </div>

              <div>
                <div className="itinerary-analysis__title">Notes</div>
                <div className="itinerary-analysis__data">
                  {analysisData.notes}
                </div>
              </div>

              <div>
                <div className="itinerary-analysis__title">Current lead status</div>
                <div className="itinerary-analysis__data">
                  {analysisData.lead_status ? analysisData.lead_status : ""}
                </div>
              </div>
            </>
          )}
        </>
      }
    />
  );
};

export default ItineraryAnalysis;
