import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./DataBlock.css";

function DataBlock({ header, content, classNameContent }) {
  return (
    <>
      <div className="data-block__header">{header}</div>
      <div className={classnames("data-block__content", classNameContent)}>
        {content}
      </div>
    </>
  );
}

DataBlock.propTypes = {
  classNameContent: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.object,
};

export default DataBlock;
