import React, { useContext } from "react";
import { AppContext } from "../../../../store/context/AppContext";
import { Form } from "react-bootstrap";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Select from "react-select";

import "./EditTemplateForm.css";

import ActivityLog from "../../../Reusable/ActivityLog/ActivityLog";

function EditTemplateForm({ formData, handleFormData }) {
  const { state: AppState } = useContext(AppContext);

  const onChangeFormData = (e) => {
    handleFormData(e.target.name, e.target.value);
  };

  const onChangeTemplate = (name, data) => {
    handleFormData(name, data);
  };

  return (
    <>
      {formData?.id && (
        <form>
          <div className="edit-template-form__name">NAME</div>
          <div className="edit-template-form__form-container">
            <Form.Label className="edit-template-form__header">
              Trip Name
            </Form.Label>
            <Form.Control
              name="title"
              className="edit-template-form__input-field"
              defaultValue={formData.title}
              onChange={onChangeFormData}
              placeholder="Enter trip template name"
            />
            <div className="edit-template-form__header">Alias</div>
            <div className="edit-template-form__alias">
              {formData.alias && formData.alias.title}
            </div>
          </div>

          <div className="edit-template-form__name">TRIP INFO</div>
          <div className="edit-template-form__form-container">
            <Form.Label className="edit-template-form__header">Type</Form.Label>
            <Select
              className="edit-template-form__input-select"
              options={AppState.inputs.typesTemplates}
              getOptionLabel={(option) => `${option.label}`}
              value={formData.type}
              getOptionValue={(option) => `${option.value}`}
              onChange={(data) => onChangeTemplate("type", data)}
              placeholder="Select type"
            />

            <Form.Label className="edit-template-form__header">Link</Form.Label>
            <Form.Control
              name="link"
              className="edit-template-form__input-field"
              defaultValue={formData.link}
              onChange={onChangeFormData}
              placeholder="Add URL to trip"
            />

            <Form.Label className="edit-template-form__header">
              Countries
            </Form.Label>
            <Select
              className="edit-template-form__input-select"
              isMulti
              placeholder="Select countries"
              options={AppState.inputs.countries.data}
              getOptionLabel={(option) => `${option.title}`}
              value={formData.countries}
              getOptionValue={(option) => `${option.id}`}
              onChange={(data) => onChangeTemplate("countries", data)}
            />

            <Form.Label className="edit-template-form__header">Tags</Form.Label>
            <Select
              className="edit-template-form__input-select"
              isMulti
              placeholder="Add a tag"
              options={AppState.inputs.tags}
              getOptionLabel={(option) => `${option.tag}`}
              value={formData.tags}
              getOptionValue={(option) => `${option.id}`}
              onChange={(data) => onChangeTemplate("tags", data)}
            />

            <Form.Label className="edit-template-form__header">
              Company
            </Form.Label>
            <Select
              className="edit-template-form__input-select"
              options={AppState.inputs.companies}
              getOptionLabel={(option) => `${option.branch_name}`}
              value={formData.branch}
              getOptionValue={(option) => `${option.id}`}
              onChange={(data) => onChangeTemplate("branch", data)}
            />
          </div>

          <div className="edit-template-form__name">
            ACTIVITY LOG
            <div className="edit-template-form__form-container">
              {formData?.user_actions?.map((user_action) => (
                <ActivityLog
                  key={user_action.id}
                  date={user_action.updated_at}
                  name={user_action.user.name}
                  module={user_action.action_model}
                  type={user_action.action}
                />
              ))}
            </div>
          </div>
        </form>
      )}
    </>
  );
}

EditTemplateForm.propTypes = {
  formData: PropTypes.object.isRequired,
  handleFormData: PropTypes.func.isRequired,
};

export default withRouter(EditTemplateForm);
