const initialState = {
  templates: {
    allTemplates: [],
    nextPageUrl: "",
    lastPageUrl: "",
    lastPage: "",
  },
  filters: {
    branch: null,
    status: null,
    title: "",
    country: "",
    channel: null,
  },
  template: {
    data: {},
    length: [],
  },
  whatsappTemplates: [],
  channels: [],
  errors: "",
  success: "",
  validateErrors: {
    file: '',
  },
};

const types = {
  UPDATE_TEMPLATES: "UPDATE_TEMPLATES",
  UPDATE_CHANNELS: "UPDATE_CHANNELS",
  UPDATE_FILTER: "UPDATE_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
  UPDATE_WHATSAPP_TEMPLATES: "UPDATE_WHATSAPP_TEMPLATES",
  UPDATE_JOURNEYDAY: "UPDATE_JOURNEYDAY",
  CLEAR_JOURNEYDAY: "CLEAR_JOURNEYDAY",
  ADD_EMPTY_JOURNEYDAY: "ADD_EMPTY_JOURNEYDAY",
  REMOVE_JOURNEYDAY: "REMOVE_JOURNEYDAY",
  SHOW_ERRORS: "SHOW_ERRORS",
  SHOW_SUCCESS: "SHOW_SUCCESS",
  VALIDATE_ERRORS: "VALIDATE_ERRORS",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case types.UPDATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case types.CLEAR_FILTER:
      return {
        ...state,
        filters: initialState.filters,
      };
    case types.UPDATE_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case types.UPDATE_WHATSAPP_TEMPLATES:
      return {
        ...state,
        whatsappTemplates: action.payload,
      };
    case types.UPDATE_CHANNELS:
      return {
        ...state,
        channels: action.payload,
      };
    case types.UPDATE_JOURNEYDAY:
      const newTemplate = state.template;
      const dayIndex = newTemplate.data.days.findIndex(
        (d) => d.day === action.payload[0].day
      );
      if(newTemplate.data.days[dayIndex]?.messages) {
        newTemplate.data.days[dayIndex].messages = action.payload;
      }

      return {
        ...state,
        template: newTemplate,
      };
    case types.CLEAR_JOURNEYDAY:
      const dayToRemoveIndex = state.template.data.days.findIndex(d => d.day === action.payload.dayIndex);
      const msgIndex = state.template.data.days[dayToRemoveIndex].messages.findIndex(msg => msg.journey_day_id === action.payload.messageId);

      state.template.data.days[dayToRemoveIndex].messages.splice(msgIndex, 1)
      return { ...state };
    case types.ADD_EMPTY_JOURNEYDAY:
      state.template.data.journey_end += 1;
      const newJourneyDay = {
        day: state.template.data.journey_end,
        messages: []
      };
      state.template.data.days.push(newJourneyDay);
      return { ...state };
    case types.REMOVE_JOURNEYDAY:
      state.template.data.journey_end -= 1;
      state.template.data.days.pop();
      return { ...state };
    case types.SHOW_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case types.SHOW_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case types.VALIDATE_ERRORS:
      return {
        ...state,
        validateErrors: action.payload,
      }
    default:
      throw new Error("Unexpected action");
  }
};
export { initialState, types, reducer };
