import React, {useContext, useEffect} from 'react';
import './Kpi.css'
import {KpiContext} from "../../store/context/KpiContext";
import {Col, Row, Table} from "reactstrap";

const Conversations = () => {
    const {state, actions} = useContext(KpiContext);

    const eventNames = [
        'optin / register signup',
        'matching journey template',
        'match interest',
        'scheduled messages',
        'sending messages',
    ];

    useEffect(() => {
        actions.fetchKpi();
    }, [])


    function kpiAvgByWeek(kpiValues) {
        if (kpiValues.length > 0) {
            let total = 0;
            for (let i = 0; i < kpiValues.length; i++) {
                total += kpiValues[i];
            }
            return (total / kpiValues.length).toFixed(2);
        }
        return 0;
    }

    return (
        <div>
            {typeof state.kpi.kpiData != 'undefined' ?
                <div>
                    <div className="text-center">
                        <p className="kpi-type-header">Proactive flow</p>
                    </div>
                    <Row>
                        <Col sm="4">
                            <Table striped bordered hover size="sm">
                                <thead>
                                <tr>
                                    <th>Target</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.values(state.kpi.kpiEventName).map((eventName, key) => (
                                    <tr key={key}>
                                        <td>KPI {key + 1}: {eventNames[key]}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td><b>Average</b></td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col sm="8" className="proactive-flow-div">
                            <Table striped bordered hover size="sm" className="proactive-flow-table">
                                <thead>
                                <tr>
                                    {Object.keys(state.kpi.kpiData).map((kpiDatum, key) => (
                                        <th key={key}>{kpiDatum}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {Object.values(state.kpi.kpiEventName).map((eventName, key) => (
                                    <tr key={key}>
                                        {Object.values(state.kpi.kpiData).map((data, i) => (
                                            <td key={i}>
                                                {Object.keys(data).map((datumKey, i) => (
                                                    <span key={i}>{datumKey == key + 1 ? data[datumKey] + '%' : ''}</span>
                                                ))}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    {Object.values(state.kpi.kpiData).map((data, i) => (
                                        <td key={i}>
                                            <span><b>{kpiAvgByWeek(Object.values(data))}%</b></span>
                                        </td>
                                    ))}
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                </div>
                : null
            }
        </div>
    );
};

export default Conversations;
