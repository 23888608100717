import React, { useContext } from "react";
import "./ConversationMessages.css";
import {
  ButtonGroup,
  Container,
  Dropdown,
  DropdownButton,
  Row,
  Col,
} from "react-bootstrap";
import copy from "copy-to-clipboard";
import { ConversationContext } from "../../../store/context/ConversationContext";
import ReactHtmlParser from "react-html-parser";
import Itinerary from "../OpenConversations/Itinerary";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InteractButton from "../InteractButtons/InteractButton";

const Message = ({
  i,
  message,
  deleteAction,
  markAsUnreadAction,
  sendToAction,
  tagMessageAction,
  setReactionAction,
}) => {
  const { state } = useContext(ConversationContext);

  function parseDate(date) {
    if (date.toString().length === 10) {
      date = date + "000";
    }
    let datetime = new Date(parseInt(date));
    return (
      ("0" + datetime.getDate()).slice(-2) +
      "-" +
      ("0" + (datetime.getMonth() + 1)).slice(-2) +
      "-" +
      datetime.getFullYear() +
      " " +
      ("0" + datetime.getHours()).slice(-2) +
      ":" +
      ("0" + datetime.getMinutes()).slice(-2)
    );
  }

  function getTime(timestamp) {
    if (timestamp.toString().length === 10) {
      timestamp = timestamp + "000";
    }
    let datetime = new Date(parseInt(timestamp));
    return (
      ("0" + datetime.getHours()).slice(-2) +
      ":" +
      ("0" + datetime.getMinutes()).slice(-2)
    );
  }

  function needDrawTimeLine() {
    if (i === 0) {
      return true;
    }

    if (
      typeof state.currentConversation.messages[i - 1] !== "undefined" &&
      typeof message.created_at != "undefined"
    ) {
      let prevDate = parseDate(
        state.currentConversation.messages[i - 1].created_at
      ).split(" ")[0];
      let currentDate = parseDate(message.created_at).split(" ")[0];
      return prevDate !== currentDate;
    }
  }

  function copyMessage(id) {
    let message = document.getElementById("message_" + id).textContent;
    copy(message);
  }

  function deleteMessage(id) {
    if (deleteAction) {
      deleteAction(id);
    }
  }

  function tagMessage(id, type, itinerary, isTemplate) {
    if (tagMessageAction) {
      tagMessageAction(id, type, itinerary, isTemplate);
    }
  }

  function markAsUnread(id) {
    if (markAsUnreadAction) {
      markAsUnreadAction(id);
    }
  }

  function sendTo(userId, messageId) {
    if (sendToAction) {
      sendToAction(userId, messageId);
    }
  }

  function setReaction(reactionId, messageId) {
    if (setReactionAction) {
      setReactionAction(reactionId, messageId);
    }
  }

  function messageStatus(status) {
    switch (status) {
      case 0:
        return "message-pending";
      case 1:
        return "message-send";
      case 2:
        return "message-failed";
    }
  }
  function sendButtonReply(messageId, reply) {
    console.log(messageId, reply);
  }

  function switchMimeType(param) {
    let messageToShow = <></>;
    switch (param) {
      case "image":
        messageToShow = (
          <>
            <img
              className={`conversation-details-${message.file_mimetype} text-left`}
              src={`${process.env.REACT_APP_AZURE_STORAGE_URL}${message.file_url}`}
            ></img>
            <p
              id={`message_${message.id}`}
              className="conversation-details-text-open text-left"
            >
              {message.message}
            </p>
          </>
        );
        break;
      case "video":
        messageToShow = (
          <>
            <video
              muted
              controls
              className={`conversation-details-${message.file_mimetype} text-left`}
            >
              <source
                src={`${process.env.REACT_APP_AZURE_STORAGE_URL}${message.file_url}`}
              ></source>
            </video>
            <p
              id={`message_${message.id}`}
              className="conversation-details-text-open text-left"
            >
              {message.message}
            </p>
          </>
        );
        break;
      case "audio":
        messageToShow = (
          <>
            <audio
              controls
              muted
              className={`conversation-details-${message.file_mimetype} text-left`}
            >
              <source
                src={`${process.env.REACT_APP_AZURE_STORAGE_URL}${message.file_url}`}
              ></source>
            </audio>
            <p
              id={`message_${message.id}`}
              className="conversation-details-text-open text-left"
            >
              {message.message}
            </p>
          </>
        );
        break;
      case "html":
        messageToShow = ReactHtmlParser(message.message).slice(1);
        break;
      case "file":
        messageToShow = (
          <>
            <iframe
              src={`${process.env.REACT_APP_AZURE_STORAGE_URL}${message.file_url}`}
            ></iframe>
            <p
              id={`message_${message.id}`}
              className="conversation-details-text-open text-left"
            >
              {message.message}
            </p>
            <p><a href={`${process.env.REACT_APP_AZURE_STORAGE_URL}${message.file_url}`} target="_blank">Download</a></p>
          </>
        );
        break;
      default:
        messageToShow = (
          <>
            <div>
              {message.reply_message_text &&
                message.reply_message_text !== null ? (
                <div className="quoted-message whatsapp-quote-msg">
                  <b>
                    <p className="reply_message_author">
                      {message.reply_message_author}
                    </p>
                  </b>
                  <p className="reply_message_text">
                    {message.reply_message_text}
                  </p>
                </div>
              ) : null}

              <p
                id={`message_${message.id}`}
                className="conversation-details-text-open text-left"
              >
                {ReactHtmlParser(message.message)}

              </p>
              <InteractButton
                buttons={message.buttons}
              />
            </div>
          </>
        );
        break;
    }
    return messageToShow;
  }

  function generateRandomColor() {
    let maxVal = 0xFFFFFF; // 16777215
    let randomNumber = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    randomNumber = randomNumber.toString(16);
    let randColor = randomNumber.padStart(6, 0);
    return `#${randColor.toUpperCase()}`
  }



  return (
    <div>
      {typeof state.currentConversation !== "undefined" &&
        typeof state.currentConversation.messages !== "undefined" ? (
        <Container className="container-dialog">
          {needDrawTimeLine() ? (
            <div id={message.id} className="conversation-date text-center">
              <p>{parseDate(message.created_at).split(" ")[0]}</p>
              <hr></hr>
            </div>
          ) : null}
          <Row>
            {message.owner_type !== "Agent" ? (
              ""
            ) : (
              <Col sm="1" className="conversation-dots">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    ...
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => copyMessage(message.id)}>
                      Copy
                    </Dropdown.Item>

                    {message.owner_type === "Agent" ? (
                      <Dropdown.Item onClick={() => deleteMessage(message.id)}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item disabled>Delete</Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={() => markAsUnread(message.id)}>
                      Mark as unread
                    </Dropdown.Item>
                    <DropdownButton
                      as={ButtonGroup}
                      key="send_to"
                      id={`dropdown-button-drop-send-to`}
                      drop="right"
                      variant="secondary"
                      title={`Send to`}
                      className="d-block conversation-submenu manager_menu three-dots-menu"
                    >
                      {state.managerList != null ? (
                        <div>
                          {state.managerList.map((manager) => (
                            <Dropdown.Item
                              key={manager.id}
                              onClick={() => sendTo(manager.id, message.id)}
                            >
                              <span>{manager.name}</span>
                            </Dropdown.Item>
                          ))}
                        </div>
                      ) : null}
                    </DropdownButton>
                    <DropdownButton
                      as={ButtonGroup}
                      key="tag_message"
                      id={`dropdown-button-drop-tag-message`}
                      drop="right"
                      variant="secondary"
                      title={`Tag message`}
                      className="d-block conversation-submenu three-dots-menu"
                    >
                      {state.currentConversation.itineraries.map((itinerary, i) => (
                        <DropdownButton
                          as={ButtonGroup}
                          key={itinerary.dossier_id}
                          id={`dropdown-button-drop-send-to`}
                          drop="right"
                          variant="secondary"
                          title={itinerary.dossier_id}
                          className="d-block conversation-submenu manager_menu three-dots-menu"
                        >
                          {state.messageTags.map((messageTag) => (
                            <Dropdown.Item
                              key={messageTag.id}
                              onClick={() => tagMessage(message.id, messageTag, itinerary, 0)}
                            >
                              <div>
                                <span
                                  className={`circle`}
                                  style={{ backgroundColor: generateRandomColor() }}
                                >
                                  {" "}
                                </span>
                                <span>{messageTag.type}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      ))}
                    </DropdownButton>


                    <DropdownButton
                      as={ButtonGroup}
                      key="tag_message"
                      id={`dropdown-button-drop-tag-message`}
                      drop="right"
                      variant="secondary"
                      title={`Template tag message`}
                      className="d-block conversation-submenu three-dots-menu"
                    >
                      {state.currentConversation.itineraries.map((itinerary, i) => (
                        <DropdownButton
                          as={ButtonGroup}
                          key={itinerary.dossier_id}
                          id={`dropdown-button-drop-send-to`}
                          drop="right"
                          variant="secondary"
                          title={itinerary.dossier_id}
                          className="d-block conversation-submenu manager_menu three-dots-menu"
                        >
                          {state.messageTemplateTags.map((messageTag) => (
                            <Dropdown.Item
                              key={messageTag.id}
                              onClick={() => tagMessage(message.id, messageTag, itinerary, 1)}
                            >
                              <div>
                                <span
                                  className={`circle`}
                                  style={{ backgroundColor: generateRandomColor() }}
                                >
                                  {" "}
                                </span>
                                <span>{messageTag.type}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      ))}
                    </DropdownButton>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
            <Col sm="11">
              <div
                className={
                  message.owner_type === "Agent"
                    ? `conversation-author-message ${messageStatus(
                      message.status
                    )}`
                    : "conversation-traveler-message"
                }
              >
                {message.file_mimetype !== "" ||
                  message.file_mimetype !== null ? (
                  switchMimeType(message.file_mimetype)
                ) : (
                  <>
                    <div
                      id={`message_${message.id}`}
                      className="conversation-details-text-open text-left"
                    >
                      {message.message}
                    </div>
                  </>
                )}
                <div className="conversation-details-text-open text-justify message-time">
                  {message.owner_type === "Agent" && message.status === 0 && (
                    <div className="status-message-pending"></div>
                  )}

                  {message.owner_type === "Agent" && message.status === 2 && (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip className="message-error-tooltip" id={`button-tooltip-${message.id}`}>
                          {message.messageerrorable.map((messageError, i) => (
                            <p key={i}>{messageError.error_message}</p>
                          ))}
                        </Tooltip>
                      }
                    >
                      <Button className="status-message-failed"></Button>
                    </OverlayTrigger>
                  )}

                  {message.owner_type === "Agent" ?
                      <div>
                        <div>
                          {message.status === 4 ? <div>✔</div> : ''}
                        </div>
                        <div>
                          {message.status === 5 ? <div>✔✔</div> : ''}
                        </div>
                      </div>
                      : ''}

                  <div>{message.time}</div>
                </div>
              </div>

              {typeof message.reactions !== "undefined" &&
                message.reactions.length > 0 ? (
                <div>
                  <div
                    className={`${message.owner_type !== "Agent"
                      ? "reaction_block traveller_reaction"
                      : "reaction_block"
                      }`}
                  >
                    {message.reactions.map((reaction, i) => (
                      <span key={i}>{reaction.context}</span>
                    ))}
                  </div>
                </div>
              ) : null}

              {message.tags && message.tags.length > 0 ? (
                <div className="d-inline message-tag">
                  {message.tags.map((tag, i) => (
                    <span
                      key={i}
                      style={{ backgroundColor: generateRandomColor() }}
                    >
                      {tag?.itinerary?.dossier_id} {tag.typeable_type === 'App\\MessageTemplateType' ? '(template)' : ''} : {tag.typeable.type}
                    </span>
                  ))}
                </div>
              ) : null}
            </Col>
            {message.owner_type !== "Agent" ? (
              <Col sm="1" className="conversation-dots">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    ...
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => copyMessage(message.id)}>
                      Copy
                    </Dropdown.Item>

                    {message.owner_type === "Agent" ? (
                      <Dropdown.Item onClick={() => deleteMessage(message.id)}>
                        Delete
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item disabled>Delete</Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={() => markAsUnread(message.id)}>
                      Mark as unread
                    </Dropdown.Item>
                    <DropdownButton
                      as={ButtonGroup}
                      key="send_to"
                      id={`dropdown-button-drop-send-to`}
                      drop="right"
                      variant="secondary"
                      title={`Send to`}
                      className="d-block conversation-submenu manager_menu three-dots-menu"
                    >
                      {state.managerList != null ? (
                        <div>
                          {state.managerList.map((manager) => (
                            <Dropdown.Item
                              key={manager.id}
                              onClick={() => sendTo(manager.id, message.id)}
                            >
                              <span>{manager.name}</span>
                            </Dropdown.Item>
                          ))}
                        </div>
                      ) : null}
                    </DropdownButton>
                    <DropdownButton
                      as={ButtonGroup}
                      key="tag_message"
                      id={`dropdown-button-drop-tag-message`}
                      drop="right"
                      variant="secondary"
                      title={`Tag message`}
                      className="d-block conversation-submenu three-dots-menu"
                    >
                      {state.currentConversation.itineraries.map((itinerary, i) => (
                        <DropdownButton
                          as={ButtonGroup}
                          key={itinerary.dossier_id}
                          id={`dropdown-button-drop-send-to`}
                          drop="right"
                          variant="secondary"
                          title={itinerary.dossier_id}
                          className="d-block conversation-submenu manager_menu three-dots-menu"
                        >
                          {state.messageTags.map((messageTag) => (
                            <Dropdown.Item
                              key={messageTag.id}
                              onClick={() => tagMessage(message.id, messageTag, itinerary, 0)}
                            >
                              <div>
                                <span
                                  className={`circle`}
                                  style={{ backgroundColor: generateRandomColor() }}
                                >
                                  {" "}
                                </span>
                                <span>{messageTag.type}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      ))}
                    </DropdownButton>


                    <DropdownButton
                      as={ButtonGroup}
                      key="tag_message"
                      id={`dropdown-button-drop-tag-message`}
                      drop="right"
                      variant="secondary"
                      title={`Template tag message`}
                      className="d-block conversation-submenu three-dots-menu"
                    >
                      {state.currentConversation.itineraries.map((itinerary, i) => (
                        <DropdownButton
                          as={ButtonGroup}
                          key={itinerary.dossier_id}
                          id={`dropdown-button-drop-send-to`}
                          drop="right"
                          variant="secondary"
                          title={itinerary.dossier_id}
                          className="d-block conversation-submenu manager_menu three-dots-menu"
                        >
                          {state.messageTemplateTags.map((messageTag) => (
                            <Dropdown.Item
                              key={messageTag.id}
                              onClick={() => tagMessage(message.id, messageTag, itinerary, 1)}
                            >
                              <div>
                                <span
                                  className={`circle`}
                                  style={{ backgroundColor: generateRandomColor() }}
                                >
                                  {" "}
                                </span>
                                <span>{messageTag.type}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      ))}
                    </DropdownButton>

                    <DropdownButton
                      as={ButtonGroup}
                      key="reactions"
                      id={`dropdown-button-drop-send-to`}
                      drop="right"
                      variant="secondary"
                      title={`Reactions`}
                      className="conversation-submenu three-dots-menu"
                    >
                      {state.reactions != null ? (
                        <div>
                          {state.reactions.map((reaction) => (
                            <Dropdown.Item
                              key={reaction.id}
                              onClick={() =>
                                setReaction(reaction.id, message.id)
                              }
                            >
                              <span>{reaction.context}</span>
                            </Dropdown.Item>
                          ))}
                        </div>
                      ) : null}
                    </DropdownButton>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Container>
      ) : (
        ""
      )}
    </div>
  );
};

export default Message;
