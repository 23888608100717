const initialState = {
  inputs: {
    companies: [],
    statusTemplates: {},
    tags: [],
    typesTips: [],
    users: [],
    countries: [],
    cities: [],
    radius: [],
    channels: [],
    languages: [],
  },
};

const types = {
  UPDATE_INPUTS: "UPDATE_INPUTS",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_INPUTS:
      return {
        ...state,
        inputs: action.payload,
      };
    default:
      throw new Error(types.error, "Unexpected action");
  }
};
export { initialState, types, reducer };
