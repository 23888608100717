import React from "react";
import axios from "axios";
import { useReducer, useEffect } from "react";
import { reducer, initialState, types } from "../../store/reducers/AppReducer";
import { useActions } from "../../store/actions/AppActions";
import Pusher from "pusher-js";

export const AppContext = React.createContext();

const AppContextApi = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APPKEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    encrypted: true,
  });

  useEffect(() => {
  
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        let linkCompanies = process.env.REACT_APP_BASEURL + "/api/branches";
        let linkTags = process.env.REACT_APP_BASEURL + "/api/tags/all";
        let linkTypesTips = process.env.REACT_APP_BASEURL + "/api/tips/types";
        let linkUsers = process.env.REACT_APP_BASEURL + "/api/agents";
        let linkStatusTemplates =
          process.env.REACT_APP_BASEURL + "/api/journeys/status";
        let linkStatusItineraries =
          process.env.REACT_APP_BASEURL + "/api/itineraries/status";
        let linktypesTemplates =
          process.env.REACT_APP_BASEURL + "/api/journeys/types";
        let linkCountries = process.env.REACT_APP_BASEURL + "/api/countries";
        let linkCities = process.env.REACT_APP_BASEURL + "/api/cities";
        let linkRadius = process.env.REACT_APP_BASEURL + "/api/tips/radius";
        let linkChannels = process.env.REACT_APP_BASEURL + "/api/channels";
        let linkLanguages = process.env.REACT_APP_BASEURL + "/api/languages";

        const requestCompanies = axios.get(linkCompanies);
        const requestTags = axios.get(linkTags);
        const requestTypesTips = axios.get(linkTypesTips);
        const requestUsers = axios.get(linkUsers);
        const requestStatusTemplates = axios.get(linkStatusTemplates);
        const requestStatusItineraries = axios.get(linkStatusItineraries);
        const requesttypesTemplates = axios.get(linktypesTemplates);
        const requestCountries = axios.get(linkCountries);
        const requestCities = axios.post(linkCities, null);
        const requestRadius = axios.get(linkRadius);
        const requestChannels = axios.get(linkChannels);
        const requestLanguages = axios.get(linkLanguages);

        await axios
          .all(
            [
              requestCompanies,
              requestTypesTips,
              requestTags,
              requestUsers,
              requestStatusTemplates,
              requestStatusItineraries,
              requesttypesTemplates,
              requestCountries,
              requestRadius,
              requestChannels,
              requestLanguages,
              requestCities
            ],
            {
              cancelToken: source.token,
            }
          )
          .then(
            axios.spread((...allData) => {
              dispatch({
                type: types.UPDATE_INPUTS,
                payload: {
                  companies: allData[0].data,
                  typesTips: allData[1].data,
                  tags: allData[2].data,
                  users: allData[3].data,
                  statusTemplates: allData[4].data,
                  statusItineraries: allData[5].data,
                  typesTemplates: allData[6].data,
                  countries: allData[7].data,
                  radius: allData[8].data,
                  channels: allData[9].data,
                  languages: allData[10].data,
                  cities: allData[11].data,
                },
              });
            }
            )
          );
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          throw error;
        }
      }
    };
    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <AppContext.Provider value={{ state, dispatch, actions, pusher }} >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextApi;
