import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./TaskList.css";

function TaskList({
  taskable_type,
  agentName,
  tripAction,
  statusColour,
  errorDetails,
  className,
}) {
  const taskListClass = `task-list ${className}`;
  const [showDetails, setShowDetails] = useState(false);

  const onClickShowErrorDetails = () => {
    if (!showDetails) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  };

  return (
    <div>
      <div className={taskListClass} onClick={onClickShowErrorDetails}>
        {showDetails ? (
          <>
            <div>
              <span className={classnames("task-list__user", statusColour)}>
                {agentName ? agentName : "No assignee"}
              </span>
            </div>
            <div className="task-list__task-related-to">
              <div className="task-list__related-to">{taskable_type}: </div>
              <div>{tripAction}</div>
            </div>
            <div className="task-list__task-error-message">
            <div>{errorDetails ? errorDetails.error_message : 'No error details to display'}</div>
              </div>
          </>
        ) : (
          <>
            <div>
              <span className={classnames("task-list__user", statusColour)}>
                {agentName ? agentName : "No assignee"}
              </span>
            </div>
            <div className="task-list__task-related-to">
              <div className="task-list__related-to">{taskable_type}: </div>
              <div>{tripAction}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

TaskList.propTypes = {
  relatedTo: PropTypes.string,
  user: PropTypes.object,
  className: PropTypes.string,
};

export default TaskList;
