import React from "react";
import PropTypes from "prop-types";

import "./TemplateItem.css";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";

function TemplateItem({ labelKey, copy, label, buttonAction }) {
  return (
    <>
      <div className="template-item__key">{labelKey}</div>
      <div className="template-item__tip">
        <div className="template-item__value">{copy}</div>
        <RectangleButton onClick={buttonAction} label={label} isSmall />
      </div>
    </>
  );
}

TemplateItem.propTypes = {
  labelKey: PropTypes.string.isRequired,
  copy: PropTypes.string,
  label: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
};

export default TemplateItem;
