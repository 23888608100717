import React, { useState, useContext } from "react";
import { ItineraryContext } from "../../../../../store/context/ItineraryContext";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import SearchInputField from "../../../../Reusable/SearchInputField/SearchInputField";

import "./AddTravellerToItinerary.css";

import RectangleButton from "../../../../Reusable/RectangleButton/RectangleButton";

function AddTravellerToItineraryModal({ show, onHide, itineraryId }) {
  const { state, actions } = useContext(ItineraryContext);
  const [phoneNumber, setPhoneNumber] = useState();
  const [initialSearch, setInitialSearch] = useState(false);

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const onClickSearch = () => {
    actions.findPhonenumber(phoneNumber, state.itinerary.data.branch_id);
    setInitialSearch(true);
  };

  const onClickAddTraveller = (conversationId) => {
    actions.addTravellerToItinerary(conversationId, itineraryId);
    onHide(onHide);
  };


  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Add traveller to itinerary</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-traveller-to-itinerary__body">
        <SearchInputField
          className="add-traveller-to-itinerary__input"
          name="phoneNumber"
          placeholder="Search phone number"
          value={phoneNumber}
          onChange={onChangePhoneNumber}
          onClickSearch={onClickSearch}
        />
        {state.searchPhoneNumberResult.data.length >= 1 ? (
          <div className="add-traveller-to-itinerary__btn-container">
            {state.searchPhoneNumberResult.data.map((phoneNumber) => (
              <RectangleButton
                className="add-traveller-to-itinerary__btn"
                label={`Add ${phoneNumber.messenger_channel_id}`}
                key={phoneNumber.id}
                onClick={() => onClickAddTraveller(phoneNumber.id)}
              />
            ))}
          </div>
        ) : (
          <>
            {initialSearch && (
              <div>We could not find a match in the database</div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

AddTravellerToItineraryModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  itineraryId: PropTypes.number,
};

export default AddTravellerToItineraryModal;
