import React, { useContext } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";

import "./IndividualItineraryContainer.css";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";

const IndividualItineraryContainer = () => {
  const { state } = useContext(ItineraryContext);

  return (
    <DataBlock
      header="ITINERARY"
      classNameContent="individual-itinerary-container"
      content={
        <div className="individual-itinerary-container__content">
          <div className="individual-itinerary-container__details">
            <div>
              <div className="individual-itinerary-container__title">Trip</div>
              {state.traveller.data.itinerary?.itinerary_sale?.sale ?
                  <div className="itinerary-container__data">
                    <b>{state.traveller.data.itinerary.itinerary_sale.sale}</b>
                  </div>
              : ''}
              <div className="individual-itinerary-container__data">
                {state.traveller.data.itinerary &&
                  state.traveller.data.itinerary.description}
              </div>
            </div>
            <div>
              <div className="individual-itinerary-container__title">
                Destination
              </div>
              <div className="individual-itinerary-container__data">
                {state.traveller.data.itinerary &&
                  state.traveller.data.itinerary.country}
              </div>
            </div>
          </div>

          <div className="individual-itinerary-container__content">
            <div className="itinerary-container__details-block">

              <div className="itinerary-container__title itinerary-container__title--accommodation">
                Accommodation
              </div>
              <div className="itinerary-container__accommodation-container">
                {state.traveller.data.itinerary?.itinerary_accomodations &&
                    state.traveller.data.itinerary?.itinerary_accomodations.map((acco, key) => (
                        <div
                            className="itinerary-container__accommodation"
                            key={key}
                        >
                          {acco.accomodation.accomodation_name !== null ? (<p className="mb-0">{acco.accomodation.accomodation_name},</p>) : null}
                          <p className="mb-0">{acco.accomodation.city.title} {acco.accomodation.city_part}</p>
                          {acco.accomodation.address !== null ? (<div>
                            {acco.accomodation.address.address !== null ? (<p className="mb-0">{acco.accomodation.address.address}</p>) : null}
                            {acco.accomodation.care_type !== null ? (<p className="mb-0">Care type: {acco.accomodation.care_type}</p>) : null}
                            {acco.accomodation.stars !== null ? (<p className="mb-0">Stars: {acco.accomodation.stars}</p>) : null}
                            {acco.accomodation.accomodation_code !== null ? (<p className="mb-0">Code: {acco.accomodation.accomodation_code}</p>) : null}
                            {acco.accomodation.address.maps_url !== null && acco.accomodation.address.maps_url !== '' ? (<a className="mb-0" target="_blank" href={acco.accomodation.address.maps_url}>See on map</a>) : null}
                          </div>) : null}
                          <p className="mb-0">{acco.departure_date} - {acco.arrival_date}</p>
                        </div>
                    ))}
              </div>


              <div className="itinerary-container__title itinerary-container__title--accommodation">
                Products
              </div>
              <div className="itinerary-container__accommodation-container">
                <div className="itinerary-container__accommodation">
                  <ul>
                    {state.traveller.data.itinerary?.itinerary_products &&
                        state.traveller.data.itinerary?.itinerary_products.map((product, key) => (
                            <li className="mb-0" key={key}>
                              {product.product_name !== null ? (
                                  <div>
                                    {product.departure_date} - {product.return_date} - {product.product_name}
                                  </div>
                              ) : null}
                            </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div className="individual-itinerary-container__body">
            <div className="individual-itinerary-container__travel-party">
              <div className="individual-itinerary-container__title">
                Travel Party
              </div>
              <div className="individual-itinerary-container__data">
                {state.traveller.data.itinerary &&
                  state.traveller.data.itinerary.travellers.map(
                    (traveller, key) => (
                      <div key={key}>{traveller.full_name}</div>
                    )
                  )}
              </div>
            </div>
            <div>
              <div className="individual-itinerary-container__title">
                Departure
              </div>
              <div className="individual-itinerary-container__data">
                {state.traveller.data.itinerary &&
                  state.traveller.data.itinerary.departure_date}
              </div>
            </div>

            <div>
              <div className="individual-itinerary-container__title">
                Return
              </div>
              <div className="individual-itinerary-container__data">
                {state.traveller.data.itinerary &&
                  state.traveller.data.itinerary.return_date}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default IndividualItineraryContainer;
