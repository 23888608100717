import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import { DashboardContext } from "../../store/context/DashboardContext";
import { Table } from "reactstrap";

const OverviewScheduledMessages = () => {
  const { state, actions } = useContext(DashboardContext);
  const [date, setDate] = useState(null);

  useEffect(() => {
    actions.fetchOverviewScheduledMessages();
  }, []);

  function handleChange(event) {
    setDate(event.target.value)
  }

  function handleSpecificDateOverview() {
    actions.fetchOverviewScheduledMessages(date);
  }
  return (
    <div className="dashboard">
      <b className="title">Overview for scheduled messages on day: </b>
      <br />
      <br />

      <Table responsive="sm">
        <thead>
          <div className="form-group date-inputs">
            <input
              type="date"
              name="date"
              className="form-control"
              onChange={(event) => handleChange(event)}
            />
            <input
              type="button"
              className="btn btn-success upload_errors_btn"
              onClick={() => handleSpecificDateOverview()}
              value="Show"
            />
          </div>
          <tr>
            <th>Total</th>
            <th>Send_at time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {state.overviewScheduledMessages &&
            state.overviewScheduledMessages.length > 0 &&
            state.overviewScheduledMessages.map((scheduledMessages, i) => (
              <tr key={i}>
                <td>{scheduledMessages.total}</td>
                <td>{scheduledMessages.send_at}</td>
                <td>{scheduledMessages.status}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default OverviewScheduledMessages;
