import React, { useState, useEffect, useContext } from "react";
import { ItineraryContext } from "../../store/context/ItineraryContext";
import ItineraryRow from "./ItineraryRow/ItineraryRow";
import AddItinerary from "./AddItinerary/AddItinerary";
import ItinerarySearchBar from "./ItinerarySearchBar/ItinerarySearchBar";

import "./Itineraries.css";

import CircleButton from "../Reusable/CircleButton/CircleButton";
import Paginator from "../Reusable/Paginator/Paginator";
import ErrorModal from "../Reusable/ErrorModal/ErrorModal";

const OpenItineraries = () => {
  const { state, actions } = useContext(ItineraryContext);
  const [filter, setFilter] = useState(state.filters);
  const [showAddItinerary, setShowAddItinerary] = useState(false);
  const [pageIndex, setPageIndex] = useState(null);

  useEffect(() => {
    setPageIndex(state.pageIndex);
  }, [state.pageIndex]);

  useEffect(() => {
    setFilter(state.filters);
  }, [state.filters])

  useEffect(() => {
    actions.fetchItineraries(pageIndex, filter, state.closed);
  }, [pageIndex]);

  const getNextPage = () => {
    actions.updatePageIndex(state.itineraries || state.itineraries.nextPageUrl
      ? pageIndex + 1
      : pageIndex
    )
  };

  const getPreviousPage = () => {
    let newPageIndex = Math.max(state.pageIndex - 1, 1)
    actions.updatePageIndex(newPageIndex);
  };

  const setPageNumber = (pageIndex) => {
    actions.updatePageIndex(pageIndex);
  };

  const sortItineraries = (sortCategorie) => {
    if (filter[sortCategorie] === "" || filter[sortCategorie] === "asc") {
      setFilter({ ...filter, [sortCategorie]: "desc" });
    } else {
      setFilter({ ...filter, [sortCategorie]: "asc" });
    }
  };

  const onClickSortDepartureDate = () => {
    sortItineraries("departure");
  };

  const onClickSortReturnDate = () => {
    sortItineraries("return");
  };

  const onClickAddItinerary = () => {
    setShowAddItinerary(true);
  };

  const onItineraryClose = () => {
    setShowAddItinerary(false);
  };

  const clearError = () => {
    actions.clearError();
  };

  const clearSort = () => {
    setFilter({ ...filter, departure: "", return: "" });
  }

  return (
    <div className="itineraries">
      <ItinerarySearchBar clearSort={clearSort} pageIndex={pageIndex} />

      <div className="itineraries__table-header">
        <p>Booking Number</p>
        <p>Customer</p>
        <p>Trip</p>
        <p>Channel</p>
        <button
          onClick={onClickSortDepartureDate}
          className="itineraries__filter-date"
        >
          Departure
            {filter.departure === "" || filter.departure === "asc" ? (
            <i className="itineraries__arrow itineraries__down" />
          ) : (
            <i className="itineraries__arrow itineraries__up" />
          )}
        </button>
        <button
          onClick={onClickSortReturnDate}
          className="itineraries__filter-date"
        >
          Return
            {filter.return === "" || filter.return === "asc" ? (
            <i className="itineraries__arrow itineraries__down" />
          ) : (
            <i className="itineraries__arrow itineraries__up" />
          )}
        </button>
        <p>Status</p>
        <p>Language</p>
      </div>
      <div className="itineraries__table-body">
        {state.itineraries.allItineraries &&
          state.itineraries.allItineraries.map((itinerary) => (
            <ItineraryRow {...itinerary} key={itinerary.id} />
          ))}
      </div>
      <Paginator
        page={pageIndex}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        lastPage={state.itineraries.lastPage}
        getPageButton={setPageNumber}
      />
      <CircleButton
        type="add"
        className="itineraries__new-btn"
        onClick={onClickAddItinerary}
      />

      <AddItinerary show={showAddItinerary} onHide={onItineraryClose} />
      <ErrorModal errorMessage={state.errors} onHide={clearError} />
    </div>
  );
};

export default OpenItineraries;
