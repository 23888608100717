import React, { useContext } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";
import TaskList from "../../../Reusable/TaskList/TaskList";

const ItineraryTaskList = ({ isIndividualMode }) => {
  const { state } = useContext(ItineraryContext);

  const itineraryData = state.itinerary.data;
  const travellerData = state.traveller.data;

  let taskListData;
  if (isIndividualMode === true) {
    taskListData = travellerData.itinerary;
  } else {
    taskListData = itineraryData;
  }

  return (
    <DataBlock
      header="TASKLIST"
      content={
        <>
          {taskListData &&
            taskListData?.tasks?.map((task) => (
              <TaskList
                key={task.id}
                taskable_type={task.taskable_type}
                agentName={task.agentname?.name}
                tripAction={task.trip_action.name}
                statusColour={task.status_color_class}
                errorDetails={task.error_details}
              />
            ))}
        </>
      }
    />
  );
};

export default ItineraryTaskList;
