import React, { useEffect, useRef } from "react";


function isTop(ref) {
    // if there are no messsages to show, the ref needs to return false
    if (!ref.current) {
        return false;
    }

    //If the top of the div that ref is pointing to is less than or equal to our window’s innerHeight, we know that our scroll position is above that div, it returns true to load more data
    return ref.current.firstChild.scrollTop === 0;
}

const InfiniteScroll = ({
    onTopHit,
    isLoading,
    hasMoreData,
    children,
}) => {
    const contentRef = useRef(null);

    useEffect(() => {
        //we only want to start listening to mousewheel when data is not loading, when there is more data to show or when the max is reached 
        const debounce = (func, wait, immediate) => {
            var timeout;
            return function () {
                var context = this, args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        var onScroll = debounce(function () {
            if (!isLoading && hasMoreData && isTop(contentRef)) {
                onTopHit(contentRef);
            }
        }, 250); 
       
        //specifically listen to mousewheel activity in this div only. Not in the entire screen
        document.querySelector('.conversation-details-open').addEventListener('mousewheel', onScroll);
        return () => document.querySelector('.conversation-details-open').removeEventListener('mousewheel', onScroll);
    }, [onTopHit, isLoading, hasMoreData]);

    return <div ref={contentRef}>{children}</div>;
};

export default InfiniteScroll;