import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../store/context/AppContext";
import { TemplateContext } from "../../../store/context/TemplateContext";
import { Modal, Form } from "react-bootstrap";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Select from "react-select";

import "./AddTemplate.css";

import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

const initialState = {
  title: "",
  journey_length: 4,
  branch_id: "",
};

function AddTemplate({ show, onHide, history }) {
  const { state: AppState } = useContext(AppContext);
  const { state: TemplateState, actions } = useContext(TemplateContext);
  const [template, setTemplate] = useState(initialState);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (template.branch_id) {
      actions.fetchChannels(template.branch_id);
    }
  }, [template]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!template.branch_id) {
      setShowError("Please select a tour operator");
    } else if (!template.channel_id){
      setShowError("Please select a channel");
    } else {
      actions.addNewTemplate(template, history);
      setTemplate(initialState);
    }
  };

  const handleTemplate = (e) => {
    setTemplate({ ...template, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            New Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="add-template__form" onSubmit={onFormSubmit}>
            <Form.Label className="add-template__title">
              TEMPLATE NAME
            </Form.Label>
            <Form.Control
              required
              name='title'
              type="text"
              value={template.title}
              onChange={handleTemplate}
              placeholder="Enter trip template name"
            />

            <Form.Label className="add-template__title">DAYS</Form.Label>
            <Form.Control
              required
              type="number"
              value={template.journey_length}
              onChange={handleTemplate}
              name="journey_length"
            />

            <Form.Label
              className={`add-template__title ${showError ? "add-template__error-message" : ""
                }`}
            >
              TOUR OPERATOR
            </Form.Label>
            <Select
              options={AppState.inputs.companies}
              getOptionLabel={(option) => `${option.branch_name}`}
              value={template.branch}
              getOptionValue={(option) => `${option.id}`}
              onChange={(data) =>
                setTemplate({
                  ...template,
                  branch_id: data.id,
                })
              }
            />
            <Form.Label
              className={`add-template__title ${showError ? "add-template__error-message" : ""
                }`}
            >
              CHANNEL
            </Form.Label>
            <Select
              placeholder="First select tour operator"
              options={TemplateState.channels}
              getOptionLabel={(option) => `${option.channel_name}`}
              value={template.channel}
              getOptionValue={(option) => `${option.id}`}
              onChange={(data) =>
                setTemplate({
                  ...template,
                  channel_id: data.id,
                })
              }
            />
            {showError && (
              <div className="add-template__error-message">{showError}</div>
            )}
            <div className="add-template__btn">
              <RectangleButton type="submit" label="next" isSmall />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

AddTemplate.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(AddTemplate);
