import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./TemplateCard.css";

function TemplateCard({ id, type, title, status, tags, url }) {
  const isDraft = status?.label === "Draft";

  return (
    <div className="template-card">
      <Link to={url}>
        <div
          key={id}
          className={`template-card__content ${
            isDraft && "template-card__is-draft"
          } `}
        >
          <div className="template-card__photo">
            <img src={"https://picsum.photos/id/1057/267/85"} alt="template" />
            <div className="template-card__draft">{isDraft && "Draft"}</div>
          </div>
          {type && <div className="template-card__type">{type.label}</div>}

          <div className="template-card__name">{title}</div>
          {tags && (
            <div className="template-card__tag-container">
              {tags.map((tag) => (
                <div className="template-card__tag" key={tag.id}>
                  {tag.tag}
                </div>
              ))}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

TemplateCard.propTypes = {
  id: PropTypes.number,
  type: PropTypes.object,
  title: PropTypes.string,
  status: PropTypes.object,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  url: PropTypes.string,
};

export default TemplateCard;
