import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function ErrorModal({ errorMessage, onHide }) {

    return (
        <div className="error-modal">
            <Modal
                show={!!errorMessage}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="error-modal__header"
                    >
                        The following error occurred:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage}
                </Modal.Body>

            </Modal>
        </div>
    );
}

ErrorModal.propTypes = {
    errorMessage: PropTypes.string,
    onHide: PropTypes.func,
  };


export default ErrorModal;