import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import "./TextInput.css";

import RectangleButton from "../../../../Reusable/RectangleButton/RectangleButton";

const initalState = {
  value: "",
};

function TextInput({ show, onHide, textData, submitText }) {
  const [text, setText] = useState(initalState);

  useEffect(() => {
    setText(textData);
  }, [textData]);

  const onChangeText = (e) => {
    setText({ ...text, [e.target.name]: e.target.value });
  };

  const onSubmitText = () => {
    if (submitText) {
      submitText(text);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            name="value"
            className="text-input__input-field"
            onChange={onChangeText}
            placeholder="Add text"
            value={(text && text.value) || ""}
            required
          ></input>
          <div className="text-input__btn-container">
            <RectangleButton onClick={onSubmitText} label="Add" isSmall />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

TextInput.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  textData: PropTypes.object,
  onChange: PropTypes.func,
};

export default TextInput;
