import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";
import classnames from "classnames";
import conversationIcon from '../../../images/no-assignee.png';

import "./ItineraryRow.css";

function ItineraryRow({
  id,
  travellers,
  departure_date,
  return_date,
  travel_party,
  tasks,
  dossier_id,
  description,
  status_label,
  branch,
  itinerary_type,
  conversations
}) {
  let dep_day = new Date(departure_date);
  let current_date = new Date();
  let ret_date = new Date(return_date);
  let total = ret_date.getTime() - dep_day.getTime();
  let current = current_date.getTime() - dep_day.getTime();
  let remaining = ret_date.getTime() - current_date.getTime();
  let now = (current / total) * 100;
  let days_remaining = Math.floor(remaining / (24 * 60 * 60 * 1000));

  if (days_remaining < 1) {
    days_remaining = 0;
  }

  function getInitials(agentName) {
    var initials = agentName.charAt(0);
    if (typeof agentName.split(' ')[1] != 'undefined') {
      initials += agentName.split(' ')[1].charAt(0);
    }
    return initials;
  }
  return (
    <>
      <div key={id} className="itinerary-row">
        <Link key={id} to={`/itineraries/${id}`}>
          <div className="itinerary-row__body">
            <div className="itenerary-row__booking-number">
              {dossier_id ? dossier_id : "missing!"}
            </div>
            <div className="itinerary-row__customer">
              {/* <div className="itinerary-row__icon"></div> */}
              <div className="itinerary-row__name">
                {branch && branch.branch_name}
              </div>
              <div className="itinerary-row__travel-party">{travel_party}</div>
            </div>
            <div className="itinerary-row__trip">{description}</div>
            <div className="itinerary-row__trip">{itinerary_type}</div>
            <div className="itinerary-row__start-date">{departure_date}</div>
            <div className="itinerary-row__end-date">{return_date}</div>
            <div
              className={classnames(
                "itinerary-row__status",
                status_label.title
              )}
            >
              {status_label.title}
            </div>

            <div className="itinerary-row__progress">
              {typeof conversations != 'undefined' && typeof conversations[0] != 'undefined' && conversations[0]?.language?.name
                  ? <span>{conversations[0].language.name}</span>
                  : '-'
              }
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}

ItineraryRow.propTypes = {
  id: PropTypes.number,
  traveller: PropTypes.object,
  departure_date: PropTypes.string,
  return_date: PropTypes.string,
  travel_party: PropTypes.object,
  tasks: PropTypes.array,
  dossier_id: PropTypes.string,
  description: PropTypes.string,
  status_label: PropTypes.object,
};

export default ItineraryRow;
