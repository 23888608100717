import React, { useState, useContext, useEffect } from "react";
import Task from "../TaskComponents/Task/Task";
import './TaskContainer.css'
import { TasksContext } from "../../../store/context/TasksContext";
import Paginator from "../../Reusable/Paginator/Paginator";
import { AppContext } from "../../../store/context/AppContext";

const CompletedTaskContainer = ({ title }) => {
  const { state, actions } = useContext(TasksContext);
  const [titleContainer] = useState(title);
  const [sortDate, setsortDate] = useState('asc');
  const [completedPage, setCompletedPage] = useState(1);
  const { pusher } = useContext(AppContext);
  const [ user ] = useState(JSON.parse(localStorage.getItem('access_token')));

  const itineraryTasksIds = [2, 3, 4, 5, 6, 14];

  useEffect(() => {
    setCompletedPage(state.completedPage);
  }, [state.completedPage]);

  useEffect(() => {
    actions.fetchCompletedTasks(state.completedPage, state.filters);

    const channel = pusher.subscribe('inbox');
    channel.bind(process.env.REACT_APP_PUSHER_INBOX_EVENT_NAME, data => {
      if (typeof data.update_tasks != 'undefined' && data.update_tasks && data.user_id !== user.id) {
        actions.fetchCompletedTasks(1, state.filters);
      }
    });

    return () => {
      channel.unbind(process.env.REACT_APP_PUSHER_INBOX_EVENT_NAME)
    }
  }, [state.completedPage, state.completedTasks.completedOrderByDate])

  const handleSortDate = () => {
    if (sortDate == "" || sortDate == "asc") {
      setsortDate('desc');
    } else {
      setsortDate('asc');
    }
    actions.sortDateCompleted(sortDate);
  };

  const getNextPage = () => {
    actions.updatePageIndex('completed', !state.completedTasks
      ? completedPage
      : completedPage + 1
    )
  };

  const getPreviousPage = () => {
    let newPageIndex = Math.max(completedPage - 1, 1)
    actions.updatePageIndex('completed', newPageIndex);  };

  const getPageButton = (pageButton) => {
    actions.updatePageIndex('completed', pageButton);
  };

  return (
    <div id="active-task-container__wrapper">
      <div className="task-container__sorting-options">
        <div>
          <h5 id="task-container__header--active">{titleContainer.label}</h5>
        </div>
        {(state.filters &&
          state.filters.status &&
          state.filters.status[0] &&
          state.filters.status[0].id &&
          itineraryTasksIds.includes(state.filters.status[0].id)) ? (
            <div>
              <button
                onClick={handleSortDate}
                className="task-container__filter-text"
              >
                Sort by: <u>date</u>
              </button>
              {sortDate === "" || sortDate === "asc" ? (
              <i className="itineraries__arrow itineraries__down" />
            ) : (
              <i className="itineraries__arrow itineraries__up" />
            )}
            </div>
          ) : (
            <div>
              </div>
          )}
      </div>
      <div className="task-container__tasks">
        {state.completedTasks.tasks.map((task, key) => {
          return <Task task={task} key={key} titleContainer={titleContainer} />
        })}

        <Paginator
          className="completed-tasks-inbox__paginate"
          page={completedPage}
          getPreviousPage={getPreviousPage}
          getNextPage={getNextPage}
          lastPage={state.completedTasks.completedLastPage}
          getPageButton={getPageButton}
        />
      </div>
    </div>
  )
}


export default CompletedTaskContainer

