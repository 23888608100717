import React from "react";
import { useReducer } from "react";
import { reducer, initialState } from "../reducers/UserRolesReducer";
import { useActions } from "../actions/UserRolesActions";

export const UserRolesContext = React.createContext();

const RolesContextApi = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const actions = useActions(state, dispatch);

    return (
        <UserRolesContext.Provider value={{state, dispatch, actions}}>
            {children}
        </UserRolesContext.Provider>
    );
};

export default RolesContextApi;
