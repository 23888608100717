import React, { useContext, useState } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import TravellerCard from "./TravellerCard/TravellerCard";

import "./TravellersContainer.css";

import DataBlock from "../../../Reusable/DataBlock/DataBlock";
import CircleButton from "../../../Reusable/CircleButton/CircleButton";
import AddTravellerToItinerary from "./AddTravellerToItinerary/AddTravellerToItinerary";

const TravellersContainer = () => {
  const { state } = useContext(ItineraryContext);
  const [showAddTravellerModal, setShowAddTravellerModal] = useState(false);

  const onClickAddTraveller = () => {
    setShowAddTravellerModal(true);
  };

  const onAddTravellerCloseClicked = () => {
    setShowAddTravellerModal(false);
  };

  return (
    <div>
      <DataBlock
        header="TRAVELLER"
        classNameContent="travellers-container"
        content={
          <>
            {state?.itinerary?.data?.conversations && (
              <>
                <div className="traveller-container__traveller-cards">
                  {state.itinerary.data.conversations.map(
                    (conversation, key) => (
                      <TravellerCard
                        key={conversation.id}
                        conversation={conversation}
                        itineraryId={state.itinerary.data.id}
                      />
                    )
                  )}
                </div>
                <CircleButton type="add" onClick={onClickAddTraveller} />
                <AddTravellerToItinerary
                  show={showAddTravellerModal}
                  onHide={onAddTravellerCloseClicked}
                  itineraryId={state.itinerary.data.id}
                />
              </>
            )}
          </>
        }
      />
    </div>
  );
};

export default TravellersContainer;
