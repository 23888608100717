import React, { useState } from "react";
import { getToken } from "../../utils/auth";
import homeBtn from "../../images/LogoFull@2x.png";
import avatarWoman from "../../images/agent-avatar.png";
import LogoutModal from "./LogoutModal/LogoutModal";

import "./Header.css";

import RectangleButton from "../../Components/Reusable/RectangleButton/RectangleButton";

const Header = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const onClickLogout = () => {
    setShowLogoutModal(true);
  };

  const onLogoutClose = () => {
    setShowLogoutModal(false);
  };

  return (
    <div className="header">
      <img className="header__home-btn" src={homeBtn} alt="home" />
      <div className="header__container">
        <img className="header__avatar-woman" src={avatarWoman} alt="avatar" />
        <div>{getToken().user_name}</div>
        <RectangleButton 
          isSmall
          isHighlight
          onClick={onClickLogout}
          label='Log out'
          className="header__logout-btn"
        />
        <LogoutModal show={showLogoutModal} onHide={onLogoutClose} />
      </div>
    </div>
  );
};

export default Header;
