import axios from "axios";
import { types } from "../reducers/DashboardReducer";

export const useActions = (state, dispatch) => {
  function fetchLastUpdates(startDate, endDate) {
    const url =
      process.env.REACT_APP_BASEURL +
      "/api/messages/errors/" +
      startDate +
      "/" +
      endDate;
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.FETCH_MESSAGE_ERRORS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchClients() {
    const url = process.env.REACT_APP_BASEURL + "/api/companies";
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.FETCH_CLIENTS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function importedFilesLogs() {
    const url = process.env.REACT_APP_BASEURL + "/api/imported-files/logs";
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.FETCH_IMPORTED_FILES_LOGS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchOverviewChangedTemplates() {
    const url = process.env.REACT_APP_BASEURL + "/api/imported-files/logs";
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.FETCH_CHANGED_TEMPLATES,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchImportedSheets() {
    const url = process.env.REACT_APP_BASEURL + "/api/imported-sheets";
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.FETCH_IMPORTED_SHEETS,
          payload: response.data.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchOverviewScheduledMessages(date = null) {
    let url = process.env.REACT_APP_BASEURL + "/api/journey-scheduled-articles/overview";

    if (date){
      url+= `?date=${date}`;
    }
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.FETCH_OVERVIEW_MESSAGES,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addNewQuickReply(data) {
    const url = process.env.REACT_APP_BASEURL + "/api/conversations/agent-templates/store";
    axios
        .post(url, data)
        .then((response) => {
          if (response.status && response.status === 200) {
            dispatch({
              type: types.SHOW_SUCCESS,
              payload:  'Quick reply created successfully',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: types.SHOW_ERRORS,
            payload:  'Failed to create a quick reply',
          });
        });
  }
  function addNewQuickReplyFile(data) {
    const url = process.env.REACT_APP_BASEURL + "/api/conversations/agent-templates/import-file";
    axios
        .post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          if (response.status && response.status === 200) {
            dispatch({
              type: types.SHOW_SUCCESS,
              payload:  'Quick reply created successfully',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: types.SHOW_ERRORS,
            payload:  'Failed to create a quick reply',
          });
        });
  }
  function clearSuccess() {
    const emptySuccess = "";
    dispatch({
      type: types.SHOW_SUCCESS,
      payload: emptySuccess,
    });
  }
  function clearErrors() {
    const emptyErrors = "";
    dispatch({
      type: types.SHOW_ERRORS,
      payload: emptyErrors,
    });
  }


  return {
    fetchLastUpdates,
    fetchClients,
    importedFilesLogs,
    fetchOverviewChangedTemplates,
    fetchImportedSheets,
    fetchOverviewScheduledMessages,
    addNewQuickReply,
    addNewQuickReplyFile,
    clearSuccess,
    clearErrors
  };
};
