import React, { useState, useContext } from "react";
import { Modal, Form } from "react-bootstrap";
import { ItineraryContext } from "../../../store/context/ItineraryContext";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { AppContext } from "../../../store/context/AppContext";
import Select from "react-select";

import "./AddItinerary.css";

import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

const initialState = {
  dossier_id: "",
  departure_date: "",
  return_date: "",
  branch_id: null,
};

function AddItinerary({ show, onHide, history }) {
  const [itinerary, setItinerary] = useState(initialState);
  const { actions } = useContext(ItineraryContext);
  const { state: AppState } = useContext(AppContext);

  const onSubmitItinerary = (e) => {
    e.preventDefault();
    actions.addNewItinerary(itinerary, history);
    setItinerary(initialState);
  };

  const onChangeItinerary = (e) => {
    setItinerary({ ...itinerary, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          New Itinerary
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="add-itinerary__form" onSubmit={onSubmitItinerary}>
          <Form.Group>
            <Form.Control
              required
              type="text"
              name="dossier_id"
              placeholder="Enter booking number"
              value={itinerary.dossier_id}
              onChange={onChangeItinerary}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="date"
              name="departure_date"
              placeholder="Enter departure date (YYYY/MM/DD)"
              value={itinerary.departure_date}
              onChange={onChangeItinerary}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="date"
              name="return_date"
              placeholder="Enter return date (YYYY/MM/DD)"
              value={itinerary.return_date}
              onChange={onChangeItinerary}
            />
          </Form.Group>
          <Form.Group>
            <Select
              name="branch_id"
              placeholder="Select branch"
              options={AppState.inputs.companies}
              getOptionLabel={(option) => `${option.branch_name}`}
              getOptionValue={(option) => `${option.id}`}
              value={itinerary.branch}
              onChange={(data) =>
                setItinerary({
                  ...itinerary,
                  branch_id: data.id,
                })
              }
            />
          </Form.Group>

          <div className="add-itinerary__btn">
            <RectangleButton type="submit" label="Next" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

AddItinerary.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(AddItinerary);
