import React from "react";
import "./TaskTemplateComponent.css";

const TaskTemplateComponent = ({ task }) => {
  return (
    <div className="task-template__container">
      <p className="task-template__title">
        {"Template name: "}{" "}
        {task.taskable && task.taskable.journey_details ? (
          <>{task.taskable.journey_details.title}</>
        ) : (
          <>{task.taskable.title}</>
        )}
      </p>
    </div>
  );
};

export default TaskTemplateComponent;
