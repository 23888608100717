import React, {useContext, useEffect, useState} from "react";
import { TipsContext } from "../../../store/context/TipsContext";
import {Dropdown, Modal} from "react-bootstrap";
import { withRouter } from "react-router";
import TipForm from "./TipForm";
import PropTypes from "prop-types";

import "../TipForm/TipForm.css";

import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";

function EditTip({ history, onClickCloseTipDetails, onHide, show }) {
  const { state, actions } = useContext(TipsContext);
  const [isDeleteTipShown, setIsDeleteTipShown] = useState(false);

  useEffect(() => {
    if(typeof state.tip.data.id != 'undefined') {
      actions.getItinerariesByTipId(state.tip.data.id);
      actions.getJourneysByTipId(state.tip.data.id);
    }
  }, [state.tip.data.id]);

  const onSubmitEditedTip = (data) => {
    actions.editTip(data, onHide, history);
  };

  const onClickDeleteTipOpen = () => {
    setIsDeleteTipShown(true);
  };

  const onClickDeleteTipClose = () => {
    setIsDeleteTipShown(false);
  };

  const onClickDeleteTip = (e) => {
    onClickCloseTipDetails();
    actions.deleteTip(state.tip.data.id);
  };

  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Edit Tip</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.tip.data && !isDeleteTipShown ? (
          <TipForm
            data={state.tip.data}
            onTipSubmit={onSubmitEditedTip}
            button={{
              label: "Delete",
              className: "tip-form__delete-btn",
              action: onClickDeleteTipOpen,
            }}
          />
        ) : (
          <div className="tip-form__delete-container">
            <div className="tip-form__delete-text">
              <p>Are you sure you want to delete?</p>
              <p>These journeys and itineraries include this tip:</p>
              <div>
                <p className="delete_info">Itineraries</p>
                <ul>
                  {state.itineraries.map((itinerary) => (
                      <li><a href={itinerary.id}>{itinerary.description}</a></li>
                  ))}
                </ul>

                <p className="delete_info">Journeys</p>
                <ul>
                  {state.journeys.map((journey) => (
                      <li><a href={journey.id}>{journey.title}</a></li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="tip-form__delete-btns">
              <RectangleButton
                onClick={onClickDeleteTip}
                label="Delete"
                className="tip-form__delete-btn"
              />
              <RectangleButton onClick={onClickDeleteTipClose} label="Close" />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

EditTip.propTypes = {
  history: PropTypes.object.isRequired,
  onClickCloseTipDetails: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
export default withRouter(EditTip);
