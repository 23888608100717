import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import InboxIcon from "../../images/IconInbox.png";
import ConversationIcon from "../../images/IconConversations.png";
import ItineraryIcon from "../../images/IconJourney.png";
import TemplatesIcon from "../../images/IconTemplates.png";
import TipsIcon from "../../images/IconSuggestions.png";
import KpiIcon from "../../images/IconKpi.png";
import DashboardIcon from "../../images/DashboardIcon.svg";
import RolesPermissions from "../../images/RolesPermissions.png";
import { getToken } from "../../utils/auth";

import "./Navbar.css";

const Navbar = () => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showConversationSubMenu, setShowConversationSubMenu] = useState(false);

  const onClickShowSubMenu = () => {
    if (!showSubMenu) {
      setShowSubMenu(true);
    } else {
      setShowSubMenu(false);
    }
  };

  const onClickShowConversationSubMenu = () => {
      console.log(getToken());
    if (!showConversationSubMenu) {
        setShowConversationSubMenu(true);
    } else {
        setShowConversationSubMenu(false);
    }
  };

  return (
    <nav className="nav-bar">
      <NavLink className="nav-bar__link" to="/inbox">
        <img src={InboxIcon} alt="inbox" /> Inbox
      </NavLink>
      <NavLink className="nav-bar__link nav-conversation" to="/conversations">
        <img src={ConversationIcon} alt="conversations" />{" "}
          <div onClick={onClickShowConversationSubMenu}> Conversations </div>
      </NavLink>
        {getToken().is_admin && showConversationSubMenu ? (
            <>
                <NavLink
                    className="nav-bar__link sub-nav-conversation"
                    to="/bulk-message"
                >
                    <img src={ConversationIcon} alt="massive-message-send" /> Bulk Upload & Send
                </NavLink>
            </>
        ) : (
            ""
        )}
      <NavLink className="nav-bar__link" to="/itineraries">
        <img src={ItineraryIcon} alt="itineraries" /> Itinerary
      </NavLink>
      <NavLink className="nav-bar__link nav-templates" to="/templates">
        <img src={TemplatesIcon} alt="templates" />{" "}
        <div onClick={onClickShowSubMenu}> Templates </div>
      </NavLink>
      {getToken().is_admin && showSubMenu ? (
        <>
          <NavLink
            className="nav-bar__link sub-nav-import-templates"
            to="/import-templates"
          >
            <img src={TemplatesIcon} alt="import-templates" /> Import templates
          </NavLink>
          <NavLink
            className="nav-bar__link sub-nav-import-templates"
            to="/create-automated-templates"
          >
            <img src={TemplatesIcon} alt="create-automated-templates" /> Import and create templates
          </NavLink>
          <NavLink
            className="nav-bar__link sub-nav-import-templates"
            to="/import-wafb-templates"
          >
            <img src={TemplatesIcon} alt="import-wafb-templates" /> Import WaFB templates
          </NavLink>
        </>
      ) : (
        ""
      )}
      <NavLink className="nav-bar__link" to="/tips">
        <img src={TipsIcon} alt="tips" /> Tips
      </NavLink>
      <NavLink className="nav-bar__link" to="/kpi">
        <img src={KpiIcon} alt="kpi" /> Kpi
      </NavLink>
      {getToken().is_admin ? (
        <NavLink className="nav-bar__link" to="/users-roles">
          <img src={RolesPermissions} alt="tips" /> Users & Roles
        </NavLink>
      ) : (
        ""
      )}

      <NavLink className="nav-bar__link" to="/dashboard">
        <img src={DashboardIcon} alt="dashboard" /> Dashboard
      </NavLink>
    </nav>
  );
};

export default Navbar;
