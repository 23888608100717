import React, {useContext, useEffect, useState} from 'react';
import './ConversationDetailsClosed.css'
import { useParams } from "react-router";
import ConversationMessages from "../ConversationMessenger/ConversationMessages";
import {Modal, Row, Col, Button} from 'react-bootstrap';
import {ConversationContext} from "../../../store/context/ConversationContext";
import ClosedConversationTabs from "./ClosedConversationTabs/ClosedConversationTabs";

function ConversationDetails() {
    const { state, actions } = useContext(ConversationContext);
    const params = useParams();

    useEffect(() => {
        actions.fetchCurrentConversation(params.id);
    }, []);

    const [show, setShow] = useState(false);
    const handleShowCloseConversation = () => setShow(false);
    const handleShowDeleteConversation = () => setShow(true);

    return (
        <div>
            <Row className="open-conversations-row">
                <Col sm="12">
                    <div className="conversation-open">
                        <Button onClick={() => actions.openConversation(params.id)}>Open Conversation</Button>
                    </div>

                    <div className="conversation-delete">
                        <Button onClick={handleShowDeleteConversation}>Delete Conversation</Button>
                    </div>

                    <Modal show={show} onHide={handleShowCloseConversation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete the conversation?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Do you really want to delete this conversation?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleShowCloseConversation}>
                                No
                            </Button>
                            <Button className="delete-button" onClick={() => actions.deleteConversation(params.id)}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
            <Row className="open-conversations-row">
                <Col sm="7">
                    { state?.currentConversation?.id && <ConversationMessages /> }
                </Col>
                <Col sm="5">
                    { state?.currentConversation?.id && <ClosedConversationTabs /> }
                </Col>
            </Row>
        </div>
    )
}

export default ConversationDetails;
