import React, {useEffect, useState} from 'react';
import conversationIcon from '../../images/no-assignee.png'
import './ConversationCard.css'
import {Row, Col} from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

const ConversationCard = ({conversation}) => {
    const [lastMessage, setLastMessage] = useState(null);
    const [dateIncomingMessage, setDateIncomingMessage] = useState(null);

    const MAX_LENGTH = 80;

    useEffect(() => {
        if (conversation?.latest_message) {
            let messageToString = conversation.latest_message;
            if (messageToString.file_mimetype && messageToString.file_mimetype === "html"){
                setLastMessage(ReactHtmlParser(messageToString.message.replace(/(<([^>]+)>)/gi, "")).slice(-1).pop());
            } else {
                setLastMessage(messageToString.message)
            }
            let newDate = new Date(conversation.last_message_time);
            setDateIncomingMessage(newDate.toLocaleString("default", {weekday: "long"}));
        }
    }, [conversation]);


    function getInitials(agentName) {
        var initials = agentName.charAt(0);
        if (typeof agentName.split(' ')[1] != 'undefined') {
            initials += agentName.split(' ')[1].charAt(0);
        }
        return initials;
    }

    return (
        <div className="conversation col-sm-12">
            <div className="conversation-container">
                {conversation?.agent?.avatar && conversation.agent.avatar.charAt(0) === '#' ?
                    <div className="agent_icon"
                         style={{backgroundColor: conversation.agent.avatar}}>{getInitials(conversation.agent.name)}</div>
                    : <img src={conversationIcon} alt='icon'/>}
                <div className="conversation-information">
                    {conversation?.traveller?.full_name ?

                        <Row>
                            <Col sm="9" className="p-0">
                                <p className="conversation-traveler-name">{conversation.traveller.full_name}</p>
                                {lastMessage != null ?
                                    <div>
                                        {lastMessage.length > MAX_LENGTH ?
                                            <div className="conversation-text">
                                                {`${lastMessage.substring(0, MAX_LENGTH).replace(/<br \/>/gi, " ")}...`}
                                            </div>
                                            :
                                            <div className="conversation-text">
                                                <div>{lastMessage.length > 0 ? lastMessage.replace(/<br \/>/gi, " ") : 'file'}</div>
                                            </div>
                                        }
                                    </div>
                                    : ''}
                            </Col>

                            <Col sm="3" className="text-center">
                                <p className="conversation-date-incoming-message">{dateIncomingMessage}</p>
                                {conversation?.unread_messages && conversation.unread_messages.length > 0 ?
                                    <p id={`unread_${conversation.id}`}
                                       className="conversation-unread">{conversation.unread_messages.length}</p>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                        : ''}
                </div>
            </div>
        </div>
    );
};

export default ConversationCard;
