import React, {useState, useContext, useEffect, useRef} from "react";
import {Button, Form} from "react-bootstrap";
import { ConversationContext } from "../../../src/store/context/ConversationContext";
import { AppContext } from "../../../src/store/context/AppContext";
import { TemplateContext } from "../../../src/store/context/TemplateContext";
import {Col, Row} from "reactstrap";
import RectangleButton from "../Reusable/RectangleButton/RectangleButton";
import './MassiveMessageSend.css'
import ErrorModal from "../Reusable/ErrorModal/ErrorModal";
import SuccessModal from "../Reusable/SuccessModal/SuccessModal";
import Select from "react-select";
import TextInput from "../Templates/EditTemplate/TemplateDay/TextInput/TextInput";
import TipSelector from "../Templates/EditTemplate/TemplateDay/TipSelector/TipSelector";
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TemplateItem from "../Templates/EditTemplate/TemplateItem/TemplateItem";
import AutoCompleteLocation from "../AutoCompleteLocation";
import {Link} from "react-router-dom";
import { Modal } from "react-bootstrap";

const MassiveMessageSend = () => {
    const { state: ConversationState, actions } = useContext(ConversationContext);
    const { state: AppState, AppActions } = useContext(AppContext);
    const { state: TemplateState, actions: TemplateActions } = useContext(TemplateContext);

    /**
     * Filters
     */
    const [branchIds, setBranchIds] = useState(null);
    const [channelIds, setChannelIds] = useState(null);
    const [departureDate, setDepartureDate] = useState(null);
    const [returnDate, setReturnDate] = useState(null);
    const [countryId, setCountryId] = useState(null);
    const [citiesIds, setCitiesIds] = useState(null);

    const [journeysIds, setJourneysIds] = useState(null);
    const [itinerariesCount, setItinerariesCount] = useState(0);
    const [itineraryIds, setItineraryIds] = useState(null);
    const [itineraryStatusIds, setItineraryStatusIds] = useState(null);
    const [itineraryProductIds, setItineraryProductIds] = useState(null);
    const [preferencesIds, setPreferencesIds] = useState(null);
    const [accommodationIds, setAccommodationIds] = useState(null);

    const [interaction, setInteraction] = useState(null);
    const [feedBack, setFeedBack] = useState(null);
    const [nps2, setNps2] = useState('');
    const [leadStatus, setLeadStatus] = useState(null);
    const [location, setLocation] = useState({lat: null, lng: null});
    const [radius, setRadius] = useState(0);


    /**
     * Message
     */
    const [useMessageTemplates, setUseMessageTemplates] = useState(true);
    const [messageTemplate, setMessageTemplate] = useState(null);
    const [name, setName] = useState('');
    const [message, setMessage] = useState(null);
    const [sendAt, setSendAt] = useState(null);
    const [vars, setVars] = useState(null);
    const [selectedText, setSelectedText] = useState(null);
    const [selectedTextId, setSelectedTextId] = useState(null);
    const [selectedTipId, setSelectedTipId] = useState(null);
    const [showTextInput, setShowTextInput] = useState(false);
    const [selectedBulkMessage, setSelectedBulkMessage] = useState(null);
    const [applyBtnText, setApplyBtnText] = useState('Apply');



    useEffect(() => {
        ConversationState.messageTemplates.find((item, key) => {
            if (messageTemplate != null && item.id === messageTemplate && selectedBulkMessage?.message_template_id !== messageTemplate) {
                setVars(item.vars);
                return true;
            }
        });

    }, [ConversationState, messageTemplate, vars, sendAt, AppState]);

    useEffect(() => {
        actions.fetchMessageTemplatesByChannel(branchIds, channelIds);
        actions.fetchAccommodations(citiesIds);
        actions.fetchAnalysisStatus();
        actions.fetchItineraryProducts();

        actions.fetchBulkMessages();
    }, []);


    /**
     * Filter Block 1
     */
    const onChangeBranch = (branches) => {
        setBranchIds(branches.id);
        TemplateActions.fetchChannelsByBranches(branches.id);
        actions.fetchItinerariesByBranches(branches.id);
        actions.fetchJourneysByBranches(branches.id);
        actions.fetchMessageTemplatesByChannel(branches.id, channelIds);
    };

    const onChangeChannel = (channels) => {
        if(channels === null) {
            const channelIds = null;
            setChannelIds(channelIds);
            actions.fetchMessageTemplatesByChannel(branchIds, channelIds);
        } else {
            const channelIds = channels.map(function (channel) {
                return channel.id;
            });
            setChannelIds(channelIds);
            actions.fetchMessageTemplatesByChannel(branchIds, channelIds);
        }
    };

    const onChangeDepartureDate = (e) => {
        setDepartureDate(e.target.value);
    };

    const onChangeReturnDate = (e) => {
        setReturnDate(e.target.value);
    };

    const onChangeCountries = (country) => {
        if(country === null) {
            setCountryId(null);
            actions.fetchCitiesByCountries(null);
        } else {
            setCountryId(country.id);
            actions.fetchCitiesByCountries(country.id);
        }
    };

    const onChangeCities = (cities) => {
        if(cities === null) {
            const citiesIds = null;
            setCitiesIds(citiesIds);
            actions.fetchAccommodations(citiesIds);
        } else {
            const citiesIds = cities.map(function (city) {
                return city.id;
            });
            setCitiesIds(citiesIds);
            actions.fetchAccommodations(citiesIds);
        }
    };


    /**
     * Filter Block 2
     */
    const onChangeJourneys = (journeys) => {
        if(journeys === null) {
            const journeysIds = null;
            setJourneysIds(journeysIds);
        } else {
            const journeysIds = journeys.map(function (journey) {
                return journey.id;
            });
            setJourneysIds(journeysIds);
        }
    };

    const onChangeItinerariesCount = (e) => {
        setItinerariesCount(e.target.value);
    };

    const onChangeItineraries = (itineraries) => {
        if(itineraries === null) {
            const itineraryIds = null;
            setItineraryIds(itineraryIds);
        } else {
            const itineraryIds = itineraries.map(function (itinerary) {
                return itinerary.id;
            });
            setItineraryIds(itineraryIds);
        }
    };

    const onChangeItineraryStatus = (statuses) => {
        if(statuses === null) {
            const statusIds = null;
            setItineraryStatusIds(statusIds);
        } else {
            const statusIds = statuses.map(function (status) {
                return status.value;
            });
            setItineraryStatusIds(statusIds);
        }
    };

    const onChangeItineraryProducts = (products) => {
        if(products === null) {
            const productIds = null;
            setItineraryProductIds(productIds);
        } else {
            const productIds = products.map(function (product) {
                return product.product_name;
            });
            setItineraryProductIds(productIds);
        }
    };

    const onChangePreferences = (preferences) => {
        if(preferences === null) {
            const preferencesIds = null;
            setPreferencesIds(preferencesIds);
        } else {
            const preferencesIds = preferences.map(function (preference) {
                return preference.id;
            });
            setPreferencesIds(preferencesIds);
        }
    };

    const onChangeAccommodations = (accommodations) => {
        if(accommodations === null) {
            const accommodationIds = null;
            setAccommodationIds(accommodationIds);
        } else {
            const accommodationIds = accommodations.map(function (accommodation) {
                return accommodation.id;
            });
            setAccommodationIds(accommodationIds);
        }
    };



    /**
     * Filter Block 3
     */
    const onChangeInteraction = (e) => {
        if(e !== null){
            setInteraction(e.value);
        } else {
            setInteraction(null);
        }
    };

    const onChangeFeedback = (feedbacks) => {
        if(feedbacks === null) {
            const feedBackIds = null;
            setFeedBack(feedBackIds);
        } else {
            const feedBackIds = feedbacks.map(function (feedback) {
                return feedback.value;
            });
            setFeedBack(feedBackIds);
        }
    };

    const onChangeNps2 = (e) => {
        if(e.target.value === '') {
            setNps2(null);
        } else {
            setNps2(e.target.value);
        }
    };

    const onChangeLeadStatus = (leadStatuses) => {
        if(leadStatuses === null) {
            const leadStatusIds = null;
            setLeadStatus(leadStatusIds);
        } else {
            const leadStatusIds = leadStatuses.map(function (leadStatus) {
                return leadStatus.value;
            });
            setLeadStatus(leadStatusIds);
        }
    };

    const onChangeRadius = (e) => {
        setRadius(e.value);
    };

    const onChangeLocation = (data) => {
        setLocation(data);
    };
    const onClickFilterLocation = () => {};
    const onClickClearFilter = () => {};



    /**
     * Other functions
     */
    const onChangeMessageTemplate = (messageTemplate) => {
        setMessageTemplate(messageTemplate);
    };

    const onChangeUseMessageTemplates = (e) => {
        setMessageTemplate(null);
        setVars(null);
        setMessage(null);

        if(selectedBulkMessage != null) {
            setMessageTemplate(selectedBulkMessage.message_template_id);
            setVars(selectedBulkMessage.vars);
            setMessage(selectedBulkMessage.message);
        }
        setUseMessageTemplates(e.target.checked);
    };

    const onChangeSendAt = (e) => {
        setSendAt(e.target.value);
    };

    const onChangeMessage = (e) => {
        setMessage(e.target.value);
    };

    const onChangeName = (e) => {
        setName(e.target.value);
    };

    const clearSuccess = () => {
        actions.clearSuccess();
        setApplyBtnText('Apply');
    };

    const clearError = () => {
        actions.clearError();
    };

    const onClickAddText = (article, key) => {
        setSelectedText(article);
        setSelectedTextId(key);
        setShowTextInput(true);
    };

    const onCloseTextInput = () => {
        setShowTextInput(false);
    };

    const submitText = (text) => {
        vars.find((item) => {
            if (item.key === text.key) {
                item.value = text.value;
                return true;
            }
        });
        setSelectedText(null);
        setSelectedTextId(null);
        setShowTextInput(false);
    };

    const onClickAddTip = (tipId) => {
        setSelectedTipId(tipId);
    };

    const onCloseTipSelector = () => {
        setSelectedTipId(null);
    };

    const submitTip = (tip) => {
        vars[selectedTipId].article_body = tip.body;
        vars[selectedTipId].article_id = tip.id;
        vars[selectedTipId].value = tip.body;
        setSelectedTipId(null);
    };

    const onClickSubmit = (e) => {
        setApplyBtnText('Loading...');

        e.preventDefault();
        var formData = new FormData();

        formData.append('branch_ids', JSON.stringify(branchIds));
        formData.append('channel_ids', JSON.stringify(channelIds));
        if(departureDate !== null) {
            formData.append('departure_date', departureDate);
        }
        if(returnDate !== null) {
            formData.append('return_date', returnDate);
        }
        formData.append('country_id', countryId);
        formData.append('city_ids', JSON.stringify(citiesIds));

        formData.append('journey_ids', JSON.stringify(journeysIds));
        formData.append('itinerary_count', itinerariesCount);
        formData.append('itinerary_ids', JSON.stringify(itineraryIds));
        formData.append('itinerary_status_ids', JSON.stringify(itineraryStatusIds));
        formData.append('itinerary_product', JSON.stringify(itineraryProductIds));
        formData.append('preference_ids', JSON.stringify(preferencesIds));
        formData.append('accommodation_ids', JSON.stringify(accommodationIds));

        formData.append('interaction',  interaction);
        formData.append('feedback',  JSON.stringify(feedBack));
        formData.append('nps_2', nps2);
        formData.append('lead_status',  JSON.stringify(leadStatus));

        formData.append('location', JSON.stringify(location));
        formData.append('radius', radius);

        actions.massiveMessagesSend(formData);
    };


    const onClickSubmitSendMessage = (e) => {
        let conversationIds = [];
        ConversationState.filteredTravellers.forEach((conversation) => {
            conversationIds.push(conversation.id);
        });

        e.preventDefault();
        var formDataSendMessage = new FormData();
        formDataSendMessage.append('conversation_ids', JSON.stringify(conversationIds));
        formDataSendMessage.append('message_template_id', messageTemplate);
        formDataSendMessage.append('name', name);
        formDataSendMessage.append('vars', JSON.stringify(vars));
        formDataSendMessage.append('message', message);
        formDataSendMessage.append('send_at', sendAt);

        actions.sendBulkMessages(formDataSendMessage);
    };
    const onClickSubmitUpdateMessage = (e) => {

        e.preventDefault();
        var formDataSendMessage = new FormData();
        formDataSendMessage.append('bulk_message_id', parseInt(selectedBulkMessage.id));
        formDataSendMessage.append('message_template_id', messageTemplate);
        formDataSendMessage.append('name', name);
        formDataSendMessage.append('vars', JSON.stringify(vars));
        formDataSendMessage.append('message', message);
        formDataSendMessage.append('send_at', sendAt);

        actions.sendBulkMessages(formDataSendMessage);
    };


    /**
     * Modals
     */
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleCloseUpdateModal = () => {
        setShowUpdateModal(false);
        setSelectedBulkMessage(null);
    }
    const openUpdateModal = (bulkMessage) => {

        setShowUpdateModal(true);
        setSelectedBulkMessage(bulkMessage);

        setUseMessageTemplates(bulkMessage.message_template_id !== null);
        setMessageTemplate(bulkMessage.message_template_id);
        setName(bulkMessage.name);
        setVars(bulkMessage.vars);
        setMessage(bulkMessage.message);
        setSendAt(bulkMessage.send_at);

    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedBulkMessage(null);
    };
    const openDeleteModal = (bulkMessage) => {
        setShowDeleteModal(true);
        setSelectedBulkMessage(bulkMessage);
    };


    return (
        <div className="massive-message-send">
            <div className="massive-message-send__header">
                <p className="massive-message-send__title">Bulk Upload & Send</p>
            </div>

            <Tabs
                defaultActiveKey="filters"
                id="uncontrolled-tab-bulk-messages"
                className="mb-3"
            >
                <Tab eventKey="filters" title="Filters">
                    <Row id="filters-collapse-text">
                        <Col className="massive-message-send__form" sm={6}>
                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Branch</Form.Label>
                                <Select
                                    name="branch_id"
                                    placeholder="Select branches"
                                    options={AppState.inputs.companies}
                                    getOptionLabel={(option) => `${option.branch_name}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={(data) =>
                                        onChangeBranch(data)
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Channel</Form.Label>
                                <Select
                                    isMulti
                                    name="channel_id"
                                    placeholder="Select channels"
                                    options={AppState.inputs.channels}
                                    getOptionLabel={(option) => `${option.channel_name}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={(data) =>
                                        onChangeChannel(data)
                                    }
                                />
                            </Form.Group>

                            <Row className="bulk-messages-dates-row">
                                <Col>
                                    <Form.Label className="massive-message-send__form__sheet-name">Departure date</Form.Label>
                                    <Form.Group>
                                        <Form.Control
                                            required
                                            type="datetime-local"
                                            name="departure_date"
                                            placeholder="Enter send at time (YYYY/MM/DD)"
                                            onChange={onChangeDepartureDate}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label className="massive-message-send__form__sheet-name">Return date</Form.Label>
                                    <Form.Group>
                                        <Form.Control
                                            required
                                            type="datetime-local"
                                            name="return_date"
                                            placeholder="Enter send at time (YYYY/MM/DD)"
                                            onChange={onChangeReturnDate}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Destination country</Form.Label>
                                <Select
                                    isClearable
                                    name="country_id"
                                    placeholder="Please select countries"
                                    options={AppState.inputs.countries.data}
                                    getOptionLabel={(option) => `${option.title}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={(data) =>
                                        onChangeCountries(data)
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Destination city <span style={{fontSize: "12px"}}>(Select country to see city options)</span></Form.Label>
                                <Select
                                    isMulti
                                    name="cities_ids"
                                    placeholder="Please select cities"
                                    options={ConversationState.cities}
                                    getOptionLabel={(option) => `${option.title}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    isDisabled={countryId === null}
                                    onChange={(data) =>
                                        onChangeCities(data)
                                    }
                                />
                            </Form.Group>

                            <br></br><hr></hr>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Journeys <span style={{fontSize: "12px"}}>(Select branches to see journeys options)</span></Form.Label>
                                <Select
                                    isMulti
                                    name="journey_ids"
                                    placeholder="Select journey"
                                    options={ConversationState.journeys}
                                    getOptionLabel={(option) => `${option.title}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    isDisabled={branchIds === null}
                                    onChange={(data) =>
                                        onChangeJourneys(data)
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Count of itineraries</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={itinerariesCount}
                                    min={0}
                                    placeholder="Set count of itineraries"
                                    onChange={onChangeItinerariesCount}
                                    name="itineraries_count"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Itinerary (dossier_ids) <span style={{fontSize: "12px"}}>(Please select branches to see itinerary options)</span></Form.Label>
                                <Select
                                    isMulti
                                    name="itinerary_id"
                                    placeholder="Dossier ids"
                                    options={ConversationState.itineraries}
                                    getOptionLabel={(option) => `${option.dossier_id}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    isDisabled={branchIds === null}
                                    onChange={(data) =>
                                        onChangeItineraries(data)
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Itinerary status</Form.Label>
                                <Select
                                    isMulti
                                    name="itinerary_status_ids"
                                    placeholder="Select itinerary status"
                                    options={AppState.inputs.statusItineraries}
                                    getOptionLabel={(option) => `${option.title}`}
                                    getOptionValue={(option) => `${option.value}`}
                                    onChange={(data) =>
                                        onChangeItineraryStatus(data)
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Itinerary products</Form.Label>
                                <Select
                                    isMulti
                                    name="itinerary_product_ids"
                                    placeholder="Select itinerary products"
                                    options={ConversationState.itineraryProducts}
                                    getOptionLabel={(option) => `${option.product_name}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={(data) =>
                                        onChangeItineraryProducts(data)
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Preferences</Form.Label>
                                <Select
                                    isMulti
                                    name="preferences_ids"
                                    placeholder="Select preferences"
                                    options={AppState.inputs.tags}
                                    getOptionLabel={(option) => `${option.tag}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={(data) =>
                                        onChangePreferences(data)
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Accommodations <span style={{fontSize: "12px"}}>(Please select cities to see accommodation options)</span></Form.Label>
                                <Select
                                    isMulti
                                    name="accommodation_ids"
                                    placeholder="Select accommodations"
                                    options={ConversationState.accommodations}
                                    getOptionLabel={(option) => `${option.accomodation_name}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    isDisabled={citiesIds === null}
                                    onChange={(data) =>
                                        onChangeAccommodations(data)
                                    }
                                />
                            </Form.Group>

                            <br></br><hr></hr>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Interaction</Form.Label>
                                <Select
                                    isClearable
                                    name="interaction"
                                    placeholder="Select interaction"
                                    options={ConversationState.interaction}
                                    getOptionLabel={(option) => `${option.label}`}
                                    getOptionValue={(option) => `${option.value}`}
                                    onChange={(data) =>
                                        onChangeInteraction(data)
                                    }
                                />
                            </Form.Group>



                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Feedback response</Form.Label>
                                <Select
                                    isMulti
                                    name="feedback_response"
                                    placeholder="Select feedback response"
                                    options={ConversationState.feedbackSelect}
                                    getOptionLabel={(option) => `${option.label}`}
                                    getOptionValue={(option) => `${option.value}`}
                                    onChange={(data) =>
                                        onChangeFeedback(data)
                                    }
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">NPS 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={nps2}
                                    onChange={onChangeNps2}
                                    placeholder="Set nps 2 value"
                                    name="nps_2"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Lead status</Form.Label>
                                <Select
                                    isMulti
                                    name="lead_status"
                                    placeholder="Select lead status"
                                    options={ConversationState.lead_status}
                                    getOptionLabel={(option) => `${option.label}`}
                                    getOptionValue={(option) => `${option.value}`}
                                    onChange={(data) =>
                                        onChangeLeadStatus(data)
                                    }
                                />
                            </Form.Group>


                            <br></br><hr></hr>





                            <Form.Group className="bulk-message-location">
                                <Form.Label className="massive-message-send__form__sheet-name">Location</Form.Label>
                                <AutoCompleteLocation
                                    onChangeLocation={onChangeLocation}
                                    onClickClearFilter={onClickClearFilter}
                                    onClickFilterLocation={onClickFilterLocation}
                                    locationClassName={{
                                        inputField: "tips__search-location-input",
                                        suggestions: "tips__search-suggestions",
                                    }}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="massive-message-send__form__sheet-name">Radius</Form.Label>
                                <Select
                                    isClearable
                                    name="radius"
                                    placeholder="Select radius"
                                    options={AppState.inputs.radius}
                                    getOptionLabel={(option) => `${option.label}`}
                                    getOptionValue={(option) => `${option.value}`}
                                    onChange={(data) =>
                                        onChangeRadius(data)
                                    }
                                />
                            </Form.Group>


                            <div className="massive-message-send__btn">
                                <RectangleButton type="submit" label={applyBtnText} className="apply-btn" isHighlight onClick={onClickSubmit} />
                            </div>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="users" title="Users">
                    <Row>
                        <Col>
                            <br/>
                            <div className="users-table">
                                <Table striped bordered hover >
                                    <thead>
                                    <tr>
                                        <th>Conversation ID</th>
                                        <th>Channel</th>
                                        <th>Itineraries</th>
                                        <th>Preferences</th>
                                        <th>Count of itineraries</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {ConversationState.filteredTravellers.map((conversation, key) => (
                                        <tr key={key}>
                                            <td>
                                                <Link
                                                    target="_blank"
                                                    key={conversation.id}
                                                    to={`/conversations/open/${conversation.id}`}>{conversation.id}</Link>
                                            </td>

                                            <td>{conversation.company_channel?.channel?.channel_name ?? '-'}</td>

                                            <td>
                                                {conversation.itineraries.map((itinerary, key) => (
                                                    <div key={key}>
                                                            <div className="itineraries-block">
                                                                <p>Dossier_id:{itinerary.dossier_id}</p>
                                                                <p>
                                                                    {typeof AppState.inputs.statusItineraries !== 'undefined' ?
                                                                        <span>{AppState.inputs.statusItineraries.map((status, key) => (
                                                                            <span key={key}>{status.value === itinerary.status ?
                                                                                'Status: ' + status.title
                                                                            : ''}</span>
                                                                        ))}</span>
                                                                        : ''}
                                                                </p>
                                                                <p>Branch: {itinerary.branch?.branch_name ?? '-'}</p>
                                                                <p>Dates: {itinerary.departure_date} - {itinerary.return_date}</p>
                                                                <p>Journey: {itinerary?.journey?.title}</p>
                                                                <p>Description:{itinerary.description}</p>

                                                                <p>Interaction: {ConversationState.interaction.map((interaction, key) => (
                                                                    <span key={key}>{interaction.value == itinerary?.analysis?.interaction ? interaction.label : ''}</span>
                                                                ))}</p>

                                                                <p>Feedback: {ConversationState.feedbackSelect.map((feedback, key) => (
                                                                    <span key={key}>{feedback.value == itinerary?.analysis?.feedback ? feedback.label : ''}</span>
                                                                ))}</p>

                                                                <p>NPS 2 :{itinerary?.analysis?.feedback_rating}</p>

                                                                <p>Feedback: {ConversationState.lead_status.map((lead_status, key) => (
                                                                    <span key={key}>{lead_status.value == itinerary?.analysis?.lead_status ? lead_status.label : ''}</span>
                                                                ))}</p>

                                                                <div>
                                                                    Itinerary products:
                                                                    <ul>{itinerary.itinerary_products.map((itinerary_product, key) => (
                                                                    <li key={key}>{itinerary_product.product_name}</li>
                                                                ))}</ul>
                                                                </div>

                                                                <div>
                                                                    Accommodations:
                                                                    <ul>{itinerary.accommodations.map((accommodation, key) => (
                                                                        <li key={key}>{accommodation.accomodation_name}</li>
                                                                    ))}</ul>
                                                                </div>

                                                                <div>{key !== conversation.itineraries.length -1 ? <hr></hr> : ''}</div>
                                                            </div>
                                                    </div>

                                                ))}
                                            </td>

                                            <td>
                                                <ul>{conversation.tags.map((tag, key) => (
                                                    <li key={key}>{tag.tag}</li>
                                                ))}</ul>
                                            </td>
                                            <td>{conversation.itineraries.length}</td>


                                        </tr>
                                    ))}


                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="message" title="Message">
                    <Row>
                        <Col sm={6}>
                            <Form.Group controlId="massive-message-send__form__sheet-name">
                                <Form.Label className="massive-message-send__form__sheet-name">Name</Form.Label>
                                <Form.Group>
                                    <input
                                        className="massive-message-send-name"
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={name}
                                        onChange={onChangeName}
                                    />
                                </Form.Group>
                            </Form.Group>

                            <div>
                                <Form.Group controlId="massive-message-send__form__sheet-name">
                                    <Form.Label className="massive-message-send__form__sheet-name">Message Templates</Form.Label>
                                    <Select
                                        className="template-day__select-whatsapp"
                                        placeholder="Select a whatsapp template to continue"
                                        options={ConversationState.messageTemplates}
                                        getOptionLabel={(option) => `${option.title} :: ${option.body} `}
                                        getOptionValue={(option) => `${option.id}`}
                                        onChange={(data) =>
                                            onChangeMessageTemplate(data.id)
                                        }
                                    />
                                </Form.Group>

                                {vars?.map((article, key) => (
                                    <div key={key}>
                                        {article.var_is_message ?
                                            <TemplateItem
                                                labelKey={`{{${key + 1}}}`}
                                                copy={article.article_body}
                                                label="Add Tip"
                                                buttonAction={() => onClickAddTip(key)}
                                            />
                                            :
                                            <TemplateItem
                                                labelKey={`{{${key + 1}}}`}
                                                copy={article.value}
                                                label="Add Text"
                                                buttonAction={() => onClickAddText(article, key)}
                                            />
                                        }
                                    </div>
                                ))}
                            </div>

                            {typeof selectedTipId === "number" && selectedText === null && (
                                <TipSelector
                                    show={typeof selectedTipId === "number" && selectedText === null}
                                    onHide={onCloseTipSelector}
                                    submitTip={submitTip}
                                />
                            )}
                            {showTextInput && (
                                <TextInput
                                    show={showTextInput}
                                    onHide={onCloseTextInput}
                                    textData={selectedText}
                                    submitText={submitText}
                                />
                            )}
                            <SuccessModal successMessage={ConversationState.success} onHide={clearSuccess} />
                            <ErrorModal errorMessage={ConversationState.errors} onHide={clearError} />

                            <Form.Group controlId="massive-message-send__form__sheet-name">
                                <Form.Label className="massive-message-send__form__sheet-name">Send time</Form.Label>
                                <Form.Group>
                                    <Form.Control
                                        required
                                        type="datetime-local"
                                        name="send_at"
                                        placeholder="Enter send at time (YYYY/MM/DD)"
                                        onChange={onChangeSendAt}
                                    />
                                </Form.Group>
                            </Form.Group>

                            <div className="massive-message-send__btn">
                                <RectangleButton type="submit" label="Send messages" isHighlight onClick={onClickSubmitSendMessage} />
                            </div>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="dashboard" title="Dashboard">
                    <Row>
                        <Col sm={12}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Bulk messages name</th>
                                    <th>Send at</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>

                                    {ConversationState.bulkMessages.map((bulkMessage, key) => (
                                        <tr key={key}>
                                            <td>{bulkMessage.id}</td>
                                            <td>{bulkMessage.name}</td>
                                            <td>{bulkMessage.send_at}</td>
                                            <td>
                                                <p>Successfully sent: {bulkMessage.successfully}</p>
                                                <p>Failed:  {bulkMessage.failed}</p>
                                                <p>Total:  {bulkMessage.total}</p>
                                            </td>

                                            {new Date(bulkMessage.send_at).getTime() > (new Date()).getTime() ?
                                                <td>
                                                    <RectangleButton className="actions-btn" type="button" label="Update" isHighlight onClick={() => openUpdateModal(bulkMessage)} />
                                                    <RectangleButton className="actions-btn" type="button" label="Delete" isHighlight onClick={() => openDeleteModal(bulkMessage)} />
                                                </td>
                                                : <td>Messages are sent</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>


                            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} size="md">
                                <Modal.Header closeButton>
                                    <Modal.Title>Update bulk messages</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <label className="conversation-task-label">
                                            <input className="conversation-task-checkbox-input"
                                                   type="checkbox"
                                                   checked={useMessageTemplates}
                                                   onChange={onChangeUseMessageTemplates}
                                            />Do you want to use message templates?
                                        </label>

                                        <Form.Group controlId="massive-message-send__form__sheet-name">
                                            <Form.Label className="massive-message-send__form__sheet-name">Name</Form.Label>
                                            <Form.Group>
                                                        <input
                                                            className="massive-message-send-name"
                                                            type="text"
                                                            name="name"
                                                            placeholder="Name"
                                                            value={name}
                                                            onChange={onChangeName}
                                                        />
                                            </Form.Group>
                                        </Form.Group>

                                        {useMessageTemplates ?
                                            <div>
                                                <Form.Group controlId="massive-message-send__form__sheet-name">
                                                    <Form.Label className="massive-message-send__form__sheet-name">Message Templates</Form.Label>
                                                    <Select
                                                        className="template-day__select-whatsapp"
                                                        placeholder="Select a whatsapp template to continue"
                                                        options={ConversationState.messageTemplates}
                                                        getOptionLabel={(option) => `${option.title} :: ${option.body} `}
                                                        getOptionValue={(option) => `${option.id}`}
                                                        value={ConversationState.messageTemplates.find(obj => obj.id == messageTemplate) ?? ''}
                                                        onChange={(data) =>
                                                            onChangeMessageTemplate(data.id)
                                                        }
                                                    />
                                                </Form.Group>

                                                {vars?.map((article, key) => (
                                                    <div key={key}>
                                                        {article.var_is_message ?
                                                            <TemplateItem
                                                                labelKey={`{{${key + 1}}}`}
                                                                copy={article.value}
                                                                label="Add Tip"
                                                                buttonAction={() => onClickAddTip(key)}
                                                            />
                                                            :
                                                            <TemplateItem
                                                                labelKey={`{{${key + 1}}}`}
                                                                copy={article.value}
                                                                label="Add Text"
                                                                buttonAction={() => onClickAddText(article, key)}
                                                            />
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            : ''}


                                        {typeof selectedTipId === "number" && selectedText === null && (
                                            <TipSelector
                                                show={typeof selectedTipId === "number" && selectedText === null}
                                                onHide={onCloseTipSelector}
                                                submitTip={submitTip}
                                            />
                                        )}
                                        {showTextInput && (
                                            <TextInput
                                                show={showTextInput}
                                                onHide={onCloseTextInput}
                                                textData={selectedText}
                                                submitText={submitText}
                                            />
                                        )}
                                        <SuccessModal successMessage={ConversationState.success} onHide={clearSuccess} />
                                        <ErrorModal errorMessage={ConversationState.errors} onHide={clearError} />



                                        {!useMessageTemplates ?
                                            <div>
                                                <Form.Group controlId="massive-message-send__form__sheet-name">
                                                    <Form.Label className="massive-message-send__form__sheet-name">Message</Form.Label>
                                                    <Form.Group>
                                                        <textarea
                                                            className="message-textarea"
                                                            name="message"
                                                            placeholder="Text message"
                                                            value={message}
                                                            onChange={onChangeMessage}
                                                        />
                                                    </Form.Group>
                                                </Form.Group>
                                            </div>
                                            : "" }


                                        <Form.Group controlId="massive-message-send__form__sheet-name">
                                            <Form.Label className="massive-message-send__form__sheet-name">Send time</Form.Label>
                                            <Form.Group>
                                                <Form.Control
                                                    required
                                                    type="datetime-local"
                                                    name="send_at"
                                                    value={sendAt}
                                                    placeholder="Enter send at time (YYYY/MM/DD)"
                                                    onChange={onChangeSendAt}
                                                />
                                            </Form.Group>
                                        </Form.Group>

                                        <div className="massive-message-send__btn">
                                            <RectangleButton type="submit" label="Send messages" isHighlight onClick={onClickSubmitUpdateMessage} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} size="md">
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete bulk messages</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Button className="delete-button" onClick={() => actions.deleteBulkMessages(selectedBulkMessage.id)}>
                                        Yes
                                    </Button>
                                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                        No
                                    </Button>
                                </Modal.Body>
                            </Modal>

                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </div>
    );
};

export default MassiveMessageSend;
