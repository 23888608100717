import React, { useState, useEffect, useContext } from "react";
import { TemplateContext } from "../../../store/context/TemplateContext";
import TemplateCard from "./TemplateCard/TemplateCard";
import AddTemplate from "../AddTemplate/AddTemplate";
import TemplatesSearchBar from "./TemplatesSearchBar/TemplatesSearchBar";

import "./Templates.css";

import ErrorModal from "../../Reusable/ErrorModal/ErrorModal";
import Paginator from "../../Reusable/Paginator/Paginator";
import CircleButton from "../../Reusable/CircleButton/CircleButton";

const Templates = () => {
  const { state, actions } = useContext(TemplateContext);
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    actions.fetchTemplates(pageIndex, state.filters);
  }, [pageIndex]);

  const getNextPage = () => {
    setPageIndex(
      state.templates || state.templates.nextPageUrl ? pageIndex + 1 : pageIndex
    );
  };

  const getPreviousPage = () => {
    setPageIndex((old) => Math.max(old - 1, 1));
  };

  const setPageNumber = (pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onClickAddTemplate = () => {
    setShowAddTemplate(true);
  };

  const onCloseAddTemplate = () => {
    setShowAddTemplate(false);
  };

  const clearError = () => {
    actions.clearError();
  };

  return (
    <div className="templates">
      <div className="templates__header">
        <p className="templates__title">Templates</p>
        <TemplatesSearchBar />
      </div>
      <div className="templates__table">
        {state.templates.allTemplates.map((template) => (
          <TemplateCard
            {...template}
            key={template.id}
            url={`/templates/${template.id}`}
          />
        ))}
      </div>
      <Paginator
        className="--templates__paginate"
        page={pageIndex}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        lastPage={state.templates.lastPage}
        getPageButton={setPageNumber}
      />
      <div>
        <CircleButton
          type="add"
          className="templates__new-template-btn"
          onClick={onClickAddTemplate}
        />
      </div>
      <ErrorModal errorMessage={state.errors} onHide={clearError} />
      <AddTemplate show={showAddTemplate} onHide={onCloseAddTemplate} />
    </div>
  );
};

export default Templates;
