import Axios from "axios";
import { types } from "../reducers/ItineraryReducer";

const OPEN_ITINERARIES_URL = `api/itineraries?page=`;
const CLOSED_ITINERARIES_URL = `api/itineraries/closed?page=`;

export const useActions = (state, dispatch) => {
  const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    withCredentials: true,
  });

  function fetchItineraries(page = 1, filters = {}, fetchClosedItineraries = false) {
    const baseUrl = fetchClosedItineraries
      ? CLOSED_ITINERARIES_URL
      : OPEN_ITINERARIES_URL;
    let url = `${baseUrl}${page}`;

    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "undefined") continue;
      if (value === null) continue;
      if (typeof value === "string" && !value.length) continue;
      url += `&${key}=${value}`;
    }

    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        const newItineraries = Object.assign({}, state.itineraries, {
          allItineraries: response.data.data,
          nextPageUrl: response.data.next_page_url,
          lastPageUrl: response.data.last_page_url,
          lastPage: response.data.last_page,
        });
        dispatch({ type: types.UPDATE_ITINERARIES, payload: newItineraries });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to fetch the itineraries from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function fetchJourneyTemplates(branchId) {
    axios({
      method: "GET",
      url: process.env.REACT_APP_BASEURL + `/api/journeys/${branchId}/dropdown`
    })
      .then((response) => {
        dispatch({
          type: types.UPDATE_JOURNEY_TEMPLATES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to fetch the templates from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function filterItineraries(filter = {}) {
    fetchItineraries(1, filter, state.closed);
    dispatch({
      type: types.UPDATE_FILTER,
      payload: filter,
    });

    dispatch({
      type: types.UPDATE_PAGE_INDEX,
      payload: 1,
    });
  }

  function clearFilterItineraries(fetchClosedItineraries, filter = {}) {
    fetchItineraries(1, filter, fetchClosedItineraries);
    dispatch({
      type: types.CLEAR_FILTER,
      payload: false,
    });

    dispatch({
      type: types.UPDATE_PAGE_INDEX,
      payload: 1,
    });
  }

  function addNewItinerary(data, history) {
    axios({
      method: "POST",
      url: "api/itineraries/create",
      data,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        const itineraryToEdit = Object.assign(state.itinerary.data, data);
        dispatch({ type: types.UPDATE_ITINERARY, payload: itineraryToEdit });
        history.push(`/itineraries/${data.id}`);
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to create a new itinerary";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function getItinerary(id) {
    axios({
      method: "GET",
      url: `api/itineraries/${id}`,
    })
      .then((response) => {
        const itineraryToEdit = Object.assign({}, state.template, {
          data: response.data,
        });
        dispatch({ type: types.UPDATE_ITINERARY, payload: itineraryToEdit });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Failed to get the itinerary from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function editItinerary(data) {
    axios({
      method: "PUT",
      url: `api/itineraries/${data.id}/update`,
      data,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => { })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to save the edited version of the itinerary";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function getItineraryTraveller(itineraryId, conversationId) {
    axios({
      method: "GET",
      url: `api/itineraries/${itineraryId}/conversations/${conversationId}`,
    })
      .then((response) => {
        const newTraveller = Object.assign({}, state.traveller, {
          data: response.data,
        });
        dispatch({ type: types.UPDATE_TRAVELLER, payload: newTraveller });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to get the traveller's data from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function editTravellerDetails(data, conversationItineraryId) {
    axios({
      method: "PUT",
      url: `api/conversations-itineraries/${conversationItineraryId}/update`,
      data,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        axios({
          method: "GET",
          url: `api/itineraries/${state.traveller.data.itinerary.id}/conversations/${state.traveller.data.conversation.id}`,
        })
          .then((response) => {
            const newTraveller = Object.assign({}, state.traveller, {
              data: response.data,
            });
            dispatch({ type: types.UPDATE_TRAVELLER, payload: newTraveller });
          })
          .catch((error) => {
            console.log(error);
            const errorMessage =
              "Sorry, we failed to get the traveller's data to the database";
            dispatch({
              type: types.SHOW_ERRORS,
              payload: errorMessage,
            });
          });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to save the traveller's data to the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function updatePreferencesTraveller(
    preferenceData,
    conversationItineraryId,
    onClickEditPreferences
  ) {
    let updatedPrefencesTraveller = {
      tags: preferenceData.tags,
    };
    axios({
      method: "PUT",
      url: `api/conversation-tags/${conversationItineraryId}/update`,
      data: updatedPrefencesTraveller,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        axios({
          method: "GET",
          url: `api/itineraries/${state.traveller.data.itinerary.id}/conversations/${state.traveller.data.conversation.id}`,
        })
          .then((response) => {
            const newTraveller = Object.assign({}, state.traveller, {
              data: response.data,
            });
            dispatch({ type: types.UPDATE_TRAVELLER, payload: newTraveller });
            if (onClickEditPreferences) onClickEditPreferences();
          })
          .catch((error) => {
            console.log(error);
            const errorMessage =
              "Sorry, we failed to get the traveller's data to the database";
            dispatch({
              type: types.SHOW_ERRORS,
              payload: errorMessage,
            });
          });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to save the traveller's preferences to the itinerary database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function updateTemplateItinerary(data) {
    let dataToSend = {};
    dataToSend.journey_id = data.id;
    dataToSend.change_template = 1;
    axios({
      method: "PUT",
      url: `api/itineraries/${state.itinerary.data.id}/update`,
      data: dataToSend,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        axios({
          method: "GET",
          url: `api/itineraries/${data.id}`,
        })
          .then((response) => {
            const itineraryToEdit = Object.assign({}, state.template, {
              data: response.data,
            });
            dispatch({
              type: types.UPDATE_ITINERARY,
              payload: itineraryToEdit,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to save the itinerary's template to the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function createJourneyDayMessage(day, closeTemplateDay) {
    axios({
      method: "POST",
      url: `api/journey/conversation-itinerary/${state.traveller.data.id}/journey-scheduled-articles/store`,
      data: day,
    }).then((result) => {
        return result.data;
    }).then((data) => {
        axios({
          method: "GET",
          url: `api/itineraries/${state.traveller.data.itinerary.id}/conversations/${state.traveller.data.conversation_id}`,
        }).then((response) => {
            const newTraveller = Object.assign({}, state.traveller, {
              data: response.data,
            });
            dispatch({ type: types.UPDATE_TRAVELLER, payload: newTraveller });
            if (closeTemplateDay) closeTemplateDay();
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 422) {
              dispatch({
                type: types.VALIDATE_ERRORS,
                payload: error.response.data,
              });

            } else {
              const errorMessage =
                  "Sorry, we failed to save the new itinerary journey day to the database";
              dispatch({
                type: types.SHOW_ERRORS,
                payload: errorMessage,
              });
            }
          });
      }).catch((error) => {
      console.log(error);
      if (error.response.status === 422) {
        dispatch({
          type: types.VALIDATE_ERRORS,
          payload: error.response.data,
        });

      } else {
        const errorMessage =
            "Sorry, we failed to save the new itinerary journey day to the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      }
    });
  }

  function updateJourneyDayMessage(day, closeTemplateDay) {
    axios({
      method: "PUT",
      url: `api/journey-scheduled-articles/${day.journey_scheduled_article_id}/update`,
      data: day,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        axios({
          method: "GET",
          url: `api/itineraries/${state.traveller.data.itinerary.id}/conversations/${state.traveller.data.conversation_id}`,
        })
          .then((response) => {
            const newTraveller = Object.assign({}, state.traveller, {
              data: response.data,
            });
            dispatch({ type: types.UPDATE_TRAVELLER, payload: newTraveller });
            if (closeTemplateDay) closeTemplateDay();
          })
          .catch((error) => {
            console.log(error);
            const errorMessage =
              "Sorry, we failed to save the updated itinerary journey day to the database";
            dispatch({
              type: types.SHOW_ERRORS,
              payload: errorMessage,
            });
          });
      });
  }

  function approveJourneyScheduledArticles(id) {
    axios({
      method: "GET",
      url: `api/conversation-itinerary/${id}/scheduled-messages/approve`,
    })
      .then((result) => {
        dispatch({ type: types.APPROVE_ARTICLES });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to mark the messages as approved";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function rejectJourneyScheduledArticles(id) {
    axios({
      method: "GET",
      url: `api/conversation-itinerary/${id}/scheduled-messages/reject`,
    })
      .then((result) => {
        dispatch({ type: types.REJECT_ARTICLES });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to mark the messages as rejected";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function addDayToCurrentTemplate() {
    let data = { add_day: 1 };

    axios({
      method: "PUT",
      url: `api/itineraries/${state.itinerary.data.id}/change-end`,
      data,
    })
      .then((result) => {
        dispatch({ type: types.ADD_EMPTY_JOURNEYDAY });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to add a day to the current template in itineraries";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function removeDayFromCurrentTemplate() {
    let data = { remove_day: 1 };

    axios({
      method: "PUT",
      url: `api/itineraries/${state.itinerary.data.id}/change-end`,
      data,
    })
      .then((result) => {
        dispatch({ type: types.REMOVE_JOURNEYDAY });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to remove a day to the current template in itineraries";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function updateLocationItinerary(location, onHide) {
    axios({
      method: "PUT",
      url: `api/journey-location/${location.id}/update`,
      data: location,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        dispatch({
          type: types.types.UPDATE_TEMPLATE,
          payload: data,
        });
        if (onHide) onHide();
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Updating the location failed";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function clearJourneyDay(message) {
    axios({
      method: "DELETE",
      url: `api/journey-scheduled-articles/${message.journey_scheduled_article_id}/delete`,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        dispatch({
          type: types.CLEAR_JOURNEYDAY,
          payload: {
            dayIndex: message.day,
            messageId: message.journey_scheduled_article_id
          },
        });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, we failed to clear the journey day in itineraries";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function disconnectTravellerFromItinerary(conversationId, itineraryId) {
    axios({
      method: "GET",
      url: `api/conversations/${conversationId}/itineraries/${itineraryId}/disconnect `,
    }).then((data) => {
      axios({
        method: "GET",
        url: `api/itineraries/${itineraryId}`,
      })
        .then((response) => {
          const itineraryToEdit = Object.assign({}, state.template, {
            data: response.data,
          });
          dispatch({
            type: types.UPDATE_ITINERARY,
            payload: itineraryToEdit,
          });
        })
        .catch((error) => {
          console.log(error);
          const errorMessage =
            "Failed to get the itinerary from the database";
          dispatch({
            type: types.SHOW_ERRORS,
            payload: errorMessage,
          });
        });
    });
  }

  function clearError() {
    const emptyError = "";
    dispatch({
      type: types.SHOW_ERRORS,
      payload: emptyError,
    });
  }

  function fetchWhatsappTemplates(branchId, companyChannelId) {
    axios({
      method: "GET",
      url: `api/message-templates/${branchId}/${companyChannelId}`,
    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        dispatch({
          type: types.UPDATE_WHATSAPP_TEMPLATES,
          payload: data,
        });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, unable to fetch whatsapp templates from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function findPhonenumber(phonenumber, branchId) {
    axios({
      method: "GET",
      url: `api/conversations/${branchId}/search/` + phonenumber,

    })
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        dispatch({
          type: types.SEARCH_PHONE_NUMBERS,
          payload: data,
        });
      })
      .catch((error) => {
        console.log(error);
        const errorMessage =
          "Sorry, unable to fetch the phone numbers from the database";
        dispatch({
          type: types.SHOW_ERRORS,
          payload: errorMessage,
        });
      });
  }

  function addTravellerToItinerary(conversationId, itineraryId) {
    axios({
      method: "POST",
      url: `api/conversations/${conversationId}/itineraries/${itineraryId}/connect`,
    }).then((data) => {
      axios({
        method: "GET",
        url: `api/itineraries/${itineraryId}`,
      })
        .then((response) => {
          const itineraryToEdit = Object.assign({}, state.template, {
            data: response.data,
          });
          dispatch({
            type: types.UPDATE_ITINERARY,
            payload: itineraryToEdit,
          });
        })
        .catch((error) => {
          console.log(error);
          const errorMessage =
            "Failed to get the itinerary from the database";
          dispatch({
            type: types.SHOW_ERRORS,
            payload: errorMessage,
          });
        });
    });
  }

  function updateStateClosed(stateClosed) {
    dispatch({
      type: types.UPDATE_CLOSED_ITINERARIES,
      payload: stateClosed,
    });
  }

  function updatePageIndex(pageIndex) {
    dispatch({
      type: types.UPDATE_PAGE_INDEX,
      payload: pageIndex,
    });
  }

  function moveMessage (id, to) {
    axios({
      method: "GET",
      url: `api/itineraries/move-message/${id}/${to}`,
    })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
  }

  return {
    fetchItineraries,
    fetchJourneyTemplates,
    filterItineraries,
    clearFilterItineraries,
    getItinerary,
    addNewItinerary,
    editItinerary,
    editTravellerDetails,
    updatePreferencesTraveller,
    updateTemplateItinerary,
    approveJourneyScheduledArticles,
    rejectJourneyScheduledArticles,
    getItineraryTraveller,
    createJourneyDayMessage,
    updateJourneyDayMessage,
    addDayToCurrentTemplate,
    removeDayFromCurrentTemplate,
    updateLocationItinerary,
    clearJourneyDay,
    disconnectTravellerFromItinerary,
    findPhonenumber,
    addTravellerToItinerary,
    clearError,
    fetchWhatsappTemplates,
    updateStateClosed,
    updatePageIndex,
    moveMessage
  };
};
