import React, {useContext, useEffect, useState} from 'react';
import Tabs from 'react-bootstrap/Tabs'
import './ClosedConversationTabs.css'
import AnalysisConversationTabs from "./../../OpenConversations/OpenConversationTabs/AnalysisConversationTabs";
import { ConversationContext } from "../../../../store/context/ConversationContext";


function ClosedConversationTabs() {
    const { state, actions } = useContext(ConversationContext);
    const [selectItineraries, setSelectItineraries] = useState([]);

    useEffect(() => {
        actions.fetchConversationTaskList(state.currentConversation.id);
        actions.fetchUserPreferences(state.currentConversation.id);

        if(typeof state.currentConversation.itineraries != 'undefined' && typeof state.currentConversation.itineraries[0] != 'undefined') {
            actions.fetchJourneyScheduledArticles(state.currentConversation.id);
            setSelectItineraries(state.currentConversation.itineraries)
        } else {
            // state.analysisData = {};
        }
        return () => {
            setSelectItineraries({});
        }
    }, [state.currentConversation.id]);

    return (
        <div className='open-conversation-tab col-sm-12'>
            <Tabs defaultActiveKey="analysis" id="conversation-tabs">
                <ClosedConversationTabs className="conversation-analysis" eventKey="analysis" title="Analysis">
                    <AnalysisConversationTabs pageName="closed" selectItineraries={selectItineraries}/>
                </ClosedConversationTabs>
            </Tabs>
        </div>
    )
}

export default ClosedConversationTabs;