import React, {useEffect, useState} from "react";
import {Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import Status from "../../../utils/Status";


function  CardMessagePreview ({items, onChangeCheck}) {

    return (
    Array.isArray(items.data) ? (items.data || []).map((item,key) => (


        <Col xs={6} md={4} key={item.conversation_itinerary_id}>
            <Card>
                <Card.Body>
                    <Card.Header>
                        <Form.Check
                            onChange={onChangeCheck}
                            value={item.conversation_itinerary_id}
                            label={`${item.dossier_id}  ${item.description}`}
                        />
                    </Card.Header>
                    <Row>
                        <ListGroup variant="flush">
                            <ListGroup.Item>Journey: {item.title}</ListGroup.Item>
                            <ListGroup.Item>departure:  {item.departure_date}</ListGroup.Item>
                            <ListGroup.Item>return: {item.return_date}</ListGroup.Item>
                            <ListGroup.Item>conversation: {item.conversation_id}</ListGroup.Item>
                            <ListGroup.Item>Status: {Status[item.status]}</ListGroup.Item>
                        </ListGroup>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )) : null
    )
}


export default  CardMessagePreview;                                                             