import React, { useReducer } from "react";
import { reducer, initialState } from "../reducers/ItineraryReducer";
import { useActions } from "../actions/ItineraryActions";

export const ItineraryContext = React.createContext();

const ItineraryContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  return (
    <ItineraryContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </ItineraryContext.Provider>
  );
};

export default ItineraryContextProvider;
