import React, { useState, useContext, useEffect, useRef } from "react";
import Task from "../TaskComponents/Task/Task";
import "./TaskContainer.css";
import { TasksContext } from "../../../store/context/TasksContext";
import Select from "react-select";
import Paginator from "../../Reusable/Paginator/Paginator";
import SearchInputField from "../../Reusable/SearchInputField/SearchInputField";
import { AppContext } from "../../../store/context/AppContext";

const AssignedTaskContainer = ({ title }) => {
  const { state, actions } = useContext(TasksContext);
  const { state: AppState } = useContext(AppContext);
  const [titleContainer] = useState(title);
  const [sortDate, setsortDate] = useState('asc');
  const [assignedPage, setAssignedPage] = useState(null);
  const { pusher } = useContext(AppContext);
  const [user] = useState(JSON.parse(localStorage.getItem("access_token")));
  const [filter, setFilter] = useState(state.filters);
  const [isInitial, setIsInitial] = useState(true);
  const selectInputRefBranch = useRef();

  useEffect(() => {
    setAssignedPage(state.assignedPage);
  }, [state.assignedPage]);

  const itineraryTasksIds = [2, 3, 4, 5, 6, 14];

  useEffect(() => {
    actions.fetchTaskActions();
  }, []);

  useEffect(() => {
    setFilter(state.filters);
  }, [state.filters]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      return;
    }
    actions.filterTasks(filter);
  }, [filter.status]);

  useEffect(() => {
    actions.fetchAssignedTasks(state.assignedPage, filter);

    const channel = pusher.subscribe("inbox");
    channel.bind(process.env.REACT_APP_PUSHER_INBOX_EVENT_NAME, (data) => {
      if (
        typeof data.update_tasks != "undefined" &&
        data.update_tasks &&
        data.user_id !== user.id
      ) {
        actions.fetchAssignedTasks(1, filter);
      }
    });

    return () => {
      channel.unbind(process.env.REACT_APP_PUSHER_INBOX_EVENT_NAME);
    };
  }, [state.assignedPage, state.assignedTasks.assignedOrderByDate]);

  const handleSortDate = () => {
    if (sortDate == "" || sortDate == "asc") {
      setsortDate('desc');
    } else {
      setsortDate('asc');
    }
    actions.sortDateAssigned(sortDate);
  };

  const updatePhone = (e) => {
    setFilter({ ...filter, phone: e.target.value });
  };

  const updateTaskAction = (data) => {
    setFilter({ ...filter, status: data });
  };

  const updateBranch = (data) => {
    if (data) {
      setFilter({ ...filter, branch: data.id });
    }
  };

  const handleClearFilter = () => {
    actions.clearFilterTasks();
    selectInputRefBranch.current.select.clearValue();
  };

  const getNextPage = () => {
    actions.updatePageIndex(
      "assigned",
      !state.assignedTasks ? assignedPage : assignedPage + 1
    );
  };

  const getPreviousPage = () => {
    let newPageIndex = Math.max(assignedPage - 1, 1);
    actions.updatePageIndex("assigned", newPageIndex);
  };

  const getPageButton = (pageButton) => {
    actions.updatePageIndex("assigned", pageButton);
  };

  const handleSearchPhoneClicked = () => {
    actions.filterTasks(filter);
  };

  function handleChange(event, type) {
    type === 'start_date' ? setFilter({ ...filter, from: event.target.value }) : setFilter({ ...filter, to: event.target.value });



  }

  return (
    <div id="active-task-container__wrapper">
      <div className="task-container__sorting-options">

        {(filter &&
          filter.status &&
          filter.status[0] &&
          filter.status[0].id &&
          itineraryTasksIds.includes(filter.status[0].id)) ? (
            <div>
              <button
                onClick={handleSortDate}
                className="task-container__filter-text"
              >
                Sort by: <u>date</u>
              </button>
              {sortDate === "" || sortDate === "asc" ? (
              <i className="itineraries__arrow itineraries__down" />
            ) : (
              <i className="itineraries__arrow itineraries__up" />
            )}
            </div>
          ) : (
            <div></div>
          )}
        <div className="task-container__filter-date">
          <input type="date" name="start_date" className="form-control" onChange={(event) => handleChange(event, 'start_date')}/>
          <span className="m-1"> - </span>
          <input type="date" name="end_date" className="form-control" onChange={(event) => handleChange(event, 'end_date')}/>
        </div>
        <div className="task-container__filter-status">
          <div className="task-container__filter-status-select">
            <Select
              isMulti
              name="status"
              placeholder="filter on status"
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              options={state.taskActions}
              value={filter.status}
              onChange={updateTaskAction}
            />
          </div>
        </div>
        <div className="task-container__filter-branch">
          <div className="task-container__filter-branch-select">
            <Select
              name="branch"
              placeholder="filter on branch"
              getOptionLabel={(option) => `${option.branch_name}`}
              getOptionValue={(option) => `${option.id}`}
              options={AppState.inputs.companies}
              defaultValue={filter.branch}
              onChange={updateBranch}
              ref={selectInputRefBranch}
            />
          </div>
          <button
            className="search-input-field__btn search-btn-branch"
            onClick={handleSearchPhoneClicked}
          ></button>
        </div>
        <div className="task-container__filter-phone">
          <SearchInputField
            placeholder="Search on phone number"
            value={filter.phone || ""}
            onChange={updatePhone}
            onClickSearch={handleSearchPhoneClicked}
          />
          <button
            className="inbox-search-bar__clear-filter-btn"
            onClick={handleClearFilter}
          ></button>
        </div>
      </div>
      <br></br>
      <div>
        <h5 id="task-container__header--active">{titleContainer.label}</h5>
      </div>
      <br></br>
      <div className="task-container__tasks">
        {state.assignedTasks.tasks.map((task, key) => {
          return <Task task={task} key={key} titleContainer={titleContainer} />;
        })}

        <Paginator
          className="assigned-tasks-inbox__paginate"
          page={assignedPage}
          getPreviousPage={getPreviousPage}
          getNextPage={getNextPage}
          lastPage={state.assignedTasks.assignedLastPage}
          getPageButton={getPageButton}
        />
      </div>
    </div>
  );
};

export default AssignedTaskContainer;
