import React, { useState, useContext } from "react";
import { TipsContext } from "../../../../../../store/context/TipsContext";
import { Marker, InfoWindow } from "react-google-maps";
import GoogleMap from "../../../../../Reusable/GoogleMap/GoogleMap";
import PropTypes from "prop-types";

import RectangleButton from "../../../../../Reusable/RectangleButton/RectangleButton";

function TemplateGoogleMap({
  lat,
  lng,
  onTipSelected,
  tipSelectorButton,
  onBoundsUpdate,
}) {
  const { state } = useContext(TipsContext);
  const [selectedTip, setSelectedTip] = useState(null);
  const [tipSelectorButtonData] = useState(tipSelectorButton);

  const onClickAddTip = () => {
    onTipSelected(selectedTip);
  };

  const allTips = state.tips.allTips.filter((tip) => tip.lat) || [];
  const google = window.google;

  return (
    <GoogleMap
      defaultZoom={13}
      center={{
        lat: lat,
        lng: lng,
      }}
      onBoundsUpdate={onBoundsUpdate}
    >
      <div>
      {(lat && state.filters.location.lat != null && google) && (
          <Marker
            position={{
              lat: lat,
              lng: lng,
            }}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeColor: "#17a3b0"
            }}
          />
        )}

        {(allTips && google) &&
          allTips.map((tip) => {
            return (
              <Marker
                key={tip.id}
                position={{
                  lat: tip.lat,
                  lng: tip.lng,
                }}
                onClick={() => {
                  setSelectedTip(tip);
                }}
                icon={{url: tip.type?.pin || "", scaledSize: new google.maps.Size(75, 75)}}
              />
            );
          })}

        {selectedTip && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedTip(null);
            }}
            position={{
              lat: selectedTip.lat,
              lng: selectedTip.lng,
            }}
          >
            <div>
              <h5>{selectedTip.title}</h5>
              <p>{selectedTip.body}</p>
              {tipSelectorButtonData && (
                <RectangleButton
                  className={tipSelectorButtonData.className}
                  onClick={onClickAddTip}
                  label={tipSelectorButtonData.label}
                />
              )}
            </div>
          </InfoWindow>
        )}
      </div>
    </GoogleMap>
  );
}

export default TemplateGoogleMap;

TemplateGoogleMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  onTipSelected: PropTypes.func,
  tipSelectorButton: PropTypes.object,
};
