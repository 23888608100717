import React, { useContext } from "react";
import { AppContext } from "../../../../store/context/AppContext";
import { Form } from "react-bootstrap";
import Select from "react-select";

import "./PreferencesIndividualItinerary.css";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";

const PreferencesIndividualItinerary = ({
  preferenceFormData,
  onChangePreferences,
  onClickSavePreferences,
  onClickEditPreferences,
  isEditPreferencesMode,
}) => {
  const { state: AppState } = useContext(AppContext);

  const onSelectChange = (name, data) => {
    onChangePreferences(name, data);
  };

  return (
    <>
      {isEditPreferencesMode ? (
        <div className="preferences-individual-itinerary__container">
          <Form.Group>
            <Select
              isMulti
              className="preferences-individual-itinerary__input"
              placeholder="Add preference"
              options={AppState.inputs.tags}
              getOptionLabel={(option) => `${option.tag}`}
              value={preferenceFormData.tags}
              getOptionValue={(option) => `${option.id}`}
              onChange={(data) => onSelectChange("tags", data)}
            />
          </Form.Group>
          <RectangleButton
            className="preferences-individual-itinerary__btn"
            isHighlight
            label="Save preferences"
            onClick={onClickSavePreferences}
          />
        </div>
      ) : (
        <div className="preferences-individual-itinerary__container">
          <ul className="preferences-individual-itinerary__list">
            {preferenceFormData.tags &&
              preferenceFormData.tags.map((tag) => (
                <li
                  key={tag.id}
                  className="preferences-individual-itinerary__tag"
                >
                  {tag.tag}
                </li>
              ))}
          </ul>
          <RectangleButton
            isHighlight
            className="preferences-individual-itinerary__btn"
            label="Edit preferences"
            onClick={onClickEditPreferences}
          />
        </div>
      )}
    </>
  );
};

export default PreferencesIndividualItinerary;
