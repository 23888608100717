import React, {useContext, useEffect, useState} from "react";
import {ItineraryContext} from "../../../../../store/context/ItineraryContext";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import "./TravellerCard.css";
import {Button, Modal} from "react-bootstrap";
import {AppContext} from "../../../../../store/context/AppContext";

function TravellerCard({conversation, itineraryId}) {
    const {actions} = useContext(ItineraryContext);
    const { state: AppState } = useContext(AppContext);

    const [show, setShow] = useState(false);
    const [language, setLanguage] = useState("");
    const handleCloseDisconnectModal = () => setShow(false);
    const handleOpenDisconnectModal = () => setShow(true);

    function onClickDisconnect() {
        actions.disconnectTravellerFromItinerary(conversation.id, itineraryId);
    }

    useEffect(() => {
        AppState.inputs.languages.find((language) => {
            if (language.id === conversation.language_id) {
                setLanguage(language.name);
            }
        });
    }, [AppState.inputs.languages, conversation]);

    return (
        <div className="traveller-card" key={conversation.id}>
            <div>
                <Link to={`/conversations/open/${conversation.id}`}>
                    <div className="traveller-card__title">Full name</div>
                    <div className="traveller-card__text">
                        {conversation.traveller.first_name} {conversation.traveller.last_name}{" "}
                        {!conversation.traveller.first_name &&
                            !conversation.traveller.last_name &&
                            conversation.traveller.full_name}
                    </div>
                </Link>

            </div>

            <div>
                <Link to={`/conversations/open/${conversation.id}`}>
                    <div className="traveller-card__title">Phone</div>
                    <div className="traveller-card__text">
                        {conversation.traveller.phone1}
                    </div>
                </Link>
            </div>

            <div>
                <Link to={`/conversations/open/${conversation.id}`}>
                    <div className="traveller-card__title">Language</div>
                    <div className="traveller-card__text">
                        {language}
                    </div>
                </Link>
            </div>

            <div className="traveller-card__btns">
                <Link to={`/itineraries/${itineraryId}/${conversation.id}`}>
                    <button className="traveller-card__btn traveller-card__btn--edit"/>
                </Link>
                <button
                    onClick={handleOpenDisconnectModal}
                    className="traveller-card__btn traveller-card__btn--disconnect"
                />

                <Modal show={show} onHide={handleCloseDisconnectModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Disconnect the traveller?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you really want to disconnect this traveller from the itinerary?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDisconnectModal}>
                            No
                        </Button>
                        <Button className="delete-button" onClick={onClickDisconnect}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

TravellerCard.propTypes = {
    conversation: PropTypes.object,
    itineraryId: PropTypes.number,
};

export default TravellerCard;
