import React from "react";
import "./Itinerary.css";
import ProgressBar from "react-bootstrap/ProgressBar";

const Itinerary = ({ task }) => {
  let now = 0;
  if (task.taskable && task.taskable.departure_date && task.taskable.return_date) {
    let dep_day = new Date(task.taskable.departure_date)
    let current_date = new Date()
    let return_date = new Date(task.taskable.return_date)
    let total = return_date.getTime() - dep_day.getTime();
    let current = current_date.getTime() - dep_day.getTime();
    now = (current / total) * 100;
  }
  return (
    <div className="itinerary__container">
      <div className="itinerary__icon"></div>
      <div className="itinerary__trip-information">
        <p className="itinerary__trip-name">
          {task.taskable && task.taskable.description}
        </p>
        <p className="itinerary__trip-dates">
          {(task.taskable && task.taskable.departure_date) &&
            task.taskable.departure_date}{" "}
            /{" "}
          {(task.taskable && task.taskable.return_date) &&
            task.taskable.return_date}
        </p>
        <div className="itinerary__progressbar">
          <ProgressBar now={now} variant={"itinerary__progressbar"} />
        </div>
      </div>
    </div>
  );
}

export default Itinerary;
