import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import './ClearButton.css'

function ClearButton({ onClick, className }) {
  return (
    <>
      <button
        className={classnames("clear-button", className)}
        onClick={onClick}
      ></button>
    </>
  );
}

ClearButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ClearButton;
