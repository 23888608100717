import React, { useContext, useEffect, useState } from "react";
import Itinerary from "../Itinerary";
import "./DefaultTabInfo.css";
import { ConversationContext } from "../../../../store/context/ConversationContext";
import ConversationAssignee from "../../ConversationAssignee";
import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";
import AddItineraryModal from "./AddItineraryModal";
import ConversationLanguage from "../../ConversationLanguage";


const DefaultTabInfo = () => {
    const { state, actions } = useContext(ConversationContext);
    const [addItineraryShow, setAddItineraryShow] = useState(false);
    const [notes, setNotes] = useState(null);
    const [isVip, setIsVip] = useState(false);

    useEffect(() => {
        setNotes(state.currentConversation.notes)
        setIsVip(state.currentConversation.is_vip)
    }, [state.currentConversation]);


    const onAddItineraryClicked = () => {
        setAddItineraryShow(true);
    };

    const onAddItineraryCloseClicked = () => {
        setAddItineraryShow(false);
    };

    const changeNotes = (event) => {
        setNotes(event.target.value);
    }

    const changeVip = (event) => {
        let isVip = event.target.checked;
        let  message = 'Are you sure you want to uncheck this conversation as VIP?';

        if(isVip === false) {
            if(!window.confirm(message)) {
                return false;
            }
        }

        setIsVip((curretIsVip) => isVip);

        actions.changeVip(state.currentConversation.id, isVip);
    }


    const handleSubmit = () => {
        let storeData = {
            id: state.currentConversation.id,
            notes: notes,
        };
        actions.storeConversationNotes(storeData);

        setNotes(null)
    };

    return (
        <div className="conversation-tab-task-container">
            <div className="task-conversation-container">
                <h5 className="task-conversations-header">ASSIGNED TO</h5>
                <div className="open-conversation-task">
                    <ConversationAssignee />
                </div>
                <h5 className="task-conversations-header">LANGUAGE</h5>
                <div className="open-conversation-task">
                    <ConversationLanguage />
                </div>
                <h5 className="task-conversations-header">TRAVEL COMPANY</h5>
                <div className="open-conversation-task">
                    <div className="default-tab-info__company-name-agent">
                        <p className="company_name">
                            {`${state.currentConversation.branch.branch_name} (${state.currentConversation.branch.agent_name})`}
                        </p>
                        <p className="company_name"></p>
                    </div>
                    <p className="company_name">
                        {state.currentConversation.branch.branch_phone}
                    </p>
                </div>
                <h5 className="task-conversations-header">VIP CONVERSATION</h5>
                <div className="open-conversation-task">
                    <div className="default-tab-info__company-name-agent">
                    <input type={'checkbox'} name={'is_vip'} onChange={changeVip} checked={isVip} />
                    </div>
                </div>

                <h5 className="task-conversations-header">ITINERARIES</h5>
                    <div className="itinerary-block">
                        {state.currentConversation.itineraries.map((itinerary, i) => (
                            <div key={i}>
                                <div className="open-conversation-task">
                                    <Itinerary itinerary={itinerary} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="open-conversation-task open-conversation-task--add-itinerary">
                        <RectangleButton
                            isHighlight
                            className="default-tab-info__add-itinerary-btn"
                            label="Add Itinerary"
                            onClick={onAddItineraryClicked}
                        />
                        <AddItineraryModal
                            show={addItineraryShow}
                            onHide={onAddItineraryCloseClicked}
                            conversationId={state.currentConversation.id}
                        />
                    </div>

                <h5 className="task-conversations-header">NOTES</h5>
                <div className="open-conversation-task conversation-notes-block">
                    <div className="default-tab-info__company-name-agent">
                        <textarea name="notes" className="conversation-notes"
                            placeholder="Notes..." onChange={changeNotes} value={notes || ""} />
                    </div>
                    <input className="default-tab-info__note-submit-btn btn btn-success" onClick={handleSubmit} defaultValue="Save" />
                </div>


            </div>
        </div>
    );
};

export default DefaultTabInfo;
