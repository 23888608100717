import React, { useState, useContext } from "react";
import { TemplateContext } from "../../../../store/context/TemplateContext";
import TemplateDay from "../../EditTemplate/TemplateDay/TemplateDay";

import Timeline from "../../../Reusable/Timeline/Timeline";
import DragDropTemplate from "../../../Reusable/DragAndDrop/DragDropTemplate";

function TemplateTimeline({
  template,
  displayHeader,
  isEditMode,
}) {
  const { actions } = useContext(TemplateContext);
  const [selectedDayData, setSelectedDayData] = useState(null);

  const closeTemplateDay = () => {
    setSelectedDayData(null);
    displayHeader();
  };

  const onClickEditJourneyDay = (selectedMessage) => {
    const day = template.days.find((d) => d.day === selectedMessage.day);
    const message = day.messages.find((msg) => msg.journey_day_id === selectedMessage.journey_day_id);
    const copyMessage = JSON.parse(JSON.stringify(message));
    setSelectedDayData(copyMessage);
    displayHeader();
  };

  const onClickClearJourneyDay = (message, handleCloseModal) => {
    actions.clearJourneyDay(message, handleCloseModal);
  };

  const onClickNewJourneyDay = (day) => {
    const selectedDay = template.days.find((d) => d.day === day.day);
    setSelectedDayData(selectedDay);
    displayHeader();
  }

  const onClickDeleteFromTemplateAndItineraries = (day, handleCloseModal) => {
    day.apply_to_current_itineraries = 1;
    actions.clearJourneyDay(day, handleCloseModal);
  }


  return (
    <>
      {selectedDayData ? (
        <TemplateDay
          closeTemplateDay={closeTemplateDay}
          selectedDayData={selectedDayData}
        />
      ) : (
        // <Timeline
        //   timelineData={template}
        //   isEditMode={isEditMode}
        //   onClickEditJourneyDay={onClickEditJourneyDay}
        //   onClickClearJourneyDay={onClickClearJourneyDay}
        //   onClickNewJourneyDay={onClickNewJourneyDay}
        //   onClickDeleteFromTemplateAndItineraries={onClickDeleteFromTemplateAndItineraries}
        //   modalTitle={'Delete template message'}
        //   modalFirstButton={'Delete from this template.'}
        // />
          <DragDropTemplate
              title={'journey'}
              timelineData={template}
              isEditMode={isEditMode}
              onClickEditJourneyDay={onClickEditJourneyDay}
              onClickClearJourneyDay={onClickClearJourneyDay}
              onClickNewJourneyDay={onClickNewJourneyDay}
              onClickDeleteFromTemplateAndItineraries={onClickDeleteFromTemplateAndItineraries}
              modalTitle={'Delete template message'}
              modalFirstButton={'Delete from this template.'}
          />
      )}
    </>
  );
}

export default TemplateTimeline;
