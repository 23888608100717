import React from "react";
import "./Agent.css";
import { useContext } from "react";
import { TasksContext } from "../../../../../store/context/TasksContext";


const Agent = ({ task }) => {
  const {actions} = useContext(TasksContext);

  return (
    <div className="agent__container">
      <div className="agent__icon"></div>
      <div className="agent__information">
        <button className="agent__mark-as-complete" onClick={() => actions.completeTask(task)}>
          Mark as completed
        </button>

        <button className="agent__unassign-task" onClick={() => actions.setUnassigned(task)}>
          Unassign task
        </button>
      </div>
    </div>
  );
};

export default Agent;
