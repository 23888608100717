import React, { useState } from "react";
import PropTypes from "prop-types";
import AddLocation from "../../Templates/EditTemplate/AddLocation/AddLocation";
import "./Timeline.css";
import {Modal, Button, Col, Image} from 'react-bootstrap';
import { getToken } from "../../../utils/auth";


const ONEDAY = 24 * 60 * 60 * 1000;
const todaysDate = new Date().toDateString();

function Timeline({
  className,
  timelineData,
  departureDate,
  isEditMode,
  onClickEditJourneyDay,
  onClickClearJourneyDay,
  onClickNewJourneyDay,
  doNotShowMessages,
  onClickDeleteFromTemplateAndItineraries,
  modalTitle,
  modalFirstButton
}) {
  const [locationId, setLocationId] = useState(null);
  const startDate = new Date(departureDate).getTime() - ONEDAY;
  const [show, setShow] = useState(false);
  const [journeyDayToDelete, setJourneyDayToDelete] = useState({});
  const handleCloseModal = () => setShow(false);

  const renderDate = (date) => {
    if (startDate) {
      const dayDate = new Date(date).toDateString();
      return (
        <div
          className={`timeline__date ${dayDate === todaysDate ? "timeline__date--today" : ""
            }`}
        >
          {date}
        </div>
      );
    }
  };

  const onClickAddLocation = (location) => {
    setLocationId(location.id);
  };

  const onCloseAddLocation = () => {
    setLocationId(null);
  };

  const deleteJourneyDay = (message) => {
    setJourneyDayToDelete(message)
    setShow(true);
  }

  return (
    <div className={className}>
      {locationId && (
        <AddLocation
          show={locationId !== null}
          onHide={onCloseAddLocation}
          data={timelineData}
          locationId={locationId}
        />
      )}
      <ul className="timeline">
        {timelineData && timelineData.days &&
          timelineData.days.map((journeyDay, key) => (
            <li
              key={key}
              className={`timeline__journey-day-list-item ${key === 0 || key > timelineData.journey_length || key > timelineData.itinerary?.timeline_length
                ? "timeline__journey-day-list-item--inactive"
                : ""
                }`}
            >
              {renderDate(journeyDay.date)}
              <div className="timeline__journey-day-header">
                <div className="timeline__journey-day-header-day">
                  Day {journeyDay.day}
                </div>

                {isEditMode ? (
                  <div className="timeline__btn-container">
                    {onClickNewJourneyDay && (
                      <button
                        type="button"
                        className="timeline__btn timeline__btn--add"
                        onClick={() => onClickNewJourneyDay(journeyDay)}
                      />
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {/* {journeyDay?.location && isEditMode && (
                <div
                  className="timeline__journey-day-location-clickable"
                  id={journeyDay.location.color}
                  onClick={() => onClickAddLocation(journeyDay.location, key)}
                >
                  {journeyDay.location.title &&
                    journeyDay.location.title}
                </div>
              )}

              {journeyDay?.location && !isEditMode && (
                <div
                  className="timeline__journey-day-location"
                  id={journeyDay.location.color}
                >
                  {journeyDay.location.title &&
                    journeyDay.location.title}
                </div>
              )}

              {!journeyDay?.location && (
                <div className="timeline__journey-day-no-location"></div>
              )} */}

              {journeyDay?.product && (
                  <div>
                    <div className="timeline__journey-day-accommodation" id={`timeline__journey-day-location`}>{journeyDay.product.country} <br/> {journeyDay.product.city}</div>
                    <div
                      className="timeline__journey-day-accommodation"
                      id={`timeline__journey-day-accommodation--${journeyDay.product.color}`}
                    >
                  {journeyDay.product.name &&
                    journeyDay.product.name}
                    </div>
                  </div>
              )}

              {!journeyDay?.product && (
                <div className="timeline__journey-day-no-accommodation"></div>
              )}

              {journeyDay?.accommodation && (
                <div
                  className="timeline__journey-day-accommodation"
                  id={`timeline__journey-day-accommodation--${journeyDay.accommodation.color}`}
                >
                  {journeyDay.accommodation.name &&
                    journeyDay.accommodation.name}
                </div>
              )}

              {!journeyDay?.accommodation && (
                <div className="timeline__journey-day-no-accommodation"></div>
              )}

              {!doNotShowMessages && journeyDay && (
                <>
                  {journeyDay.messages &&
                    journeyDay.messages.map((message, key) => (
                      <div className="timeline__message" key={key}>
                        {isEditMode ? (
                          <div className="timeline__btn-container">
                            {onClickEditJourneyDay && (
                              <button
                                type="button"
                                className="timeline__btn timeline__btn--add"
                                onClick={() => onClickEditJourneyDay(message)}
                              />
                            )}
                            {onClickClearJourneyDay && message?.complete_message && (
                              <button
                                type="button"
                                className="timeline__btn timeline__btn--delete"
                                onClick={() => deleteJourneyDay(message)}
                              />
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        <Col md={6}  >
                          {message?.image !== null ? (
                              <Col xs={6} md={4} className={'float-right'} >
                                <Image  src={message?.image} thumbnail />
                              </Col>

                          ) :  ( <></> ) }
                        </Col>
                        {message.complete_message}

                      </div>
                    ))}
                </>
              )}
            </li>
          ))}
      </ul>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete this message?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onClickClearJourneyDay(journeyDayToDelete, handleCloseModal)}>
            {modalFirstButton}
          </Button>
          {(getToken().is_admin && onClickDeleteFromTemplateAndItineraries != '' )&& (
            <Button className="delete-button" onClick={() => onClickDeleteFromTemplateAndItineraries(journeyDayToDelete, handleCloseModal)}>
              Delete from this template and from current itineraries.
            </Button>
          )}

        </Modal.Footer>
      </Modal>
    </div>
  );
}

Timeline.propTypes = {
  className: PropTypes.string,
  timelineData: PropTypes.object,
  departureDate: PropTypes.string,
  onClickEditJourneyDay: PropTypes.func,
  onClickClearJourneyDay: PropTypes.func,
  isEditMode: PropTypes.bool,
  doNotShowMessages: PropTypes.bool,
};

export default Timeline;
