import React from "react";
import "./TaskTemplateComponent.css";

const TaskTemplateDayComponent = ({ task }) => {
  return (
    <div className="task-template-day__container">
        <p className="task-template-day__title">      
          {task.taskable && task.taskable ? (
              <p>{"Day "} {task.taskable.day} {" failed. Go to template to see details"}</p> 
          ) : (
            <></>
          )}
        </p>
    </div>
  );
};

export default TaskTemplateDayComponent;
