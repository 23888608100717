import React, { Component } from "react";
import Navbar from "./Navbar/Navbar";
import Header from "./Header/Header";

import AppContextProvider from "../store/context/AppContext";
import ConversationContextProvider from "../store/context/ConversationContext";
import TasksContextProvider from "../store/context/TasksContext";
import ItineraryContextProvider from "../store/context/ItineraryContext";
import TemplateContextProvider from "../store/context/TemplateContext";
import TipsContextProvider from "../store/context/TipsContext";
import KpiContextProvider from "../store/context/KpiContext";
import DashboardContextProvider from "../store/context/DashboardContext";
import RolesContextProvider from "../store/context/UserRolesContext";

import "./PrivateLayout.css";

class PrivateLayout extends Component {
  render() {
    return (
      <div className="private-layout">
        <React.Fragment>
          <Header />
          <div className="private-layout__nav-and-body">
            <Navbar />
            <div className="private-layout__body">
              <AppContextProvider>
                <ConversationContextProvider>
                  <TasksContextProvider>
                    <ItineraryContextProvider>
                      <TemplateContextProvider>
                        <TipsContextProvider>
                          <KpiContextProvider>
                            <DashboardContextProvider>
                              <RolesContextProvider>
                                {this.props.children}
                              </RolesContextProvider>
                            </DashboardContextProvider>
                          </KpiContextProvider>
                        </TipsContextProvider>
                      </TemplateContextProvider>
                    </ItineraryContextProvider>
                  </TasksContextProvider>
                </ConversationContextProvider>
              </AppContextProvider>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default PrivateLayout;
