import React from "react";
import { useReducer } from "react";
import { reducer, initialState } from "../../store/reducers/TaskReducer";
import { useActions } from "../../store/actions/TaskActions";

export const TasksContext = React.createContext();

const TasksContextApi = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(state, dispatch);

  return (
    <TasksContext.Provider value={{state, dispatch, actions}}>
      {children}
    </TasksContext.Provider>
  );
};

export default TasksContextApi;
