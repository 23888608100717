import React from "react";
import "./Traveler.css";

const Traveler = ({ task }) => {
  return (
    <div className="traveler__container">
      <div className="traveler__information">
        {task.taskable && task.taskable_type !== 'App\\Article' && (
          <p className="traveler__booking-number">
            #{task.taskable.dossier_id && task.taskable.dossier_id}
          </p>
        )}
          {task.taskable && task.taskable_type === 'App\\Article' && (
              <a className="traveler__booking-number" href={`/tips/${task.taskable.id}`}>
                  #{task.taskable.id}
              </a>
          )}
        {!task.taskable && <p className="traveler__booking-number">#</p>}
        {task.traveller ? (
          <>
            <p className="traveler__type">
              {task.traveller.full_name && task.traveller.full_name}
              {!task.traveller.full_name && task.traveller.first_name}{" "}
              {!task.traveller.full_name && task.traveller.last_name}
            </p>
            <p className="traveler__phone-number">
              {task.traveller && task.traveller.phone1}
            </p>
          </>
        ) : (
          <>
            <p className="traveler__type"></p>
            <p className="traveler__phone-number"></p>
          </>
        )}
      </div>
    </div>
  );
};

export default Traveler;
