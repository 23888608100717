import React, { useState, useContext } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { TipsContext } from "../../../store/context/TipsContext";
import GoogleMap from "../../Reusable/GoogleMap/GoogleMap";

function TipGoogleMap({ lat, lng, onBoundsUpdate }) {
  const [selectedTip, setSelectedTip] = useState(null);
  const { state } = useContext(TipsContext);

  const allTips = state.tips.allTips.filter((tip) => tip.lat) || [];
  const google = window.google;

  return (
    <GoogleMap
      defaultZoom={13}
      center={{ lat: lat, lng: lng }}
      onBoundsUpdate={onBoundsUpdate}
    >
      <>
        {(lat && state.filters.location.lat != null && google) && (
          <Marker
            position={{
              lat: lat,
              lng: lng,
            }}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeColor: "#17a3b0"
            }}
          />
        )}

        {(allTips && google) &&
          allTips.map((tip) => {
            return (
              <Marker
                key={tip.id}
                position={{
                  lat: tip.lat,
                  lng: tip.lng,
                }}
                onClick={() => {
                  setSelectedTip(tip);
                }}
                icon={{
                  url: tip.type?.pin || "",
                  scaledSize: new google.maps.Size(65, 65),
                }}
              />
            );
          })}
        {selectedTip && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedTip(null);
            }}
            position={{
              lat: selectedTip.lat,
              lng: selectedTip.lng,
            }}
          >
            <>
              <h5>{selectedTip.title}</h5>
              <p>{selectedTip.body}</p>
            </>
          </InfoWindow>
        )}
      </>
    </GoogleMap>
  );
}

export default TipGoogleMap;
