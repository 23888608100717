import React, { useState, useContext } from "react";
import { ConversationContext } from "../../../../store/context/ConversationContext";
import { Modal, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import SearchInputField from "../../../Reusable/SearchInputField/SearchInputField";
import { AppContext } from "../../../../store/context/AppContext";
import Select from "react-select";

import "./AddItineraryModal.css";

import RectangleButton from "../../../Reusable/RectangleButton/RectangleButton";

const initialState = {
  dossier_id: "",
  departure_date: "",
  return_date: "",
  branch_id: null,
};

function AddItineraryModal({ show, onHide, conversationId }) {
  const { state: ConversationState, actions } = useContext(ConversationContext);
  const [itinerary, setItinerary] = useState(initialState);
  const [dossierId, setDossierId] = useState();
  const [searchedDossierId, setSearchDossierId] = useState(false);
  const { state: AppState } = useContext(AppContext);

  const handleDossierIdInput = (e) => {
    setDossierId(e.target.value);
  };

  const onSearchDossierIdClicked = () => {
    actions.findDossierId(dossierId, ConversationState.currentConversation.branch_id);
    setSearchDossierId(true);
  };

  const addItineraryToConversation = (ItineraryId) => {
    actions.addItineraryToConversation(conversationId, ItineraryId);
    onHide(onHide);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const data = itinerary;

    actions.addNewItinerary(data, conversationId);
    setItinerary(initialState);
  };

  //Change the data
  const onChange = (e) => {
    setItinerary({ ...itinerary, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add itinerary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-itinerary-modal__body">
          <SearchInputField
            className="add-itinerary-modal__input"
            name="dossier_id"
            placeholder="Search booking number"
            value={dossierId}
            onChange={handleDossierIdInput}
            onClickSearch={onSearchDossierIdClicked}
          />
          {ConversationState.searchDossierIdResult.length >= 1 ? (
            <div className="add-itinerary-modal__btn-container">
              {ConversationState.searchDossierIdResult.map((dossierId) => (
                <RectangleButton
                  className="add-itinerary-modal__btn"
                  label={`Add itinerary ${dossierId.dossier_id}`}
                  key={dossierId.id}
                  onClick={() => addItineraryToConversation(dossierId.id)}
                />
              ))}
            </div>
          ) : (
              <>
                {searchedDossierId && (
                  <>
                    <div className="add-itinerary-modal__text">
                      We did not find any matching itineraries, please add a new
                      one
                  </div>

                    <Form
                      className="add-itinerary-modal__form"
                      onSubmit={onFormSubmit}
                    >
                      <Form.Group>
                        <Form.Control
                          className="add-itinerary-modal__form-input"
                          required
                          type="text"
                          value={itinerary.dossier_id}
                          onChange={onChange}
                          name="dossier_id"
                          placeholder="Enter booking number"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          className="add-itinerary-modal__form-input"
                          required
                          type="date"
                          value={itinerary.departure_date}
                          onChange={onChange}
                          name="departure_date"
                          placeholder="Enter departure date (YYYY/MM/DD)"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          className="add-itinerary-modal__form-input"
                          required
                          type="date"
                          value={itinerary.return_date}
                          onChange={onChange}
                          name="return_date"
                          placeholder="Enter return date (YYYY/MM/DD)"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Select
                         className="add-itinerary-modal__form-input"
                          name="branch_id"
                          placeholder="Select branch"
                          options={AppState.inputs.companies}
                          getOptionLabel={(option) => `${option.branch_name}`}
                          getOptionValue={(option) => `${option.id}`}
                          value={itinerary.branch}
                          onChange={(data) =>
                            setItinerary({
                              ...itinerary,
                              branch_id: data.id,
                            })
                          }
                        />
                      </Form.Group>
                      <RectangleButton
                        type="submit"
                        label="Next"
                        className="add-itinerary-modal__submit-btn"
                      />
                    </Form>
                  </>
                )}
              </>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
}

AddItineraryModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  itineraryId: PropTypes.number,
};

export default AddItineraryModal;
