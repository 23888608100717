import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./CircleButton.css";

function CircleButton({ type, onClick, href, className = "", disabled }) {
  const onButtonClick = (e) => {
    e.preventDefault();
    if (onClick) onClick();
  };

  let buttonClass = `circle-button ${className}`;
  var re = new RegExp("^(http|https)://", "i");
  const isInternal = !re.test(href);

  let icon;
  switch (type) {
    case "prev":
      buttonClass += ' circle-button--prev'
      break;
    case "next":
      buttonClass += ' circle-button--next'
      break;
    case "add":
      buttonClass += ' circle-button--add'
      break;
    case "close":
      buttonClass += ' circle-button--close'
    default:
      icon = "x";
  }

  const renderInternalLink = () => {
    return (
      <Link to={href} className={buttonClass} disabled={disabled}>
        {icon}
      </Link>
    );
  };

  const renderLink = () => {
    return (
      <a className={buttonClass} href={href} disabled={disabled}>
        {icon}
      </a>
    );
  };

  const renderButton = () => {
    return (
      <button className={buttonClass} onClick={onButtonClick} disabled={disabled}>
        <span>{icon}</span>
      </button>
    );
  };

  return (
    <>
      {href && isInternal && renderInternalLink()}
      {href && !isInternal && renderLink()}
      {!href && renderButton()}
    </>
  );
}

CircleButton.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.object,
};

export default CircleButton;
