import React, {Component} from "react";
import "../OpenConversations/Itinerary.css";
import ProgressBar from 'react-bootstrap/ProgressBar'
import {Link} from 'react-router-dom';
import {Col, Container, Row} from "react-bootstrap";

class Itinerary extends Component {
    render() {
        let departure_date = new Date(this.props.itinerary.departure_date)
        let current_date = new Date()
        let return_date = new Date(this.props.itinerary.return_date)
        let total = return_date.getTime() - departure_date.getTime();
        let current = current_date.getTime() - departure_date.getTime();
        let now = (current / total) * 100;
        return (
            <div className="itinerary__container">

                <Link to={`/itineraries/${this.props.itinerary.id}`} className="itinerary-link">
                    <Container>
                        <Row>
                            <Col sm={2}>
                                <div className="itinerary__icon"></div>
                            </Col>
                            <Col sm={6}>
                                <div className="itinerary__trip-details">
                                    <p className="itinerary__trip-name">{(this.props.itinerary) && this.props.itinerary.description}</p>
                                    <p className="itinerary__trip-dates">{(this.props.itinerary) && this.props.itinerary.departure_date} / {(this.props.itinerary) && this.props.itinerary.return_date}</p>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="itinerary__progressbar">
                                    <p className="itinerary__dossier_id">#{this.props.itinerary.dossier_id}</p>
                                    <p className="itinerary__dossier_id">{this.props.itinerary.itinerary_type}</p>
                                    <ProgressBar now={now} variant={"itinerary__progressbar"}/>
                                </div>
                                <p className="itinerary-arrow">></p>
                            </Col>
                        </Row>
                    </Container>
                </Link>
            </div>
        );
    }
}

export default Itinerary;
