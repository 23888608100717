import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function SuccessModal({ successMessage, onHide }) {

    return (
        <div className="success-modal">
            <Modal
                show={!!successMessage}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="success-modal__header"
                    >
                        Successful action:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {successMessage}
                </Modal.Body>

            </Modal>
        </div>
    );
}

SuccessModal.propTypes = {
    onHide: PropTypes.func,
  };


export default SuccessModal;