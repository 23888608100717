import React, { useEffect, useContext, useState } from "react";
import { ItineraryContext } from "../../../store/context/ItineraryContext";
import { useParams } from "react-router";
import { DeepClone } from "../../../utils";
import ItineraryHeader from "../ItineraryGroup/ItineraryHeader/ItineraryHeader";
import IndividualItineraryContainer from "./IndividualItineraryContainer/IndividualItineraryContainer";
import ItineraryTaskList from "../ItineraryGroup/ItineraryTaskList/ItineraryTaskList";
import ItineraryActivityLog from "../ItineraryGroup/ItineraryActivityLog/ItineraryActivityLog";
import IndividualTravellerContainer from "./IndividualTravellerContainer/IndividualTravellerContainer";
import IndividualTimeline from "./IndividualTimeline/IndividualTimeline";
import SummaryTravelContainer from "./SummaryTravellerContainer/SummaryTravellerContainer";
import ItineraryAnalysis from "./ItineraryAnalysis/ItineraryAnalysis";
import { AppContext } from "../../../store/context/AppContext";

import "./ItineraryIndividual.css";

import ErrorModal from "../../Reusable/ErrorModal/ErrorModal";

const IndividualItinerary = () => {
  const { state, actions } = useContext(ItineraryContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [areDataContainersVisible, setAreDataContainersVisible] = useState(
    true
  );
  const [selectedDayData, setSelectedDayData] = useState(null);
  const [individualTimelineData, setIndividualTimelineData] = useState({});
  const [formData, setFormData] = useState({});
  const [preferenceFormData, setPreferenceFormData] = useState({});
  const [isEditPreferencesMode, setIsEditPreferencesMode] = useState(false);
  const { pusher } = useContext(AppContext);

  const params = useParams();

  useEffect(() => {
    if (!params.itineraryId || !params.conversationId) return;
    actions.getItineraryTraveller(params.itineraryId, params.conversationId);

    const channel = pusher.subscribe('itinerary');
    channel.bind(process.env.REACT_APP_PUSHER_ITINERARY_EVENT_NAME, data => {
      if (typeof data.update_itinerary != 'undefined' && data.update_itinerary && data.itinerary_id == params.itineraryId) {
        actions.getItineraryTraveller(params.itineraryId, params.conversationId);
        console.log('fetching individual itinerary')
      }
    });

    return () => {
      channel.unbind(process.env.REACT_APP_PUSHER_ITINERARY_EVENT_NAME)
    }
  }, [params]);

  useEffect(() => {
    if (state?.traveller?.data?.id) {
      const clonedIndividualTimelineData = DeepClone(
        state.traveller.data
      );
      setIndividualTimelineData(clonedIndividualTimelineData);
    }
    if (state?.traveller?.data?.id) {
      const clonedIndividualFormData = DeepClone(
        state.traveller.data.conversation.traveller
      );
      setFormData(clonedIndividualFormData);
      const clonedIndividualPreferenceFormData = DeepClone(
        state.traveller.data.conversation
      );
      setPreferenceFormData(clonedIndividualPreferenceFormData);
    }
  }, [state]);

  const onChangeFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const onChangePreferenceFormData = (key, value) => {
    setPreferenceFormData({ ...preferenceFormData, [key]: value });
  };

  const onClickSave = () => {
    processForm();
  };

  const onClickSavePreferences = () => {
    processPreferenceForm();
  };

  const processForm = () => {
    actions.editTravellerDetails(formData, state.traveller.data.id);
    setIsEditMode(false);
  };

  const processPreferenceForm = () => {
    actions.updatePreferencesTraveller(
      preferenceFormData,
      state.traveller.data.id,
      onClickEditPreferences
    );
    setIsEditMode(false);
  };

  const onClickEditJourneyDay = (selectedMessage) => {
    const day = individualTimelineData.days.find((d) => d.day === selectedMessage.day);
    const message = day.messages.find((msg) => msg.journey_scheduled_article_id === selectedMessage.journey_scheduled_article_id);
    const copyMessage = JSON.parse(JSON.stringify(message));
    setSelectedDayData(copyMessage);
    setAreDataContainersVisible(false);
  };

  const onClickNewJourneyDay = (day) => {
    const selectedDay = individualTimelineData.days.find((d) => d.day === day.day);
    setSelectedDayData(selectedDay);
    setAreDataContainersVisible(false);
  }

  const onClickEdit = () => {
    if (isEditMode === false) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  };

  const onClickEditPreferences = () => {
    if (isEditPreferencesMode === false) {
      setIsEditPreferencesMode(true);
    } else {
      setIsEditPreferencesMode(false);
    }
  };

  const closeTemplateDay = () => {
    setAreDataContainersVisible(true);
    setSelectedDayData(null);
  };

  const clearError = () => {
    actions.clearError();
  };

  return (
    <>
      {areDataContainersVisible ? (
        <div className="individual-itinerary">
          <div className="individual-itinerary__header">
            <ItineraryHeader
              isIndividualMode
              isEditMode={isEditMode}
              onClickEdit={onClickEdit}
              onClickSave={onClickSave}
            />
          </div>
          <div className="individual-itinerary__itinerary-container">
            <IndividualItineraryContainer />
          </div>
          <div className="individual-itinerary__travellers-container">
            <IndividualTravellerContainer
              isEditMode={isEditMode}
              isEditPreferencesMode={isEditPreferencesMode}
              formData={formData}
              preferenceFormData={preferenceFormData}
              onChangeFormData={onChangeFormData}
              onChangePreferenceFormData={onChangePreferenceFormData}
              onClickSavePreferences={onClickSavePreferences}
              onClickEditPreferences={onClickEditPreferences}
            />
          </div>
          <div className="individual-itinerary__timeline">
            <IndividualTimeline
              isEditMode={isEditMode}
              selectedDayData={selectedDayData}
              onClickEditJourneyDay={onClickEditJourneyDay}
              individualData={individualTimelineData}
              onClickNewJourneyDay={onClickNewJourneyDay}
            />
          </div>
          <div className="individual-itinerary__task-list">
            <ItineraryTaskList isIndividualMode />
          </div>
          <div className="individual-itinerary__activity-log">
            <ItineraryActivityLog isIndividualMode />
          </div>
          <div className="individual-itinerary__itinerary-analysis">
            <ItineraryAnalysis />
          </div>
        </div>
      ) : (
        <div className="individual-itinerary__template-day-message">
          <div>
            <IndividualTimeline
              isEditMode={isEditMode}
              individualData={individualTimelineData}
              selectedDayData={selectedDayData}
              onClickEditJourneyDay={onClickEditJourneyDay}
              onClickNewJourneyDay={onClickNewJourneyDay}
              closeTemplateDay={closeTemplateDay}
            />
          </div>
          <div className="individual-itinerary__traveller-summary">
            <SummaryTravelContainer />
          </div>
          <ErrorModal errorMessage={state.errors} onHide={clearError} />
        </div>
      )}
    </>
  );
};

export default IndividualItinerary;
