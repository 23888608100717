import React, { useContext, useState } from "react";
import { ItineraryContext } from "../../../../store/context/ItineraryContext";
import Timeline from "../../../Reusable/Timeline/Timeline";
import AddTemplateToItineraryModal from "../AddTemplateToItineraryModal/AddTemplateToItineraryModal";

import "./ItineraryTimeline.css";

import CircleButton from "../../../Reusable/CircleButton/CircleButton";
import PillButton from "../../../Reusable/PillButton/PillButton";
import DataBlock from "../../../Reusable/DataBlock/DataBlock";

const ItineraryTimeline = ({ isEditMode }) => {
  const { state, actions } = useContext(ItineraryContext);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);

  const itineraryData = state.itinerary.data;

  const onClickAddDay = (e) => {
    actions.addDayToCurrentTemplate();
  };

  const onClickRemoveDay = (e) => {
    actions.removeDayFromCurrentTemplate();
  };

  const onClickAddTemplate = () => {
    setShowAddTemplateModal(true);
  };

  const onCloseAddTemplate = () => {
    setShowAddTemplateModal(false);
  };

  return (
    <div className="itinerary-timeline">
      {itineraryData && (
        <>
          <AddTemplateToItineraryModal
            show={showAddTemplateModal}
            onHide={onCloseAddTemplate}
          />
          <DataBlock
            header="TIMELINE"
            content={
              <>
                {!state.itinerary.data.journey ? (
                  <div className="itinerary-timeline__no-template">
                    <CircleButton
                      onClick={onClickAddTemplate}
                      type="add"
                      className="itinerary-timeline__add-btn"
                    />
                  </div>
                ) : (
                    <>
                    <span className="itinerary-timeline__journey-title">{state.itinerary.data.journey && state.itinerary.data.journey.title}</span>
                      {isEditMode && (
                        <div className="itinerary-timeline__btn-container">
                          <PillButton label="Add a day" onClick={onClickAddDay} />
                          <PillButton
                            label="Remove a day"
                            onClick={onClickRemoveDay}
                          />
                          <PillButton
                            label="Change template"
                            onClick={onClickAddTemplate}
                          />
                        </div>
                      )}
                      <Timeline
                        className="itinerary-timeline__timeline"
                        timelineData={state.itinerary.data.journey}
                        departureDate={state.itinerary.data.departure_date}
                        doNotShowMessages={true}
                      />
                    </>
                  )}
              </>
            }
          />
        </>
      )}
    </div>
  );
};

export default ItineraryTimeline;
